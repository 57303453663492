import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import FilterIcon from '../../../assets/filter_icons/setting-4.png';

const ListFilters = ({
  onSearchHandler,
  searchTerm,
  searchOnly,
  toggleFilter,
  filterOptions,
  handlerFilters,
}) => {
  console.log('Filter');
  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()} className="form">
        {searchOnly
          ? (
            <Row className="smalldiv__flexselfcenter" role="presentation">
              <Col md={12} lg={3} className="form-group">
                <input
                  name="searchterm"
                  value={searchTerm}
                  component="input"
                  id="searchterm"
                  onChange={(e) => onSearchHandler(e)}
                  onKeyDown={(e) => onSearchHandler(e)}
                  type="text"
                  title="Search Format: Sph Cyl Add Axis &#013; Example: 0 0.75 - -"
                  placeholder="Search by name..."
                />
              </Col>
              <Col
                md={12}
                lg={3}
                className="form-group smalldiv__flexselfcenter"
                style={{ gap: '5px', display: 'flex' }}
              >
                <img style={{ width: '20px' }} src={FilterIcon} alt="filterIcon" />
                <p
                  style={{
                    cursor: 'pointer',
                    marginTop: '0px',
                    display: 'flex',
                  }}
                  role="presentation"
                  onClick={() => toggleFilter()}
                >
                  Filters
                </p>
              </Col>
            </Row>
          ) : (
            <div className="lensFilter__mainMenu">
              <Row className="lensFilter__options form-group">
                {
                  filterOptions.map((filter) => (
                    <Col className="col smalldiv__samerow" md={2}>
                      <p
                        style={(filter.count || filter.checked) ? { color: '#03a5a5', fontWeight: 600 } : {}}
                        role="presentation"
                        onClick={() => handlerFilters('options', filter.label)}
                      >
                        {filter.label}
                      </p>
                    </Col>
                  ))
                }
                <Col md={2}>
                  <p role="presentation" onClick={() => handlerFilters('resetAll')}>Reset</p>
                </Col>
              </Row>
              <Row className="lensFilter__subOptions  form-group">
                {
                  filterOptions.map((filter) => (
                    filter.checked
                    && (
                      filter.options.map((subFilters) => (
                        <Col md={3} className="smalldiv__flexselfcenter smalldiv__samerow">
                          <input
                            onChange={(e) => handlerFilters('subOptions', subFilters.label, e, filter.label)}
                            name="checkbox"
                            checked={subFilters.checked}
                            type="checkbox"
                            className="checkbox-btn"
                            style={{ width: '15px' }}
                          />
                          <p>{subFilters.label}</p>
                        </Col>
                      ))
                    )
                  ))
                }
              </Row>
            </div>
          )}
      </form>
    </div>
  );
};

ListFilters.propTypes = {
  onSearchHandler: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  searchOnly: PropTypes.bool.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  filterOptions: PropTypes.arrayOf.isRequired,
  handlerFilters: PropTypes.func.isRequired,
};

export default ListFilters;
