function tableHeader(item) {
  let header = [];
  if (item === 'customerorder') {
    header = [{
      title: 'customerOrderId', tableHeader: 'Customer Order ID/Date', type: 'Id', dateKey: 'customerOrderDate',
    },
    {
      title: 'referanceOrderId', tableHeader: 'Reference Order ID/Date', type: 'Id', dateKey: 'referanceOrderDate',
    },
    {
      title: 'customerName', tableHeader: 'Customer Name', type: 'name',
    },
    {
      title: 'singleVision', tableHeader: 'Single Vision', type: 'lensType',
    },
    {
      title: 'bifocalKt', tableHeader: 'Bifocal KT', type: 'lensType',
    },
    {
      title: 'bifocalD', tableHeader: 'Bifocal D', type: 'lensType',
    },
    {
      title: 'cr', tableHeader: 'CR', type: 'lensMetrial',
    },
    {
      title: 'acrylic', tableHeader: 'Acrylic', type: 'lensMetrial',
    },
    {
      title: 'totalQuantity', tableHeader: 'Total Quantity', type: 'total',
    },
    ];
  } else if (item === 'purchase') {
    header = [
      {
        // eslint-disable-next-line max-len
        title: 'orderId', tableHeader: 'Purchase Order ID/Date', type: 'Id', dateKey: 'customerOrderDate',
      },
      {
        title: 'customerOrderId', tableHeader: 'Customer Order ID/Date', type: 'Id', dateKey: 'customerOrderDate',
      },
      {
        title: 'referanceOrderId', tableHeader: 'Reference Order ID/Date', type: 'Id', dateKey: 'referanceOrderDate',
      },
      { title: 'customerName', tableHeader: 'Customer Name', type: 'Name' },
      { title: 'supplier', tableHeader: 'Vendor Name', type: 'Name' },
      {
        title: 'singleVision', tableHeader: 'Single Vision', type: 'lensType',
      },
      {
        title: 'bifocalKt', tableHeader: 'Bifocal KT', type: 'lensType',
      },
      {
        title: 'bifocalD', tableHeader: 'Bifocal D', type: 'lensType',
      },
      {
        title: 'cr', tableHeader: 'CR', type: 'lensMetrial',
      },
      {
        title: 'acrylic', tableHeader: 'Acrylic', type: 'lensMetrial',
      },
      {
        title: 'totalQuantity', tableHeader: 'Total Quantity', type: 'total',
      }];
  } else if (item === 'grn' || item === 'pendingpurchase') {
    header = [
      {
        title: 'grnId', tableHeader: 'GRN ID/Date', type: 'Id', dateKey: 'grnDate',
      },
      {
        title: 'customerOrderId', tableHeader: 'Customer Order ID/Date', type: 'Id', dateKey: 'customerOrderDate',
      },
      {
        title: 'vendorInvoiceId', tableHeader: 'Vendor Invoice ID/Date', type: 'Id', dateKey: 'vendorInvoiceDate',
      },
      {
        title: 'orderId', tableHeader: 'Purchase Order ID/Date', type: 'Id', dateKey: 'orderDate',
      },
      {
        title: 'referanceOrderId', tableHeader: 'Reference Order ID/Date', type: 'Id', dateKey: 'referanceOrderDate',
      },
      { title: 'customerName', tableHeader: 'Customer Name', type: 'Name' },
      { title: 'supplier', tableHeader: 'Vendor Name', type: 'Name' },
      {
        title: 'singleVision', tableHeader: 'Single Vision', type: 'lensType',
      },
      {
        title: 'bifocalKt', tableHeader: 'Bifocal KT', type: 'lensType',
      },
      {
        title: 'bifocalD', tableHeader: 'Bifocal D', type: 'lensType',
      },
      {
        title: 'cr', tableHeader: 'CR', type: 'lensMetrial',
      },
      {
        title: 'acrylic', tableHeader: 'Acrylic', type: 'lensMetrial',
      },
      {
        title: 'totalQuantity', tableHeader: 'Total Quantity', type: 'total',
      }];
  } else if (item === 'return') {
    header = [
      {
        title: 'returnId', tableHeader: 'Return Order ID/Date', type: 'Id', dateKey: 'returnOrderDate',
      },
      {
        title: 'vendorInvoiceId', tableHeader: 'Purchase Order ID/Date', type: 'Id', dateKey: 'vendorInvoiceDate',
      },
      { title: 'supplier', tableHeader: 'Vendor Name', type: 'Name' },
      {
        title: 'singleVision', tableHeader: 'Single Vision', type: 'lensType',
      },
      {
        title: 'bifocalKt', tableHeader: 'Bifocal KT', type: 'lensType',
      },
      {
        title: 'bifocalD', tableHeader: 'Bifocal D', type: 'lensType',
      },
      {
        title: 'cr', tableHeader: 'CR', type: 'lensMetrial',
      },
      {
        title: 'acrylic', tableHeader: 'Acrylic', type: 'lensMetrial',
      },
      {
        title: 'totalQuantity', tableHeader: 'Total Quantity', type: 'total',
      }];
  } else if (item === 'inventory') {
    header = [
      {
        title: 'adjustmentDate', tableHeader: 'Date', type: null,
      },
      // { title: 'LensType', tableHeader: 'LensType', type: 'lens' },
      {
        title: 'singleVision', tableHeader: 'Single Vision', type: 'lensType',
      },
      {
        title: 'bifocalKt', tableHeader: 'Bifocal KT', type: 'lensType',
      },
      {
        title: 'bifocalD', tableHeader: 'Bifocal D', type: 'lensType',
      },
      {
        title: 'cr', tableHeader: 'CR', type: 'lensMetrial',
      },
      {
        title: 'acrylic', tableHeader: 'Acrylic', type: 'lensMetrial',
      },
      {
        title: 'totalQuantity', tableHeader: 'Total Quantity', type: 'total',
      }];
  }
  return (
    header
  );
}

export default tableHeader;
