import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Container, Button,
} from 'reactstrap';
import FullVariantsTable from '../../Tables/BasicTables/components/FullVariantsGrn';

const FullVariants = ({
  tableRowsData,
  variantsHeaderData,
  activePageHandler,
  searchedPo,
  updateVariants,
  viewDetailsMode,
  currentViewDetailsData,
  groupIndex,
}) => {
  console.log(variantsHeaderData);
  return (
    <Container className="addpurchase">
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => activePageHandler('AddGrn')}
      >
        {' < '}
      </Button>
      <Row className="smalldiv__cardsContainer" style={{ marginBottom: '10px' }}>
        <Col md={2} className="smalldiv__samerow">
          <span>LENS TYPE</span>
          {!viewDetailsMode
            && <p style={{ fontWeight: 600 }}>{searchedPo.groups[groupIndex].LensType || ''}</p>}
          {viewDetailsMode
            && <p style={{ fontWeight: 600 }}>{currentViewDetailsData.groups[groupIndex].LensType || ''}</p>}
        </Col>
        <Col md={2} className="smalldiv__samerow">
          <span>LENS MATERIAL</span>
          {!viewDetailsMode
            && <p style={{ fontWeight: 600 }}>{searchedPo.groups[groupIndex].LensMaterial || ''}</p>}
          {viewDetailsMode
            && <p style={{ fontWeight: 600 }}>{currentViewDetailsData.groups[groupIndex].LensMaterial || ''}</p>}
        </Col>
        <Col md={2} className="smalldiv__samerow">
          <span>ORDERED VARIANTS</span>
          {!viewDetailsMode
            && <p style={{ fontWeight: 600 }}>{searchedPo.groups[groupIndex].variants.length || ''}</p>}
          {viewDetailsMode
            && <p style={{ fontWeight: 600 }}>{currentViewDetailsData.groups[groupIndex].variants.length || ''}</p>}
        </Col>
        <Col md={2} className="smalldiv__samerow">
          <span>ORDERED QUANTITY</span>
          {!viewDetailsMode
            && (
              <p style={{ fontWeight: 600 }}>
                {searchedPo.groups[groupIndex].variants.reduce((prev, current) => {
                  const quantity = Number(prev) + Number(current.Quantity);
                  return quantity;
                }, 0)}
              </p>
            )}
          {viewDetailsMode
            && (
              <p style={{ fontWeight: 600 }}>
                {currentViewDetailsData.groups[groupIndex].variants.reduce((prev, current) => {
                  const quantity = Number(prev) + Number(current.Quantity);
                  return quantity;
                }, 0)}
              </p>
            )}
        </Col>
      </Row>
      <FullVariantsTable
        updateVariants={updateVariants}
        groupIndex={groupIndex}
        tableRowsData={tableRowsData}
        tableHeaderData={variantsHeaderData}
        currentViewDetailsData={currentViewDetailsData}
        viewDetailsMode={viewDetailsMode}
        from="GRN"
      />
      <Row style={{ marginTop: '15px' }}>
        <Col md={2} className="form-group">
          <Button
            onClick={() => activePageHandler('AddGrn')}
            className="rounded"
            color="success"
            size="sm"
          >
            Confirm
          </Button>
        </Col>
      </Row>

    </Container>
  );
};

FullVariants.propTypes = {
  variantsHeaderData: PropTypes.arrayOf.isRequired,
  tableRowsData: PropTypes.arrayOf.isRequired,
  searchedPo: PropTypes.arrayOf.isRequired,
  activePageHandler: PropTypes.func.isRequired,
  updateVariants: PropTypes.func.isRequired,
  viewDetailsMode: PropTypes.bool.isRequired,
  currentViewDetailsData: PropTypes.objectOf.isRequired,
  groupIndex: PropTypes.number.isRequired,
};

export default FullVariants;
