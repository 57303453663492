import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import TopbarMenuLink from './TopbarMenuLink';

const TopbarProfile = ({
  userInfo,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };

  const logout = () => {
    localStorage.removeItem('oicToken');
  };

  return (
    <div className="topbar__profile">
      <button type="button" className="topbar__avatar" onClick={setIsCollapsed}>
        <p />
        <div>
          <p className="topbar__avatar-name">{userInfo.userDetails.name}</p>
          <p className="topbar__avatar-name" style={{ marginTop: '5px' }}>{userInfo.userDetails.userType}</p>
        </div>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          type="button"
          aria-label="profile button"
          onClick={toggleProfile}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink
            title="Logout"
            icon="exit"
            path="/"
            onClick={logout}
          />
        </div>
      </Collapse>
    </div>
  );
};

TopbarProfile.propTypes = {
  userInfo: PropTypes.objectOf.isRequired,
};

export default TopbarProfile;
