import React from 'react';
import {
  Button,
  Col,
  Row,
  Container,
} from 'reactstrap';
import PropTypes from 'prop-types';
import BasicTable from '../../Tables/BasicTables/components/ListGrn';

const ListGrn = ({
  grnHeaderData,
  grnRowsData,
  activeModalAction,
  viewDetails,
}) => {
  console.log(grnRowsData);
  return (
    <Container>
      <Row>
        <Col md={12} className="smalldiv__listheader">
          <h4>
            GRN
          </h4>
          <Button
            className="rounded btn-right"
            color="success"
            onClick={() => activeModalAction('EnterPOId')}
          >
            New Receipt
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {
            grnRowsData.isFetching
              ? (
                <div style={{ textAlign: 'center' }}>
                  <div className="spinner-border" style={{ color: '#03a5a5' }}>
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <BasicTable
                  tableHeaderData={grnHeaderData}
                  tableRowsData={grnRowsData.grnDetails}
                  viewDetails={viewDetails}
                />
              )
          }
        </Col>
      </Row>

    </Container>
  );
};

export default ListGrn;

ListGrn.propTypes = {
  grnHeaderData: PropTypes.arrayOf.isRequired,
  grnRowsData: PropTypes.arrayOf.isRequired,
  activeModalAction: PropTypes.func.isRequired,
  viewDetails: PropTypes.func.isRequired,
};
