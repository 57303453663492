import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import Proptypes from 'prop-types';
import { toast } from 'react-toastify';
import ListInventoryTrack from './components/ListInventoryTrack';
import InventoryTrackSummary from './components/InventoryTrackSummary';
import FullVariants from './components/FullVariants';
import {
  fetchInventoryTrack,
} from '../../redux/actions/inventoryTrackActions';
import api from '../../utils/apiClient';

const InventoryTrack = ({
  fetchInventoryTrackAction,
  inventoryTrackData,
}) => {
  const [activePage, setActivePage] = useState('ListInventoryTrackData');
  const [loadingGroupSummary, setLoadingGroupSummary] = useState(false);
  const [currentViewDetailsData, setCurrentViewDetailsData] = useState({});
  const [currentFullVariantsData, setCurrentFullVariantsData] = useState({});

  useEffect(() => {
    fetchInventoryTrackAction();
  }, []);

  useEffect(() => {
    console.log(inventoryTrackData);
  }, [inventoryTrackData]);

  const activePageHandler = (pageName) => {
    setActivePage(pageName);
  };

  const viewGroupSummary = async (data) => {
    /* eslint no-underscore-dangle: 0 */
    setLoadingGroupSummary(true);
    try {
      const response = await api.inventoryTrack.getDetails({
        trackId: data.trackId,
      });
      console.log(response);
      setCurrentViewDetailsData({
        trackId: data.trackId,
        _created_at: data._created_at,
        source: data.source,
        comments: data.comments,
        inventoryGroupedData: response.data.data,
      });
      setLoadingGroupSummary(false);
      activePageHandler('InventoryTrackGroupSummary');
    } catch (err) {
      setLoadingGroupSummary(false);
      toast.error('Unexpected error :(');
    }
  };

  const viewFullVariants = (data) => {
    setActivePage('FullVariants');
    setCurrentFullVariantsData(data);
  };

  return (
    <div className="users">
      {
        activePage === 'ListInventoryTrackData'
        && (
          <Container>
            <Row>
              <Col md={12} className="smalldiv__listheader">
                <h4>
                  Inventory Track
                </h4>
              </Col>
            </Row>
            <Row style={{ placeContent: 'center' }}>
              {
                inventoryTrackData.isFetching
                  ? (
                    <div className="spinner-border" style={{ color: '#03a5a5' }}>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <ListInventoryTrack
                      inventoryTrackData={inventoryTrackData.inventoryTrackDetails}
                      viewGroupSummary={viewGroupSummary}
                    />
                  )
              }
            </Row>
          </Container>
        )
      }

      {
        activePage === 'InventoryTrackGroupSummary'
        && (
          <Container>
            <InventoryTrackSummary
              loadingGroupSummary={loadingGroupSummary}
              currentViewDetailsData={currentViewDetailsData}
              activePageHandler={activePageHandler}
              viewFullVariants={viewFullVariants}
            />
          </Container>
        )
      }

      {
        activePage === 'FullVariants'
        && (
          <Container>
            <FullVariants
              currentFullVariantsData={currentFullVariantsData}
              activePageHandler={activePageHandler}
            />
          </Container>
        )
      }
    </div>
  );
};

InventoryTrack.propTypes = {
  fetchInventoryTrackAction: Proptypes.func.isRequired,
  inventoryTrackData: Proptypes.arrayOf.isRequired,
};

const mapDispatchToProps = {
  fetchInventoryTrackAction: fetchInventoryTrack,
};

const mapStateToProps = (state) => ({
  inventoryTrackData: state.inventoryTrackData,
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryTrack);
