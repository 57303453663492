import { handleActions } from 'redux-actions';
import {
  fetchInventoryRequest,
  fetchInventorySuccess,
  fetchInventoryFailure,
  saveInventoryRequest,
  saveInventorySuccess,
  saveInventoryFailure,
  defaultInventory,
  addToInventory,
  updateFilterInventory,
} from '../actions/inventoryActions';

const defaultState = {
  isSaving: false,
  error: null,
  inventoryDetails: {},
};

const defaultStateFetch = {
  isFetching: false,
  error: null,
  inventoryDetails: [],
  limit: 500,
  skip: 0,
  q: '',
  filter: {},
  noMoreData: false,
};
/* eslint-disable import/prefer-default-export */

const inventorySaveReducer = handleActions(
  {
    [saveInventoryRequest](state) {
      console.log(' saveInventoryRequest ', state);
      return {
        ...state,
        isSaving: true,
      };
    },
    [saveInventorySuccess](state, { payload }) {
      console.log(' saveInventorySuccess ', state);
      return {
        ...state,
        inventoryDetails: payload,
        isSaving: false,
      };
    },
    [defaultInventory](state) {
      return {
        ...state,
        inventoryDetails: [],
      };
    },
    [saveInventoryFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isSaving: false,
      };
    },
  },
  defaultState,
);

const inventoryFetchReducer = handleActions(
  {
    [updateFilterInventory](state, { payload }) {
      console.log(payload);
      return {
        ...state,
        ...payload,
      };
    },
    [fetchInventoryRequest](state) {
      console.log(state);
      return {
        ...state,
        isFetching: true,
      };
    },
    [fetchInventorySuccess](state, { payload }) {
      console.log('fetchSuccess', state);
      console.log('payload', payload);

      const data = {
        ...state,
        limit: state.limit,
        storeId: '',
        offset: state.offset,
        sort: { _created_at: -1 },
        ignoreCategory: ['SUNGLASSES', 'EYEGLASSES', 'CONTACTLENS'],
        isFetching: false,
        noMoreData: false,
      };

      if (payload.merge) {
        data.inventoryDetails = [...state.inventoryDetails, ...payload.inventoryDetails];
      } else {
        data.inventoryDetails = payload.inventoryDetails;
      }

      if (payload.inventoryDetails.length < 500) {
        data.noMoreData = true;
      }

      return data;
    },
    [fetchInventoryFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    },
    [addToInventory](state, { payload }) {
      // const invt = state.inventoryDetails;
      // console.log(invt);
      const pl = payload.map((val) => val.inventory);
      console.log(pl);
      console.log(payload);
      return {
        ...state,
        inventoryDetails: [...pl, ...state.inventoryDetails],
      };
    },
  },
  defaultStateFetch,
);

export { inventoryFetchReducer, inventorySaveReducer };
