import React from 'react';
import {
  Container,
  Col,
  Row,
  Button,
} from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import PurchaseSummary from './PurchaseSummary';

const AddGrn = ({
  onInputChangeHandler,
  // grnForm,
  // searchPo,
  searchedPo,
  activePageHandler,
  submitReceipt,
  grnUpdate,
  grnSave,
  // grId,
  viewDetailsMode,
  currentViewDetailsData,
  viewSpecificDetails,
}) => {
  console.log(currentViewDetailsData);
  return (
    <Container>
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => activePageHandler('ListGrn')}
      >
        {' < '}
      </Button>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{!viewDetailsMode ? 'NEW RECEIPT' : 'RECEIPT DETAILS'}</h3>
        </Col>
      </Row>
      <form onSubmit={(e) => e.preventDefault()} className="form">
        {viewDetailsMode
          && (
            /* eslint no-underscore-dangle: 0 */
            <Row className="smalldiv__cardsContainer">
              <Col md={2} className="smalldiv__samerow form-group">
                <span>GRN ID/DATE</span>
                <p style={{ fontWeight: 600 }}>
                  {currentViewDetailsData.grId}
                  /
                  {moment(currentViewDetailsData._created_at).format('DD MMM YYYY')}
                </p>
              </Col>
              <Col md={2} className="smalldiv__samerow form-group">
                <span>CUSTOMER ORDER ID/DATE</span>
                <p style={{ fontWeight: 600 }}>
                  {currentViewDetailsData.sysCustomerOrderId}
                  /
                  {moment(currentViewDetailsData.sysCustomerOrderDate).format('DD MMM YYYY')}
                </p>
              </Col>
              <Col md={2} className="smalldiv__samerow form-group">
                <span>VENDOR INVOICE ID/DATE</span>
                <p style={{ fontWeight: 600 }}>
                  {currentViewDetailsData.vendorInvoiceId}
                  /
                  {moment(currentViewDetailsData.vendorInvoiceDate).format('DD MMM YYYY')}
                </p>
              </Col>
              <Col md={2} className="smalldiv__samerow form-group">
                <span>REFERENCE ORDER ID/DATE</span>
                <p style={{ fontWeight: 600 }}>
                  {currentViewDetailsData.customerOrderId}
                  /
                  {moment(currentViewDetailsData.customerOrderDate).format('DD MMM YYYY')}
                </p>
              </Col>
              <Col md={2} className="smalldiv__samerow form-group">
                <span>PURCHASE ORDER ID/DATE</span>
                <p style={{ fontWeight: 600 }}>
                  {currentViewDetailsData.poId}
                  /
                  {moment(currentViewDetailsData.poDate).format('DD MMM YYYY')}
                </p>
              </Col>
            </Row>
          )}
        {!viewDetailsMode
          && (
            /* eslint no-underscore-dangle: 0 */
            <Row className="smalldiv__cardsContainer">
              <Col md={2} className="smalldiv__samerow form-group">
                <span>CUSTOMER ORDER ID/DATE</span>
                <p style={{ fontWeight: 600 }}>
                  {searchedPo.sysCustomerOrderId}
                  /
                  {moment(searchedPo.sysCustomerOrderDate).format('DD MMM YYYY')}
                </p>
              </Col>
              <Col md={2} className="smalldiv__samerow form-group">
                <span>CUSTOMER NAME</span>
                <p style={{ fontWeight: 600 }}>{searchedPo.customerName}</p>
              </Col>
              <Col md={2} className="smalldiv__samerow form-group">
                <span>REFERENCE ORDER ID/DATE</span>
                <p style={{ fontWeight: 600 }}>
                  {searchedPo.customerOrderId}
                  /
                  {moment(searchedPo.customerOrderDate).format('DD MMM YYYY')}
                </p>
              </Col>
              <Col md={2} className="smalldiv__samerow form-group">
                <span>PURCHASE ORDER ID/DATE</span>
                <p style={{ fontWeight: 600 }}>
                  {searchedPo.orderId}
                  /
                  {moment(searchedPo.poDate).format('DD MMM YYYY')}
                </p>
              </Col>
            </Row>
          )}
        <React.Fragment>
          {!viewDetailsMode && (
            <Row>
              <Col md={3} className="form-group">
                <label htmlFor="searchPo">VENDOR INVOICE ID *</label>
                <input
                  name="invoiceId"
                  component="input"
                  type="text"
                  onChange={(e) => onInputChangeHandler(e, 'vendorInvoiceId', 'textInput', 'searchedPo')}
                  value={viewDetailsMode ? currentViewDetailsData.vendorInvoiceId : searchedPo.vendorInvoiceId}
                  placeholder="type here..."
                />
              </Col>
              <Col md={3}>
                <label htmlFor="invoiceDate">VENDOR INVOICE DATE *</label>
                <input
                  name="invoiceDate"
                  component="input"
                  type="date"
                  onChange={(e) => onInputChangeHandler(e, 'vendorInvoiceDate', 'textInput', 'searchedPo')}
                  value={viewDetailsMode
                    ? currentViewDetailsData.vendorInvoiceDate
                    : searchedPo.vendorInvoiceDate}
                  placeholder="type here..."
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col md={12}>
              <h4 className="page-subhead">Purchase Summary</h4>
            </Col>
          </Row>
          <PurchaseSummary
            searchedPo={searchedPo}
            currentViewDetailsData={currentViewDetailsData}
            viewDetailsMode={viewDetailsMode}
            viewSpecificDetails={viewSpecificDetails}
          />

          <Row style={{ marginTop: '15px' }}>
            {!viewDetailsMode
              && (
                <Col md={2} className="form-group">
                  <Button
                    disabled={grnSave.isSaving && true}
                    onClick={() => submitReceipt('AddGrn')}
                    className="rounded"
                    color="success"
                    size="sm"
                  >
                    Confirm Receipt
                  </Button>
                </Col>
              )}

            {viewDetailsMode
              && (
                <Col md={2} className="form-group">
                  <Button
                    onClick={() => submitReceipt('AddGrn')}
                    disabled={grnUpdate.isUpdating}
                    className="rounded"
                    color="success"
                    size="sm"
                  >
                    Update Receipt
                  </Button>
                </Col>
              )}
          </Row>
        </React.Fragment>
      </form>
    </Container>
  );
};

AddGrn.propTypes = {
  onInputChangeHandler: PropTypes.func.isRequired,
  // grnForm: PropTypes.arrayOf.isRequired,
  // searchPo: PropTypes.arrayOf.isRequired,
  searchedPo: PropTypes.objectOf.isRequired,
  activePageHandler: PropTypes.func.isRequired,
  submitReceipt: PropTypes.func.isRequired,
  grnSave: PropTypes.objectOf.isRequired,
  grnUpdate: PropTypes.objectOf.isRequired,
  // grId: PropTypes.number.isRequired,
  currentViewDetailsData: PropTypes.objectOf.isRequired,
  viewDetailsMode: PropTypes.bool.isRequired,
  viewSpecificDetails: PropTypes.func.isRequired,
};

export default AddGrn;
