import { handleActions } from 'redux-actions';
import { fetchMasterDataRequest, fetchMasterDataSuccess, fetchMasterDataFailure } from '../actions/masterActions';

const defaultState = {
  masterDetails: [],
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [fetchMasterDataRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchMasterDataSuccess](state, { payload }) {
      return {
        ...state,
        masterDetails: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchMasterDataFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState,
);
