import React from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import ListCheckoutTable from '../../Tables/BasicTables/components/ListCheckout';

const ListCheckout = ({
  checkoutDataState,
  viewDetails,
}) => {
  console.log('Checkout List Component');
  console.log(checkoutDataState);
  return (
    <Container>
      <ListCheckoutTable
        tableRowsData={checkoutDataState.checkoutDetails}
        viewDetails={viewDetails}
      />
    </Container>
  );
};

export default ListCheckout;

ListCheckout.propTypes = {
  checkoutDataState: PropTypes.arrayOf.isRequired,
  viewDetails: PropTypes.func.isRequired,
};
