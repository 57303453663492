import { get } from './base/index';

export default {
  get: (data = {}) => {
    const params = { ...data };
    return get(
      `${process.env.REACT_APP_API_URL}v2/inventory/client/wreports/customerorder`,
      { params },
    );
  },
  eod: (data = {}) => {
    const params = { ...data };
    return get(
      `${process.env.REACT_APP_API_URL}v2/inventory/client/wreports/eod`,
      { params },
    );
  },
};
