import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from '../../redux/actions/sidebarActions';
import { SidebarProps } from '../../shared/prop-types/ReducerProps';
// import {
// fetchAccount,
//   fetchCurrentUser,
// } from '../../redux/actions/accountActions';
// import { getToken, decodeJWTToken } from '../../utils/helpers';

const Layout = ({
  sidebar,
  // fetchAccountAction,
  // fetchCurrentUserAction,
  // accountState,
  changeMobileSidebarVisibilityAction,
  changeSidebarVisibilityAction,
  fetchCurrentUserState,
}) => {
  const layoutClass = classNames({
    layout: true,
    'layout--collapse': sidebar.collapse,
  });

  const sidebarVisibility = () => {
    changeSidebarVisibilityAction();
  };

  const mobileSidebarVisibility = () => {
    changeMobileSidebarVisibilityAction();
  };

  // useEffect(() => {
  // if (
  //   !accountState.error
  //   && accountState.accountDetails
  //   && Object.keys(accountState.accountDetails).length === 0
  // ) {
  //   fetchAccountAction();
  // }

  // const headerTokens = getToken();
  // const user = decodeJWTToken(headerTokens['oic-authorization']);

  // if (
  //   !fetchCurrentUserState.error
  //   && fetchCurrentUserState.userDetails
  //   && Object.keys(fetchCurrentUserState.userDetails).length === 0
  // ) {
  //   fetchCurrentUserAction(user);
  // }
  // }, []);

  return (
    <div className={layoutClass}>
      <Topbar
        changeMobileSidebarVisibility={mobileSidebarVisibility}
        changeSidebarVisibility={sidebarVisibility}
        // accountInfo={accountState}
        userInfo={fetchCurrentUserState}
      />
      <Sidebar
        userInfo={fetchCurrentUserState}
        sidebar={sidebar}
        changeMobileSidebarVisibility={mobileSidebarVisibility}
      />
    </div>
  );
};

Layout.propTypes = {
  sidebar: SidebarProps.isRequired,
  // accountState: PropTypes.objectOf.isRequired,
  // fetchAccountAction: PropTypes.func.isRequired,
  changeSidebarVisibilityAction: PropTypes.func.isRequired,
  changeMobileSidebarVisibilityAction: PropTypes.func.isRequired,
  // fetchCurrentUserAction: PropTypes.func.isRequired,
  fetchCurrentUserState: PropTypes.objectOf.isRequired,
};

const mapDispatchToProps = {
  // fetchAccountAction: fetchAccount,
  changeSidebarVisibilityAction: changeSidebarVisibility,
  changeMobileSidebarVisibilityAction: changeMobileSidebarVisibility,
  // fetchCurrentUserAction: fetchCurrentUser,
};

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  // accountState: state.account,
  fetchCurrentUserState: state.fetchCurrentUser,
});

export default withRouter((connect(mapStateToProps, mapDispatchToProps))(Layout));
