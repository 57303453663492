import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import csvjson from 'csvjson';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Row,
  Col,
  Button,
  Container,
} from 'reactstrap';
import S3 from 'react-aws-s3';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import AddOrder from './components/AddOrder';
import ListOrder from './components/ListOrder';
import MaterialSummary from './components/MaterialSummary';
import {
  saveCustomerOrder,
  fetchCustomerOrder,
  saveNewPoCustomerOrder,
  cancelCustomerOrder,
} from '../../redux/actions/customerOrderActions';
import Modal from '../../shared/components/modal/Modal';
import { fetchCustomer } from '../../redux/actions/customerActions';
import lensPower from '../../utils/commonFunctions/lensPower';
import { customerOrderSummaryGeneration } from '../../utils/commonFunctions/summaryGeneration';

const config = {
  bucketName: process.env.REACT_APP_AWS_BUCKET,
  dirName: 'lofty',
  region: 'ap-south-1',
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};

const ReactS3Client = new S3(config);

const CustomerOrder = ({
  saveCustomerOrderAction,
  saveCustomerOrderState,
  // fetchVendorsAction,
  // vendor,
  saveNewPoCustomerOrderAction,
  saveNewPoCustomerOrderState,
  fetchCustomerOrderAction,
  customerOrderData,
  fetchCustomerAction,
  cancelCustomerOrderAction,
  customerDataState,
  saveCancelState,
}) => {
  const [customerInfo, setCustomerInfo] = useState({});
  const [totalOrders, setTotalOrders] = useState(0);
  const [activePage, setActivePage] = useState('ListOrder');
  const [customerOptions, setCustomerOptions] = useState([]);
  const [consolidatedOrderData, setConsolidatedOrderData] = useState({});
  const [fileProcessing, setFileProcessing] = useState(false);
  const [activeModal, setActiveModal] = useState('');
  const [prescriptionFilters, setPrescriptionFilters] = useState({});
  // const [vendorOptions, setVendorOptions] = useState([]);
  const [lensPowers, setLensPowers] = useState({});
  const [selectedPurchaseList, setSelectedPurchaseList] = useState(0);
  const [totalPurchaseListSelectedQuantity, setTotalPurchaseListSelectedQuantity] = useState(0);
  const [viewDetailsMode, setViewDetailsMode] = useState(false);
  const [cancelFormData, setCancelFormData] = useState([]);

  const [summary, setSummary] = useState({
    inventories: [],
  });
  const history = useHistory();

  const onInputChangeHandler = (e, fieldName, type) => {
    let fieldValue;

    if (type === 'select') {
      fieldValue = e;
    }

    if (type === 'textInput') {
      fieldValue = e.target.value;
    }

    const customerInfoSetter = { ...customerInfo, [fieldName]: fieldValue };
    setCustomerInfo(customerInfoSetter);
    const cancelFormDataSetter = { ...cancelFormData, [fieldName]: fieldValue };
    setCancelFormData(cancelFormDataSetter);
  };

  useEffect(() => {
    const lensPowerHolder = { ...lensPowers, ...lensPower() };
    setLensPowers(lensPowerHolder);
    fetchCustomerOrderAction(customerOrderData);
    // fetchVendorsAction(vendor);
    fetchCustomerAction(customerDataState);
  }, []);

  useEffect(() => {
    const customerDetailsLocal = _.cloneDeep(customerDataState.customerDetails);

    if (customerDetailsLocal) {
      const customerOptionsLocal = [];
      customerDetailsLocal.map((customer) => {
        customerOptionsLocal.push({
          label: customer.customerName,
          value: customer.customerName,
        });
        return 1;
      });
      setCustomerOptions(customerOptionsLocal);
    }
  }, [customerDataState]);

  // useEffect(() => {
  //   console.log(vendor);
  //   if (!vendor.error && vendor.vendorDetails) {
  //     const vendorsList = [];
  //     let tempVendor;
  //     const vendorLocal = _.cloneDeep(vendor.vendorDetails);

  //     vendorLocal.map((vendorInfo) => {
  //       tempVendor = vendorInfo;
  //       const optionBrands = [];

  //       if (vendorInfo.brand && vendorInfo.brand.length > 0) {
  //         vendorInfo.brand.map((brandObj) => {
  //           optionBrands.push({
  //             label: brandObj,
  //             value: brandObj,
  //           });
  //           return 1;
  //         });

  //         tempVendor.brand = optionBrands;
  //       }

  //       console.log(tempVendor);
  //       vendorsList.push({
  //         label: tempVendor.buisnessName,
  //         value: tempVendor,
  //       });
  //       return 1;
  //     });

  //     setVendorOptions(vendorsList);
  //   }

  //   if (vendor.error) {
  //     console.log('Unable to get vendors!');
  //   }
  // }, [vendor]);

  const csvToJSON = (csv) => {
    setFileProcessing(false);
    const lines = csv.split('\n');
    const incomingHeaders = lines[0].split(',');

    const newIncomingHeaders = [];

    incomingHeaders.map((header) => {
      let temp = header;

      temp = temp.replace('\r', '');

      newIncomingHeaders.push(temp);
      return 1;
    });

    const requirementHeaders = [
      'District',
      'Age',
      'Gender',
      'LensType',
      'LensMaterial',
      'R_SPH',
      'L_SPH',
      'R_CYL',
      'L_CYL',
      'R_ADD',
      'L_ADD',
      'R_AXIS',
      'L_AXIS',
      'FrameModel',
      'FrameColor',
      'FrameSize',
    ];

    let fieldCount = 0;

    requirementHeaders.map((reqHeader) => {
      if (newIncomingHeaders.includes(reqHeader)) {
        fieldCount += 1;
      }
      return 1;
    });

    if (requirementHeaders.length !== fieldCount) {
      toast.error('Template not matched!');
      return 1;
    }

    const jsonOrder = csvjson.toSchemaObject(csv);

    setTotalOrders(jsonOrder.length);

    let formatOrder = [];

    let prescriptionsError = '';
    let lensError = '';

    console.log(jsonOrder);

    jsonOrder.map((order, index) => {
      if ((order.L_SPH === '') || (order.L_CYL === '') || (order.L_ADD === '') || (order.L_AXIS === '')
        || (order.R_SPH === '') || (order.R_CYL === '') || (order.R_ADD === '') || (order.R_AXIS === '')) {
        console.log(order);
        prescriptionsError += `${index} `;
      }

      console.log(order.LensMaterial);
      if ((order.LensMaterial === '') || (order.LensType === '')) {
        console.log(index);
        lensError += `${index} `;
      }

      const baseOrder = {
        district: order.District,
        age: order.Age,
        gender: order.Gender,
      };

      const frameDetails = {
        LensType: order.LensType,
        LensMaterial: order.LensMaterial,
        FrameModel: order.FrameModel,
        FrameColor: order.FrameColor,
        SizeActual: order.FrameSize,
        Category: 'LENS',
      };

      const prescription = [{
        Eye: 'LE',
        Spherical: order.L_SPH,
        Cylindrical: order.L_CYL,
        Add: order.L_ADD,
        Axis: order.L_AXIS,
      }, {
        Eye: 'RE',
        Spherical: order.R_SPH,
        Cylindrical: order.R_CYL,
        Add: order.R_ADD,
        Axis: order.R_AXIS,
      }];

      const inventoriesData = {
        ...frameDetails,
        Prescription: prescription,
      };

      const orderData = {
        ...baseOrder,
        inventories: inventoriesData,
      };

      formatOrder = [...formatOrder, orderData];
      return 1;
    });

    if (prescriptionsError || lensError) {
      return toast.error(`Empty prescription in line nos: ${prescriptionsError}
        \nEmpty lens informations in line nos: ${lensError}`);
    }

    if (!customerInfo.customerName
      || !customerInfo.customerOrderId
      || !customerInfo.customerOrderDate
      || !customerInfo.customerEmailId
    ) {
      toast.error('Please fill the mandatory fields!');
      return 1;
    }

    const formedData = {
      order: formatOrder,
      customerName: customerInfo.customerName.value,
      customerEmailId: customerInfo.customerEmailId.value,
      customerOrderId: customerInfo.customerOrderId,
      customerOrderDate: customerInfo.customerOrderDate,
      comments: customerInfo.comments,
    };

    saveCustomerOrderAction(formedData);

    return 1;
  };

  const handleImport = (files) => {
    setFileProcessing(true);
    ReactS3Client
      .uploadFile(files[0], `customerorders/${customerInfo.customerOrderId}`)
      .then((data) => console.log(data))
      .catch((err) => console.error(err));

    const reader = new FileReader();
    reader.readAsText(files[0]);
    reader.onload = () => {
      const text = reader.result;
      csvToJSON(text);
    };
  };

  const activePageHandler = (pageName) => {
    setActivePage(pageName);

    if (pageName === 'ListOrder') {
      setSelectedPurchaseList(0);
    }
  };

  const consolidatedDataHandler = (e, index, type, name) => {
    const consolidatedOrderDataLocal = _.cloneDeep(consolidatedOrderData);

    if (type === 'checkbox') {
      let selectedPurchaseListLocal = selectedPurchaseList;
      if (index === 'All') {
        if (e.target.checked) {
          selectedPurchaseListLocal = 0;
        }
        consolidatedOrderDataLocal.inventories.map((inventory, index1) => {
          if (inventory.status === 'Pending'
            && !inventory.omitted
            && inventory.PurchaseQuantity > 0
            && activePage === 'ViewPurchase') {
            consolidatedOrderDataLocal.inventories[index1].checked = e.target.checked;
            if (e.target.checked) {
              selectedPurchaseListLocal += 1;
            } else {
              selectedPurchaseListLocal -= 1;
            }
          }
          return 1;
        });
      } else {
        consolidatedOrderDataLocal.inventories[index].checked = e.target.checked;
        if (e.target.checked) {
          selectedPurchaseListLocal += 1;
        } else {
          selectedPurchaseListLocal -= 1;
        }
      }
      setSelectedPurchaseList(selectedPurchaseListLocal);
    }

    if (type === 'textInput') {
      consolidatedOrderDataLocal.inventories[index][name] = e.target.value;
    }

    if (type === 'select') {
      consolidatedOrderDataLocal[name] = e;
    }

    setConsolidatedOrderData(consolidatedOrderDataLocal);
  };

  useEffect(() => {
    if (consolidatedOrderData.inventories) {
      setSummary(customerOrderSummaryGeneration(consolidatedOrderData.inventories));
    }
  }, [consolidatedOrderData]);

  const activeModalAction = (action, close) => {
    if (action === 'GroupSummary') {
      activePageHandler(action);
      setCustomerInfo({});
    }

    if (action === 'BackToPurchaseList') {
      history.push({
        pathname: '/purchase/orders',
      });
    }
    if (action === 'BacktoCustomerOrder') {
      history.push({
        pathname: '/production/orders',
      });
    }
    if (close) {
      setActiveModal('');
      setSelectedPurchaseList(0);
    } else {
      setActiveModal(action);
    }
  };

  useEffect(() => {
    console.log(saveCustomerOrderState.customerOrderDetails);
    if (!saveCustomerOrderState.error
      && !saveCustomerOrderState.isSaving
      && saveCustomerOrderState.customerOrderDetails
      && Object.keys(saveCustomerOrderState.customerOrderDetails).length > 0) {
      setConsolidatedOrderData(saveCustomerOrderState.customerOrderDetails);
      activeModalAction('OrderImportSuccess');
    }
  }, [saveCustomerOrderState]);

  const prescriptionFiltersHandler = (value, name) => {
    const prescriptionFiltersLocal = { ...prescriptionFilters, [name]: value };

    setPrescriptionFilters(prescriptionFiltersLocal);
  };

  const filterPurchaseList = (finalFilter) => {
    const consolidatedOrderDataLocal = _.cloneDeep(consolidatedOrderData);

    consolidatedOrderDataLocal.inventories.map((inventory, index) => {
      console.log(Number(inventory.Spherical), finalFilter.startSph);
      if ((finalFilter.startSph !== undefined ? (Number(inventory.Spherical) >= finalFilter.startSph) : true)
        && (finalFilter.endSph !== undefined ? (Number(inventory.Spherical) <= finalFilter.endSph) : true)
        && (finalFilter.startCyl !== undefined ? (Number(inventory.Cylindrical) >= finalFilter.startCyl) : true)
        && (finalFilter.endCyl !== undefined ? (Number(inventory.Cylindrical) <= finalFilter.endCyl) : true)
        && (finalFilter.startAdd !== undefined ? (Number(inventory.Add) >= finalFilter.startAdd) : true)
        && (finalFilter.endAdd !== undefined ? (Number(inventory.Add) <= finalFilter.endAdd) : true)
      ) {
        consolidatedOrderDataLocal.inventories[index].omitted = false;
      } else {
        consolidatedOrderDataLocal.inventories[index].omitted = true;
      }
      return 1;
    });

    setConsolidatedOrderData(consolidatedOrderDataLocal);
  };

  const applyPrescriptionFilter = () => {
    const prescriptionFiltersLocal = { ...prescriptionFilters };

    const keys = Object.keys(prescriptionFiltersLocal);

    const finalFilter = {};

    keys.map((key) => {
      finalFilter[key] = prescriptionFiltersLocal[key].value;
      return 1;
    });

    console.log(finalFilter);
    filterPurchaseList(finalFilter);
  };

  const resetPrescriptionFilter = () => {
    setPrescriptionFilters({});

    const consolidatedOrderDataLocal = _.cloneDeep(consolidatedOrderData);

    consolidatedOrderDataLocal.inventories.map((inventory, index) => {
      consolidatedOrderDataLocal.inventories[index].omitted = false;
      return 1;
    });

    setConsolidatedOrderData(consolidatedOrderDataLocal);
  };

  const afterPurchaseOrder = () => {
    setActiveModal('PurchaseOrderSuccess');
    const consolidatedOrderDataLocal = _.cloneDeep(consolidatedOrderData);
    const newInventories = [];

    consolidatedOrderDataLocal.inventories.map((inventory) => {
      if (!inventory.checked) {
        newInventories.push(inventory);
      }
      return 1;
    });

    consolidatedOrderDataLocal.inventories = newInventories;

    setConsolidatedOrderData(consolidatedOrderDataLocal);
  };

  useEffect(() => {
    console.log(saveNewPoCustomerOrderState);
    if (!saveNewPoCustomerOrderState.error
      && !saveNewPoCustomerOrderState.isSaving
      && saveNewPoCustomerOrderState.purchaseOrderDetails
      && saveNewPoCustomerOrderState.purchaseOrderDetails.message) {
      if (saveNewPoCustomerOrderState.purchaseOrderDetails.message === 'success') {
        afterPurchaseOrder();
      } else {
        toast.error('Unexpected Error:(');
      }
    }

    if (saveNewPoCustomerOrderState.error) {
      toast.error('Unexpected Error:(');
    }
  }, [saveNewPoCustomerOrderState]);

  const saveNewPurchase = () => {
    const consolidatedOrderDataLocal = _.cloneDeep(consolidatedOrderData);
    // console.log(consolidatedOrderDataLocal.inventories[0]);
    // if (!consolidatedOrderDataLocal.vendorName) {
    //   toast.error('Please select vendor!');
    //   return 1;
    // }

    let totalQuantityLocal = 0;
    consolidatedOrderDataLocal.inventories.map((inventory) => {
      if (inventory.checked) {
        totalQuantityLocal += Number(inventory.PurchaseQuantity);
      }
      return 1;
    });

    setTotalPurchaseListSelectedQuantity(totalQuantityLocal);

    if (selectedPurchaseList === 0) {
      setActiveModal('');
      toast.error('Please select atleast 1 inventory!');
      return 1;
    }

    if (totalQuantityLocal === 0) {
      setActiveModal('');
      toast.error('Quantity should not be empty!');
      return 1;
    }

    /* eslint no-underscore-dangle: 0 */

    const finalInventories = [];
    consolidatedOrderDataLocal.inventories.map((inventory) => {
      if (inventory.checked) {
        const temp = inventory;
        temp.Category = 'LENS';
        temp.ItemCode = '';
        temp.ProductName = '';

        if (inventory.Spherical) {
          temp.ItemCode += `${inventory.Spherical} `;
        } else {
          temp.ItemCode += '0 ';
        }

        if (inventory.Cylindrical) {
          temp.ItemCode += `${inventory.Cylindrical} `;
        } else {
          temp.ItemCode += '0 ';
        }

        if (inventory.Add) {
          temp.ItemCode += `${inventory.Add} `;
        } else {
          temp.ItemCode += '0 ';
        }

        if (inventory.Axis) {
          temp.ItemCode += `${inventory.Axis}`;
        } else {
          temp.ItemCode += '0';
        }

        if (inventory.LensType) {
          temp.ProductName += `${inventory.LensType} `;
        }

        if (inventory.LensMaterial) {
          temp.ProductName += `${inventory.LensMaterial} `;
        }

        if (inventory.ItemCode) {
          temp.ProductName += `${inventory.ItemCode} `;
        }

        temp.ProductName += temp.Category;
        finalInventories.push(temp);
      }
      return 1;
    });

    const formedData = {};
    formedData.customerName = consolidatedOrderDataLocal.customerName;
    formedData.customerOrderDate = consolidatedOrderDataLocal.customerOrderDate;
    formedData.customerOrderId = consolidatedOrderDataLocal.customerOrderId;
    formedData.customerEmailId = consolidatedOrderDataLocal.customerEmailId;
    formedData.sysCustomerOrderId = consolidatedOrderDataLocal.orderId;
    formedData.sysCustomerOrderDate = consolidatedOrderDataLocal._created_at;
    formedData.variants = finalInventories;
    // formedData.supplier = consolidatedOrderDataLocal.vendorName.label;
    // formedData.supplierId = consolidatedOrderDataLocal.vendorName.value._id;
    console.log(formedData);

    saveNewPoCustomerOrderAction(formedData);
    return 1;
  };
  const viewDetails = (data) => {
    console.log(data);
    setConsolidatedOrderData(data);
    setSummary(customerOrderSummaryGeneration(data.inventories));
    setViewDetailsMode(true);
    activePageHandler('GroupSummary');
  };
  const saveCancelFunc = () => {
    const cancelDatas = { ...consolidatedOrderData, ...cancelFormData };
    setCancelFormData({});
    if (cancelDatas.status === 'Processed'
     || cancelDatas.status === 'Active'
     || cancelDatas.status === 'Shipped') {
      toast.error('Can not cancel order!');
      return 1;
    }
    cancelCustomerOrderAction(cancelDatas);
    return 1;
  };
  useEffect(() => {
    console.log(saveCancelState);
    if (!saveCancelState.error
      && !saveCancelState.isSaving
      && saveCancelState.customerOrderDetails
      && saveCancelState.customerOrderDetails.message) {
      if (saveCancelState.customerOrderDetails.message === 'success') {
        setActiveModal('CancelSuccess');
      } else {
        toast.error('Unexpected Error:(');
      }
    }

    if (saveCancelState.error) {
      toast.error('Unexpected Error:(');
    }
  }, [saveCancelState]);

  return (
    <div className="customerorder">
      {
        activeModal === 'OrderImportSuccess'
        && (
          <Modal
            modalStatus={activeModal === 'OrderImportSuccess'}
            modalAction={() => activeModalAction('BackToPurchaseList', 'close')}
            color="success"
            title="Well Done!"
            btn="Success"
            message={(
              activePage === 'AddOrder'
              && (
                <div>Orders Imported Successfully! <br /><br />
                  <span>Total Orders: <b>{totalOrders}</b></span><br />
                  <Row style={{ marginTop: '15px' }}>
                    <Col md="6">
                      <Button
                        outline
                        type="button"
                        className="rounded"
                        color="success"
                        onClick={() => activeModalAction('BackToPurchaseList', 'close')}
                      >
                        View Purchase
                      </Button>
                    </Col>
                    <Col md="6">
                      <Button
                        type="button"
                        className="rounded"
                        color="success"
                        onClick={() => activeModalAction('GroupSummary', 'close')}
                      >
                        Group Summary
                      </Button>
                    </Col>
                  </Row>
                </div>
              )

            )}
          />
        )
      }
      {
        activeModal === 'PurchaseOrderSuccess'
        && (
          <Modal
            modalStatus={activeModal === 'PurchaseOrderSuccess'}
            modalAction={() => activeModalAction('', 'close')}
            color="success"
            title="Well Done!"
            btn="Success"
            message={(
              <div>
                <div>Purchase Order Generated Successfully! <br /><br />
                  <span>Total Variants: <b>{selectedPurchaseList}</b></span><br />
                  <span>Total Quantity: <b>{totalPurchaseListSelectedQuantity}</b></span>
                </div>
                <Button
                  style={{ marginTop: '10px' }}
                  type="button btn-sm"
                  className="rounded"
                  color="success"
                  onClick={() => activeModalAction('BackToPurchaseList', 'close')}
                >
                  View Purchase List
                </Button>
              </div>
            )}
          />
        )
      }

      {
        activeModal === 'AddCancel'
        && (
          <Modal
            modalStatus={activeModal === 'AddCancel'}
            header
            title="Cancel Order"
            color="success"
            modalAction={() => activeModalAction('BacktoCustomerOrder', 'close')}
            message={(
              <>
                <form onSubmit={(e) => e.preventDefault()} className="form">
                  <Row style={{ alignItems: 'center' }}>
                    <Col md={6} className="form-group">
                      <label htmlFor="sph" className="reason-for-cancelation">Reason for Cancellation</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="form-group">
                      <div>
                        <input
                          style={{
                            width: '390px',
                            height: '119px',
                            margin: '6px 73.9px 21px 0',
                            padding: '18px 28px 16px 22px',
                            border: 'solid 1px #d8d8d8',
                            backgroundcolor: '#fff',
                          }}
                          onChange={(e) => onInputChangeHandler(e, 'cancelReason', 'textInput')}
                          name="cancelReason"
                          component="input"
                          value={cancelFormData.cancelReason}
                          type="text"
                        />
                      </div>
                    </Col>
                  </Row>
                </form>
                <Button
                  style={{ marginTop: '15px', alignItems: 'center' }}
                  type="button"
                  className="rounded btn-sm"
                  color="success"
                  disabled={saveCancelState.isSaving}
                  onClick={() => {
                    saveCancelFunc(consolidatedOrderData);
                    activeModalAction('BacktoCustomerOrder', 'close');
                  }}
                >
                  Confirm
                </Button>
              </>
            )}
          />
        )
      }

      {
        activeModal === 'SelectPrescription'
        && (
          <Modal
            modalStatus={activeModal === 'SelectPrescription'}
            header
            title="Filter"
            color="success"
            modalAction={() => activeModalAction('', 'close')}
            message={(
              <div>
                <form onSubmit={(e) => e.preventDefault()} className="form">
                  <Row style={{ alignItems: 'center' }}>
                    <Col md={2} className="form-group">
                      <></>
                      <label htmlFor="sph">SPH</label>
                    </Col>
                    <Col md={4} className="form-group">
                      <label htmlFor="startSph">START</label>
                      <Select
                        onChange={(e) => prescriptionFiltersHandler(e, 'startSph')}
                        name="startSph"
                        value={prescriptionFilters.startSph || ''}
                        id="startSph"
                        options={lensPowers.spherical}
                        clearable={false}
                        className="react-select"
                        placeholder=""
                        classNamePrefix="react-select"
                      />
                    </Col>
                    <Col md={4} className="form-group">
                      <label htmlFor="endSph">END</label>
                      <Select
                        onChange={(e) => prescriptionFiltersHandler(e, 'endSph')}
                        name="endSph"
                        value={prescriptionFilters.endSph || ''}
                        id="endSph"
                        options={lensPowers.spherical}
                        clearable={false}
                        className="react-select"
                        placeholder=""
                        classNamePrefix="react-select"
                      />
                    </Col>
                  </Row>
                  <Row style={{ alignItems: 'center' }}>
                    <Col md={2} className="form-group">
                      <></>
                      <label htmlFor="cyl">CYL</label>
                    </Col>
                    <Col md={4} className="form-group">
                      <label htmlFor="startCyl">START</label>
                      <Select
                        onChange={(e) => prescriptionFiltersHandler(e, 'startCyl')}
                        name="startCyl"
                        value={prescriptionFilters.startCyl || ''}
                        id="startCyl"
                        options={lensPowers.cylindrical}
                        clearable={false}
                        className="react-select"
                        placeholder=""
                        classNamePrefix="react-select"
                      />
                    </Col>
                    <Col md={4} className="form-group">
                      <label htmlFor="endCyl">END</label>
                      <Select
                        onChange={(e) => prescriptionFiltersHandler(e, 'endCyl')}
                        name="endCyl"
                        value={prescriptionFilters.endCyl || ''}
                        id="endCyl"
                        options={lensPowers.cylindrical}
                        clearable={false}
                        className="react-select"
                        placeholder=""
                        classNamePrefix="react-select"
                      />
                    </Col>
                  </Row>
                  <Row style={{ alignItems: 'center' }}>
                    <Col md={2} className="form-group">
                      <></>
                      <label htmlFor="add">ADD</label>
                    </Col>
                    <Col md={4} className="form-group">
                      <label htmlFor="startAdd">START</label>
                      <Select
                        onChange={(e) => prescriptionFiltersHandler(e, 'startAdd')}
                        name="startAdd"
                        value={prescriptionFilters.startAdd || ''}
                        id="startAdd"
                        options={lensPowers.add}
                        clearable={false}
                        className="react-select"
                        placeholder=""
                        classNamePrefix="react-select"
                      />
                    </Col>
                    <Col md={4} className="form-group">
                      <label htmlFor="endAdd">END</label>
                      <Select
                        onChange={(e) => prescriptionFiltersHandler(e, 'endAdd')}
                        name="endAdd"
                        value={prescriptionFilters.endAdd || ''}
                        id="endAdd"
                        options={lensPowers.add}
                        clearable={false}
                        className="react-select"
                        placeholder=""
                        classNamePrefix="react-select"
                      />
                    </Col>
                  </Row>
                </form>
                <Row style={{ marginTop: '10px' }}>
                  <Col md={3}>
                    <Button
                      outline
                      type="button"
                      className="rounded btn-sm"
                      color="success"
                      onClick={() => resetPrescriptionFilter()}
                    >
                      Reset
                    </Button>
                  </Col>
                  <Col md={3}>
                    <Button
                      type="button"
                      className="rounded btn-sm"
                      color="success"
                      onClick={() => applyPrescriptionFilter()}
                    >
                      Apply
                    </Button>
                  </Col>
                </Row>

              </div>
            )}
          />
        )
      }

      {/* {
        activeModal === 'SelectVendor'
        && (
          <Modal
            modalStatus={activeModal === 'SelectVendor'}
            header
            title="Choose Vendor"
            color="success"
            modalAction={() => activeModalAction('', 'close')}
            message={(
              <div>
                <form onSubmit={(e) => e.preventDefault()} className="form">
                  <Row>
                    <Col md={6} className="form-group">
                      <label htmlFor="comments">VENDOR *</label>
                      <Select
                        onChange={(e) => consolidatedDataHandler(e, '', 'select', 'vendorName')}
                        name="vendorName"
                        value={consolidatedOrderData.vendorName}
                        id="vendorName"
                        options={vendorOptions}
                        clearable={false}
                        className="react-select"
                        placeholder=""
                        classNamePrefix="react-select"
                      />
                    </Col>
                  </Row>
                </form>
                <Button
                  style={{ marginTop: '15px' }}
                  type="button"
                  className="rounded btn-sm"
                  color="success"
                  disabled={saveNewPoCustomerOrderState.isSaving}
                  onClick={() => saveNewPurchase()}
                >
                  Submit
                </Button>
              </div>
            )}
          />
        )
      } */}

      {
        activePage === 'ListOrder'
        && (
          <Container>
            <Row>
              <Col md={12} className="smalldiv__listheader">
                <h4>
                  Orders
                </h4>
                <Button
                  className="rounded btn-right"
                  color="success"
                  onClick={() => activePageHandler('AddOrder')}
                >
                  New Order
                </Button>
              </Col>
            </Row>
            <Row style={{ placeContent: 'center' }}>
              {
                customerOrderData.isFetching
                  ? (
                    <div className="spinner-border" style={{ color: '#03a5a5' }}>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <ListOrder
                      viewDetails={viewDetails}
                      customerOrderData={customerOrderData.customerOrderDetails}
                      cancelFormData={cancelFormData}
                    />
                  )
              }
            </Row>
          </Container>
        )
      }

      {
        activePage === 'AddOrder'
        && (
          <AddOrder
            customerOptions={customerOptions}
            activePageHandler={activePageHandler}
            handleImport={handleImport}
            customerInfo={customerInfo}
            onInputChangeHandler={onInputChangeHandler}
            saveCustomerOrderState={saveCustomerOrderState}
            fileProcessing={fileProcessing}
          />
        )
      }
      {
        (activePage === 'MaterialSummary' || activePage === 'ViewPurchase' || activePage === 'GroupSummary')
        && (
          <MaterialSummary
            saveNewPurchase={saveNewPurchase}
            saveNewPoCustomerOrderState={saveNewPoCustomerOrderState}
            summary={summary}
            viewDetailsMode={viewDetailsMode}
            prescriptionFilters={prescriptionFilters}
            selectedPurchaseList={selectedPurchaseList}
            activePageHandler={activePageHandler}
            activePage={activePage}
            consolidatedOrderData={consolidatedOrderData}
            consolidatedDataHandler={consolidatedDataHandler}
            activeModalAction={activeModalAction}
          />
        )
      }
    </div>
  );
};

CustomerOrder.propTypes = {
  saveCustomerOrderAction: Proptypes.func.isRequired,
  saveCustomerOrderState: Proptypes.objectOf.isRequired,
  // fetchVendorsAction: Proptypes.func.isRequired,
  // vendor: Proptypes.arrayOf.isRequired,
  saveNewPoCustomerOrderAction: Proptypes.func.isRequired,
  saveNewPoCustomerOrderState: Proptypes.objectOf.isRequired,
  fetchCustomerOrderAction: Proptypes.func.isRequired,
  customerOrderData: Proptypes.arrayOf.isRequired,
  customerDataState: Proptypes.arrayOf.isRequired,
  fetchCustomerAction: Proptypes.func.isRequired,
  cancelCustomerOrderAction: Proptypes.func.isRequired,
  saveCancelState: Proptypes.arrayOf.isRequired,
};

const mapDispatchToProps = {
  saveCustomerOrderAction: saveCustomerOrder,
  // fetchVendorsAction: fetchVendor,
  saveNewPoCustomerOrderAction: saveNewPoCustomerOrder,
  fetchCustomerOrderAction: fetchCustomerOrder,
  fetchCustomerAction: fetchCustomer,
  cancelCustomerOrderAction: cancelCustomerOrder,
};

const mapStateToProps = (state) => ({
  saveCustomerOrderState: state.saveCustomerOrder,
  // vendor: state.vendorData,
  saveNewPoCustomerOrderState: state.saveNewPoCustomerOrder,
  customerOrderData: state.customerOrderData,
  customerDataState: state.customerData,
  saveCancelState: state.cancelCustomerOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrder);
