import { handleActions } from 'redux-actions';
import _ from 'lodash';
import {
  saveCustomerOrderRequest,
  saveCustomerOrderSuccess,
  saveCustomerOrderFailure,
  defaultCustomerOrder,
  addToCustomerOrder,
  fetchCustomerOrderRequest,
  fetchCustomerOrderSuccess,
  fetchCustomerOrderFailure,
  saveNewPoCustomerOrderRequest,
  saveNewPoCustomerOrderSuccess,
  saveNewPoCustomerOrderFailure,
  defaultSaveNewPoCustomerOrder,
  updateCustomerOrder,
  cancelCustomerOrderFailure,
  cancelCustomerOrderRequest,
  cancelCustomerOrderSuccess,
  defaultCancelCustomerOrder,
} from '../actions/customerOrderActions';

const defaultSaveState = {
  isSaving: false,
  error: null,
  customerOrderDetails: {},
};

const defaultNewPoCustomerOrderState = {
  isSaving: false,
  error: null,
  purchaseOrderDetails: {},
};

const defaultFetchState = {
  isfetching: false,
  error: null,
  customerOrderDetails: [],
  limit: 500,
  skip: 0,
};

const defaultcancelCustomerOrderState = {
  isDeleting: false,
  error: null,
  customerOrderDetails: {},
};
export const customerOrderSaveReducer = handleActions(
  {
    [saveCustomerOrderRequest](state) {
      console.log(state);
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    },
    [saveCustomerOrderSuccess](state, { payload }) {
      return {
        ...state,
        customerOrderDetails: payload,
        isSaving: false,
        error: null,
      };
    },
    [defaultCustomerOrder](state) {
      return {
        ...state,
        customerOrderDetails: {},
        error: null,
        isSaving: false,
      };
    },
    [saveCustomerOrderFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isSaving: false,
      };
    },
  },
  defaultSaveState,
);

export const customerOrderFetchReducer = handleActions(
  {
    [fetchCustomerOrderRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchCustomerOrderSuccess](state, { payload }) {
      return {
        ...state,
        customerOrderDetails: payload,
        limit: state.limit,
        skip: state.skip,
        isFetching: false,
        error: null,
      };
    },
    [fetchCustomerOrderFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    },
    [addToCustomerOrder](state, { payload }) {
      return {
        ...state,
        customerOrderDetails: [payload, ...state.customerOrderDetails],
      };
    },
    /* eslint no-underscore-dangle: 0 */
    [updateCustomerOrder](state, { payload }) {
      const customerOrderDetailsLocal = _.cloneDeep(state.customerOrderDetails);
      const foundIndex = customerOrderDetailsLocal.findIndex((po) => po._id === payload._id);

      customerOrderDetailsLocal[foundIndex] = payload;

      console.log(customerOrderDetailsLocal[foundIndex]);

      return {
        ...state,
        customerOrderDetails: customerOrderDetailsLocal,
      };
    },
  },
  defaultFetchState,
);

export const saveNewPoCustomerOrderReducer = handleActions(
  {
    [saveNewPoCustomerOrderRequest](state) {
      console.log(state);
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    },
    [saveNewPoCustomerOrderSuccess](state, { payload }) {
      return {
        ...state,
        purchaseOrderDetails: payload,
        isSaving: false,
        error: null,
      };
    },
    [defaultSaveNewPoCustomerOrder](state) {
      return {
        ...state,
        purchaseOrderDetails: {},
        error: null,
        isSaving: false,
      };
    },
    [saveNewPoCustomerOrderFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isSaving: false,
      };
    },
  },
  defaultNewPoCustomerOrderState,
);
export const cancelCustomerOrderReducer = handleActions(
  {
    [cancelCustomerOrderRequest](state) {
      console.log(state);
      console.log('request');
      return {
        ...state,
        isDeleting: true,
        error: null,
      };
    },
    [cancelCustomerOrderSuccess](state, { payload }) {
      console.log('success');
      return {
        ...state,
        purchaseOrderDetails: payload,
        isDeleting: false,
        error: null,
      };
    },
    [defaultCancelCustomerOrder](state) {
      console.log('default');
      return {
        ...state,
        purchaseOrderDetails: {},
        error: null,
        isDeleting: false,
      };
    },
    [cancelCustomerOrderFailure](state, { payload }) {
      console.log('failed');
      return {
        ...state,
        error: payload,
        isDeleting: false,
      };
    },
  },
  defaultcancelCustomerOrderState,
);
