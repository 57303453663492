import React, { useState, useEffect } from 'react';
import {
  Button,
  Row,
  Col,
  Container,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ListReason from './components/ListReason';
import Modal from '../../shared/components/modal/Modal';
import { saveReason, fetchReason, removeReason } from '../../redux/actions/reasonActions';

const Reason = ({
  saveReasonState,
  saveReasonAction,
  reasonDataState,
  fetchReasonAction,
  removeReasonAction,
  removeReasonState,
}) => {
  const [reasonFormData, setReasonFormData] = useState(false);
  const [activeModal, setActiveModal] = useState('');
  const [activePage] = useState('ListReason');

  const activeModalAction = (action, close) => {
    if (close) {
      setActiveModal('');
      setReasonFormData({});
    } else {
      setActiveModal(action);
    }
  };

  const onInputChangeHandler = (e, fieldName, type) => {
    let fieldValue;

    if (type === 'select') {
      fieldValue = e;
    }

    if (type === 'textInput') {
      fieldValue = e.target.value;
    }

    if (type === 'radio') {
      fieldValue = e;
    }

    console.log(fieldName);

    const reasonFormDataSetter = { ...reasonFormData, [fieldName]: fieldValue };
    setReasonFormData(reasonFormDataSetter);
  };

  const saveReasonFunc = () => {
    console.log(reasonFormData);
    setReasonFormData({});
    saveReasonAction(reasonFormData);
    return 1;
  };

  useEffect(() => {
    if (
      !reasonDataState.error
      && reasonDataState.reasonDetails
      && Object.keys(reasonDataState.reasonDetails).length === 0
    ) {
      fetchReasonAction(reasonDataState);
    }
  }, []);

  useEffect(() => {
    console.log(saveReasonState);
    if (!saveReasonState.error
      && !saveReasonState.isSaving
      && saveReasonState.reasonDetails
      && saveReasonState.reasonDetails.message) {
      if (saveReasonState.reasonDetails.message === 'success') {
        setActiveModal('ReasonSuccess');
      } else {
        toast.error('Unexpected Error:(');
      }
    }

    if (saveReasonState.error) {
      toast.error('Unexpected Error:(');
    }
  }, [saveReasonState]);

  useEffect(() => {
    console.log(removeReasonState);
    if (!removeReasonState.error
      && !removeReasonState.isRemoving
      && removeReasonState.reasonDetails
      && removeReasonState.reasonDetails.message) {
      if (removeReasonState.reasonDetails.message === 'success') {
        toast.success('Reason Removed Successfully!');
      } else {
        toast.error('Unexpected Error:(');
      }
    }

    if (removeReasonState.error) {
      toast.error('Unexpected Error:(');
    }
  }, [removeReasonState]);

  const removeReasonFunc = (item) => {
    removeReasonAction(item);
  };

  return (
    <div className="reason">
      {
        activePage === 'ListReason'
        && (
          <Container>
            <Row>
              <Col md={12} className="smalldiv__listheader">
                <h4>
                  Reasons
                </h4>
                <Button
                  className="rounded btn-right"
                  color="success"
                  onClick={() => activeModalAction('AddReason')}
                >
                  Add Reason
                </Button>
              </Col>
            </Row>
            <Row style={{ placeContent: 'center' }}>
              {
                reasonDataState.isFetching
                  ? (
                    <div className="spinner-border" style={{ color: '#03a5a5' }}>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <ListReason
                      reasonData={reasonDataState}
                      removeReasonState={removeReasonState}
                      removeReason={removeReasonFunc}
                    />
                  )
              }
            </Row>
          </Container>
        )
      }
      {
        activeModal === 'AddReason'
        && (
          <Modal
            modalStatus={activeModal === 'AddReason'}
            header
            title="Add Reason"
            color="success"
            modalAction={() => activeModalAction('', 'close')}
            message={(
              <div>
                <form onSubmit={(e) => e.preventDefault()} className="form">
                  <Row>
                    <Col md={6} className="smalldiv__samerow">
                      <label htmlFor="type">TYPE</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="form-group smalldiv__samerow">
                      <div className="masters__radioSplitter">
                        <input
                          style={{ width: '17px' }}
                          name="adjustments"
                          type="radio"
                          checked={reasonFormData.type === 'adjustments'}
                          onChange={() => onInputChangeHandler('adjustments', 'type', 'radio')}
                        />
                        <label style={{ marginBottom: '0px' }} htmlFor="adjustments">Adjustments</label>
                      </div>
                      <div className="masters__radioSplitter">
                        <input
                          style={{ width: '17px' }}
                          name="returns"
                          type="radio"
                          checked={reasonFormData.type === 'returns'}
                          onChange={() => onInputChangeHandler('returns', 'type', 'radio')}
                        />
                        <label style={{ marginBottom: '0px' }} htmlFor="returns">Returns</label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="form-group">
                      <label htmlFor="type">TITLE</label>
                      <div>
                        <input
                          onChange={(e) => onInputChangeHandler(e, 'title', 'textInput')}
                          name="title"
                          component="input"
                          value={reasonFormData.title}
                          type="text"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="form-group">
                      <label htmlFor="type">DESCRIPTION</label>
                      <input
                        style={{}}
                        onChange={(e) => onInputChangeHandler(e, 'description', 'textInput')}
                        name="description"
                        component="input"
                        value={reasonFormData.description}
                        type="text"
                      />
                    </Col>
                  </Row>
                </form>
                <Button
                  style={{ marginTop: '15px' }}
                  type="button"
                  className="rounded btn-sm"
                  color="success"
                  disabled={saveReasonState.isSaving}
                  onClick={() => saveReasonFunc()}
                >
                  Submit
                </Button>
              </div>
            )}
          />
        )
      }
      {
        activeModal === 'UserAdded'
        && (
          <Modal
            modalStatus={activeModal === 'UserAdded'}
            modalAction={() => activeModalAction('', 'close')}
            color="success"
            title="User Added Successfully"
            btn="Success"
            message={(
              <div>
                <p style={{ fontWeight: 'bold' }}>Vijay Added</p>
                <Button>New User</Button>
              </div>
            )}
          />
        )
      }
    </div>
  );
};

Reason.propTypes = {
  saveReasonAction: PropTypes.func.isRequired,
  saveReasonState: PropTypes.objectOf.isRequired,
  reasonDataState: PropTypes.arrayOf.isRequired,
  fetchReasonAction: PropTypes.func.isRequired,
  removeReasonAction: PropTypes.func.isRequired,
  removeReasonState: PropTypes.objectOf.isRequired,
};

const mapDispatchToProps = {
  saveReasonAction: saveReason,
  fetchReasonAction: fetchReason,
  removeReasonAction: removeReason,
};

const mapStateToProps = (state) => ({
  saveReasonState: state.saveReason,
  reasonDataState: state.reasonData,
  removeReasonState: state.removeReason,
});

export default connect(mapStateToProps, mapDispatchToProps)(Reason);
