import React from 'react';
import Proptypes from 'prop-types';
import {
  Container,
  Col,
  Row,
  Button,
} from 'reactstrap';
import Select from 'react-select';

const AddCustomer = ({
  onInputChangeHandler,
  activePageHandler,
  customerFormData,
  stateOptions,
  cityOptions,
  saveCustomer,
  saveCustomerState,
}) => {
  console.log('Add Cutomer');
  return (
    <Container>
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => activePageHandler('ListCustomer')}
      >
        {' < '}
      </Button>
      <Row>
        <Col md={12}>
          <h4 className="page-title">New Customer</h4>
        </Col>
      </Row>
      <form onSubmit={(e) => e.preventDefault()} className="form">
        <Row>
          <Col md={3} className="form-group">
            <label htmlFor="customerName">CUSTOMER NAME *</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'customerName', 'textInput')}
              name="customerName"
              component="input"
              value={customerFormData.customerName || ''}
              type="text"
              placeholder="type here..."
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="phoneNumber">PHONE NUMBER</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'phoneNumber', 'textInput')}
              name="phoneNumber"
              component="input"
              value={customerFormData.phoneNumber || ''}
              type="number"
              placeholder="type here..."
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="address">ADDRESS</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'address', 'textInput')}
              name="address"
              component="input"
              value={customerFormData.address || ''}
              type="text"
              placeholder="type here..."
            />
          </Col>
        </Row>
        <Row>
          <Col md={3} className="form-group">
            <label htmlFor="state">STATE</label>
            <Select
              onChange={(e) => onInputChangeHandler(e, 'state', 'select')}
              name="state"
              value={customerFormData.state || ''}
              id="state"
              options={stateOptions}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="city">CITY</label>
            <Select
              onChange={(e) => onInputChangeHandler(e, 'city', 'select')}
              name="city"
              value={customerFormData.city || ''}
              id="city"
              options={cityOptions[customerFormData.state && customerFormData.state.name]}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="emailId">EMAIL ADDDRESS</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'emailId', 'textInput')}
              name="emailId"
              component="input"
              value={customerFormData.emailId || ''}
              type="text"
              placeholder="type here..."
            />
          </Col>
        </Row>
      </form>
      <Button
        style={{ marginTop: '10px' }}
        className="rounded"
        disabled={saveCustomerState.isSaving}
        onClick={() => saveCustomer()}
        color="success"
        size="sm"
      >
        Submit
      </Button>
    </Container>
  );
};

AddCustomer.propTypes = {
  onInputChangeHandler: Proptypes.func.isRequired,
  customerFormData: Proptypes.objectOf.isRequired,
  activePageHandler: Proptypes.func.isRequired,
  stateOptions: Proptypes.arrayOf.isRequired,
  cityOptions: Proptypes.arrayOf.isRequired,
  saveCustomer: Proptypes.func.isRequired,
  saveCustomerState: Proptypes.objectOf.isRequired,
};

export default AddCustomer;
