import React from 'react';
import Select from 'react-select';
import {
  Row, Col, Container, Button,
} from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import PurchaseSummary from './PurchaseSummary';

const NewPurchaseForm = ({
  purchaseForm,
  generateVariants,
  onInputChangeHandler,
  lensPowers,
  vendorOptions,
  metaV1Options,
  activePageHandler,
  createdVariants,
  activePage,
  viewSpecificDetails,
  // purchaseOrderCount,
  totalVariantQuantity,
  saveNewPurchase,
  viewDetailsMode,
  currentViewDetailsData,
  purchaseOrderUpdate,
  purchaseOrder,
  groupIndex,
  assignVendorForm,
}) => {
  /* eslint no-underscore-dangle: 0 */
  console.log(currentViewDetailsData);
  return (
    <Container>
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => {
          if (viewDetailsMode) {
            activePageHandler('ListPurchaseOrder');
          }

          if (!viewDetailsMode && activePage !== 'NewPurchaseForm') {
            activePageHandler('FullVariants');
          } else {
            activePageHandler('ListPurchaseOrder');
          }
        }}
      >
        {' < '}
      </Button>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{!viewDetailsMode ? 'NEW PURCHASE' : 'PURCHASE DETAILS'}</h3>
        </Col>
      </Row>
      <form onSubmit={(e) => e.preventDefault()} className="form">
        <Row className="smalldiv__cardsContainer" style={{ marginBottom: '10px' }}>

          {
            currentViewDetailsData.supplier && (
              <Col md={2} className="smalldiv__samerow">
                <span>VENDOR NAME</span>
                <p style={{ fontWeight: 600 }}>{currentViewDetailsData.supplier}</p>
              </Col>
            )
          }

          <Col md={2} className="smalldiv__samerow">
            <span>PURCHASE ORDER ID/DATE</span>
            <p style={{ fontWeight: 600 }}>
              {currentViewDetailsData.orderId}
              /
              {moment(currentViewDetailsData._created_at).format('DD MMM YYYY')}
            </p>
          </Col>

          <Col md={2} className="smalldiv__samerow">
            <span>CUSTOMER ORDER ID/DATE</span>
            <p style={{ fontWeight: 600 }}>
              {currentViewDetailsData.sysCustomerOrderId}
              /
              {moment(currentViewDetailsData.sysCustomerOrderDate).format('DD MMM YYYY')}
            </p>
          </Col>

          <Col md={2} className="smalldiv__samerow">
            <span>CUSTOMER NAME</span>
            <p style={{ fontWeight: 600 }}>{currentViewDetailsData.customerName}</p>
          </Col>

          <Col md={2} className="smalldiv__samerow">
            <span>REF. ID/DATE</span>
            <p style={{ fontWeight: 600 }}>
              {currentViewDetailsData.customerOrderId}
              /
              {moment(currentViewDetailsData.customerOrderDate).format('DD MMM YYYY')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={3} className="form-group">
            {
              viewDetailsMode
              && activePage === 'PurchaseSummary'
              && !currentViewDetailsData.supplier
              && (
                <>
                  <label htmlFor="selectVendor">{!viewDetailsMode ? 'SELECT VENDOR *' : 'VENDOR'}</label>
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'vendorDetails', 'select', true)}
                    name="name"
                    value={
                      currentViewDetailsData.supplier ? (
                        {
                          label: currentViewDetailsData.supplier,
                          value: currentViewDetailsData.supplier,
                        }
                      ) : (
                        assignVendorForm.vendorDetails
                      )
                    }
                    id="selectVendor"
                    options={vendorOptions}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </>
              )
            }

            {
              !viewDetailsMode && activePage !== 'PurchaseSummary'
              && (
                <Select
                  onChange={(e) => onInputChangeHandler(e, 'vendorDetails', 'select')}
                  name="name"
                  value={purchaseForm.vendorDetails || {}}
                  id="selectVendor"
                  options={vendorOptions}
                  clearable={false}
                  className="react-select"
                  placeholder=""
                  classNamePrefix="react-select"
                />
              )
            }
            {
              !viewDetailsMode && activePage === 'PurchaseSummary'
              && (
                <input
                  name="selectVendor"
                  component="input"
                  disabled={activePage === 'PurchaseSummary' && true}
                  value={purchaseForm.vendorDetails ? purchaseForm.vendorDetails.label : ''}
                  type="text"
                />
              )
            }

          </Col>
          {/* <Col md={3} className="smalldiv__samerow form-group">
            <span>ORDER DATE: </span>
            {
              viewDetailsMode
              && (
                <p>{moment(currentViewDetailsData._created_at).format('DD MMM YYYY')}</p>
              )
            }

            {
              !viewDetailsMode
              && (
                <p>{moment(new Date()).format('DD MMM YYYY')}</p>
              )
            }
          </Col>
          <Col md={3} className="smalldiv__samerow form-group">
            <span>ORDER ID: </span>
            {
              viewDetailsMode
              && (
                <p>{currentViewDetailsData.orderId}</p>
              )
            }

            {
              !viewDetailsMode
              && (
                <p>{purchaseOrderCount}</p>
              )
            }
          </Col> */}
        </Row>

        {activePage === 'PurchaseSummary'
          ? (
            <React.Fragment>
              <Row>
                <Col md={12}>
                  <h4 className="page-subhead">Purchase Summary</h4>
                </Col>
              </Row>
              <PurchaseSummary
                groupIndex={groupIndex}
                viewSpecificDetails={viewSpecificDetails}
                totalVariantQuantity={totalVariantQuantity}
                purchaseForm={purchaseForm}
                viewDetailsMode={viewDetailsMode}
                currentViewDetailsData={currentViewDetailsData}
                activePageHandler={activePageHandler}
              />
              {
                !viewDetailsMode
                && (
                  <Row>
                    <Col md={4} style={{ marginTop: '20px' }}>
                      <Button
                        disabled={(createdVariants.length === 0 || purchaseOrder.isSaving) && true}
                        className="rounded"
                        color="success"
                        size="sm"
                        onClick={() => saveNewPurchase()}
                      >
                        Generate Purchase Order
                      </Button>
                    </Col>
                  </Row>
                )
              }

              {
                (viewDetailsMode && (!currentViewDetailsData.supplier))
                && (
                  <Row>
                    <Col md={4} style={{ marginTop: '20px' }}>
                      <Button
                        className="rounded"
                        color="success"
                        size="sm"
                        disabled={purchaseOrderUpdate.isUpdating || currentViewDetailsData.status === 'Processed'}
                        onClick={() => saveNewPurchase()}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                )
              }

            </React.Fragment>
          )
          : (
            <React.Fragment>

              <Row>
                <Col md={12}>
                  <h4 className="page-subhead">Prescription Details</h4>
                </Col>
              </Row>
              <Row>
                <Col md={3} className="form-group">
                  <label htmlFor="lensType">LENS TYPE *</label>
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'lensType', 'select')}
                    name="lensType"
                    value={purchaseForm.lensType || {}}
                    id="lensType"
                    options={metaV1Options.LensType}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col md={3} className="form-group">
                  <label htmlFor="brand">BRAND</label>
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'brand', 'select')}
                    name="brand"
                    value={purchaseForm.brand || {}}
                    id="brand"
                    options={purchaseForm.vendorDetails && purchaseForm.vendorDetails.value.brand}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col md={3} className="form-group">
                  <label htmlFor="model">MODEL</label>
                  <input
                    onChange={(e) => onInputChangeHandler(e, 'model', 'textInput')}
                    name="model"
                    component="input"
                    value={purchaseForm.model || ''}
                    type="text"
                    placeholder="Model"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3} className="form-group">
                  <label htmlFor="lensColor">LENS COLOR</label>
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'lensColor', 'select')}
                    name="lensColor"
                    value={purchaseForm.lensColor || {}}
                    id="lensColor"
                    options={metaV1Options.LensColor}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col md={3} className="form-group">
                  <label htmlFor="lensCoating">LENS COATING</label>
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'lensCoating', 'select')}
                    name="lensCoating"
                    value={purchaseForm.lensCoating || {}}
                    id="lensCoating"
                    options={metaV1Options.LensCoating}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col md={3} className="form-group">
                  <label htmlFor="lensMaterial">LENS MATERIAL *</label>
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'lensMaterial', 'select')}
                    name="lensMaterial"
                    value={purchaseForm.lensMaterial || {}}
                    id="lensMaterial"
                    options={metaV1Options.LensMaterial}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3} className="form-group">
                  <label htmlFor="eye">EYE</label>
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'eye', 'select')}
                    name="eye"
                    value={purchaseForm.eye || {}}
                    id="eye"
                    options={metaV1Options.Eye}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col md={3} className="form-group">
                  <label htmlFor="index">INDEX</label>
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'index', 'select')}
                    name="index"
                    value={purchaseForm.index || {}}
                    id="index"
                    options={metaV1Options.Index}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col md={3} className="form-group">
                  <label htmlFor="hsnCode">HSN CODE</label>
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'hsnCode', 'select')}
                    name="hsnCode"
                    value={purchaseForm.hsnCode || {}}
                    id="hsnCode"
                    options={metaV1Options.HSNCode}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={1} className="form-group" />
                <Col md={2} className="form-group">
                  <span>SPH</span>
                </Col>
                <Col md={2} className="form-group">
                  <span>CYL</span>
                </Col>
                <Col md={2} className="form-group">
                  <span>ADD</span>
                </Col>
                <Col md={2} className="form-group">
                  <span>AXIS</span>
                </Col>
              </Row>
              <Row className="smalldiv__flexselfcenter">
                <Col md={1} className="form-group">
                  <span>START</span>
                </Col>
                <Col md={2} className="form-group">
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'startSph', 'select')}
                    name="startSpehrical"
                    value={purchaseForm.startSph || ''}
                    id="startSpehrical"
                    options={lensPowers.spherical}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col md={2} className="form-group">
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'startCyl', 'select')}
                    name="startCylindrical"
                    value={purchaseForm.startCyl || ''}
                    id="startCylindrical"
                    options={lensPowers.cylindrical}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col md={2} className="form-group">
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'startAdd', 'select')}
                    name="startAdd"
                    value={purchaseForm.startAdd || ''}
                    id="startAdd"
                    options={lensPowers.add}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col md={2} className="form-group">
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'startAxis', 'select')}
                    name="startAxis"
                    value={purchaseForm.startAxis || ''}
                    id="startAxis"
                    options={lensPowers.axis}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
              </Row>
              <Row className="smalldiv__flexselfcenter">
                <Col md={1} className="form-group">
                  <span>END</span>
                </Col>
                <Col md={2} className="form-group">
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'endSph', 'select')}
                    name="endSpeherical"
                    value={purchaseForm.endSph || ''}
                    id="endSpeherical"
                    options={lensPowers.spherical}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col md={2} className="form-group">
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'endCyl', 'select')}
                    name="endCylindrical"
                    value={purchaseForm.endCyl || ''}
                    id="startCylindrical"
                    options={lensPowers.cylindrical}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col md={2} className="form-group">
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'endAdd', 'select')}
                    name="endAdd"
                    value={purchaseForm.endAdd || ''}
                    id="startAdd"
                    options={lensPowers.add}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
              </Row>
              <Row className="smalldiv__flexselfcenter">
                <Col md={1} className="form-group">
                  <span>Increment</span>
                </Col>
                <Col md={2} className="form-group">
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'incSph', 'select')}
                    name="incSpherical"
                    value={purchaseForm.incSph || ''}
                    id="incSpherical"
                    options={lensPowers.incrementOptions}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col md={2} className="form-group">
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'incCyl', 'select')}
                    name="incCylindrical"
                    value={purchaseForm.incCyl || ''}
                    id="incCylindrical"
                    options={lensPowers.incrementOptions}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col md={2} className="form-group">
                  <Select
                    onChange={(e) => onInputChangeHandler(e, 'incAdd', 'select')}
                    name="incAdd"
                    value={purchaseForm.incAdd || ''}
                    id="incAdd"
                    options={lensPowers.incrementOptions}
                    clearable={false}
                    className="react-select"
                    placeholder=""
                    classNamePrefix="react-select"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={2} className="form-group">
                  <Button
                    className="rounded"
                    color="success"
                    size="sm"
                    outline
                    onClick={() => generateVariants()}
                  >
                    Generate Variants
                  </Button>
                </Col>

                <Col md={2} className="form-group">

                  <Button
                    disabled={createdVariants.length === 0 && true}
                    className="rounded"
                    color="success"
                    size="sm"
                    outline
                    onClick={() => activePageHandler('FullVariants')}
                  >
                    View Variants
                  </Button>
                </Col>
              </Row>

            </React.Fragment>
          )}
      </form>
    </Container>
  );
};

NewPurchaseForm.propTypes = {
  totalVariantQuantity: PropTypes.number.isRequired,
  purchaseForm: PropTypes.objectOf.isRequired,
  lensPowers: PropTypes.arrayOf.isRequired,
  // purchaseOrderCount: PropTypes.number.isRequired,
  createdVariants: PropTypes.arrayOf.isRequired,
  generateVariants: PropTypes.func.isRequired,
  onInputChangeHandler: PropTypes.func.isRequired,
  vendorOptions: PropTypes.arrayOf.isRequired,
  metaV1Options: PropTypes.objectOf.isRequired,
  activePageHandler: PropTypes.func.isRequired,
  activePage: PropTypes.string.isRequired,
  saveNewPurchase: PropTypes.func.isRequired,
  viewDetailsMode: PropTypes.bool.isRequired,
  currentViewDetailsData: PropTypes.objectOf.isRequired,
  purchaseOrderUpdate: PropTypes.objectOf.isRequired,
  purchaseOrder: PropTypes.objectOf.isRequired,
  viewSpecificDetails: PropTypes.func.isRequired,
  groupIndex: PropTypes.number.isRequired,
  assignVendorForm: PropTypes.objectOf.isRequired,
};

export default NewPurchaseForm;
