import { createAction } from 'redux-actions';
import api from '../../utils/apiClient';

export const savePurchaseOrderRequest = createAction('SAVE_PURCHASE_ORDER_REQUEST');
export const savePurchaseOrderSuccess = createAction('SAVE_PURCHASE_ORDER_SUCCESS');
export const savePurchaseOrderFailure = createAction('SAVE_PURCHASE_ORDER_FAILURE');
export const defaultPurchaseOrder = createAction('DEFAULT_PURCHASE_ORDER');

export const updatePurchaseOrderRequest = createAction('UPDATE_PURCHASE_ORDER_REQUEST');
export const updatePurchaseOrderSuccess = createAction('UPDATE_PURCHASE_ORDER_SUCCESS');
export const updatePurchaseOrderFailure = createAction('UPDATE_PURCHASE_ORDER_FAILURE');
export const defaultUpdatePurchaseOrder = createAction('DEFAULT_UPDATE_PURCHASE_ORDER');
export const updateToPurchaseOrder = createAction('UPDATE_TO_PURCHASE_ORDER');

export const fetchPurchaseOrderRequest = createAction('FETCH_PURCHASE_ORDER_REQUEST');
export const fetchPurchaseOrderSuccess = createAction('FETCH_PURCHASE_ORDER_SUCCESS');
export const fetchPurchaseOrderFailure = createAction('FETCH_PURCHASE_ORDER_FAILURE');
export const addToPurchaseOrder = createAction('ADD_TO_PURCHASE_ORDER');

export const savePurchaseOrder = (data) => async (dispatch) => {
  try {
    dispatch(savePurchaseOrderRequest());
    const response = await api.purchaseOrder.post(data);
    console.log(response);
    dispatch(savePurchaseOrderSuccess(response.data.data));
    dispatch(addToPurchaseOrder(response.data.data));
    dispatch(defaultPurchaseOrder());
  } catch (e) {
    dispatch(savePurchaseOrderFailure(e));
  }
};

export const updatePurchaseOrder = (data) => async (dispatch) => {
  try {
    dispatch(updatePurchaseOrderRequest());
    const response = await api.purchaseOrder.update(data);
    console.log(response);
    dispatch(updatePurchaseOrderSuccess(response.data));
    if (response.data.data) {
      dispatch(updateToPurchaseOrder(response.data.data));
    }
    dispatch(defaultUpdatePurchaseOrder());
  } catch (e) {
    dispatch(updatePurchaseOrderFailure(e));
  }
};

export const fetchPurchaseOrder = (data) => async (dispatch) => {
  try {
    console.log(data);
    const params = {
      limit: data.limit || 500,
      skip: data.skip || 0,
    };
    dispatch(fetchPurchaseOrderRequest());
    const response = await api.purchaseOrder.get(params);
    dispatch(fetchPurchaseOrderSuccess(response.data.data));
  } catch (e) {
    dispatch(fetchPurchaseOrderFailure(e));
  }
};
