import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Button,
  Container,
} from 'reactstrap';
import FullVariantsReceipt from '../../Tables/BasicTables/components/FullVariantsReceipt';

const FullVariants = ({
  currentViewDetailsData,
  updateVariants,
  groupIndex,
  activeModalAction,
  activePageHandler,
}) => (
  <Container>
    <Button
      outline
      className="backButton btn btn-sm"
      onClick={() => activePageHandler('AddPendingReceipt')}
      type="button"
    >
      {' < '}
    </Button>
    <Button
      type="button"
      className="rounded btn-sm btn-right"
      color="success"
      outline
      onClick={() => activeModalAction('SelectPrescription')}
    >
      Filter
    </Button>
    <Row className="smalldiv__cardsContainer">
      <Col md={2} className="smalldiv__samerow form-group">
        <span>LENS TYPE</span>
        <p style={{ fontWeight: 600 }}>{currentViewDetailsData.groups[groupIndex].LensType}</p>
      </Col>
      <Col md={2} className="smalldiv__samerow form-group">
        <span>LENS MATERIAL</span>
        <p style={{ fontWeight: 600 }}>{currentViewDetailsData.groups[groupIndex].LensMaterial}</p>
      </Col>
      <Col md={2} className="smalldiv__samerow form-group">
        <span>TOTAL VARIANTS</span>
        <p style={{ fontWeight: 600 }}>{currentViewDetailsData.groups[groupIndex].variants.length}</p>
      </Col>
      <Col md={2} className="smalldiv__samerow form-group">
        <span>TOTAL QUANTITY</span>
        <p style={{ fontWeight: 600 }}>
          {currentViewDetailsData.groups[groupIndex].variants.reduce((prev, current) => {
            const quantity = Number(prev) + Number(current.Quantity) - Number(current.ReceivedQuantity);
            return quantity;
          }, 0)}
        </p>
      </Col>
    </Row>
    <Row style={{ marginTop: '20px' }}>
      <Col md={12}>
        <FullVariantsReceipt
          currentViewDetailsData={currentViewDetailsData}
          updateVariants={updateVariants}
          groupIndex={groupIndex}
        />
      </Col>
    </Row>
  </Container>
);

FullVariants.propTypes = {
  currentViewDetailsData: PropTypes.arrayOf.isRequired,
  updateVariants: PropTypes.func.isRequired,
  groupIndex: PropTypes.number.isRequired,
  activeModalAction: PropTypes.func.isRequired,
  activePageHandler: PropTypes.func.isRequired,
};

export default FullVariants;
