import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import Inventory from '../../../assets/sidebar_icons/inventory/box.png';
import Purchase from '../../../assets/sidebar_icons/purchase/purchase.png';
import Production from '../../../assets/sidebar_icons/production/production.png';
import Master from '../../../assets/sidebar_icons/master/master.png';
import Report from '../../../assets/sidebar_icons/report/report.png';

const SidebarContent = ({ onClick, userInfo }) => {
  const handleHideSidebar = () => {
    onClick();
  };

  console.log(userInfo);

  return (
    <div className="sidebar__content">
      {(userInfo.userDetails.userType === 'Warehouse Manager'
        || userInfo.userDetails.userType === 'Owner & Manager'
        || userInfo.userDetails.userType === 'Purchase Admin'
        || userInfo.userDetails.userType === 'Production Admin'
      )
        && (
          <ul className="sidebar__block">
            <SidebarCategory title="Production" icon={Production}>
              {(userInfo.userDetails.userType === 'Warehouse Manager'
                || userInfo.userDetails.userType === 'Owner & Manager'
                || userInfo.userDetails.userType === 'Purchase Admin'
                || userInfo.userDetails.userType === 'Production Admin'
              )
                && (
                  <SidebarLink title="Customer Orders" route="/production/orders" onClick={handleHideSidebar} />
                )}

              {(userInfo.userDetails.userType === 'Warehouse Manager'
                || userInfo.userDetails.userType === 'Owner & Manager'
                || userInfo.userDetails.userType === 'Production Admin'
              )
                && (
                  <SidebarLink title="Gatepass" route="/production/checkout" onClick={handleHideSidebar} />
                )}

              {(userInfo.userDetails.userType === 'Warehouse Manager'
                || userInfo.userDetails.userType === 'Owner & Manager'
              )
                && (
                  <SidebarLink title="Customers" route="/production/customers" onClick={handleHideSidebar} />
                )}
            </SidebarCategory>
          </ul>
        )}

      {(userInfo.userDetails.userType === 'Warehouse Manager'
        || userInfo.userDetails.userType === 'Owner & Manager'
        || userInfo.userDetails.userType === 'Purchase Admin'
        || userInfo.userDetails.userType === 'GRN Admin'
      )
        && (
          <ul className="sidebar__block">
            <SidebarCategory title="Purchases" icon={Purchase}>
              {(userInfo.userDetails.userType === 'Warehouse Manager'
                || userInfo.userDetails.userType === 'Owner & Manager'
                || userInfo.userDetails.userType === 'Purchase Admin'
                || userInfo.userDetails.userType === 'GRN Admin'
              )
                && (
                  <SidebarLink title="Orders" route="/purchase/orders" onClick={handleHideSidebar} />
                )}
              {(userInfo.userDetails.userType === 'Warehouse Manager'
                || userInfo.userDetails.userType === 'Owner & Manager'
                || userInfo.userDetails.userType === 'GRN Admin'
              )
                && (
                  <SidebarLink title="GRN" route="/purchase/grn" onClick={handleHideSidebar} />
                )}
              {(userInfo.userDetails.userType === 'Warehouse Manager'
                || userInfo.userDetails.userType === 'Owner & Manager'
                || userInfo.userDetails.userType === 'Purchase Admin'
              )
                && (
                  <SidebarLink title="Pendings" route="/purchase/pending" onClick={handleHideSidebar} />
                )}
              {(userInfo.userDetails.userType === 'Warehouse Manager'
                || userInfo.userDetails.userType === 'Owner & Manager'
                || userInfo.userDetails.userType === 'Purchase Admin'
                || userInfo.userDetails.userType === 'GRN Admin'
              )
                && (
                  <SidebarLink title="Returns" route="/purchase/return" onClick={handleHideSidebar} />
                )}
              {(userInfo.userDetails.userType === 'Warehouse Manager'
                || userInfo.userDetails.userType === 'Owner & Manager'
              )
                && (
                  <SidebarLink title="Vendors" route="/purchase/vendor" onClick={handleHideSidebar} />
                )}
            </SidebarCategory>
          </ul>
        )}

      {(userInfo.userDetails.userType === 'Warehouse Manager'
        || userInfo.userDetails.userType === 'Owner & Manager'
        || userInfo.userDetails.userType === 'Purchase Admin'
        || userInfo.userDetails.userType === 'GRN Admin'
        || userInfo.userDetails.userType === 'Production Admin'
      )
        && (
          <ul className="sidebar__block">
            <SidebarCategory title="Inventory" icon={Inventory}>
              {(userInfo.userDetails.userType === 'Warehouse Manager'
                || userInfo.userDetails.userType === 'Owner & Manager'
                || userInfo.userDetails.userType === 'Purchase Admin'
                || userInfo.userDetails.userType === 'GRN Admin'
                || userInfo.userDetails.userType === 'Production Admin'
              )
                && (
                  <SidebarLink title="Lenses" route="/inventory/lenses" onClick={handleHideSidebar} />
                )}

              {(userInfo.userDetails.userType === 'Warehouse Manager'
                || userInfo.userDetails.userType === 'Owner & Manager'
                || userInfo.userDetails.userType === 'Purchase Admin'
              )
                && (
                  <SidebarLink title="Adjustments" route="/inventory/adjustments" onClick={handleHideSidebar} />
                )}

              {(userInfo.userDetails.userType === 'Warehouse Manager'
                || userInfo.userDetails.userType === 'Owner & Manager'
              )
                && (
                  <SidebarLink title="Inventory Track" route="/inventory/inventorytrack" onClick={handleHideSidebar} />
                )}
            </SidebarCategory>
          </ul>
        )}

      {(userInfo.userDetails.userType === 'Warehouse Manager'
        || userInfo.userDetails.userType === 'Owner & Manager')
        && (
          <ul className="sidebar__block">
            <SidebarCategory title="Masters" icon={Master}>
              <SidebarLink title="Users" route="/masters/users" onClick={handleHideSidebar} />
              <SidebarLink title="Reasons" route="/masters/reasons" onClick={handleHideSidebar} />
              <SidebarLink title="Brands" route="/masters/brands" onClick={handleHideSidebar} />
            </SidebarCategory>
          </ul>
        )}

      <ul className="sidebar__block">
        <SidebarCategory title="Analysis" icon={Master}>
          <SidebarLink title="Orders" route="/analysis/customerorder" onClick={handleHideSidebar} />
          <SidebarLink title="EOD" route="/analysis/eod" onClick={handleHideSidebar} />
        </SidebarCategory>
      </ul>
      <ul className="sidebar__block">
        <SidebarLink title="Reports" icon={Report} route="/reports" onClick={handleHideSidebar} />
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  userInfo: PropTypes.objectOf.isRequired,
};

export default SidebarContent;
