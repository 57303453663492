import { get, post } from './base/index';

export default {
  get: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}v2/inventory/client/wpurchase/purchase`, { params });
  },
  getPOCount: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}v2/inventory/client/wpurchase/purchasecount`, { params });
  },
  post: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/wpurchase/purchase`, body);
  },
  update: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/wpurchase/updatepurchase`, body);
  },
};
