import { get, post } from './base/index';

export default {
  getMetav1: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}v2/inventory/common/meta/getv1`, { params });
  },
  newReason: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/master/reason`, body);
  },
  getReason: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}v2/inventory/client/master/reason`, { params });
  },
  removeReason: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/master/removereason`, body);
  },
  newBrand: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/master/brand`, body);
  },
  getBrand: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}v2/inventory/client/master/brand`, { params });
  },
  removeBrand: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/master/removebrand`, body);
  },
};
