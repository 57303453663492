import React from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import BasicTable from '../../Tables/BasicTables/components/ListInventoryTrack';

const ListInventoryTrack = ({
  inventoryTrackData,
  viewGroupSummary,
}) => {
  console.log(inventoryTrackData);
  return (
    <Container>
      <BasicTable
        tableRowsData={inventoryTrackData}
        viewGroupSummary={viewGroupSummary}
      />
    </Container>
  );
};

export default ListInventoryTrack;

ListInventoryTrack.propTypes = {
  inventoryTrackData: PropTypes.arrayOf.isRequired,
  viewGroupSummary: PropTypes.arrayOf.isRequired,
};
