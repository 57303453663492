import { handleActions } from 'redux-actions';
import _ from 'lodash';
import {
  saveBrandRequest,
  saveBrandSuccess,
  saveBrandFailure,
  defaultSaveBrand,
  addToBrandData,
  fetchBrandRequest,
  fetchBrandSuccess,
  fetchBrandFailure,
  removeBrandRequest,
  removeBrandSuccess,
  removeBrandFailure,
  defaultRemoveBrand,
  removeFromBrandData,
} from '../actions/brandActions';

const defaultSaveState = {
  isSaving: false,
  error: null,
  brandDetails: {},
};

const defaultRemoveState = {
  isRemoving: false,
  error: null,
  brandDetails: {},
};

const defaultFetchState = {
  isfetching: false,
  error: null,
  brandDetails: [],
  limit: 500,
  skip: 0,
};
export const brandSaveReducer = handleActions(
  {
    [saveBrandRequest](state) {
      console.log(state);
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    },
    [saveBrandSuccess](state, { payload }) {
      return {
        ...state,
        brandDetails: payload,
        isSaving: false,
        error: null,
      };
    },
    [saveBrandFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isSaving: false,
      };
    },
    [defaultSaveBrand](state) {
      return {
        ...state,
        brandDetails: {},
        error: null,
        isSaving: false,
      };
    },
  },
  defaultSaveState,
);

export const brandRemoveReducer = handleActions(
  {
    [removeBrandRequest](state) {
      console.log(state);
      return {
        ...state,
        isRemoving: true,
        error: null,
      };
    },
    [removeBrandSuccess](state, { payload }) {
      return {
        ...state,
        brandDetails: payload,
        isRemoving: false,
        error: null,
      };
    },
    [removeBrandFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isRemoving: false,
      };
    },
    [defaultRemoveBrand](state) {
      return {
        ...state,
        brandDetails: {},
        error: null,
        isRemoving: false,
      };
    },
  },
  defaultRemoveState,
);

export const brandFetchReducer = handleActions(
  {
    [fetchBrandRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchBrandSuccess](state, { payload }) {
      return {
        ...state,
        brandDetails: payload,
        limit: state.limit,
        skip: state.skip,
        isFetching: false,
        error: null,
      };
    },
    [fetchBrandFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    },
    [addToBrandData](state, { payload }) {
      return {
        ...state,
        brandDetails: [payload, ...state.brandDetails],
        error: null,
      };
    },
    [removeFromBrandData](state, { payload }) {
      /* eslint no-underscore-dangle: 0 */
      const cloneState = _.cloneDeep(state);

      cloneState.brandDetails.map((cloned, index) => {
        if (cloned._id === payload._id) {
          cloneState.brandDetails.splice(index, 1);
        }
        return 1;
      });

      return {
        ...state,
        brandDetails: cloneState.brandDetails,
        error: null,
      };
    },
  },
  defaultFetchState,
);
