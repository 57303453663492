import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import moment from 'moment';

const adjustmentsTableHeaderData = [
  { id: 1, title: 'Date' },
  { id: 3, title: 'Lens Type' },
  { id: 6, title: 'Sph' },
  { id: 7, title: 'Cyl' },
  { id: 9, title: 'Axis' },
  { id: 8, title: 'Add' },
  { id: 11, title: 'Qty' },
  { id: 12, title: 'Reason' },
  { id: 13, title: 'Comments' },
];

function InventoryAdjustmentsTable({ adjustmentsTableRowData = [] }) {
  /* {moment(item._created_at).format('DD MMM YYYY')} */
  return (
    /* eslint no-underscore-dangle: 0 */
    <Table responsive hover>
      <thead>
        <tr>
          {adjustmentsTableHeaderData.map((item) => (
            <th style={{ fontWeight: 'bold' }} key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {adjustmentsTableRowData.map((item) => (
          <>
            {item.inventories.map((item1) => (
              <tr>
                <td>{moment(item.adjustmentDate).format('DD MMM YYYY')}</td>
                <td>{item1.LensType || '-'}</td>
                <td>{item1.Spherical || '0' }</td>
                <td>{item1.Cylindrical || '0'}</td>
                <td>{item1.Axis || '0'}</td>
                <td>{item1.Add || '0'}</td>
                <td>{item1.Quantity === undefined ? '-' : item1.Quantity}</td>
                <td>{item1.Reason || '-'}</td>
                <td>{item1.Comments || '-'}</td>
              </tr>
            ))}
            {/* </tr> */}
          </>
        ))}
      </tbody>
    </Table>
  );
}

InventoryAdjustmentsTable.propTypes = {
  adjustmentsTableRowData: PropTypes.arrayOf.isRequired,
};

export default InventoryAdjustmentsTable;
