import { createAction } from 'redux-actions';
import api from '../../utils/apiClient';

export const saveReasonRequest = createAction('SAVE_REASON_REQUEST');
export const saveReasonSuccess = createAction('SAVE_REASON_SUCCESS');
export const saveReasonFailure = createAction('SAVE_REASON_FAILURE');
export const defaultSaveReason = createAction('DEFAULT_REASON');
export const addToReasonData = createAction('ADD_TO_REASON_DATA');

export const removeReasonRequest = createAction('REMOVE_REASON_REQUEST');
export const removeReasonSuccess = createAction('REMOVE_REASON_SUCCESS');
export const removeReasonFailure = createAction('REMOVE_REASON_FAILURE');
export const defaultRemoveReason = createAction('DEFAULT_REMOVE_REASON');
export const removeFromReasonData = createAction('REMOVE_FROM_REASON_DATA');

export const fetchReasonRequest = createAction('FETCH_REASON_REQUEST');
export const fetchReasonSuccess = createAction('FETCH_REASON_SUCCESS');
export const fetchReasonFailure = createAction('FETCH_REASON_FAILURE');

export const saveReason = (data) => async (dispatch) => {
  try {
    dispatch(saveReasonRequest());
    const response = await api.master.newReason(data);
    console.log(response.data);
    dispatch(saveReasonSuccess(response.data));
    if (response.data.data) {
      dispatch(addToReasonData(response.data.data));
    }
    dispatch(defaultSaveReason());
  } catch (e) {
    console.log(e);
    dispatch(saveReasonFailure(e));
  }
};

export const removeReason = (data) => async (dispatch) => {
  try {
    dispatch(removeReasonRequest());
    const response = await api.master.removeReason(data);
    console.log(response.data);
    dispatch(removeReasonSuccess(response.data));
    if (response.data.data) {
      dispatch(removeFromReasonData(response.data.data));
    }
    dispatch(defaultRemoveReason());
  } catch (e) {
    console.log(e);
    dispatch(removeReasonFailure(e));
  }
};

export const fetchReason = (data) => async (dispatch) => {
  try {
    const params = {
      limit: data.limit,
      skip: data.skip,
    };
    console.log(params);
    dispatch(fetchReasonRequest());
    const response = await api.master.getReason(params);
    console.log(response);
    dispatch(fetchReasonSuccess(response.data.data));
  } catch (e) {
    console.log(e);
    dispatch(fetchReasonFailure(e));
  }
};
