import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles(() => ({
  selectAllText: {
    fontWeight: 500,
    fontSize: '13px',
  },
  selectText: {
    fontSize: '13px',
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    fontSize: '13px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

// eslint-disable-next-line no-undef
const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: '13px',
    width: '100%',
    display: 'block',
    padding: '10px 26px 10px 12px',
    overflowY: 'auto',
    wordBreak: 'break-all',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Gilroy'].join(','),
    '&:focus': {
      borderRadius: 4,
      border: '1px solid rgb(3, 165, 165)',
    },
  },
// eslint-disable-next-line no-undef
}))(InputBase);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 'auto',
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

export {
  useStyles, BootstrapInput, MenuProps,
};
