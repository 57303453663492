import React, { useState, useEffect } from 'react';
import {
  Container,
  Button,
  Row,
  Col,
} from 'reactstrap';
import _ from 'lodash';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import IndianStatesCitiesList from 'indian-states-cities-list';
import { toast } from 'react-toastify';
import Modal from '../../shared/components/modal/Modal';
import AddVendor from './components/AddVendor';
import ListVendor from './components/ListVendor';
import {
  saveVendor,
  fetchVendor,
} from '../../redux/actions/vendorActions';
import {
  fetchBrand,
} from '../../redux/actions/brandActions';

const Vendors = ({
  saveVendorAction,
  vendorDataState,
  saveVendorState,
  fetchVendorAction,
  brandDataState,
  fetchBrandAction,
}) => {
  const [activePage, setActivePage] = useState('ListVendor');
  const [activeModal, setActiveModal] = useState('');
  const [vendorFormData, setVendorFormData] = useState({});
  const [stateOptions] = useState(IndianStatesCitiesList.STATES_OBJECT);
  const [cityOptions] = useState(IndianStatesCitiesList.STATE_WISE_CITIES);
  const [brandOptions, setBrandOptions] = useState([]);

  const activePageHandler = (pageName) => {
    setActivePage(pageName);
  };

  const activeModalAction = (action, close) => {
    if (action === 'ListVendor') {
      activePageHandler(action);
    }

    if (close) {
      setActiveModal('');
      setVendorFormData({});
    } else {
      setActiveModal(action);
    }
  };

  const onInputChangeHandler = (e, fieldName, type) => {
    let fieldValue;

    if (type === 'select') {
      fieldValue = e;
    }

    if (type === 'textInput') {
      fieldValue = e.target.value;
    }

    const vendorFormDataSetter = { ...vendorFormData, [fieldName]: fieldValue };
    setVendorFormData(vendorFormDataSetter);
  };

  const saveVendorFunc = async () => {
    if (!vendorFormData.buisnessName || !vendorFormData.phoneNumber || !vendorFormData.brand) {
      return toast.error('Please fill all the mandatory fields!');
    }

    if (!Number(vendorFormData.phoneNumber)) {
      return toast.error('Phone Number should only contain numbers!');
    }

    if (vendorFormData.phoneNumber.length !== 10) {
      return toast.error('Phone Number should be 10 digit!');
    }

    const brandsNew = [];

    vendorFormData.brand.map((brand) => {
      brandsNew.push(brand.value);
      return 1;
    });

    const formedData = {
      buisnessName: vendorFormData.buisnessName,
      phoneNumber: `91${vendorFormData.phoneNumber}`,
      contactPerson: vendorFormData.contactPerson,
      state: vendorFormData.state ? vendorFormData.state.value : '',
      city: vendorFormData.city ? vendorFormData.city.value : '',
      email: vendorFormData.emailId || '',
      address: vendorFormData.address || '',
      gstIn: vendorFormData.gstIn || '',
      brand: brandsNew || [],
    };

    console.log(formedData);
    saveVendorAction(formedData);
    return 1;
  };

  useEffect(() => {
    fetchVendorAction(vendorFormData);
    fetchBrandAction(brandDataState);
  }, []);

  useEffect(() => {
    const brandDataLocal = _.cloneDeep(brandDataState);

    const brandOptionsLocal = [];
    if (brandDataLocal.brandDetails) {
      brandDataLocal.brandDetails.map((brand) => {
        brandOptionsLocal.push({
          label: brand.brandName,
          value: brand.brandName,
        });
        return 1;
      });
      console.log(brandOptionsLocal);
      setBrandOptions(brandOptionsLocal);
    }
  }, [brandDataState]);

  useEffect(() => {
    console.log(saveVendorState);
    if (!saveVendorState.error
      && !saveVendorState.isSaving
      && saveVendorState.vendorDetails
      && saveVendorState.vendorDetails.message) {
      if (saveVendorState.vendorDetails.message === 'success') {
        activeModalAction('VendorSuccess');
      } else {
        toast.error('Unexpected Error:(');
      }
    }
  }, [saveVendorState]);

  return (

    <div className="vendors">
      {
        activePage === 'ListVendor'
        && (
          <Container>
            <Row>
              <Col md={12} className="smalldiv__listheader">
                <h4>
                  Vendors
                </h4>
                <Button
                  className="rounded btn-right"
                  color="success"
                  onClick={() => activePageHandler('AddVendor')}
                >
                  Add Vendor
                </Button>
              </Col>
            </Row>

            <Row style={{ placeContent: 'center' }}>
              {
                vendorDataState.isFetching
                  ? (
                    <div className="spinner-border" style={{ color: '#03a5a5' }}>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <ListVendor
                      vendorDataState={vendorDataState}
                    />
                  )
              }
            </Row>

          </Container>
        )
      }
      {
        activePage === 'AddVendor'
        && (
          <AddVendor
            activePageHandler={activePageHandler}
            vendorFormData={vendorFormData}
            onInputChangeHandler={onInputChangeHandler}
            saveVendor={saveVendorFunc}
            saveVendorState={saveVendorState}
            stateOptions={stateOptions}
            cityOptions={cityOptions}
            brandOptions={brandOptions}
          />
        )
      }
      {
        activeModal === 'VendorSuccess'
        && (
          <Modal
            modalStatus={activeModal === 'VendorSuccess'}
            modalAction={() => activeModalAction('ListVendor', 'close')}
            color="success"
            title="Well Done!"
            btn="Success"
            message={(
              <div>
                <div>Vendor Added Successfully! <br /><br />
                  <span><b>{vendorFormData.buisnessName}</b></span><br />
                </div>
                <Button
                  style={{ marginTop: '10px' }}
                  type="button btn-sm"
                  className="rounded"
                  color="success"
                  onClick={() => activeModalAction('NewVendor', 'close')}
                >
                  New Vendor
                </Button>
              </div>
            )}
          />
        )
      }
    </div>
  );
};

Vendors.propTypes = {
  saveVendorAction: Proptypes.func.isRequired,
  fetchVendorAction: Proptypes.func.isRequired,
  vendorDataState: Proptypes.arrayOf.isRequired,
  saveVendorState: Proptypes.objectOf.isRequired,
  fetchBrandAction: Proptypes.func.isRequired,
  brandDataState: Proptypes.arrayOf.isRequired,
};

const mapDispatchToProps = {
  saveVendorAction: saveVendor,
  fetchVendorAction: fetchVendor,
  fetchBrandAction: fetchBrand,
};

const mapStateToProps = (state) => ({
  saveVendorState: state.vendorSave,
  vendorDataState: state.vendorData,
  brandDataState: state.brandData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Vendors);
