import React, { useState } from 'react';
import {
  Row,
  Col,
  Container,
} from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import ReportsGroupSummary from '../Tables/BasicTables/components/ReportsGroupSummary';
import ReportsPurchaseOrder from '../Tables/BasicTables/components/ReportsPurchaseOrder';
import ReportsGrn from '../Tables/BasicTables/components/ReportsGrn';
import ReportsGatepass from '../Tables/BasicTables/components/ReportsGatepass';
import api from '../../utils/apiClient';

const Reports = () => {
  const [result, setResult] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const searchFunc = async (e) => {
    if (e.key === 'Enter') {
      setResult(false);
      try {
        const response = await api.reports.get({ customerOrderId: searchTerm });
        if (response.data.data) {
          setResult(response.data.data);
        } else {
          throw new Error();
        }
      } catch (err) {
        console.log(err);
        toast.error('Reference Id not found!');
      }
    }
  };

  return (
    <div className="reports">
      <Container>
        <Row>
          <Col md={6}>
            <form onSubmit={(e) => e.preventDefault()} className="form">
              <input
                name="searchterm"
                component="input"
                value={searchTerm}
                placeholder="Search by Reference Id"
                onKeyDown={(e) => searchFunc(e)}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </form>
          </Col>
        </Row>
        {result && (
          <>
            <Row style={{ marginTop: '15px' }} className="smalldiv__cardsContainer">
              <Col md={2} className="smalldiv__samerow form-group">
                <span>CUSTOMER ORDER ID/DATE</span>
                <p style={{ fontWeight: 600 }}>
                  {result.customerOrderId}/{moment(result.customerOrderDate).format('DD MMM YYYY')}
                </p>
              </Col>
              <Col md={2} className="smalldiv__samerow form-group">
                <span>REFERENCE ORDER ID/DATE</span>
                <p style={{ fontWeight: 600 }}>
                  {result.referenceOrderId}/{moment(result.referenceOrderDate).format('DD MMM YYYY')}
                </p>
              </Col>
              <Col md={2} className="smalldiv__samerow form-group">
                <span>CUSTOMER NAME</span>
                <p style={{ fontWeight: 600 }}>{result.customerName}</p>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <h4 className="page-subhead">Group Summary</h4>
              </Col>
            </Row>
            <ReportsGroupSummary
              tableRowsData={result}
            />

            <Row style={{ marginTop: '15px' }}>
              <Col md={12}>
                <h4 className="page-subhead">Gatepass</h4>
              </Col>
            </Row>
            <ReportsGatepass
              tableRowsData={result}
            />

            <Row style={{ marginTop: '15px' }}>
              <Col md={12}>
                <h4 className="page-subhead">Purchase Orders</h4>
              </Col>
            </Row>
            <ReportsPurchaseOrder
              tableRowsData={result}
            />

            <Row style={{ marginTop: '15px' }}>
              <Col md={12}>
                <h4 className="page-subhead">GRN</h4>
              </Col>
            </Row>
            <ReportsGrn
              tableRowsData={result}
            />
          </>
        )}
      </Container>
    </div>
  );
};

export default Reports;
