import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import moment from 'moment';

const tableHeaderData = [
  { id: 1, title: 'Purchase Order ID/Date' },
  { id: 2, title: 'Vendor Name' },
  { id: 3, title: 'Total Variants' },
  { id: 4, title: 'Total Quantity' },
];

const BasicTable = ({ tableRowsData = {} }) => {
  console.log('Basic Table');
  return (
    <Table responsive hover>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRowsData.purchaseOrders.map((item) => (
          /* eslint no-underscore-dangle: 0 */
          <tr role="presentation">
            <td>
              {item.orderId}
              /
              {moment(item._created_at).format('DD MMM YYYY')}
            </td>
            <td>{item.supplier}</td>
            <td>{item.totalVariants}</td>
            <td>{item.totalQuantity}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

BasicTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
};

export default BasicTable;
