import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Col,
  Row,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import PendingReceiptSummary from '../../Tables/BasicTables/components/PendingReceiptSummary';

const AddPendingReceipt = ({
  vendorOptions,
  activePageHandler,
  currentViewDetailsData,
  onInputChangeHandler,
  viewDetailsMode,
  generatePurchaseOrder,
  saveNewPoGrnState,
  groupIndex,
  viewSpecificDetails,
  activeModalAction,
}) => {
  console.log(currentViewDetailsData);
  /* eslint no-underscore-dangle: 0 */
  return (
    <Container>
      <Button
        outline
        className="backButton btn btn-sm"
        onClick={() => activePageHandler('ListPendingReceipt')}
        type="button"
      >
        {' < '}
      </Button>
      <Row>
        <Col md={12}>
          <h3 className="page-title">PENDING PURCHASE</h3>
        </Col>
      </Row>
      <form onSubmit={(e) => e.preventDefault()} className="form">
        <Row className="smalldiv__cardsContainer">
          <Col md={2} className="smalldiv__samerow form-group">
            <span>GRN ID/DATE</span>
            <p style={{ fontWeight: 600 }}>
              {currentViewDetailsData.grId}
              /
              {moment(currentViewDetailsData._created_at).format('DD MMM YYYY')}
            </p>
          </Col>
          <Col md={2} className="smalldiv__samerow form-group">
            <span>CUSTOMER ORDER ID/DATE</span>
            <p style={{ fontWeight: 600 }}>
              {currentViewDetailsData.sysCustomerOrderId}
              /
              {moment(currentViewDetailsData.sysCustomerOrderDate).format('DD MMM YYYY')}
            </p>
          </Col>
          <Col md={2} className="smalldiv__samerow form-group">
            <span>REFERENCE ORDER ID/DATE</span>
            <p style={{ fontWeight: 600 }}>
              {currentViewDetailsData.customerOrderId}
              /
              {moment(currentViewDetailsData.customerOrderDate).format('DD MMM YYYY')}
            </p>
          </Col>
          <Col md={2} className="smalldiv__samerow form-group">
            <span>PO ORDER ID/DATE</span>
            <p style={{ fontWeight: 600 }}>
              {currentViewDetailsData.poId}
              /
              {moment(currentViewDetailsData.poDate).format('DD MMM YYYY')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={3} className="form-group">
            <label htmlFor="selectVendor">SELECT VENDOR *</label>
            <Select
              name="name"
              id="selectVendor"
              options={vendorOptions}
              onChange={(e) => onInputChangeHandler(e, 'vendorDetails', 'select')}
              clearable={false}
              className="react-select"
              placeholder="Search..."
              classNamePrefix="react-select"
            />
          </Col>
        </Row>
      </form>
      <PendingReceiptSummary
        currentViewDetailsData={currentViewDetailsData}
        viewDetailsMode={viewDetailsMode}
        viewSpecificDetails={viewSpecificDetails}
        groupIndex={groupIndex}
      />
      <Row>
        <Button
          style={{ marginTop: '15px' }}
          type="button"
          className="rounded Rectangle-868 Rectangle-cancel btn-cancelOrder "
          onClick={() => activeModalAction('AddCancel')}
        >
          Cancel Order
        </Button>
        <Button
          style={{ marginTop: '15px' }}
          className="rounded btn-sm"
          color="success"
          disabled={saveNewPoGrnState.isSaving}
          onClick={() => generatePurchaseOrder()}
        >
          Generate Purchase Order
        </Button>
      </Row>
    </Container>
  );
};

AddPendingReceipt.propTypes = {
  vendorOptions: PropTypes.arrayOf.isRequired,
  activePageHandler: PropTypes.func.isRequired,
  onInputChangeHandler: PropTypes.func.isRequired,
  currentViewDetailsData: PropTypes.arrayOf.isRequired,
  viewDetailsMode: PropTypes.bool.isRequired,
  generatePurchaseOrder: PropTypes.func.isRequired,
  saveNewPoGrnState: PropTypes.objectOf.isRequired,
  groupIndex: PropTypes.number.isRequired,
  viewSpecificDetails: PropTypes.func.isRequired,
  activeModalAction: PropTypes.func.isRequired,

};

export default AddPendingReceipt;
