import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';

const tableHeaderData = [
  { id: 1, title: 'S.No' },
  { id: 2, title: 'Lens Type' },
  { id: 3, title: 'Lens Material' },
  { id: 4, title: 'Total Variants' },
  { id: 5, title: 'Total Quantity' },

];

const BasicTable = ({ tableRowsData = [], viewFullVariants }) => {
  console.log('List Inventory Track', tableRowsData);
  return (
    <Table responsive hover>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th style={{ fontWeight: 'bold' }} key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRowsData.map((item, index) => (
          <tr role="presentation" onClick={() => viewFullVariants(item)}>
            <td>{index + 1}</td>
            <td>{item.LensType || '-'}</td>
            <td>{item.LensMaterial || '-'}</td>
            <td>{item.totalInventories || '-'}</td>
            <td>{item.totalQuantities || '-'}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

BasicTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
  viewFullVariants: PropTypes.func.isRequired,
};

export default BasicTable;
