import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';

const headerData = [
  { id: 1, title: 'S.No' },
  { id: 2, title: 'LensType' },
  { id: 4, title: 'Sph' },
  { id: 5, title: 'Cyl' },
  { id: 7, title: 'Axis' },
  { id: 6, title: 'Add' },
  { id: 9, title: 'QTY' },
  { id: 12, title: 'Reason' },
];

function ReturnSummary({
  returnForm = [],
  deleteReturn,
}) {
  return (
    /* eslint no-underscore-dangle: 0 */
    <Table responsive hover>
      <thead>
        <tr>
          {headerData.map((item) => (
            <th style={{ fontWeight: 'bold' }} key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {returnForm.inventories.map((item, index) => (
          <tr key={item.ItemCode}>
            <td>{index + 1}</td>
            <td>{item.lensType === undefined ? '-' : item.lensType.value}</td>
            <td>{item.sph === undefined ? '0' : item.sph.value}</td>
            <td>{item.cyl === undefined ? '0' : item.cyl.value}</td>
            <td>{item.axis === undefined ? '0' : item.axis.value}</td>
            <td>{item.add === undefined ? '0' : item.add.value}</td>
            <td>{item.qty === undefined ? '-' : item.qty}</td>
            <td>{item.reason === undefined ? '-' : item.reason.value}</td>
            <td>
              <button
                onClick={() => deleteReturn(index)}
                className="smalldiv__table-btn"
                type="button"
              >
                <DeleteForeverIcon />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

ReturnSummary.propTypes = {
  returnForm: PropTypes.arrayOf.isRequired,
  deleteReturn: PropTypes.func.isRequired,
};

export default ReturnSummary;
