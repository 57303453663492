import { createAction } from 'redux-actions';
import api from '../../utils/apiClient';

export const saveAdjustmentsRequest = createAction('SAVE_ADJUSTMENTS_REQUEST');
export const saveAdjustmentsSuccess = createAction('SAVE_ADJUSTMENTS_SUCCESS');
export const saveAdjustmentsFailure = createAction('SAVE_ADJUSTMENTS_FAILURE');
export const defaultAdjustment = createAction('DEFAULT_ADJUSTMENTS');

export const fetchAdjustmentsRequest = createAction('FETCH_ADJUSTMENTS_REQUEST');
export const fetchAdjustmentsSuccess = createAction('FETCH_ADJUSTMENTS_SUCCESS');
export const fetchAdjustmentsFailure = createAction('FETCH_ADJUSTMENTS_FAILURE');
export const addToAdjustments = createAction('ADD_TO_ADJUSTMENTS');

export const saveAdjustments = (data) => async (dispatch) => {
  try {
    dispatch(saveAdjustmentsRequest());
    const response = await api.adjustments.post(data);
    dispatch(saveAdjustmentsSuccess(response.data));

    if (response.data.data) {
      dispatch(addToAdjustments(response.data.data));
    }

    dispatch(defaultAdjustment());
  } catch (e) {
    console.log(e);
    dispatch(saveAdjustmentsFailure(e));
  }
};

export const fetchAdjustments = (data) => async (dispatch) => {
  try {
    const params = {
      limit: data.limit || 500,
      skip: data.skip || 0,
    };
    dispatch(fetchAdjustmentsRequest());
    const response = await api.adjustments.get(params);
    dispatch(fetchAdjustmentsSuccess(response.data.data));
  } catch (e) {
    console.log(e);
    dispatch(fetchAdjustmentsFailure(e));
  }
};
