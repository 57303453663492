import React from 'react';
import Proptypes from 'prop-types';
import {
  Container,
  Col,
  Row,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import ReactFileReader from 'react-file-reader';

const AddOrder = ({
  handleImport,
  customerInfo,
  onInputChangeHandler,
  activePageHandler,
  saveCustomerOrderState,
  fileProcessing,
  customerOptions,
}) => {
  console.log('Add Customer Order');
  return (
    <Container>
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => activePageHandler('ListOrder')}
      >
        {' < '}
      </Button>
      <Row>
        <Col md={12}>
          <h4 className="page-subhead">Add Order</h4>
        </Col>
      </Row>
      <form onSubmit={(e) => e.preventDefault()} className="form">
        <Row>
          <Col md={3} className="form-group">
            <label htmlFor="customerName">CUSTOMER NAME *</label>
            <Select
              onChange={(e) => onInputChangeHandler(e, 'customerName', 'select')}
              name="customerName"
              value={customerInfo.customerName || ''}
              id="customerName"
              options={customerOptions}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="customerOrderId">REFERENCE ORDER ID *</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'customerOrderId', 'textInput')}
              name="customerOrderId"
              component="input"
              value={customerInfo.customerOrderId || ''}
              type="text"
              placeholder="type here..."
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="customerOrderDate">REFERENCE ORDER DATE *</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'customerOrderDate', 'textInput')}
              name="customerOrderDate"
              component="input"
              value={customerInfo.customerOrderDate || ''}
              type="date"
              placeholder=""
            />
          </Col>
        </Row>
        <Row>
          <Col md={3} className="form-group">
            <label htmlFor="comments">COMMENTS</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'comments', 'textInput')}
              name="comments"
              component="input"
              value={customerInfo.comments || ''}
              type="text"
              placeholder="type here..."
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="customerEmailId">E-MAIL ID *</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'customerEmailId', 'textInput')}
              name="emailid"
              component="input"
              value={customerInfo.customerEmailId || ''}
              type="text"
              placeholder="type here..."
            />
          </Col>
        </Row>
      </form>
      <Row>
        <Col md={6} className="smalldiv__flexselfcenter" style={{ display: 'flex' }}>
          <span>To Import Your Order Data</span>

          {!customerInfo.customerName
            || !customerInfo.customerOrderId
            || !customerInfo.customerOrderDate
            || !customerInfo.customerEmailId ? (
              <Button
                style={{ marginBottom: 0, marginLeft: '10px' }}
                className="rounded"
                color="success"
                size="sm"
                disabled={1}
              >
                Import From Excel
              </Button>
            ) : (
              <div>

                {saveCustomerOrderState.isSaving
                  && (
                    <Button
                      style={{ marginBottom: 0, marginLeft: '10px' }}
                      className="rounded"
                      color="success"
                      size="sm"
                      disabled={1}
                    >
                      Importing...
                    </Button>
                  )}

                {!saveCustomerOrderState.isSaving
                  && (
                    <ReactFileReader
                      handleFiles={handleImport}
                      fileTypes=".csv"
                    >
                      <Button
                        style={{ marginBottom: 0, marginLeft: '10px' }}
                        className="rounded"
                        disabled={fileProcessing
                          || !customerInfo.customerName
                          || !customerInfo.customerOrderId
                          || !customerInfo.customerOrderDate}
                        color="success"
                        size="sm"
                      >
                        {fileProcessing ? 'Processing...' : 'Import From Excel'}
                      </Button>
                    </ReactFileReader>
                  )}
              </div>
            )}
        </Col>
      </Row>
    </Container>
  );
};
AddOrder.propTypes = {
  handleImport: Proptypes.func.isRequired,
  onInputChangeHandler: Proptypes.func.isRequired,
  customerInfo: Proptypes.objectOf.isRequired,
  saveCustomerOrderState: Proptypes.objectOf.isRequired,
  activePageHandler: Proptypes.func.isRequired,
  customerOptions: Proptypes.arrayOf.isRequired,
  fileProcessing: Proptypes.func.isRequired,
};
export default AddOrder;
