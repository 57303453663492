import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Row,
  Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import AddCheckout from './components/AddCheckout';
import ListCheckout from './components/ListCheckout';
import api from '../../utils/apiClient';
import { saveCheckout, fetchCheckout } from '../../redux/actions/checkoutActions';
import Modal from '../../shared/components/modal/Modal';
import { checkoutSummaryGeneration } from '../../utils/commonFunctions/summaryGeneration';

const Checkout = ({
  saveCheckoutState,
  saveCheckoutAction,
  checkoutDataState,
  fetchCheckoutAction,
}) => {
  const [activePage, setActivePage] = useState('ListCheckout');
  const [checkoutForm, setCheckoutForm] = useState({});
  const [searchedCoStatus, setSearchedCoStatus] = useState('isSearched');
  const [searchedCo, setSearchedCo] = useState({});
  const [checkAvailabilityStatus, setCheckingAvailability] = useState('Not Checked');
  const [callCheckAvailability, setCallCheckAvailability] = useState(0);
  const [activeModal, setActiveModal] = useState('');
  const [selectedInventories, setSelectedInventories] = useState(0);
  const [selectedInventoriesQuantity, setSelectedInventoriesQuantity] = useState(0);
  const [currentViewDetailsData, setCurrentViewDetailsData] = useState({});
  const [viewDetailsMode, setViewDetailsMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [viewPossibleCheckouts, setViewPossibleCheckouts] = useState(false);
  const [downloadableCheckouts, setDownloadableCheckouts] = useState([]);
  const [summary, setSummary] = useState({
    inventories: [],
  });

  useEffect(() => {
    console.log(summary);
  }, [summary]);

  const onInputChangeHandler = (e, fieldName, type) => {
    let fieldValue;

    if (type === 'select') {
      fieldValue = e;
    }

    if (type === 'textInput') {
      fieldValue = e.target.value;
    }

    const checkoutFormSetter = { ...checkoutForm, [fieldName]: fieldValue };
    setCheckoutForm(checkoutFormSetter);
  };

  const searchedCoHandler = (e, fieldName, type, index) => {
    const searchedCoLocal = _.cloneDeep(searchedCo);

    if (fieldName === 'CheckoutQuantityLocal') {
      searchedCoLocal.inventories[index][fieldName] = e.target.value;
    }

    if (type === 'checkbox') {
      searchedCoLocal.inventories[index][fieldName] = e.target.checked;
      console.log(searchedCoLocal);
    }

    if (searchedCoLocal.inventories[index].checked && (!searchedCoLocal.inventories[index].CheckoutQuantityLocal
      || Number(searchedCoLocal.inventories[index].CheckoutQuantityLocal) <= 0
      || ((searchedCoLocal.inventories[index].CheckoutQuantity + Number(searchedCoLocal
        .inventories[index].CheckoutQuantityLocal))
        > searchedCoLocal.inventories[index].ActualQuantity)
      || (searchedCoLocal.inventories[index].CheckoutQuantity + Number(searchedCoLocal
        .inventories[index].CheckoutQuantityLocal))
      > searchedCoLocal.inventories[index].Quantity)) {
      searchedCoLocal.inventories[index].error = true;
    } else {
      searchedCoLocal.inventories[index].error = false;
    }

    console.log(searchedCoLocal);

    setSearchedCo(searchedCoLocal);
  };

  const activePageHandler = (pageName) => {
    if (pageName === 'ListCheckout') {
      setCurrentViewDetailsData({});
      setViewDetailsMode(false);
      setCheckoutForm({});
      setSearchedCo({});
      setCheckingAvailability('Not Checked');
      setCallCheckAvailability(false);
    }
    setActivePage(pageName);
  };

  useEffect(() => {
    fetchCheckoutAction(checkoutDataState);
  }, []);

  const activeModalAction = (action, close) => {
    if (action === 'ListCheckout') {
      activePageHandler(action);
    }

    if (action === 'AddCheckout') {
      activePageHandler(action);
    }

    if (close) {
      setActiveModal('');
    } else {
      setActiveModal(action);
    }
  };

  const autoFillInputs = (responseData) => {
    console.log(responseData);
    setSummary(checkoutSummaryGeneration(responseData));

    const responseDataClone = _.cloneDeep(responseData);
    const downloadableCheckoutsLocal = [];

    responseDataClone.map((inventory, index) => {
      console.log(responseDataClone[index].ActualQuantity, ' - ', index);
      if ((responseDataClone[index].CheckoutQuantity < responseDataClone[index].Quantity) && responseDataClone[index]
        .ActualQuantity && responseDataClone[index].AvailableQuantity > 0) {
        let temp = {
          LensType: inventory.LensType,
          LensMaterial: inventory.LensMaterial,
          Spherical: inventory.Spherical,
          Cylindrical: inventory.Cylindrical,
          Add: inventory.Add,
          Axis: inventory.Axis,
        };
        if (((responseDataClone[index].Quantity - responseDataClone[index].CheckoutQuantity)
          <= responseDataClone[index].ActualQuantity)) {
          temp = {
            ...temp,
            Quantity: responseDataClone[index].Quantity
              - responseDataClone[index].CheckoutQuantity,
          };
          responseDataClone[index].CheckoutQuantityLocal = responseDataClone[index].Quantity
            - responseDataClone[index].CheckoutQuantity;
        } else {
          temp = {
            ...temp,
            Quantity: responseDataClone[index].ActualQuantity,
          };
          responseDataClone[index].CheckoutQuantityLocal = responseDataClone[index].ActualQuantity;
        }

        downloadableCheckoutsLocal.push(temp);
        responseDataClone[index].checked = true;
      }
      return 1;
    });

    console.log(responseDataClone);
    setDownloadableCheckouts(downloadableCheckoutsLocal);
    setSearchedCo({ ...searchedCo, inventories: responseDataClone });
    setCheckingAvailability('Checked');
    activeModalAction('AddCheckout', 'close');
  };

  const checkAvailability = async () => {
    setCheckingAvailability('Checking');
    try {
      const response = await api.customerOrder.checkAvailability(searchedCo);
      console.log(response.data.data);
      if (response.data && response.data.data && response.data.data.length > 0) {
        autoFillInputs(response.data.data);
      } else {
        setCheckingAvailability('Checked');
        throw new Error('Unexpected Error');
      }
    } catch (err) {
      setCheckingAvailability('Checked');
      toast.error('Unexpected error :(');
    }
  };

  const searchCustomerOrder = async () => {
    setCallCheckAvailability(false);
    setViewPossibleCheckouts(false);
    setSearchedCo({});
    const filter = {};
    filter.orderId = checkoutForm.orderId;

    const data = { filter };

    setSearchedCoStatus('isSearching');
    // eslint-disable-next-line no-empty
    // if (checkoutDataState.checkoutDetails.find((details) => details.orderId === filter.orderId)) {
    //   toast.error('OrderId already there..');
    // } else {
    //   try {
    //     const response = await api.customerOrder.get(data);
    //     if (response.data && response.data.data && response.data.data.length > 0) {
    //       setSearchedCo(response.data.data[0]);
    //     } else {
    //       toast.error('OrderId not found!');
    //     }
    //   } catch (err) {
    //     toast.error('Unexpected error :(');
    //   }
    // }
    try {
      const response = await api.customerOrder.get(data);
      if (response.data && response.data.data && response.data.data.length > 0) {
        setSearchedCo(response.data.data[0]);
      } else {
        toast.error('OrderId not found!');
      }
    } catch (err) {
      toast.error('Unexpected error :(');
    }

    setSearchedCoStatus('isSearched');
  };

  const newCheckout = async () => {
    const searchedCoLocal = _.cloneDeep(searchedCo);
    const processedInventory = [];
    let selectedInventoriesLocal = 0;
    let selectedInventoriesQuantityLocal = 0;
    let notValid = false;

    /* eslint no-underscore-dangle: 0 */
    searchedCoLocal.sysCustomerOrderDate = searchedCoLocal._created_at;
    searchedCoLocal.sysCustomerOrderId = searchedCoLocal.orderId;

    searchedCoLocal.inventories.map((inventory) => {
      if (inventory.checked && inventory.possibleCheckout) {
        if (!inventory.CheckoutQuantityLocal || Number(inventory.CheckoutQuantityLocal) <= 0) {
          notValid = true;
          console.log('From First Condition, ', inventory.ProductName);
        } else if (
          (Number(inventory.CheckoutQuantityLocal) > inventory.ActualQuantity)
          || (inventory.CheckoutQuantity + Number(inventory.CheckoutQuantityLocal)) > inventory.Quantity) {
          console.log(inventory.CheckoutQuantity + Number(inventory
            .CheckoutQuantityLocal), ' ', inventory.ActualQuantity, ' ', inventory.Quantity);
          console.log('From Second Condition, ', inventory);
          notValid = true;
        } else {
          selectedInventoriesQuantityLocal += Number(inventory.CheckoutQuantityLocal);
          selectedInventoriesLocal += 1;

          const temp = inventory;
          temp.CheckoutQuantity = Number(inventory.CheckoutQuantityLocal);
          processedInventory.push(inventory);
        }
      }

      return 1;
    });

    if (notValid) {
      toast.error('Checkout quantity is not valid!');
      return 1;
    }

    setSelectedInventories(selectedInventoriesLocal);
    setSelectedInventoriesQuantity(selectedInventoriesQuantityLocal);

    if (processedInventory.length === 0) {
      toast.error('Please select atleast 1 inventory!');
      return 1;
    }

    searchedCoLocal.inventories = processedInventory;

    console.log(searchedCoLocal);

    saveCheckoutAction(searchedCoLocal);
    return 1;
  };

  useEffect(() => {
    console.log(saveCheckoutState);
    if (!saveCheckoutState.error
      && !saveCheckoutState.isSaving
      && saveCheckoutState.checkoutDetails
      && saveCheckoutState.checkoutDetails.message) {
      if (saveCheckoutState.checkoutDetails.message === 'success') {
        setActiveModal('CheckoutSuccess');
      } else {
        toast.error('Unexpected Error:(');
      }
    }

    if (saveCheckoutState.error) {
      toast.error('Unexpected Error:(');
    }
  }, [saveCheckoutState]);

  useEffect(() => {
    console.log(searchedCo);

    if (searchedCo && searchedCo.inventories) {
      if (!callCheckAvailability) {
        setCallCheckAvailability(true);
      }
    }
  }, [searchedCo]);

  useEffect(() => {
    if (callCheckAvailability) {
      checkAvailability();
    }
  }, [callCheckAvailability]);

  const viewDetails = (item) => {
    console.log(item.inventories);
    setSummary(checkoutSummaryGeneration(item.inventories));
    setCurrentViewDetailsData(item);
    setViewDetailsMode(true);
    activePageHandler('AddCheckout');
  };

  const onSearchHandler = (e) => {
    if (e.key === 'Enter') {
      const searchTermLocal = searchTerm.toUpperCase();
      const searchedCoLocal = _.cloneDeep(searchedCo);

      if (searchTerm === '') {
        searchedCoLocal.inventories.map((inventory, index) => {
          searchedCoLocal.inventories[index].omitted = false;
          return 1;
        });
      } else {
        searchedCoLocal.inventories.map((inventory, index) => {
          if (!inventory.ProductName.includes(searchTermLocal)) {
            searchedCoLocal.inventories[index].omitted = true;
          } else {
            searchedCoLocal.inventories[index].omitted = false;
          }
          return 1;
        });
      }

      setSearchedCo(searchedCoLocal);
    } else {
      setSearchTerm(e.target.value);
    }
  };

  const checkPossibleCheckouts = () => {
    const searchedCoLocal = _.cloneDeep(searchedCo);

    searchedCoLocal.inventories.map((inventory, index) => {
      if ((searchedCoLocal.inventories[index].CheckoutQuantity < searchedCoLocal
        .inventories[index].Quantity) && searchedCoLocal.inventories[index].ActualQuantity) {
        searchedCoLocal.inventories[index].possibleCheckout = true;
      } else {
        searchedCoLocal.inventories[index].possibleCheckout = false;
      }
      return 1;
    });

    setViewPossibleCheckouts(true);
    setSearchedCo(searchedCoLocal);
  };

  const addComments = () => {
    activeModalAction('EnterComments');
  };

  return (
    <div className="checkout">
      {
        activePage === 'ListCheckout'
        && (
          <Container>
            <Row>
              <Col md={12} className="smalldiv__listheader">
                <h4>
                  Gatepass
                </h4>
                <Button
                  className="rounded btn-right"
                  color="success"
                  onClick={() => activeModalAction('EnterCustomerOrderId')}
                >
                  New Gatepass
                </Button>
              </Col>
            </Row>
            <Row style={{ placeContent: 'center' }}>
              {
                checkoutDataState.isFetching
                  ? (
                    <div className="spinner-border" style={{ color: '#03a5a5' }}>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <ListCheckout
                      viewDetails={viewDetails}
                      checkoutDataState={checkoutDataState}
                    />
                  )
              }
            </Row>
          </Container>
        )
      }

      {
        activePage === 'AddCheckout'
        && (
          <AddCheckout
            summary={summary}
            downloadableCheckouts={downloadableCheckouts}
            viewPossibleCheckouts={viewPossibleCheckouts}
            checkPossibleCheckouts={checkPossibleCheckouts}
            onSearchHandler={onSearchHandler}
            searchedCoHandler={searchedCoHandler}
            onInputChangeHandler={onInputChangeHandler}
            checkoutForm={checkoutForm}
            searchCustomerOrder={searchCustomerOrder}
            searchedCo={searchedCo}
            searchedCoStatus={searchedCoStatus}
            checkAvailabilityStatus={checkAvailabilityStatus}
            newCheckout={newCheckout}
            activePageHandler={activePageHandler}
            saveCheckoutState={saveCheckoutState}
            addComments={addComments}
            currentViewDetailsData={currentViewDetailsData}
            viewDetailsMode={viewDetailsMode}
          />
        )
      }
      {
        activeModal === 'EnterCustomerOrderId'
        && (
          <Modal
            modalStatus={activeModal === 'EnterCustomerOrderId'}
            header
            title="New Gatepass"
            color="success"
            modalAction={() => activeModalAction('', 'close')}
            message={(
              <form onSubmit={(e) => e.preventDefault()} className="form">
                <Row style={{ alignItems: 'center' }}>
                  <Col md={6} className="form-group">
                    <label htmlFor="sph">CUSTOMER ORDER ID</label>
                    <input
                      onChange={(e) => onInputChangeHandler(e, 'orderId', 'textInput')}
                      name="orderId"
                      component="input"
                      value={checkoutForm.orderId || ''}
                      type="text"
                      placeholder="type here..."
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                  <Col md={12}>
                    <Button
                      type="button"
                      className="rounded btn-sm"
                      color="success"
                      onClick={() => searchCustomerOrder()}
                      disabled={searchedCoStatus === 'isSearching' || checkAvailabilityStatus === 'Checking'}
                    >
                      {(searchedCoStatus === 'isSearching' || checkAvailabilityStatus === 'Checking')
                        ? 'Please wait...' : 'Submit'}
                    </Button>
                  </Col>
                </Row>
              </form>
            )}
          />
        )
      }
      {
        activeModal === 'EnterComments'
        && (
          <Modal
            modalStatus={activeModal === 'EnterComments'}
            header
            title="Comments"
            color="success"
            modalAction={() => activeModalAction('', 'close')}
            message={(
              <form onSubmit={(e) => e.preventDefault()} className="form">
                <Row style={{ alignItems: 'center' }}>
                  <Col md={12} className="form-group">
                    <label htmlFor="sph">Enter Comments</label>
                    <input
                      onChange={(e) => onInputChangeHandler(e, 'comments', 'textInput')}
                      name="comments"
                      component="input"
                      value={checkoutForm.comments || ''}
                      type="text"
                      placeholder="type here..."
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                  <Col md={12}>
                    <Button
                      type="button"
                      className="rounded btn-sm"
                      color="success"
                      onClick={() => newCheckout()}
                      disabled={saveCheckoutState.isSaving}
                    >
                      {(saveCheckoutState.isSaving)
                        ? 'Please wait...' : 'Submit'}
                    </Button>
                  </Col>
                </Row>
              </form>
            )}
          />
        )
      }
      {
        activeModal === 'CheckoutSuccess'
        && (
          <Modal
            modalStatus={activeModal === 'CheckoutSuccess'}
            modalAction={() => activeModalAction('ListCheckout', 'close')}
            color="success"
            title="Well Done!"
            btn="Success"
            message={(
              <div>
                <div>Checkout Generated Successfully! <br /><br />
                  <span>Total Variants: <b>{selectedInventories}</b></span><br />
                  <span>Total Quantity: <b>{selectedInventoriesQuantity}</b></span>
                </div>
                <Button
                  style={{ marginTop: '10px' }}
                  type="button btn-sm"
                  className="rounded"
                  color="success"
                  onClick={() => activeModalAction('ListCheckout', 'close')}
                >
                  Back To Checkout
                </Button>
              </div>
            )}
          />
        )
      }
    </div>
  );
};

Checkout.propTypes = {
  saveCheckoutAction: PropTypes.func.isRequired,
  saveCheckoutState: PropTypes.objectOf.isRequired,
  checkoutDataState: PropTypes.arrayOf.isRequired,
  fetchCheckoutAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  saveCheckoutAction: saveCheckout,
  fetchCheckoutAction: fetchCheckout,
};

const mapStateToProps = (state) => ({
  saveCheckoutState: state.saveCheckout,
  checkoutDataState: state.checkoutData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
