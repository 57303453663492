import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import ListPurchaseOrder from './components/ListPurchaseOrder';
import AddPurchaseOrder from './components/AddPurchaseOrder';
import { fetchVendor } from '../../redux/actions/vendorActions';
import { fetchMaster } from '../../redux/actions/masterActions';
import {
  savePurchaseOrder,
  fetchPurchaseOrder,
  updatePurchaseOrder,
} from '../../redux/actions/purchaseOrderActions';
// import { fetchPurchaseOrderCount, increasePurchaseOrderCount } from '../../redux/actions/purchaseOrderCountActions';
import createVariants from '../../utils/commonFunctions/createVariants';
import lensPower from '../../utils/commonFunctions/lensPower';
import Modal from '../../shared/components/modal/Modal';

const purchaseTableHeader = [
  { id: 1, title: 'Purchase Order ID/Date' },
  { id: 2, title: 'Customer Order ID/Date' },
  { id: 3, title: 'Vendor Name' },
  { id: 4, title: 'Reference Order ID/Date' },
  { id: 5, title: 'Total Variants' },
  { id: 6, title: '' },
];

const Orders = ({
  fetchVendorsAction,
  fetchMastersAction,
  savePurchaseOrderAction,
  updatePurchaseOrderAction,
  // fetchPurchaseOrderCountAction,
  vendor,
  master,
  // purchaseOrderCount,
  purchaseOrder,
  // increasePurchaseOrderCountAction,
  fetchPurchaseOrderAction,
  purchaseOrderData,
  purchaseOrderUpdate,
}) => {
  const [activePage, setActivePage] = useState('ListPurchaseOrder');
  const [vendorOptions, setVendorOptions] = useState([]);
  const [metaV1Options, setMetaV1Options] = useState({});
  const [totalVariantQuantity, setTotalVariantQuantity] = useState(0);
  const [createdVariants, setCreatedVariants] = useState([]);
  const [purchaseForm, setPurchaseForm] = useState({});
  const [assignVendorForm, setAssignVendorForm] = useState({});
  const [lensPowers, setLensPowers] = useState({});
  const [currentViewDetailsData, setCurrentViewDetailsData] = useState({});
  const [viewDetailsMode, setViewDetailsMode] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [groupIndex, setGroupIndex] = useState(false);

  console.log(metaV1Options);
  const onInputChangeHandler = (e, fieldName, type, assignVendor) => {
    let fieldValue;

    if (type === 'select') {
      fieldValue = e;
    }

    if (type === 'textInput') {
      fieldValue = e.target.value;
    }

    if (assignVendor) {
      const assignVendorFormSetter = { ...assignVendorForm, [fieldName]: fieldValue };
      setAssignVendorForm(assignVendorFormSetter);
      return 1;
    }

    const purchaseFormSetter = { ...purchaseForm, [fieldName]: fieldValue };
    setPurchaseForm(purchaseFormSetter);
    return 1;
  };

  useEffect(() => {
    console.log(assignVendorForm);
  }, [assignVendorForm]);

  const activePageHandler = (pageName) => {
    console.log(createdVariants);

    if (pageName === 'PurchaseSummary') {
      const createdVariantsLocal = _.cloneDeep(createdVariants);
      let totalCount = 0;
      createdVariantsLocal.map((variant) => {
        if (variant.Quantity) {
          totalCount += Number(variant.Quantity);
        }
        return 1;
      });

      console.log(totalCount);

      setTotalVariantQuantity(totalCount);
    }
    if (pageName === 'ListPurchaseOrder') {
      setCurrentViewDetailsData({});
      setViewDetailsMode(false);
    }

    setActivePage(pageName);
  };

  const viewSpecificDetails = (pageName, index) => {
    activePageHandler(pageName);
    setGroupIndex(index);
  };
  const saveNewPurchase = () => {
    if (!viewDetailsMode) {
      const createdVariantsLocal = [...createdVariants];
      const formedBody = {};
      if (!purchaseForm.lensType || !purchaseForm.lensMaterial
        || !purchaseForm.vendorDetails || !purchaseForm.vendorDetails.label
        || createdVariants.length === 0) {
        toast.error('Please fill all the mandatory fields!');
        activePageHandler('NewPurchaseForm');
        return 1;
      }
      /* eslint no-underscore-dangle: 0 */
      formedBody.supplier = purchaseForm.vendorDetails.label;
      formedBody.supplierId = purchaseForm.vendorDetails.value._id;

      const processedVariants = [];
      createdVariantsLocal.map((variant) => {
        if (variant.Quantity) {
          const tempVariant = variant;
          tempVariant.ProductName = '';
          tempVariant.ProductName += `${purchaseForm.lensType.value} `;
          tempVariant.ProductName += `${purchaseForm.lensMaterial.value} `;
          tempVariant.ProductName += `${variant.ItemCode} `;
          tempVariant.ProductName += 'LENS';

          tempVariant.Brand = purchaseForm.brand ? purchaseForm.brand.value : '';
          tempVariant.LensType = purchaseForm.lensType ? purchaseForm.lensType.value : '';
          tempVariant.LensColor = purchaseForm.lensColor ? purchaseForm.lensColor.value : '';
          tempVariant.LensMaterial = purchaseForm.lensMaterial ? purchaseForm.lensMaterial.value : '';
          tempVariant.LensCoating = purchaseForm.lensCoating ? purchaseForm.lensCoating.value : '';
          tempVariant.Index = purchaseForm.index ? purchaseForm.index.value : '';
          tempVariant.HSNCode = purchaseForm.hsnCode ? purchaseForm.hsnCode.value : '';
          tempVariant.Model = purchaseForm.model;
          tempVariant.Category = 'LENS';

          processedVariants.push(tempVariant);
        }
        return 1;
      });

      formedBody.variants = processedVariants;
      console.log('processedVariants', formedBody);

      savePurchaseOrderAction(formedBody);
    }

    if (viewDetailsMode) {
      // const currentViewDetailsDataLocal = _.cloneDeep(currentViewDetailsData);
      // const freshVariants = [];

      // currentViewDetailsDataLocal.groups.map((group) => {
      //   group.variants.map((variant) => {
      //     freshVariants.push(variant);
      //     return 1;
      //   });
      //   return 1;
      // });

      // delete currentViewDetailsDataLocal.groups;

      // currentViewDetailsDataLocal.variants = freshVariants;

      console.log(assignVendorForm);

      if (!assignVendorForm.vendorDetails) {
        return toast.error('Please select the vendor!');
      }

      const data = {
        supplier: assignVendorForm.vendorDetails.label,
        supplierId: assignVendorForm.vendorDetails.value._id,
        _id: currentViewDetailsData._id,
      };
      updatePurchaseOrderAction(data);
    }
    return 1;
  };

  const viewDetails = (data) => {
    const dataLocal = _.cloneDeep(data);

    const freshVariants = [];

    dataLocal.variants.map((variant) => {
      let exists = false;
      freshVariants.map((freshVariant, index) => {
        if ((freshVariant.LensType === variant.LensType) && (freshVariant.LensMaterial === variant.LensMaterial)) {
          exists = true;

          freshVariants[index].variants.push(variant);
        }
        return 1;
      });

      if (!exists) {
        freshVariants.push({
          LensType: variant.LensType,
          LensMaterial: variant.LensMaterial,
          Brand: variant.Brand || '',
          Model: variant.Model || '',
          variants: [variant],
        });
      }
      exists = false;

      return 1;
    });

    delete dataLocal.variants;

    dataLocal.groups = freshVariants;
    console.log(dataLocal);

    setCurrentViewDetailsData(dataLocal);
    setViewDetailsMode(true);
    activePageHandler('PurchaseSummary');
  };

  const updateVariants = (e, index) => {
    if (!viewDetailsMode) {
      const updatedVariants = [...createdVariants];
      updatedVariants[index].Quantity = e.target.value;
      setCreatedVariants(updatedVariants);
    } else {
      const currentViewDetailsDataLocal = _.cloneDeep(currentViewDetailsData);
      currentViewDetailsDataLocal.groups[groupIndex].variants[index].Quantity = e.target.value;
      setCurrentViewDetailsData(currentViewDetailsDataLocal);
    }
  };

  const deleteVariant = (index) => {
    if (!viewDetailsMode) {
      const updatedVariants = [...createdVariants];
      updatedVariants.splice(index, 1);
      setCreatedVariants(updatedVariants);
      console.log('updated-variant', updatedVariants);
    } else {
      const currentViewDetailsDataLocal = _.cloneDeep(currentViewDetailsData);
      currentViewDetailsDataLocal.groups[groupIndex].variants.splice(index, 1);
      console.log('currentViewDetailsDataLocal', currentViewDetailsDataLocal);
      setCurrentViewDetailsData(currentViewDetailsDataLocal);
    }
  };

  const generateVariants = () => {
    console.log(purchaseForm);
    const createdVariantsLocal = createVariants(purchaseForm);
    console.log(createdVariantsLocal);
    if (!createdVariantsLocal.showPrescriptions) {
      toast.error(createdVariantsLocal);
    } else if (createdVariantsLocal.Prescriptions.length === 0) {
      toast.error('Variants created: 0');
    } else {
      toast.success(`Variants created: ${createdVariantsLocal.Prescriptions.length}`);
      setCreatedVariants(createdVariantsLocal.Prescriptions);
      console.log(createdVariants);
    }
  };

  useEffect(() => {
    // if (!purchaseOrderData || purchaseOrderData.purchaseOrderDetails.length === 0) {
    fetchPurchaseOrderAction(purchaseOrderData);
    // }
    const lensPowerHolder = { ...lensPowers, ...lensPower() };
    setLensPowers(lensPowerHolder);

    if (
      !vendor.error
      && vendor.vendorDetails
      && vendor.vendorDetails.length === 0) {
      fetchVendorsAction(vendor);
    }

    if (
      !master.error
      && master.masterDetails
      && Object.keys(master.masterDetails).length === 0
    ) {
      fetchMastersAction();
    }

    // fetchPurchaseOrderCountAction();
  }, []);

  const resetAll = () => {
    if (!viewDetailsMode) {
      // increasePurchaseOrderCountAction();
      setPurchaseForm({});
      setCreatedVariants([]);
    }
    activePageHandler('ListPurchaseOrder');
  };

  const modalAction = () => {
    resetAll();
    setModalStatus(!modalStatus);
  };

  useEffect(() => {
    console.log(purchaseOrder);
    if (!purchaseOrder.error
      && !purchaseOrder.isSaving
      && purchaseOrder.purchaseOrderDetails
      && Object.keys(purchaseOrder.purchaseOrderDetails).length > 0) {
      setModalStatus(true);
    }

    if (purchaseOrder.error) {
      toast.error('Failed to save!');
    }
  }, [purchaseOrder]);

  useEffect(() => {
    console.log(purchaseOrderUpdate);
    if (!purchaseOrderUpdate.error
      && !purchaseOrderUpdate.isUpdating
      && purchaseOrderUpdate.updatedPurchaseOrderDetails) {
      if (purchaseOrderUpdate.updatedPurchaseOrderDetails.data) {
        toast.success('Updated Successfully!');
        resetAll();
      }
      if (purchaseOrderUpdate.updatedPurchaseOrderDetails.message !== 'success') {
        toast.error(purchaseOrderUpdate.updatedPurchaseOrderDetails.message);
      }
    }
  }, [purchaseOrderUpdate]);

  useEffect(() => {
    if (!master.error && master.masterDetails && Object.keys(master.masterDetails).length > 0) {
      const lensTypeList = [];
      const lensColorList = [];
      const lensCoatingList = [];
      const lensMaterialList = [];
      const eyeList = [];
      const indexList = [];
      const hsnCodeList = [{
        label: '90014090',
        value: '90014090',
      }];

      master.masterDetails.LLensType.map((obj) => {
        lensTypeList.push({
          label: obj,
          value: obj,
        });
        return 1;
      });

      master.masterDetails.LensColor.map((obj) => {
        lensColorList.push({
          label: obj,
          value: obj,
        });
        return 1;
      });

      master.masterDetails.LensCoating.map((obj) => {
        lensCoatingList.push({
          label: obj,
          value: obj,
        });
        return 1;
      });

      master.masterDetails.LLensMaterial.map((obj) => {
        lensMaterialList.push({
          label: obj,
          value: obj,
        });
        return 1;
      });

      master.masterDetails.Eye.map((obj) => {
        eyeList.push({
          label: obj,
          value: obj,
        });
        return 1;
      });

      master.masterDetails.Index.map((obj) => {
        indexList.push({
          label: obj,
          value: obj,
        });
        return 1;
      });

      setMetaV1Options({
        LensType: lensTypeList,
        LensColor: lensColorList,
        LensCoating: lensCoatingList,
        LensMaterial: lensMaterialList,
        Eye: eyeList,
        Index: indexList,
        HSNCode: hsnCodeList,
      });
      console.log(metaV1Options);
    }
  }, [master]);

  useEffect(() => {
    console.log(vendor);
    if (!vendor.error && vendor.vendorDetails) {
      const vendorsList = [];
      let tempVendor;
      const vendorLocal = _.cloneDeep(vendor.vendorDetails);

      vendorLocal.map((vendorInfo) => {
        tempVendor = vendorInfo;
        const optionBrands = [];

        if (vendorInfo.brand && vendorInfo.brand.length > 0) {
          vendorInfo.brand.map((brandObj) => {
            optionBrands.push({
              label: brandObj,
              value: brandObj,
            });
            return 1;
          });

          tempVendor.brand = optionBrands;
        }

        console.log(tempVendor);
        vendorsList.push({
          label: tempVendor.buisnessName,
          value: tempVendor,
        });
        return 1;
      });

      setVendorOptions(vendorsList);
    }

    if (vendor.error) {
      console.log('Unable to get vendors!');
    }
  }, [vendor]);
  return (
    <div className="purchase">
      {
        modalStatus
        && (
          <Modal
            modalStatus={modalStatus}
            modalAction={() => modalAction()}
            color="success"
            title="Well Done!"
            btn="Success"
            message={(
              <div>Purchase Order Generated Successfully! <br /><br />
                <span>Total Variants: <b>{createdVariants.length}</b></span><br />
                <span>Total Quantity: <b>{totalVariantQuantity}</b></span>
              </div>
            )}
          />
        )
      }
      {
        (activePage === 'NewPurchaseForm'
          || activePage === 'FullVariants'
          || activePage === 'PurchaseSummary')
          && (
            <AddPurchaseOrder
              assignVendorForm={assignVendorForm}
              groupIndex={groupIndex}
              viewSpecificDetails={viewSpecificDetails}
              viewDetailsMode={viewDetailsMode}
              currentViewDetailsData={currentViewDetailsData}
              saveNewPurchase={saveNewPurchase}
              purchaseOrder={purchaseOrder}
              purchaseOrderUpdate={purchaseOrderUpdate}
              totalVariantQuantity={totalVariantQuantity}
              generateVariants={generateVariants}
              updateVariants={updateVariants}
              createdVariants={createdVariants}
              deleteVariant={deleteVariant}
              // purchaseOrderCount={purchaseOrderCount.count !== '' ? purchaseOrderCount.count + 1 : ''}
              activePageHandler={activePageHandler}
              vendorOptions={vendorOptions}
              metaV1Options={metaV1Options}
              activePage={activePage}
              purchaseForm={purchaseForm}
              lensPowers={lensPowers}
              onInputChangeHandler={onInputChangeHandler}
            />
          )
      }

      {
        activePage === 'ListPurchaseOrder'
        && (
          <ListPurchaseOrder
            activePageHandler={activePageHandler}
            viewDetails={viewDetails}
            tableHeaderData={purchaseTableHeader}
            tableRowsData={purchaseOrderData}
          />
        )
}
    </div>
  );
};

Orders.propTypes = {
  vendor: PropTypes.arrayOf.isRequired,
  master: PropTypes.objectOf.isRequired,
  // purchaseOrderCount: PropTypes.number.isRequired,
  fetchVendorsAction: PropTypes.func.isRequired,
  fetchMastersAction: PropTypes.func.isRequired,
  savePurchaseOrderAction: PropTypes.func.isRequired,
  updatePurchaseOrderAction: PropTypes.func.isRequired,
  // fetchPurchaseOrderCountAction: PropTypes.func.isRequired,
  purchaseOrder: PropTypes.objectOf.isRequired,
  // increasePurchaseOrderCountAction: PropTypes.func.isRequired,
  fetchPurchaseOrderAction: PropTypes.func.isRequired,
  purchaseOrderData: PropTypes.objectOf.isRequired,
  purchaseOrderUpdate: PropTypes.objectOf.isRequired,
};

const mapDispatchToProps = {
  fetchVendorsAction: fetchVendor,
  fetchMastersAction: fetchMaster,
  savePurchaseOrderAction: savePurchaseOrder,
  updatePurchaseOrderAction: updatePurchaseOrder,
  fetchPurchaseOrderAction: fetchPurchaseOrder,
  // fetchPurchaseOrderCountAction: fetchPurchaseOrderCount,
  // increasePurchaseOrderCountAction: increasePurchaseOrderCount,
};

const mapStateToProps = (state) => ({
  vendor: state.vendorData,
  master: state.master,
  // purchaseOrderCount: state.purchaseOrderCount,
  purchaseOrder: state.purchaseOrder,
  purchaseOrderData: state.purchaseOrderData,
  purchaseOrderUpdate: state.purchaseOrderUpdate,
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
