import { get, post } from './base/index';

export default {
  post: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/wgrn/grn`, body);
  },
  getGrnCount: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}v2/inventory/client/wgrn/grncount`, { params });
  },
  get: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}v2/inventory/client/wgrn/grn`, { params });
  },
  update: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/wgrn/updategrn`, body);
  },
  getPending: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}v2/inventory/client/wgrn/grnpending`, { params });
  },
  newPoGrn: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/wgrn/newpogrn`, body);
  },
  cancelPendingOrder: (data = {}) => {
    const body = { ...data };
    // return post('http://localhost/api/v2/inventory/client/wgrn/cancelpendingorder', body);
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/wgrn/cancelpendingorder`, body);
  },
};
