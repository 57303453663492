import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import moment from 'moment';

const tableHeaderData = [
  { id: 1, title: 'Customer Order ID/Date' },
  { id: 2, title: 'Customer Name' },
  { id: 3, title: 'Reference Order ID/Date' },
  { id: 4, title: 'Total Records' },
];
const tableHeaderDatas = [
  { id: 1, title: 'S.No', dataIndex: 'S.no' },
  { id: 2, title: 'Customer Order ID/Date', dataIndex: 'orderId' },
  { id: 3, title: 'Customer Name', dataIndex: 'customerName' },
  { id: 4, title: 'Reference Order ID/Date', dataIndex: 'customerOrderId' },
  { id: 6, title: 'Cancel Reason', dataIndex: 'cancelReason' },
];
const BasicTable = ({ tableRowsData = [], viewDetails }) => {
  console.log(tableRowsData);
  return (
    <Table responsive hover>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRowsData.map((item) => (
          /* eslint no-underscore-dangle: 0 */
          <tr
            key={item._id}
            role="presentation"
            onClick={() => viewDetails(item)}
            title={item.cancelReason}
            style={{
              backgroundColor: (item.status === 'cancelled' ? '#fff2f2' : ''),
              borderLeft: (item.status === 'cancelled' ? 'solid red' : ''),
            }}
          >
            <td>
              {item.orderId}/{moment(item._created_at).format('DD MMM YYYY')}
            </td>
            <td>{item.customerName || '-'}</td>
            <td>{item.customerOrderId}/{moment(item.customerOrderDate).format('DD MMM YYYY')}</td>
            <td>{item.inventories.length}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

BasicTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
  viewDetails: PropTypes.func.isRequired,
};

export default BasicTable;
export { tableHeaderDatas };
