import { createAction } from 'redux-actions';
import api from '../../utils/apiClient';

export const saveCustomerOrderRequest = createAction('SAVE_CUSTOMER_ORDER_REQUEST');
export const saveCustomerOrderSuccess = createAction('SAVE_CUSTOMER_ORDER_SUCCESS');
export const saveCustomerOrderFailure = createAction('SAVE_CUSTOMER_ORDER_FAILURE');
export const defaultCustomerOrder = createAction('DEFAULT_CUSTOMER_ORDER');
export const addToCustomerOrder = createAction('ADD_TO_CUSTOMER_ORDER');

export const fetchCustomerOrderRequest = createAction('FETCH_CUSTOMER_ORDER_REQUEST');
export const fetchCustomerOrderSuccess = createAction('FETCH_CUSTOMER_ORDER_SUCCESS');
export const fetchCustomerOrderFailure = createAction('FETCH_CUSTOMER_ORDER_FAILURE');
export const updateCustomerOrder = createAction('UPDATE_CUSTOMER_ORDER');

export const saveNewPoCustomerOrderRequest = createAction('SAVE_NEW_PO_CUSTOMER_ORDER_REQUEST');
export const saveNewPoCustomerOrderSuccess = createAction('SAVE_NEW_PO_CUSTOMER_ORDER_SUCCESS');
export const saveNewPoCustomerOrderFailure = createAction('SAVE_NEW_PO_CUSTOMER_ORDER_FAILURE');
export const defaultSaveNewPoCustomerOrder = createAction('DEFAULT_NEW_PO_CUSTOMER_ORDER');

export const cancelCustomerOrderRequest = createAction('CANCEL_CUSTOMER_ORDER_REQUEST');
export const cancelCustomerOrderSuccess = createAction('CANCEL_CUSTOMER_ORDER_SUCCESS');
export const cancelCustomerOrderFailure = createAction('CANCEL_CUSTOMER_ORDER_FAILURE');
export const defaultCancelCustomerOrder = createAction('DEFAULT_CANCEL_CUSTOMER_ORDER');

export const saveCustomerOrder = (data) => async (dispatch) => {
  try {
    dispatch(saveCustomerOrderRequest());
    const response = await api.customerOrder.post(data);
    dispatch(saveCustomerOrderSuccess(response.data.data));
    if (response.data.data) {
      dispatch(addToCustomerOrder(response.data.data));
    }
    dispatch(defaultCustomerOrder());
  } catch (e) {
    dispatch(saveCustomerOrderFailure(e));
  }
};

export const fetchCustomerOrder = (data) => async (dispatch) => {
  try {
    const params = {
      limit: data.limit,
      skip: data.skip,
    };
    console.log(params);
    dispatch(fetchCustomerOrderRequest());
    const response = await api.customerOrder.get(params);
    console.log(response);
    dispatch(fetchCustomerOrderSuccess(response.data.data));
  } catch (e) {
    console.log(e);
    dispatch(fetchCustomerOrderFailure(e));
  }
};

export const saveNewPoCustomerOrder = (data) => async (dispatch) => {
  try {
    dispatch(saveNewPoCustomerOrderRequest());
    const response = await api.customerOrder.newPoCustomerOrder(data);
    console.log(response);
    dispatch(saveNewPoCustomerOrderSuccess(response.data));
    if (response.data.data) {
      dispatch(updateCustomerOrder(response.data.data));
    }
    dispatch(defaultSaveNewPoCustomerOrder());
  } catch (e) {
    dispatch(saveNewPoCustomerOrderFailure(e));
  }
};

export const cancelCustomerOrder = (data) => async (dispatch) => {
  try {
    console.log('cancelorders');
    dispatch(cancelCustomerOrderRequest());
    const response = await api.customerOrder.cancelCustomerOrder(data);
    console.log('response is coming');
    console.log(response);
    dispatch(cancelCustomerOrderSuccess(response.data));
    if (response.data.data) {
      dispatch(defaultCancelCustomerOrder(response.data.data));
    }
  } catch (e) {
    dispatch(cancelCustomerOrderFailure(e));
  }
};
