import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SidebarCategory = ({
  title, icon, isNew, children,
}) => {
  const history = useHistory();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const categoryClass = classNames({
    'sidebar__category-wrap': true,
    'sidebar__category-wrap--open': isCollapsed,
  });

  const handleCollapse = () => {
    children.map((child) => {
      if (child.props && (child.props.route === history.location.pathname)) {
        setIsCollapsed(true);
      }
      return 1;
    });
  };

  useEffect(() => {
    handleCollapse();
  }, []);

  return (
    <div className={categoryClass}>
      <button type="button" className="sidebar__link sidebar__category" onClick={() => setIsCollapsed(!isCollapsed)}>
        {icon ? <img className="sidebar__link-icon lnr" style={{ width: '17px' }} src={icon} alt="Inventory" /> : ''}
        <p className="sidebar__link-title">{title}
          {isNew && <span className="sidebar__category-new" />}
        </p>
        <span className="sidebar__category-icon lnr lnr-chevron-right" />
      </button>
      <Collapse isOpen={isCollapsed} className="sidebar__submenu-wrap">
        <ul className="sidebar__submenu">
          <div>
            {children}
          </div>
        </ul>
      </Collapse>
    </div>
  );
};

SidebarCategory.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  isNew: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

SidebarCategory.defaultProps = {
  icon: '',
  isNew: false,
};

export default SidebarCategory;
