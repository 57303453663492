import { createAction } from 'redux-actions';
import api from '../../utils/apiClient';

export const fetchInventoryTrackRequest = createAction('FETCH_INVENTORY_TRACK_REQUEST');
export const fetchInventoryTrackSuccess = createAction('FETCH_INVENTORY_TRACK_SUCCESS');
export const fetchInventoryTrackFailure = createAction('FETCH_INVENTORY_TRACK_FAILURE');

export const fetchInventoryTrack = () => async (dispatch) => {
  try {
    dispatch(fetchInventoryTrackRequest());
    const params = {
      limit: 500,
      q: '',
      offset: 0,
      filter: {},
      sort: { _created_at: -1 },
    };

    const response = await api.inventoryTrack.get(params);

    dispatch(fetchInventoryTrackSuccess(response.data.data));
  } catch (e) {
    console.log(e);
    dispatch(fetchInventoryTrackFailure(e));
  }
};
