import React from 'react';
import {
  Row,
  Col,
  Button,
  Container,
} from 'reactstrap';
import PropTypes from 'prop-types';
import AdjustmentSummaryTableList from './AdjustmentsSummaryTablesList';

function AdjustmentSummary({
  adjustmentsSummaryTableHeaderData,
  deleteAdjustmentVariant,
  adjustmentForm,
  activePageHandler,
  addNewAdjustment,
  saveNewAdjustments,
  adjustmentSave,
}) {
  console.log('Adjustment Summary');
  return (
    <Container>
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => activePageHandler('ADD ADJUSTMENT')}
      >
        {' < '}
      </Button>
      <Row>
        <Col md={12}>
          <h4 className="page-title">Add Adjustment</h4>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <h4 className="page-subhead">Summary</h4>
        </Col>
      </Row>
      <Row
        style={{ marginBottom: '30px' }}
      >
        <Col md={12}>
          <AdjustmentSummaryTableList
            adjustmentsSummaryTableHeaderData={adjustmentsSummaryTableHeaderData}
            adjustmentForm={adjustmentForm}
            deleteAdjustmentVariant={deleteAdjustmentVariant}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: '30px', marginLeft: '0px' }}>
        <Button
          color="success"
          outline
          size="sm"
          className="rounded"
          onClick={() => addNewAdjustment()}
          disabled={adjustmentSave.isSaving}
        >Add Variants
        </Button>
        <Button
          color="success"
          size="sm"
          className="rounded"
          disabled={adjustmentSave.isSaving}
          onClick={() => saveNewAdjustments()}
        >Confirm
        </Button>
      </Row>
    </Container>
  );
}
AdjustmentSummary.propTypes = {
  deleteAdjustmentVariant: PropTypes.func.isRequired,
  adjustmentsSummaryTableHeaderData: PropTypes.arrayOf.isRequired,
  adjustmentForm: PropTypes.arrayOf.isRequired,
  activePageHandler: PropTypes.func.isRequired,
  addNewAdjustment: PropTypes.func.isRequired,
  saveNewAdjustments: PropTypes.func.isRequired,
  adjustmentSave: PropTypes.objectOf.isRequired,
};
export default AdjustmentSummary;
