import React from 'react';
import Proptypes from 'prop-types';
import {
  Container,
  Col,
  Row,
  Button,
} from 'reactstrap';
import Select from 'react-select';

const AddCustomer = ({
  onInputChangeHandler,
  activePageHandler,
  userFormData,
  saveUser,
  saveUserState,
  stateOptions,
  cityOptions,
  userRoles,
  phoneNumberChecking,
}) => {
  console.log('Add Cutomer');
  return (
    <Container>
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => activePageHandler('ListUser')}
      >
        {' < '}
      </Button>
      <Row>
        <Col md={12}>
          <h4 className="page-title">New User</h4>
        </Col>
      </Row>
      <form onSubmit={(e) => e.preventDefault()} className="form">
        <Row>
          <Col md={3} className="form-group">
            <label htmlFor="userName">USER NAME *</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'userName', 'textInput')}
              name="userName"
              component="input"
              value={userFormData.userName || ''}
              type="text"
              placeholder="type here..."
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="userRole">USER ROLE *</label>
            <Select
              onChange={(e) => onInputChangeHandler(e, 'userRole', 'select')}
              name="userRole"
              value={userFormData.userRole || ''}
              id="userRole"
              options={userRoles}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="phoneNumber">PHONE NUMBER *</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'phoneNumber', 'textInput')}
              name="phoneNumber"
              component="input"
              value={userFormData.phoneNumber || ''}
              type="number"
              placeholder="type here..."
            />
          </Col>
        </Row>
        <Row>
          <Col md={3} className="form-group">
            <label htmlFor="state">STATE</label>
            <Select
              onChange={(e) => onInputChangeHandler(e, 'state', 'select')}
              name="state"
              value={userFormData.state || ''}
              id="state"
              options={stateOptions}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="city">CITY</label>
            <Select
              onChange={(e) => onInputChangeHandler(e, 'city', 'select')}
              name="city"
              value={userFormData.city || ''}
              id="city"
              options={cityOptions[userFormData.state && userFormData.state.name]}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="address">ADDRESS</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'address', 'textInput')}
              name="address"
              component="input"
              value={userFormData.address || ''}
              type="text"
              placeholder="type here..."
            />
          </Col>
        </Row>
        <Row>
          <Col md={3} className="form-group">
            <label htmlFor="emailId">EMAIL ADDRESS</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'emailId', 'textInput')}
              name="emailId"
              component="input"
              value={userFormData.emailId || ''}
              type="text"
              placeholder="type here..."
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="password">PASSWORD *</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'password', 'textInput')}
              name="password"
              component="input"
              value={userFormData.password || ''}
              type="password"
              placeholder="type here..."
            />
          </Col>
        </Row>
      </form>
      <Button
        style={{ marginTop: '10px' }}
        className="rounded"
        disabled={saveUserState.isSaving || phoneNumberChecking}
        onClick={() => saveUser()}
        color="success"
        size="sm"
      >
        Submit
      </Button>
    </Container>
  );
};

AddCustomer.propTypes = {
  onInputChangeHandler: Proptypes.func.isRequired,
  userFormData: Proptypes.objectOf.isRequired,
  activePageHandler: Proptypes.func.isRequired,
  saveUser: Proptypes.func.isRequired,
  saveUserState: Proptypes.objectOf.isRequired,
  stateOptions: Proptypes.arrayOf.isRequired,
  cityOptions: Proptypes.arrayOf.isRequired,
  userRoles: Proptypes.arrayOf.isRequired,
  phoneNumberChecking: Proptypes.bool.isRequired,
};

export default AddCustomer;
