import React, { useState, useEffect } from 'react';
import {
  Button,
  Row,
  Col,
  Container,
} from 'reactstrap';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ListBrand from './components/ListBrand';
import Modal from '../../shared/components/modal/Modal';
import { saveBrand, fetchBrand, removeBrand } from '../../redux/actions/brandActions';

const categoryOptions = [{
  label: 'LENS',
  value: 'LENS',
}, {
  label: 'SUNGLASSES',
  value: 'SUNGLASSES',
}, {
  label: 'EYEGLASSES',
  value: 'EYEGLASSES',
}, {
  label: 'CONTACTLENS',
  value: 'CONTACTLENS',
}];

const Brand = ({
  saveBrandState,
  saveBrandAction,
  brandDataState,
  fetchBrandAction,
  removeBrandAction,
  removeBrandState,
}) => {
  const [brandFormData, setBrandFormData] = useState(false);
  const [activeModal, setActiveModal] = useState('');
  const [activePage] = useState('ListBrand');

  const activeModalAction = (action, close) => {
    if (close) {
      setActiveModal('');
      setBrandFormData({});
    } else {
      setActiveModal(action);
    }
  };

  const onInputChangeHandler = (e, fieldName, type) => {
    let fieldValue;

    if (type === 'select') {
      fieldValue = e;
    }

    if (type === 'textInput') {
      fieldValue = e.target.value;
    }

    if (type === 'radio') {
      fieldValue = e;
    }

    console.log(fieldName);

    const brandFormDataSetter = { ...brandFormData, [fieldName]: fieldValue };
    setBrandFormData(brandFormDataSetter);
  };

  const saveBrandFunc = () => {
    console.log(brandFormData);

    const brandDataStateLocal = _.cloneDeep(brandDataState.brandDetails);

    if (!brandFormData.brandName || !brandFormData.category) {
      return toast.error('Please fill all the mandatory fields');
    }

    let brandNameExists = false;
    brandDataStateLocal.map((brandInfo) => {
      if (brandInfo.brandName === brandFormData.brandName.toUpperCase()) {
        brandNameExists = true;
      }
      return 1;
    });

    if (brandNameExists) {
      return toast.error('Brand name already exists!');
    }

    const formedData = {};

    formedData.brandName = brandFormData.brandName.toUpperCase();
    formedData.category = brandFormData.category.value;

    saveBrandAction(formedData);
    return 1;
  };

  useEffect(() => {
    fetchBrandAction(brandDataState);
  }, []);

  useEffect(() => {
    console.log(saveBrandState);
    if (!saveBrandState.error
      && !saveBrandState.isSaving
      && saveBrandState.brandDetails
      && saveBrandState.brandDetails.message) {
      if (saveBrandState.brandDetails.message === 'success') {
        setActiveModal('BrandSuccess');
      } else {
        toast.error('Unexpected Error:(');
      }
    }

    if (saveBrandState.error) {
      toast.error('Unexpected Error:(');
    }
  }, [saveBrandState]);

  useEffect(() => {
    console.log(removeBrandState);
    if (!removeBrandState.error
      && !removeBrandState.isRemoving
      && removeBrandState.brandDetails
      && removeBrandState.brandDetails.message) {
      if (removeBrandState.brandDetails.message === 'success') {
        toast.success('Brand Removed Successfully!');
      } else {
        toast.error('Unexpected Error:(');
      }
    }

    if (removeBrandState.error) {
      toast.error('Unexpected Error:(');
    }
  }, [removeBrandState]);

  const removeBrandFunc = (item) => {
    removeBrandAction(item);
  };

  return (
    <div className="brand">
      {
        activePage === 'ListBrand'
        && (
          <Container>
            <Row>
              <Col md={12} className="smalldiv__listheader">
                <h4>
                  Brands
                </h4>
                <Button
                  className="rounded btn-right"
                  color="success"
                  onClick={() => activeModalAction('AddBrand')}
                >
                  Add Brand
                </Button>
              </Col>
            </Row>
            <Row style={{ placeContent: 'center' }}>
              {
                brandDataState.isFetching
                  ? (
                    <div className="spinner-border" style={{ color: '#03a5a5' }}>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <ListBrand
                      brandData={brandDataState}
                      removeBrand={removeBrandFunc}
                      removeBrandState={removeBrandState}
                    />
                  )
              }
            </Row>
          </Container>
        )
      }
      {
        activeModal === 'AddBrand'
        && (
          <Modal
            modalStatus={activeModal === 'AddBrand'}
            header
            title="Add Brand"
            color="success"
            modalAction={() => activeModalAction('', 'close')}
            message={(
              <div>
                <form onSubmit={(e) => e.preventDefault()} className="form">
                  <Row>
                    <Col md={6} className="form-group">
                      <label htmlFor="brandName">BRAND NAME *</label>
                      <div>
                        <input
                          onChange={(e) => onInputChangeHandler(e, 'brandName', 'textInput')}
                          name="brandName"
                          component="input"
                          value={brandFormData.brandName}
                          type="text"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="form-group">
                      <label htmlFor="category">CATEGORY *</label>
                      <Select
                        onChange={(e) => onInputChangeHandler(e, 'category', 'select')}
                        name="category"
                        value={brandFormData.category || ''}
                        id="category"
                        options={categoryOptions}
                        clearable={false}
                        className="react-select"
                        placeholder=""
                        classNamePrefix="react-select"
                      />
                    </Col>
                  </Row>
                </form>
                <Button
                  style={{ marginTop: '15px' }}
                  type="button"
                  className="rounded btn-sm"
                  color="success"
                  disabled={saveBrandState.isSaving}
                  onClick={() => saveBrandFunc()}
                >
                  Submit
                </Button>
              </div>
            )}
          />
        )
      }
      {
        activeModal === 'BrandSuccess'
        && (
          <Modal
            modalStatus={activeModal === 'BrandSuccess'}
            modalAction={() => activeModalAction('', 'close')}
            color="success"
            title="Brand Added Successfully!"
            btn="Success"
            message={(
              <div>
                <p style={{ fontWeight: 'bold' }}>{brandFormData.brandName}</p>
              </div>
            )}
          />
        )
      }
    </div>
  );
};

Brand.propTypes = {
  saveBrandAction: PropTypes.func.isRequired,
  saveBrandState: PropTypes.objectOf.isRequired,
  brandDataState: PropTypes.arrayOf.isRequired,
  fetchBrandAction: PropTypes.func.isRequired,
  removeBrandAction: PropTypes.func.isRequired,
  removeBrandState: PropTypes.objectOf.isRequired,
};

const mapDispatchToProps = {
  saveBrandAction: saveBrand,
  fetchBrandAction: fetchBrand,
  removeBrandAction: removeBrand,
};

const mapStateToProps = (state) => ({
  saveBrandState: state.saveBrand,
  brandDataState: state.brandData,
  removeBrandState: state.removeBrand,
});

export default connect(mapStateToProps, mapDispatchToProps)(Brand);
