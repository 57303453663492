/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';

import {
  Row, Col, Button,
} from 'reactstrap';
import Modal from '../../../../shared/components/modal/Modal';

function ModalPop(prop) {
  const {
    saveReportFunc,
    activeModalAction,
    activeModal,
    ModalTitle,
  } = prop;
  const [ModalField, setModalField] = useState([]);
  const [isDisabled, setDisabled] = useState(true);
  const [Fields, setFields] = useState([]);
  const onInputChangeHandler = (event, index) => {
    const payload = {};
    const getHandler = [...ModalField];
    getHandler.forEach((fields, ind) => {
      if (fields.fieldName === Fields[index].labelName) {
        getHandler.splice(ind, 1);
      }
    });
    Fields[index].fieldValue = event.target.value;
    if (event.target.value) {
      payload.fieldName = Fields[index].labelName;
      payload.fieldValue = event.target.value;
      payload.fileType = Fields[index].fieldType;
      getHandler.push(payload);
    } else {
      getHandler.forEach((fields, ind) => {
        if (fields.fieldName === Fields[index].labelName) {
          getHandler.splice(ind, 1);
        }
      });
    }
    setModalField([...getHandler]);
  };
  const resetReportFunc = () => {
    // eslint-disable-next-line no-debugger
    // eslint-disable-next-line no-unused-expressions
    const changed = ModalField.map((f) => ({ ...f, fieldValue: '' }));
    const resetField = Fields.map((m) => ({ ...m, fieldValue: '' }));
    setModalField(changed);
    setFields(resetField);
    setDisabled(null);
  };
  useEffect(() => {
    if (activeModal === 'AddReport') {
      setFields(
        [
          {
            fieldName: 'Template Name', labelName: 'reportName', fieldType: 'textInput', fieldValue: '',
          },
        ],
      );
    } else {
      setFields(
        [
          {
            fieldName: 'From Date', labelName: 'startDate', fieldType: 'calander', fieldValue: '',
          },
          {
            fieldName: 'To Date', labelName: 'endDate', fieldType: 'calander', fieldValue: '',
          },
        ],
      );
    }
  }, []);
  useEffect(() => {
    if (ModalField.length > 0) {
      const isRequired = Fields.find((field) => field.fieldValue === '');
      // eslint-disable-next-line no-debugger
      if (isRequired?.fieldValue === '') {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [ModalField]);
  return (
    <Modal
      modalStatus={activeModal}
      header
      title={ModalTitle}
      textcolor="black"
      modalAction={() => {
        setModalField([]);
        activeModalAction('', 'close');
      }}
      message={(
        <div>
          <form onSubmit={(e) => e.preventDefault()} className="form">
            <Row>
              {Fields.map((list, l) => (

                <Col md={6} className="form-group">
                  <label htmlFor="ReportName" className="font-bld">{list.fieldName}</label>
                  <div>
                    {list.fieldType === 'textInput' && (
                    <input
                      onChange={(e) => onInputChangeHandler(e, l)}
                      name="brandName"
                      component="input"
                      value={list.fieldValue}
                      type="text"
                    />
                    )}
                    {list.fieldType === 'calander' && (
                    <input
                      onChange={(e) => onInputChangeHandler(e, l)}
                      name="brandName"
                      component="input"
                      value={list.fieldValue}
                      type="date"
                    />
                    )}
                  </div>
                </Col>

              ))}
            </Row>
          </form>
          { !isDisabled
          && (
          <Button
            style={{ marginTop: '15px' }}
            type="button"
            className="rounded btn-sm"
            color="success"
            disabled={isDisabled}
            onClick={() => {
              saveReportFunc(ModalField);
              setModalField([]);
            }}
          >
            Save
          </Button>
          )}
          <Button
            style={{ marginTop: '15px' }}
            type="button"
            className="rounded btn-sm"
            color="secondary"
            disabled={isDisabled}
            onClick={() => resetReportFunc()}
          >
            Reset
          </Button>
        </div>
      )}
    />
  );
}

export default ModalPop;
