import { handleActions } from 'redux-actions';
import {
  fetchPurchaseOrderCountRequest,
  fetchPurchaseOrderCountSuccess,
  fetchPurchaseOrderCountFailure,
  increasePurchaseOrderCountAction,
} from '../actions/purchaseOrderCountActions';

const defaultState = {
  count: '',
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [fetchPurchaseOrderCountRequest](state) {
      return {
        ...state,
        isFetching: true,
      };
    },
    [fetchPurchaseOrderCountSuccess](state, { payload }) {
      return {
        ...state,
        count: payload,
        isFetching: false,
      };
    },
    [fetchPurchaseOrderCountFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [increasePurchaseOrderCountAction](state) {
      return {
        ...state,
        count: state.count + 1,
      };
    },
  },
  defaultState,
);
