import React from 'react';
import Proptypes from 'prop-types';
import {
  Container,
  Col,
  Row,
  Button,
} from 'reactstrap';
import moment from 'moment';
import InventoryTrackGroupSummary from '../../Tables/BasicTables/components/InventoryTrackGroupSummary';

const InventoryTrackSummary = ({
  currentViewDetailsData,
  activePageHandler,
  viewFullVariants,
}) => {
  /* eslint no-underscore-dangle: 0 */
  console.log(currentViewDetailsData);
  return (
    <Container>
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => activePageHandler('ListInventoryTrackData')}
      >
        {' < '}
      </Button>
      <Row>
        <Col md={12}>
          <h4 className="page-title">Inventory Track Summary</h4>
        </Col>
      </Row>
      <Row className="smalldiv__cardsContainer">
        <Col md={2} className="form-group smalldiv__samerow">
          <span>INVENTORY TRACK ID/DATE</span>
          <p>
            <b>
              {currentViewDetailsData.trackId}
              /
              {moment(currentViewDetailsData._created_at).format('DD MMM YYYY')}
            </b>
          </p>
        </Col>
        <Col md={2} className="form-group smalldiv__samerow">
          <span>SOURCE: </span>
          <p>
            <b>
              {currentViewDetailsData.source}
            </b>
          </p>
        </Col>
        <Col md={2} className="form-group smalldiv__samerow">
          <span>COMMENTS: </span>
          <p>
            <b>
              {currentViewDetailsData.comments}
            </b>
          </p>
        </Col>
      </Row>
      <div>
        <Row>
          <Col md={12}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
            >
              <h4 className="page-subhead">
                Group Summary
              </h4>
            </div>
          </Col>
        </Row>
        <InventoryTrackGroupSummary
          viewFullVariants={viewFullVariants}
          tableRowsData={
            currentViewDetailsData.inventoryGroupedData ? currentViewDetailsData.inventoryGroupedData : []
          }
        />
      </div>
    </Container>
  );
};

InventoryTrackSummary.propTypes = {
  activePageHandler: Proptypes.func.isRequired,
  currentViewDetailsData: Proptypes.objectOf.isRequired,
  viewFullVariants: Proptypes.func.isRequired,
};

export default InventoryTrackSummary;
