import React from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import BasicTable from '../../Tables/BasicTables/components/ListBrand';

const ListBrand = ({
  brandData,
  removeBrand,
  removeBrandState,
}) => {
  console.log(brandData);
  return (
    <Container>
      <BasicTable
        tableRowsData={brandData.brandDetails}
        removeBrand={removeBrand}
        removeBrandState={removeBrandState}
      />
    </Container>
  );
};

export default ListBrand;

ListBrand.propTypes = {
  brandData: PropTypes.arrayOf.isRequired,
  removeBrand: PropTypes.func.isRequired,
  removeBrandState: PropTypes.objectOf.isRequired,
};
