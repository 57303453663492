import React from 'react';
import Proptypes from 'prop-types';
import {
  Container,
  Col,
  Row,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import PrescriptionForm from './PrescriptionForm';
import ReturnSummary from './ReturnSummary';

const AddReturn = ({
  onInputChangeHandler,
  activePageHandler,
  saveReturn,
  saveReturnState,
  activePage,
  deleteReturn,
  returnForm,
  addNewReturn,
  saveNewReturns,
  lensPowers,
  reasonOptions,
  metaV1Options,
  vendorOptions,
  currentFormIndex,
}) => {
  console.log('Add Cutomer');
  return (
    <Container>
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => activePageHandler('ListReturn')}
      >
        &lt;
      </Button>
      <Row>
        <Col md={12}>
          <h4 className="page-title">New Return</h4>
        </Col>
      </Row>
      <form onSubmit={(e) => e.preventDefault()} className="form">

        <Row>
          <Col md={3} className="form-group">
            <label htmlFor="selectVendor">SELECT VENDOR *</label>
            <Select
              name="vendorDetails"
              id="selectVendor"
              options={vendorOptions}
              onChange={(e) => onInputChangeHandler(e, 'vendorDetails', 'select')}
              clearable={false}
              className="react-select"
              placeholder="Search..."
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="vendorInvoiceId">VENDOR INVOICE ID</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'vendorInvoiceId', 'textInput')}
              name="vendorInvoiceId"
              component="input"
              type="text"
              placeholder="type here..."
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="vendorInvoiceDate">VENDOR INVOICE DATE</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'vendorInvoiceDate', 'textInput')}
              name="vendorInvoiceDate"
              component="input"
              type="date"
              placeholder="type here..."
            />
          </Col>
        </Row>
      </form>
      {activePage === 'AddReturn'
        && (
          <PrescriptionForm
            metaV1Options={metaV1Options}
            returnForm={returnForm}
            saveReturn={saveReturn}
            saveReturnState={saveReturnState}
            onInputChangeHandler={onInputChangeHandler}
            lensPowers={lensPowers}
            reasonOptions={reasonOptions}
            currentFormIndex={currentFormIndex}
          />
        )}
      {
        activePage === 'ReturnSummary'
        && (
          <ReturnSummary
            deleteReturn={deleteReturn}
            returnForm={returnForm}
            addNewReturn={addNewReturn}
            saveNewReturns={saveNewReturns}
            saveReturn={saveReturn}
          />
        )
      }

    </Container>
  );
};

AddReturn.propTypes = {
  onInputChangeHandler: Proptypes.func.isRequired,
  activePageHandler: Proptypes.func.isRequired,
  saveReturn: Proptypes.func.isRequired,
  saveReturnState: Proptypes.objectOf.isRequired,
  activePage: Proptypes.string.isRequired,
  deleteReturn: Proptypes.func.isRequired,
  returnForm: Proptypes.objectOf.isRequired,
  addNewReturn: Proptypes.func.isRequired,
  saveNewReturns: Proptypes.func.isRequired,
  lensPowers: Proptypes.objectOf.isRequired,
  reasonOptions: Proptypes.arrayOf.isRequired,
  metaV1Options: Proptypes.objectOf.isRequired,
  vendorOptions: Proptypes.arrayOf.isRequired,
  currentFormIndex: Proptypes.number.isRequired,
};

export default AddReturn;
