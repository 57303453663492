import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';

const Topbar = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
  // accountInfo,
  userInfo,
}) => (
  <div className="topbar">
    <div className="topbar__wrapper">
      <div className="topbar__left">
        <TopbarSidebarButton
          changeMobileSidebarVisibility={changeMobileSidebarVisibility}
          changeSidebarVisibility={changeSidebarVisibility}
        />
        <Link className="topbar__logo" to="/purchase/orders" />
      </div>
      <div className="topbar__right">
        <TopbarProfile
          // accountInfo={accountInfo}
          userInfo={userInfo}
        />
      </div>
    </div>
  </div>
);

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
  // accountInfo: PropTypes.objectOf.isRequired,
  userInfo: PropTypes.objectOf.isRequired,
};

export default Topbar;
