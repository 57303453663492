import { createAction } from 'redux-actions';
import api from '../../utils/apiClient';

export const saveReturnRequest = createAction('SAVE_RETURN_REQUEST');
export const saveReturnSuccess = createAction('SAVE_RETURN_SUCCESS');
export const saveReturnFailure = createAction('SAVE_RETURN_FAILURE');
export const defaultSaveReturn = createAction('DEFAULT_SAVE_RETURN');
export const addToReturnData = createAction('ADD_TO_RETURN_DATA');

export const fetchReturnRequest = createAction('FETCH_RETURN_REQUEST');
export const fetchReturnSuccess = createAction('FETCH_RETURN_SUCCESS');
export const fetchReturnFailure = createAction('FETCH_RETURN_FAILURE');

export const updateReturnRequest = createAction('UPDATE_RETURN_REQUEST');
export const updateReturnSuccess = createAction('UPDATE_RETURN_SUCCESS');
export const updateReturnFailure = createAction('UPDATE_RETURN_FAILURE');
export const updateToReturnData = createAction('UPDATE_TO_RETURN_DATA');
export const defaultUpdateReturn = createAction('DEFAULT_UPDATE_RETURN');

export const saveReturn = (data) => async (dispatch) => {
  try {
    dispatch(saveReturnRequest());
    const response = await api.returns.newReturn(data);
    console.log(response.data);
    dispatch(saveReturnSuccess(response.data));
    if (response.data.data) {
      dispatch(addToReturnData(response.data.data));
    }
    dispatch(defaultSaveReturn());
  } catch (e) {
    console.log(e);
    dispatch(saveReturnFailure(e));
  }
};

export const fetchReturn = (data) => async (dispatch) => {
  try {
    const params = {
      limit: data.limit,
      skip: data.skip,
    };
    console.log(params);
    dispatch(fetchReturnRequest());
    const response = await api.returns.get(params);
    console.log(response);
    dispatch(fetchReturnSuccess(response.data.data));
  } catch (e) {
    console.log(e);
    dispatch(fetchReturnFailure(e));
  }
};

export const updateReturn = (data) => async (dispatch) => {
  try {
    dispatch(updateReturnRequest());
    const response = await api.returns.updateReturn(data);
    console.log(response.data);
    dispatch(updateReturnSuccess(response.data));
    if (response.data.data) {
      dispatch(updateToReturnData(response.data.data));
    }
    dispatch(defaultUpdateReturn());
  } catch (e) {
    console.log(e);
    dispatch(updateReturnFailure(e));
  }
};
