import { handleActions } from 'redux-actions';
import {
  fetchInventoryTrackRequest,
  fetchInventoryTrackSuccess,
  fetchInventoryTrackFailure,
} from '../actions/inventoryTrackActions';

const defaultFetchState = {
  isfetching: false,
  error: null,
  inventoryTrackDetails: [],
  limit: 500,
  skip: 0,
};

const inventoryTrackFetchReducer = handleActions(
  {
    [fetchInventoryTrackRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchInventoryTrackSuccess](state, { payload }) {
      return {
        ...state,
        inventoryTrackDetails: payload,
        limit: state.limit,
        skip: state.skip,
        isFetching: false,
        error: null,
      };
    },
    [fetchInventoryTrackFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    },
  },
  defaultFetchState,
);

export default inventoryTrackFetchReducer;
