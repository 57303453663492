import { handleActions } from 'redux-actions';
import _ from 'lodash';
import {
  saveCustomerRequest,
  saveCustomerSuccess,
  saveCustomerFailure,
  defaultSaveCustomer,
  addToCustomerData,
  fetchCustomerRequest,
  fetchCustomerSuccess,
  fetchCustomerFailure,
  removeCustomerRequest,
  removeCustomerSuccess,
  removeCustomerFailure,
  defaultRemoveCustomer,
  removeFromCustomerData,
} from '../actions/customerActions';

const defaultSaveState = {
  isSaving: false,
  error: null,
  customerDetails: {},
};

const defaultRemoveState = {
  isRemoving: false,
  error: null,
  customerDetails: {},
};

const defaultFetchState = {
  isfetching: false,
  error: null,
  customerDetails: [],
  limit: 500,
  skip: 0,
};

export const customerSaveReducer = handleActions(
  {
    [saveCustomerRequest](state) {
      console.log(state);
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    },
    [saveCustomerSuccess](state, { payload }) {
      return {
        ...state,
        customerDetails: payload,
        isSaving: false,
        error: null,
      };
    },
    [saveCustomerFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isSaving: false,
      };
    },
    [defaultSaveCustomer](state) {
      return {
        ...state,
        customerDetails: {},
        error: null,
        isSaving: false,
      };
    },
  },
  defaultSaveState,
);

export const customerRemoveReducer = handleActions(
  {
    [removeCustomerRequest](state) {
      console.log(state);
      return {
        ...state,
        isRemoving: true,
        error: null,
      };
    },
    [removeCustomerSuccess](state, { payload }) {
      return {
        ...state,
        customerDetails: payload,
        isRemoving: false,
        error: null,
      };
    },
    [removeCustomerFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isRemoving: false,
      };
    },
    [defaultRemoveCustomer](state) {
      return {
        ...state,
        customerDetails: {},
        error: null,
        isRemoving: false,
      };
    },
  },
  defaultRemoveState,
);

export const customerFetchReducer = handleActions(
  {
    [fetchCustomerRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchCustomerSuccess](state, { payload }) {
      return {
        ...state,
        customerDetails: payload,
        limit: state.limit,
        skip: state.skip,
        isFetching: false,
        error: null,
      };
    },
    [fetchCustomerFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    },
    [addToCustomerData](state, { payload }) {
      return {
        ...state,
        customerDetails: [payload, ...state.customerDetails],
        error: null,
      };
    },
    [removeFromCustomerData](state, { payload }) {
      /* eslint no-underscore-dangle: 0 */
      const cloneState = _.cloneDeep(state);

      cloneState.customerDetails.map((cloned, index) => {
        if (cloned._id === payload._id) {
          cloneState.customerDetails.splice(index, 1);
        }
        return 1;
      });

      return {
        ...state,
        customerDetails: cloneState.customerDetails,
        error: null,
      };
    },
  },
  defaultFetchState,
);
