import { createAction } from 'redux-actions';
import api from '../../utils/apiClient';

export const fetchAccountDataRequest = createAction('FETCH_ACCOUNT_DATA_REQUEST');
export const fetchAccountDataSuccess = createAction('FETCH_ACCOUNT_DATA_SUCCESS');
export const fetchAccountDataFailure = createAction('FETCH_ACCOUNT_DATA_FAILURE');

export const saveUserRequest = createAction('SAVE_USER_REQUEST');
export const saveUserSuccess = createAction('SAVE_USER_SUCCESS');
export const saveUserFailure = createAction('SAVE_USER_FAILURE');
export const defaultSaveUser = createAction('DEFAULT_USER');
export const addToUserData = createAction('ADD_TO_USER_DATA');

export const fetchUserRequest = createAction('FETCH_USER_REQUEST');
export const fetchUserSuccess = createAction('FETCH_USER_SUCCESS');
export const fetchUserFailure = createAction('FETCH_USER_FAILURE');

export const fetchCurrentUserRequest = createAction('FETCH_CURREENT_USER_REQUEST');
export const fetchCurrentUserSuccess = createAction('FETCH_CURRENT_USER_SUCCESS');
export const fetchCurrentUserFailure = createAction('FETCH_CURRENT_USER_FAILURE');

export const fetchAccount = () => async (dispatch) => {
  try {
    dispatch(fetchAccountDataRequest());
    const response = await api.account.getAccount();
    console.log(response);
    dispatch(fetchAccountDataSuccess(response.data.data.data[0]));
  } catch (e) {
    dispatch(fetchAccountDataFailure(e));
  }
};

export const fetchCurrentUser = (user) => async (dispatch) => {
  try {
    dispatch(fetchCurrentUserRequest());
    const response = await api.account.getCurrentUser(user);
    dispatch(fetchCurrentUserSuccess(response.data.data.data));
  } catch (e) {
    dispatch(fetchCurrentUserFailure(e));
  }
};

export const saveUser = (data) => async (dispatch) => {
  try {
    dispatch(saveUserRequest());
    const response = await api.account.createUser(data);
    if (response.data) {
      dispatch(saveUserSuccess(response.data.data));
      dispatch(addToUserData(response.data.data.data));
    }
    dispatch(defaultSaveUser());
  } catch (e) {
    console.log(e);
    dispatch(saveUserFailure(e));
  }
};

export const fetchUser = (data) => async (dispatch) => {
  try {
    const params = {
      limit: data.limit,
      skip: data.skip,
    };
    console.log(params);
    dispatch(fetchUserRequest());
    const response = await api.account.getUser(params);
    console.log(response);
    if (response.data.data) {
      dispatch(fetchUserSuccess(response.data.data.data));
    }
  } catch (e) {
    console.log(e);
    dispatch(fetchUserFailure(e));
  }
};
