import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Container, Button,
} from 'reactstrap';
import FullVariantsTable from '../../Tables/BasicTables/components/FullVariantsTable';

const FullVariants = ({
  tableRowsData,
  tableHeaderData,
  activePageHandler,
  purchaseForm,
  updateVariants,
  deleteVariant,
  currentViewDetailsData,
  viewDetailsMode,
  groupIndex,
}) => {
  console.log(currentViewDetailsData, groupIndex);
  return (
    <Container className="addpurchase">
      {!viewDetailsMode
        && (
          <Button
            outline
            className="backButton btn btn-sm"
            type="button"
            onClick={() => activePageHandler('NewPurchaseForm')}
          >
            {' < '}
          </Button>
        )}
      {viewDetailsMode
        && (
          <Button
            outline
            className="backButton btn btn-sm"
            type="button"
            onClick={() => activePageHandler('PurchaseSummary')}
          >
            {' < '}
          </Button>
        )}
      <Row className="smalldiv__cardsContainer" style={{ marginBottom: '10px' }}>

        <Col md={2} className="smalldiv__samerow">
          <span>LENS TYPE</span>
          {!viewDetailsMode
            && <p style={{ fontWeight: 600 }}>{purchaseForm.lensType ? purchaseForm.lensType.value : ''}</p>}
          {viewDetailsMode
            && <p style={{ fontWeight: 600 }}>{currentViewDetailsData.groups[groupIndex].LensType}</p>}
        </Col>

        <Col md={2} className="smalldiv__samerow">
          <span>LENS MATERIAL</span>
          {!viewDetailsMode
            && <p style={{ fontWeight: 600 }}>{purchaseForm.lensMaterial ? purchaseForm.lensMaterial.value : ''}</p>}
          {viewDetailsMode
            && <p style={{ fontWeight: 600 }}>{currentViewDetailsData.groups[groupIndex].LensMaterial}</p>}
        </Col>

        <Col md={2} className="smalldiv__samerow">
          <span>ORDERED VARIANTS</span>
          {!viewDetailsMode
            && <p style={{ fontWeight: 600 }}>{purchaseForm.lensMaterial ? purchaseForm.lensMaterial.value : ''}</p>}
          {viewDetailsMode
            && <p style={{ fontWeight: 600 }}>{currentViewDetailsData.groups[groupIndex].variants.length}</p>}
        </Col>
        <Col md={2} className="smalldiv__samerow">
          <span>ORDERED QUANTITY</span>
          {viewDetailsMode
            && (
              <p style={{ fontWeight: 600 }}>
                {currentViewDetailsData.groups[groupIndex].variants.reduce((prev, current) => {
                  const quantity = Number(prev) + Number(current.Quantity);
                  return quantity;
                }, 0)}
              </p>
            )}
        </Col>
      </Row>
      <FullVariantsTable
        updateVariants={updateVariants}
        deleteVariant={deleteVariant}
        tableRowsData={tableRowsData}
        tableHeaderData={tableHeaderData}
        currentViewDetailsData={currentViewDetailsData}
        viewDetailsMode={viewDetailsMode}
        groupIndex={groupIndex}
        from="PO"
      />
      <Row style={{ marginTop: '15px' }}>
        <Col md={2} className="form-group">
          <Button
            onClick={() => activePageHandler('PurchaseSummary')}
            className="rounded"
            color="success"
            size="sm"
          >
            Submit
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

FullVariants.propTypes = {
  tableHeaderData: PropTypes.arrayOf.isRequired,
  tableRowsData: PropTypes.arrayOf.isRequired,
  purchaseForm: PropTypes.arrayOf.isRequired,
  activePageHandler: PropTypes.func.isRequired,
  updateVariants: PropTypes.func.isRequired,
  deleteVariant: PropTypes.func.isRequired,
  viewDetailsMode: PropTypes.bool.isRequired,
  currentViewDetailsData: PropTypes.objectOf.isRequired,
  groupIndex: PropTypes.number.isRequired,
};

export default FullVariants;
