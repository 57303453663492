import { get, post } from './base/index';

export default {
  newCheckout: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/wcustomerorder/checkout`, body);
  },
  get: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}v2/inventory/client/wcustomerorder/checkout`, { params });
  },
};
