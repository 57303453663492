import React from 'react';
import PropTypes from 'prop-types';
import AdjustmentSummaryTableData from '../../Tables/AdjustmentsTables/components/SummaryTables';

function AdjustmentSummaryTableList({
  adjustmentForm,
  deleteAdjustmentVariant,
}) {
  return (
    <div className="inventory__adjustments__table">
      <AdjustmentSummaryTableData
        adjustmentForm={adjustmentForm}
        deleteAdjustmentVariant={deleteAdjustmentVariant}
      />
    </div>
  );
}

export default AdjustmentSummaryTableList;

AdjustmentSummaryTableList.propTypes = {
  adjustmentForm: PropTypes.arrayOf.isRequired,
  deleteAdjustmentVariant: PropTypes.func.isRequired,
};
