import React from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import BasicTable from '../../Tables/BasicTables/components/ListUser';

const ListUser = ({
  userDataState,
}) => {
  console.log(userDataState);
  return (
    <Container>
      <BasicTable
        tableRowsData={userDataState.userDetails}
      />
    </Container>
  );
};

ListUser.propTypes = {
  userDataState: PropTypes.arrayOf.isRequired,
};

export default ListUser;
