export const getOicStorageKey = () => {
  const authSettings = localStorage.getItem('oicToken');
  if (authSettings) {
    return authSettings;
  }
  return null;
};

export const getOicInfo = () => {
  const key = getOicStorageKey();
  if (key) {
    const oic = JSON.parse(key);
    return oic;
  }
  return null;
};

export const decodeJWTToken = (token) => {
  const payload = JSON.parse(atob(token.split('.')[1]));
  return payload;
};

export const getToken = () => {
  const oic = getOicInfo();
  if (oic) {
    return {
      'oic-authorization': oic.token,
      'oic-refresh-token': oic.refreshToken,
    };
  }
  return null;
};
