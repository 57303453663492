import { createAction } from 'redux-actions';
import api from '../../utils/apiClient';

export const saveVendorRequest = createAction('SAVE_VENDOR_REQUEST');
export const saveVendorSuccess = createAction('SAVE_VENDOR_SUCCESS');
export const saveVendorFailure = createAction('SAVE_VENDOR_FAILURE');
export const defaultSaveVendor = createAction('DEFAULT_VENDOR');
export const addToVendorData = createAction('ADD_TO_VENDOR_DATA');

export const fetchVendorRequest = createAction('FETCH_VENDOR_REQUEST');
export const fetchVendorSuccess = createAction('FETCH_VENDOR_SUCCESS');
export const fetchVendorFailure = createAction('FETCH_VENDOR_FAILURE');

export const saveVendor = (data) => async (dispatch) => {
  try {
    dispatch(saveVendorRequest());
    const response = await api.vendor.saveVendor(data);
    console.log(response.data);
    dispatch(saveVendorSuccess(response.data));
    if (response.data.data) {
      dispatch(addToVendorData(response.data.data));
    }
    dispatch(defaultSaveVendor());
  } catch (e) {
    console.log(e);
    dispatch(saveVendorFailure(e));
  }
};

export const fetchVendor = (data) => async (dispatch) => {
  try {
    const params = {
      limit: data.limit,
      skip: data.skip,
    };
    console.log(params);
    dispatch(fetchVendorRequest());
    const response = await api.vendor.getVendors(params);
    console.log(response);
    dispatch(fetchVendorSuccess(response.data.data));
  } catch (e) {
    console.log(e);
    dispatch(fetchVendorFailure(e));
  }
};
