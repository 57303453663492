import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import api from '../../utils/apiClient';
import AddGrn from './components/AddGrn';
import ListGrn from './components/ListGrn';
import FullVariants from './components/FullVariants';
import { saveGrn, fetchGrn, updateGrn } from '../../redux/actions/grnActions';
import Modal from '../../shared/components/modal/Modal';

const variantsHeaderData = [
  { id: 7, title: 'S.No' },
  { id: 1, title: 'Lens Type' },
  { id: 2, title: 'Spherical' },
  { id: 3, title: 'Cylindrical' },
  { id: 5, title: 'Axis' },
  { id: 4, title: 'Add' },
  { id: 6, title: 'Order Quantity' },
  { id: 8, title: 'Received Quantity' },
];

const grnHeader = [
  { id: 1, title: 'GRN ID/Date' },
  { id: 2, title: 'Customer Order ID/Date' },
  { id: 3, title: 'Customer Name' },
  { id: 7, title: 'Reference Order ID/Date' },
  { id: 4, title: 'Purchase Order ID/Date' },
  { id: 5, title: 'Vendor Name' },
  { id: 6, title: 'Vendor Invoice ID/Date' },
];

const GoodsReceiptNote = ({
  saveGrnAction,
  grnSave,
  grnData,
  fetchGrnAction,
  updateGrnAction,
  grnUpdate,
}) => {
  const [activePage, setActivePage] = useState('ListGrn');
  const [grnForm, setGrnForm] = useState({});
  const [searchedPo, setSearchedPo] = useState({});
  const [searchedPoStatus, setSearchedPoStatus] = useState('isSearched');
  // const [grId, setGrId] = useState(false);
  const [currentViewDetailsData, setCurrentViewDetailsData] = useState({});
  const [viewDetailsMode, setViewDetailsMode] = useState(false);
  const [groupIndex, setGroupIndex] = useState(false);
  const [totals, setTotals] = useState({});
  const [activeModal, setActiveModal] = useState('');

  useEffect(() => {
    // if (!grnData || grnData.grnDetails.length === 0) {
    fetchGrnAction(grnData);
    // }
  }, []);

  const onInputChangeHandler = (e, fieldName, type, formName) => {
    let fieldValue;

    if (type === 'select') {
      fieldValue = e;
    }

    if (type === 'textInput') {
      fieldValue = e.target.value;
    }

    if (formName === 'grnForm') {
      const grnFormSetter = { ...grnForm, [fieldName]: fieldValue };
      setGrnForm(grnFormSetter);
    }

    if (formName === 'searchedPo') {
      const searchedPoSetter = { ...searchedPo, [fieldName]: fieldValue };
      setSearchedPo(searchedPoSetter);
    }
  };

  const splitForPurchaseSummary = (data) => {
    const dataLocal = _.cloneDeep(data);

    const freshVariants = [];

    dataLocal.variants.map((variant) => {
      let exists = false;
      freshVariants.map((freshVariant, index) => {
        if ((freshVariant.LensType === variant.LensType) && (freshVariant.LensMaterial === variant.LensMaterial)) {
          exists = true;

          freshVariants[index].variants.push(variant);
        }
        return 1;
      });

      if (!exists) {
        freshVariants.push({
          LensType: variant.LensType,
          LensMaterial: variant.LensMaterial,
          Brand: variant.Brand || '',
          Model: variant.Model || '',
          variants: [variant],
        });
      }
      exists = false;

      return 1;
    });

    delete dataLocal.variants;

    dataLocal.groups = freshVariants;
    setSearchedPo(dataLocal);
  };

  const activePageHandler = (pageName) => {
    if (pageName === 'ListGrn') {
      setCurrentViewDetailsData({});
      setSearchedPoStatus('isSearched');
      setViewDetailsMode(false);
      setSearchedPo({});
      setGrnForm({});
    }

    setActivePage(pageName);
  };

  const activeModalAction = (action, close) => {
    console.log(action);
    if (action === 'ListGrn') {
      activePageHandler(action);
    }
    if (action === 'AddGrn') {
      activePageHandler(action);
    }

    if (close) {
      setActiveModal('');
    } else {
      setActiveModal(action);
    }
  };

  const searchPurchaseOrder = async () => {
    setSearchedPo({});
    const filter = {};
    filter.orderId = grnForm.poOrderId;

    const data = { filter };

    setSearchedPoStatus('isSearching');
    try {
      const response = await api.purchaseOrder.get(data);
      if (response.data && response.data.data && response.data.data.length > 0) {
        if (response.data.data[0].status === 'Processed') {
          toast.error('Order already processed!');
        } else {
          splitForPurchaseSummary(response.data.data[0]);
          setSearchedPoStatus('isSearched');
          activeModalAction('AddGrn', 'close');
        }
      } else {
        toast.error('OrderId not found!');
      }
    } catch (err) {
      toast.error('Unexpected error :(');
    }
  };

  // const getGrnCount = async () => {
  //   try {
  //     const response = await api.grn.getGrnCount();
  //     if (response.data && response.data.data !== undefined) {
  //       setGrId(response.data.data + 1);
  //     } else {
  //       toast.error('Unable to get gr id!');
  //     }
  //   } catch (err) {
  //     toast.error('Unexpected error :(');
  //   }
  // };

  const searchPo = async () => {
    await searchPurchaseOrder();
  };

  const updateVariants = (e, index) => {
    if (new RegExp('^\\d+$').test(e.target.value) === false) {
      return false;
    }

    if (!viewDetailsMode) {
      const searchedPoLocal = _.cloneDeep(searchedPo);
      searchedPoLocal.groups[groupIndex].variants[index].ReceivedQuantity = Number(e.target.value);
      setSearchedPo(searchedPoLocal);
    } else {
      const currentViewDetailsDataLocal = _.cloneDeep(currentViewDetailsData);
      currentViewDetailsDataLocal.groups[groupIndex].variants[index].ReceivedQuantity = e.target.value;
      setCurrentViewDetailsData(currentViewDetailsDataLocal);
    }
    return true;
  };

  useEffect(() => {
    if (!grnUpdate.error
      && !grnUpdate.isUpdating
      && grnUpdate.updatedGrnDetails) {
      if (grnUpdate.updatedGrnDetails.data && Object.keys(grnUpdate.updatedGrnDetails.data).length > 0) {
        toast.success('Updated Successfully!');
        activePageHandler('ListGrn');
      }
      if (grnUpdate.updatedGrnDetails.message !== 'success') {
        toast.error(grnUpdate.updatedGrnDetails.message);
      }
    }
  }, [grnUpdate]);

  const viewDetails = (data) => {
    const dataLocal = _.cloneDeep(data);

    const freshVariants = [];

    dataLocal.variants.map((variant) => {
      let exists = false;
      freshVariants.map((freshVariant, index) => {
        if ((freshVariant.LensType === variant.LensType) && (freshVariant.LensMaterial === variant.LensMaterial)) {
          exists = true;

          freshVariants[index].variants.push(variant);
        }
        return 1;
      });

      if (!exists) {
        freshVariants.push({
          LensType: variant.LensType,
          LensMaterial: variant.LensMaterial,
          Brand: variant.Brand || '',
          Model: variant.Model || '',
          variants: [variant],
        });
      }
      exists = false;

      return 1;
    });

    delete dataLocal.variants;

    dataLocal.groups = freshVariants;
    console.log(dataLocal);

    setCurrentViewDetailsData(dataLocal);
    setViewDetailsMode(true);
    activePageHandler('AddGrn');
  };

  useEffect(() => {
    if (!grnSave.error
      && !grnSave.isSaving
      && grnSave.grnDetails) {
      if (grnSave.grnDetails.data && Object.keys(grnSave.grnDetails.data).length > 0) {
        setActiveModal('ReceiptSuccess');
      }
      if (grnSave.grnDetails.message !== 'success') {
        toast.error(grnSave.grnDetails.message);
      }
    }

    if (grnSave.error) {
      toast.error('Failed to save!');
    }
  }, [grnSave]);

  const viewSpecificDetails = (pageName, index) => {
    activePageHandler(pageName);
    setGroupIndex(index);
  };

  const submitReceipt = () => {
    console.log(searchedPo);
    if (!viewDetailsMode) {
      const searchedPoLocal = _.cloneDeep(searchedPo);

      const freshVariants = [];
      const totalsLocal = {
        totalQuantity: 0,
        totalReceivedQuantity: 0,
      };

      searchedPoLocal.groups.map((group) => {
        group.variants.map((variant) => {
          totalsLocal.totalQuantity = variant.Quantity
            ? (totalsLocal.totalQuantity + variant.Quantity) : totalsLocal.totalQuantity;
          totalsLocal.totalReceivedQuantity = variant.ReceivedQuantity
            ? (totalsLocal.totalReceivedQuantity + variant.ReceivedQuantity) : totalsLocal.totalReceivedQuantity;
          freshVariants.push(variant);
          return 1;
        });
        return 1;
      });

      setTotals(totalsLocal);
      delete searchedPoLocal.groups;

      searchedPoLocal.variants = freshVariants;

      console.log(searchedPoLocal);

      if (!searchedPoLocal.supplier
        || !searchedPoLocal.vendorInvoiceId
        || !searchedPoLocal.vendorInvoiceDate
        || searchedPoLocal.variants.length === 0) {
        toast.error('Please fill all the mandatory fields!');
        return 1;
      }

      const formedBody = {};

      /* eslint no-underscore-dangle: 0 */

      formedBody.customerOrderDate = searchedPoLocal.customerOrderDate;
      formedBody.customerOrderId = searchedPoLocal.customerOrderId;
      formedBody.sysCustomerOrderDate = searchedPoLocal.sysCustomerOrderDate;
      formedBody.sysCustomerOrderId = searchedPoLocal.sysCustomerOrderId;
      formedBody.customerName = searchedPoLocal.customerName;
      formedBody.supplier = searchedPoLocal.supplier;
      formedBody.supplierId = searchedPoLocal.supplierId;
      formedBody.poId = searchedPoLocal.orderId;
      formedBody.poDate = searchedPoLocal._created_at;
      formedBody.category = 'LENS';
      formedBody.variants = searchedPoLocal.variants;
      formedBody.vendorInvoiceId = searchedPoLocal.vendorInvoiceId;
      formedBody.vendorInvoiceDate = searchedPoLocal.vendorInvoiceDate;
      formedBody._id = searchedPoLocal._id;

      console.log(formedBody);

      saveGrnAction(formedBody);
    }
    if (viewDetailsMode) {
      const currentViewDetailsDataLocal = _.cloneDeep(currentViewDetailsData);

      const freshVariants = [];

      currentViewDetailsDataLocal.groups.map((group) => {
        group.variants.map((variant) => {
          freshVariants.push(variant);
          return 1;
        });
        return 1;
      });

      delete currentViewDetailsDataLocal.groups;

      currentViewDetailsDataLocal.variants = freshVariants;

      updateGrnAction(currentViewDetailsDataLocal);
    }
    return 1;
  };

  return (
    <div className="grn">
      {
        activeModal === 'ReceiptSuccess'
        && (
          <Modal
            modalStatus={activeModal === 'ReceiptSuccess'}
            modalAction={() => activeModalAction('ListGrn', 'close')}
            color="success"
            title="Well Done!"
            btn="Success"
            message={(
              <div>Receipt Generated Successfully! <br /><br />
                <span>Total Order Quantity: <b>{totals.totalQuantity}</b></span><br />
                <span>Total Received Quantity: <b>{totals.totalReceivedQuantity || 0}</b></span>
              </div>
            )}
          />
        )
      }
      {
        activeModal === 'EnterPOId'
        && (
          <Modal
            modalStatus={activeModal === 'EnterPOId'}
            header
            title="New GRN"
            color="success"
            modalAction={() => activeModalAction('', 'close')}
            message={(
              <form onSubmit={(e) => e.preventDefault()} className="form">
                <Row style={{ alignItems: 'center' }}>
                  <Col md={6} className="form-group">
                    <label htmlFor="sph">PURCHASE ORDER ID</label>
                    <input
                      onChange={(e) => onInputChangeHandler(e, 'poOrderId', 'textInput', 'grnForm')}
                      name="searchPo"
                      component="input"
                      value={grnForm.poOrderId}
                      type="text"
                      placeholder="type here..."
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                  <Col md={12}>
                    <Button
                      type="button"
                      className="rounded btn-sm"
                      color="success"
                      onClick={() => searchPo()}
                      disabled={Object.keys(searchedPo).length > 0}
                    >
                      {(Object.keys(searchedPo).length === 0)
                        ? 'Submit' : 'Please wait...'}
                    </Button>
                  </Col>
                </Row>
              </form>
            )}
          />
        )
      }
      {
        activePage === 'ListGrn'
        && (
          <ListGrn
            activeModalAction={activeModalAction}
            grnHeaderData={grnHeader}
            viewDetails={viewDetails}
            grnRowsData={grnData}
          />
        )
      }
      {
        activePage === 'AddGrn'
        && (
          <AddGrn
            groupIndex={groupIndex}
            viewSpecificDetails={viewSpecificDetails}
            viewDetailsMode={viewDetailsMode}
            currentViewDetailsData={currentViewDetailsData}
            searchedPoStatus={searchedPoStatus}
            grnForm={grnForm}
            onInputChangeHandler={onInputChangeHandler}
            activePageHandler={activePageHandler}
            searchPo={searchPo}
            searchedPo={searchedPo}
            submitReceipt={submitReceipt}
            grnSave={grnSave}
            grnUpdate={grnUpdate}
          // grId={grId}
          />
        )
      }
      {
        activePage === 'FullVariants'
        && (
          <FullVariants
            groupIndex={groupIndex}
            updateVariants={updateVariants}
            searchedPo={searchedPo}
            activePageHandler={activePageHandler}
            variantsHeaderData={variantsHeaderData}
            tableRowsData={searchedPo}
            viewDetailsMode={viewDetailsMode}
            currentViewDetailsData={currentViewDetailsData}
          />
        )
      }
    </div>
  );
};

GoodsReceiptNote.propTypes = {
  saveGrnAction: PropTypes.func.isRequired,
  grnSave: PropTypes.objectOf.isRequired,
  grnData: PropTypes.arrayOf.isRequired,
  fetchGrnAction: PropTypes.func.isRequired,
  updateGrnAction: PropTypes.func.isRequired,
  grnUpdate: PropTypes.objectOf.isRequired,
};

const mapStateToProps = (state) => ({
  grnSave: state.grnSave,
  grnData: state.grnData,
  grnUpdate: state.grnUpdate,
});

const mapDispatchToProps = {
  saveGrnAction: saveGrn,
  fetchGrnAction: fetchGrn,
  updateGrnAction: updateGrn,
};

export default connect(mapStateToProps, mapDispatchToProps)(GoodsReceiptNote);
