import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Container, Button,
} from 'reactstrap';
import FullVariantsTable from '../../Tables/BasicTables/components/FullVariantsInventoryTrack';

const FullVariants = ({
  currentFullVariantsData,
  activePageHandler,
}) => {
  console.log(currentFullVariantsData);
  return (
    <Container className="addpurchase">
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => activePageHandler('InventoryTrackGroupSummary')}
      >
        {' < '}
      </Button>
      <Row className="smalldiv__cardsContainer" style={{ marginBottom: '10px' }}>
        <Col md={2} className="smalldiv__samerow">
          <span>LENS TYPE</span>
          <p style={{ fontWeight: 600 }}>{currentFullVariantsData.LensType || '-'}</p>
        </Col>

        <Col md={2} className="smalldiv__samerow">
          <span>LENS Material</span>
          <p style={{ fontWeight: 600 }}>{currentFullVariantsData.LensMaterial || '-'}</p>
        </Col>

        <Col md={2} className="smalldiv__samerow">
          <span>Total Inventories</span>
          <p style={{ fontWeight: 600 }}>{currentFullVariantsData.totalInventories || '-'}</p>
        </Col>
      </Row>
      <FullVariantsTable
        tableRowsData={currentFullVariantsData.inventories ? currentFullVariantsData.inventories : []}
      />
    </Container>
  );
};

FullVariants.propTypes = {
  activePageHandler: PropTypes.func.isRequired,
  currentFullVariantsData: PropTypes.objectOf.isRequired,
};

export default FullVariants;
