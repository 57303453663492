import React from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import BasicTable from '../../Tables/BasicTables/components/ListCustomerOrder';

const ListGrn = ({
  customerOrderData,
  viewDetails,
}) => {
  console.log(customerOrderData);
  return (
    <Container>
      <BasicTable
        tableRowsData={customerOrderData}
        viewDetails={viewDetails}
      />
    </Container>
  );
};

export default ListGrn;

ListGrn.propTypes = {
  customerOrderData: PropTypes.arrayOf.isRequired,
  viewDetails: PropTypes.func.isRequired,
};
