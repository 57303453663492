import { handleActions } from 'redux-actions';
import _ from 'lodash';
import {
  saveReturnRequest,
  saveReturnSuccess,
  saveReturnFailure,
  defaultSaveReturn,
  addToReturnData,
  fetchReturnRequest,
  fetchReturnSuccess,
  fetchReturnFailure,
  updateReturnRequest,
  updateReturnSuccess,
  updateReturnFailure,
  defaultUpdateReturn,
  updateToReturnData,
} from '../actions/returnActions';

const defaultSaveState = {
  isSaving: false,
  error: null,
  returnDetails: {},
};

const defaultUpdateState = {
  isUpdating: false,
  error: null,
  returnDetails: {},
};

const defaultFetchState = {
  isfetching: false,
  error: null,
  returnDetails: [],
  limit: 500,
  skip: 0,
};

export const returnSaveReducer = handleActions(
  {
    [saveReturnRequest](state) {
      console.log(state);
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    },
    [saveReturnSuccess](state, { payload }) {
      return {
        ...state,
        returnDetails: payload,
        isSaving: false,
        error: null,
      };
    },
    [saveReturnFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isSaving: false,
      };
    },
    [defaultSaveReturn](state) {
      return {
        ...state,
        returnDetails: {},
        error: null,
        isSaving: false,
      };
    },
  },
  defaultSaveState,
);

export const returnFetchReducer = handleActions(
  {
    [fetchReturnRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchReturnSuccess](state, { payload }) {
      return {
        ...state,
        returnDetails: payload,
        limit: state.limit,
        skip: state.skip,
        isFetching: false,
        error: null,
      };
    },
    [fetchReturnFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    },
    [addToReturnData](state, { payload }) {
      return {
        ...state,
        returnDetails: [payload, ...state.returnDetails],
        error: null,
      };
    },
    /* eslint no-underscore-dangle: 0 */
    [updateToReturnData](state, { payload }) {
      const returnDetailsLocal = _.cloneDeep(state.returnDetails);
      const foundIndex = returnDetailsLocal.findIndex((returnData) => returnData._id === payload._id);

      returnDetailsLocal[foundIndex] = payload;

      console.log(returnDetailsLocal);
      return {
        ...state,
        returnDetails: returnDetailsLocal,
      };
    },
  },
  defaultFetchState,
);

export const returnUpdateReducer = handleActions(
  {
    [updateReturnRequest](state) {
      console.log(state);
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [updateReturnSuccess](state, { payload }) {
      return {
        ...state,
        returnDetails: payload,
        isUpdating: false,
        error: null,
      };
    },
    [updateReturnFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isUpdating: false,
      };
    },
    [defaultUpdateReturn](state) {
      return {
        ...state,
        returnDetails: {},
        error: null,
        isUpdating: false,
      };
    },
  },
  defaultUpdateState,
);
