import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Table,
} from 'reactstrap';

const tableHeaderData = [
  { id: 1, title: 'S.NO' },
  { id: 4, title: 'Lens Type' },
  { id: 5, title: 'Lens Material' },
  { id: 7, title: 'Order Variants' },
  { id: 6, title: 'Order Quantity' },
  { id: 9, title: 'Received Variants' },
  { id: 8, title: 'Received Quantity' },
];

const PurchaseSummaryTable = ({
  viewSpecificDetails,
  currentViewDetailsData,
  viewDetailsMode,
  tableRowsData,
}) => {
  console.log(tableRowsData);
  const processSearchPOLocal = _.cloneDeep(tableRowsData);
  const currentViewDetailsDataLocal = _.cloneDeep(currentViewDetailsData);

  let totalOrderVariants = 0;
  let totalOrderQuantity = 0;
  let totalReceivedVariants = 0;
  let totalReceivedQuantity = 0;

  if (viewDetailsMode) {
    currentViewDetailsDataLocal.groups.map((group, index) => {
      group.variants.map((variant) => {
        totalOrderQuantity += variant.Quantity;
        totalOrderVariants += 1;

        currentViewDetailsDataLocal.groups[index].totalQuantity = group.totalQuantity
          ? (group.totalQuantity + Number(variant.Quantity)) : Number(variant.Quantity);

        currentViewDetailsDataLocal.groups[index].totalReceivedQuantity = group.totalReceivedQuantity
          ? group.totalReceivedQuantity + Number(variant.ReceivedQuantity) : Number(variant.ReceivedQuantity);

        totalReceivedQuantity += Number(variant.ReceivedQuantity);

        currentViewDetailsDataLocal.groups[index].totalOrderVariants = group.totalOrderVariants
          ? group.totalOrderVariants + 1 : 1;

        if (variant.ReceivedQuantity) {
          currentViewDetailsDataLocal.groups[index].totalReceivedVariants = group.totalReceivedVariants
            ? group.totalReceivedVariants + 1 : 1;

          totalReceivedVariants += 1;
        }

        return 1;
      });
      return 1;
    });

    console.log(currentViewDetailsDataLocal);
  } else {
    processSearchPOLocal.groups.map((group, index) => {
      group.variants.map((variant) => {
        totalOrderQuantity += variant.Quantity;
        totalOrderVariants += 1;

        processSearchPOLocal.groups[index].totalOrderVariants = group.totalOrderVariants
          ? group.totalOrderVariants + 1 : 1;

        if (variant.ReceivedQuantity) {
          totalReceivedVariants += 1;

          processSearchPOLocal.groups[index].totalReceivedVariants = group.totalReceivedVariants
            ? group.totalReceivedVariants + 1 : 1;
        }

        processSearchPOLocal.groups[index].totalQuantity = group.totalQuantity
          ? variant.Quantity + group.totalQuantity : variant.Quantity;

        if (group.totalReceivedQuantity === undefined) {
          processSearchPOLocal.groups[index].totalReceivedQuantity = variant.ReceivedQuantity;
        } else if (variant.ReceivedQuantity === undefined) {
          processSearchPOLocal.groups[index].totalReceivedQuantity = group.totalReceivedQuantity;
        } else {
          processSearchPOLocal.groups[index].totalReceivedQuantity = variant.ReceivedQuantity
            + group.totalReceivedQuantity;
        }

        if (variant.ReceivedQuantity) {
          totalReceivedQuantity += Number(variant.ReceivedQuantity);
        }

        // console.log(processSearchPOLocal.groups[index].totalReceivedQuantity);
        return 1;
      });
      return 1;
    });
  }

  return (
    <Table responsive hover>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {
          viewDetailsMode
            ? (
              currentViewDetailsDataLocal.groups.map((group, index) => (
                <>
                  <tr>
                    <td>{index + 1}</td>

                    <td>{group.LensType || '-'}</td>

                    <td>{group.LensMaterial || '-'}</td>

                    <td>{group.totalOrderVariants || '-'}</td>

                    <td>{group.totalQuantity || 0}</td>

                    <td>{group.totalReceivedVariants || 0}</td>

                    <td>{group.totalReceivedQuantity || 0}</td>

                    <td style={{ color: '#03a5a5' }}>
                      <div
                        role="presentation"
                        onClick={() => viewSpecificDetails('FullVariants', index)}
                      >
                        View/Update Receipt
                      </div>
                    </td>
                  </tr>
                  {
                    ((currentViewDetailsDataLocal.groups.length - 1) === index)
                    && (
                      <tr>
                        <td />
                        <td />
                        <td><b>Grand Total:</b></td>
                        <td><b>{totalOrderVariants}</b></td>
                        <td><b>{totalOrderQuantity}</b></td>
                        <td><b>{totalReceivedVariants}</b></td>
                        <td><b>{totalReceivedQuantity}</b></td>
                        <td />
                      </tr>
                    )
                  }
                </>
              ))
            ) : (
              processSearchPOLocal.groups.map((group, index) => (
                <>
                  <tr>
                    <td>{index + 1}</td>

                    <td>{group.LensType || '-'}</td>

                    <td>{group.LensMaterial || '-'}</td>

                    <td>{group.totalOrderVariants || 0}</td>

                    <td>{group.totalQuantity || 0}</td>

                    <td>{group.totalReceivedVariants || 0}</td>

                    <td>{group.totalReceivedQuantity || 0}</td>

                    <td style={{ color: '#03a5a5' }}>
                      <div
                        role="presentation"
                        onClick={() => viewSpecificDetails('FullVariants', index)}
                      >
                        View/Update Receipt
                      </div>
                    </td>
                  </tr>
                  {
                    ((processSearchPOLocal.groups.length - 1) === index)
                    && (
                      <tr>
                        <td />
                        <td />
                        <td><b>Grand Total:</b></td>
                        <td><b>{totalOrderVariants}</b></td>
                        <td><b>{totalOrderQuantity}</b></td>
                        <td><b>{totalReceivedVariants}</b></td>
                        <td><b>{totalReceivedQuantity}</b></td>
                        <td />
                      </tr>
                    )
                  }
                </>
              ))
            )
        }
      </tbody>
    </Table>
  );
};

PurchaseSummaryTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
  currentViewDetailsData: PropTypes.objectOf.isRequired,
  viewDetailsMode: PropTypes.bool.isRequired,
  viewSpecificDetails: PropTypes.func.isRequired,
};

export default PurchaseSummaryTable;
