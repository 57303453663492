/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import AddIcon from '../../assets/sidebar_icons/add_icon/add.png';
import api from '../../utils/apiClient';

function OICReport() {
  /* eslint arrow-body-style: ["error", "never"] */
  /* eslint-env es6 */
  /* eslint no-underscore-dangle: 0 */
  // eslint-disable-next-line arrow-body-style, no-unused-vars
  const [getReports, setReports] = useState([]);
  // eslint-disable-next-line arrow-body-style
  useEffect(async () => {
    const payload = {
      limit: 500,
      skip: 0,
    };
    localStorage.removeItem('viewReport');
    try {
      const getReportList = await api.IocReportFuction.getReports(payload);
      // console.log('getReportList', getReportList);
      setReports(getReportList.data.data.length ? getReportList.data.data : []);
    } catch (e) {
      console.log(e);
      setReports([]);
    }
  }, []);
  const getReadOnlyDetails = (report) => {
    localStorage.setItem('viewReport', JSON.stringify(report));
  };
  return (
    <div className="container">
      <div className="row">
        <div className="smalldiv__listheader col-md-12">
          <h4>
            Reports
          </h4>
        </div>
      </div>
      {/* smalldiv__listheader col-md-12 */}
      <div className="flex-container">
        <div
          className="d-flex align-items-center justify-content-center Rectangle-478 text-center"
          style={{ backgroundColor: '#e1f5f5', marginRight: '10px' }}
        >
          <div>
            <NavLink
              to="/reports/add"
              activeClassName="sidebar__link-active"
            >
              <img
                src={AddIcon}
                alt="AddReportcard"
                className="border border-1 bg-light"
                style={{ width: '40px', borderRadius: '50%', borderColor: '#03a5a5' }}
              />
            </NavLink>
            <p className="fontsz-3" style={{ margin: '13px 3px 0px 0px', color: '#03a5a5' }}>New Report</p>
          </div>
        </div>
        {
          getReports.length > 0
          && (getReports.map((report) => (
            <div
              className="d-flex align-items-center justify-content-center Rectangle-478 text-center"
              style={{ backgroundColor: 'white', marginRight: '10px' }}
            >
              <div>
                <NavLink
                  to={`/reports/${report._id}`}
                  activeClassName="sidebar__link-active"
                >
                  <ScreenShareIcon onClick={() => { getReadOnlyDetails(report); }} style={{ fill: 'rgb(3, 165, 165)' }} />
                </NavLink>
                <p className="fontsz-3" style={{ margin: '13px 3px 0px 0px', color: 'black' }}>{report.reportName?.toUpperCase()}</p>
              </div>
            </div>
          )))
        }
      </div>
    </div>
  );
}

export default OICReport;
