import { createAction } from 'redux-actions';
import api from '../../utils/apiClient';

export const fetchPurchaseOrderCountRequest = createAction('FETCH_PURCHASE_ORDER_COUNT_REQUEST');
export const fetchPurchaseOrderCountSuccess = createAction('FETCH_PURCHASE_ORDER_COUNT_SUCCESS');
export const fetchPurchaseOrderCountFailure = createAction('FETCH_PURCHASE_ORDER_COUNT_FAILURE');
export const increasePurchaseOrderCountAction = createAction('INCREASE_PURCHASE_ORDER_COUNT');

export const fetchPurchaseOrderCount = () => async (dispatch) => {
  try {
    dispatch(fetchPurchaseOrderCountRequest());
    const response = await api.purchaseOrder.getPOCount();
    dispatch(fetchPurchaseOrderCountSuccess(response.data.data));
  } catch (e) {
    dispatch(fetchPurchaseOrderCountFailure(e));
  }
};

export const increasePurchaseOrderCount = () => (dispatch) => {
  dispatch(increasePurchaseOrderCountAction());
};
