import React from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import BasicTable from '../../Tables/BasicTables/components/ListReason';

const ListReason = ({
  reasonData,
  removeReasonState,
  removeReason,
}) => {
  console.log(reasonData);
  return (
    <Container>
      <BasicTable
        tableRowsData={reasonData.reasonDetails}
        removeReasonState={removeReasonState}
        removeReason={removeReason}
      />
    </Container>
  );
};

export default ListReason;

ListReason.propTypes = {
  reasonData: PropTypes.arrayOf.isRequired,
  removeReasonState: PropTypes.objectOf.isRequired,
  removeReason: PropTypes.func.isRequired,
};
