import { createAction } from 'redux-actions';
import api from '../../utils/apiClient';

export const saveCheckoutRequest = createAction('SAVE_CHECKOUT_REQUEST');
export const saveCheckoutSuccess = createAction('SAVE_CHECKOUT_SUCCESS');
export const saveCheckoutFailure = createAction('SAVE_CHECKOUT_FAILURE');
export const defaultSaveCheckout = createAction('DEFAULT_CHECKOUT');
export const addToCheckoutData = createAction('ADD_TO_CHECKOUT_DATA');

export const fetchCheckoutRequest = createAction('FETCH_CHECKOUT_REQUEST');
export const fetchCheckoutSuccess = createAction('FETCH_CHECKOUT_SUCCESS');
export const fetchCheckoutFailure = createAction('FETCH_CHECKOUT_FAILURE');

export const saveCheckout = (data) => async (dispatch) => {
  try {
    dispatch(saveCheckoutRequest());
    const response = await api.checkout.newCheckout(data);
    console.log(response.data);
    dispatch(saveCheckoutSuccess(response.data));
    if (response.data.data) {
      dispatch(addToCheckoutData(response.data.data));
    }
    dispatch(defaultSaveCheckout());
  } catch (e) {
    console.log(e);
    dispatch(saveCheckoutFailure(e));
  }
};

export const fetchCheckout = (data) => async (dispatch) => {
  try {
    const params = {
      limit: data.limit,
      skip: data.skip,
    };
    console.log(params);
    dispatch(fetchCheckoutRequest());
    const response = await api.checkout.get(params);
    console.log(response);
    dispatch(fetchCheckoutSuccess(response.data.data));
  } catch (e) {
    console.log(e);
    dispatch(fetchCheckoutFailure(e));
  }
};
