import { handleActions } from 'redux-actions';
import _ from 'lodash';
import {
  saveReasonRequest,
  saveReasonSuccess,
  saveReasonFailure,
  defaultSaveReason,
  addToReasonData,
  fetchReasonRequest,
  fetchReasonSuccess,
  fetchReasonFailure,
  removeReasonRequest,
  removeReasonSuccess,
  removeReasonFailure,
  defaultRemoveReason,
  removeFromReasonData,
} from '../actions/reasonActions';

const defaultSaveState = {
  isSaving: false,
  error: null,
  reasonDetails: {},
};

const defaultRemoveState = {
  isRemoving: false,
  error: null,
  reasonDetails: {},
};

const defaultFetchState = {
  isfetching: false,
  error: null,
  reasonDetails: [],
  limit: 500,
  skip: 0,
};
export const reasonSaveReducer = handleActions(
  {
    [saveReasonRequest](state) {
      console.log(state);
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    },
    [saveReasonSuccess](state, { payload }) {
      return {
        ...state,
        reasonDetails: payload,
        isSaving: false,
        error: null,
      };
    },
    [saveReasonFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isSaving: false,
      };
    },
    [defaultSaveReason](state) {
      return {
        ...state,
        reasonDetails: {},
        error: null,
        isSaving: false,
      };
    },
  },
  defaultSaveState,
);

export const reasonRemoveReducer = handleActions(
  {
    [removeReasonRequest](state) {
      console.log(state);
      return {
        ...state,
        isRemoving: true,
        error: null,
      };
    },
    [removeReasonSuccess](state, { payload }) {
      return {
        ...state,
        reasonDetails: payload,
        isRemoving: false,
        error: null,
      };
    },
    [removeReasonFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isRemoving: false,
      };
    },
    [defaultRemoveReason](state) {
      return {
        ...state,
        reasonDetails: {},
        error: null,
        isRemoving: false,
      };
    },
  },
  defaultRemoveState,
);

export const reasonFetchReducer = handleActions(
  {
    [fetchReasonRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchReasonSuccess](state, { payload }) {
      return {
        ...state,
        reasonDetails: payload,
        limit: state.limit,
        skip: state.skip,
        isFetching: false,
        error: null,
      };
    },
    [fetchReasonFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    },
    [addToReasonData](state, { payload }) {
      return {
        ...state,
        reasonDetails: [payload, ...state.reasonDetails],
        error: null,
      };
    },
    [removeFromReasonData](state, { payload }) {
      /* eslint no-underscore-dangle: 0 */
      const cloneState = _.cloneDeep(state);

      cloneState.reasonDetails.map((cloned, index) => {
        if (cloned._id === payload._id) {
          cloneState.reasonDetails.splice(index, 1);
        }
        return 1;
      });

      return {
        ...state,
        reasonDetails: cloneState.reasonDetails,
        error: null,
      };
    },
  },
  defaultFetchState,
);
