/* eslint-disable no-unused-vars */
import { get, post } from './base/index';

// 'https://localhost/api/';
export default {
  getReportFunction: (data = {}, param) => {
    // eslint-disable-next-line no-debugger
    const params = { ...data };
    return get(
      `${process.env.REACT_APP_API_URL}v2/inventory/client/w${param.fieldValue}/${param.value}`, { params },
    );
  },
  getFilterReport: (payload) => {
    const params = { ...payload };
    return get(
      `${process.env.REACT_APP_API_URL}v2/inventory/client/wOicreports/Oicreports`, { params },
    );
  },
  getReports: (payload) => {
    const params = { ...payload };
    // return get('http://localhost/api/v2/inventory/client/wOicreports/Oicreports', { params });
    return get(`${process.env.REACT_APP_API_URL}v2/inventory/client/wOicreports/Oicreports`, { params });
  },
  saveReport: (payload = {}) => {
    const body = { ...payload };
    // return post('http://localhost/api/v2/inventory/client/wOicreports/Oicreports', body);
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/wOicreports/Oicreports`, body);
  },
};
