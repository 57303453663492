/* eslint-disable no-debugger */
import React, { useState, useEffect } from 'react';
import {
  Container,
  Button,
  Row,
  Col,
} from 'reactstrap';
import _ from 'lodash';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import AddReturn from './components/AddReturn';
import ListReturn from './components/ListReturn';
import { saveReturn, fetchReturn, updateReturn } from '../../redux/actions/returnActions';
import { fetchMaster } from '../../redux/actions/masterActions';
import Modal from '../../shared/components/modal/Modal';
import lensPower from '../../utils/commonFunctions/lensPower';
import { fetchReason } from '../../redux/actions/reasonActions';
import { fetchVendor } from '../../redux/actions/vendorActions';
import ReturnDetails from './components/ReturnDetails';

const Return = ({
  fetchVendorsAction,
  vendor,
  saveReturnState,
  saveReturnAction,
  updateReturnState,
  updateReturnAction,
  returnDataState,
  fetchReturnAction,
  fetchReasonAction,
  reasonDataState,
  fetchMastersAction,
  master,
}) => {
  const [vendorOptions, setVendorOptions] = useState([]);
  const [returnDetailsData, setReturnDetailsData] = useState([]);
  const [activePage, setActivePage] = useState('ListReturn');
  const [activeModal, setActiveModal] = useState('');
  const [returnForm, setReturnForm] = useState({
    inventories: [],
  });
  const [metaV1Options, setMetaV1Options] = useState({});
  const [lensPowers, setLensPowers] = useState({});
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [reasonOptions, setReasonOptions] = useState([]);
  const [totalReturnQuantity, setTotalReturnQuantity] = useState(0);
  const [statusForm, setStatusForm] = useState({});
  const [currentRowInfo, setCurrentRowInfo] = useState({});

  const activePageHandler = (pageName) => {
    setActivePage(pageName);
  };

  const activeModalAction = (action, close) => {
    if (action === 'ListReturn') {
      setActiveModal('');
      setReturnForm({
        inventories: [],
      });
      setCurrentFormIndex(0);
      activePageHandler(action);
      return 1;
    }

    if (close) {
      setStatusForm({});
      setActiveModal('');
    } else {
      setActiveModal(action);
    }
    return 1;
  };

  useEffect(() => {
    if (reasonDataState.reasonDetails) {
      const reasonLocal = [];
      reasonDataState.reasonDetails.map((reason) => {
        if (reason.type === 'returns') {
          reasonLocal.push({
            label: reason.title,
            value: reason.title,
          });
        }
        return 1;
      });
      setReasonOptions(reasonLocal);
    }
    return 1;
  }, [reasonDataState]);

  const onInputChangeHandler = (e, fieldName, type) => {
    let fieldValue;
    const returnFormLocal = _.cloneDeep(returnForm);
    if (fieldName === 'vendorInvoiceId' || fieldName === 'vendorInvoiceDate ') {
      fieldValue = e.target.value;
      returnFormLocal[fieldName] = fieldValue;
      setReturnForm(returnFormLocal);
      return 1;
    }

    if (fieldName === 'vendorDetails') {
      fieldValue = e;
      returnFormLocal[fieldName] = fieldValue;
      setReturnForm(returnFormLocal);
      return 1;
    }

    if (type === 'textInput') {
      fieldValue = e.target.value;
      if (fieldName === 'qty' && new RegExp('^\\d+$').test(fieldValue) === false) {
        return false;
      }
      if (!returnFormLocal.inventories[currentFormIndex]) {
        returnFormLocal.inventories.push({});
      }

      returnFormLocal.inventories[currentFormIndex][fieldName] = fieldValue;
    }

    if (type === 'select') {
      fieldValue = e;
      if (!returnFormLocal.inventories[currentFormIndex]) {
        returnFormLocal.inventories.push({});
      }
      returnFormLocal.inventories[currentFormIndex][fieldName] = fieldValue;
    }

    setReturnForm(returnFormLocal);
    return 1;
  };
  const validateForm = () => {
    if (returnForm.inventories[currentFormIndex]) {
      if (!returnForm.inventories[currentFormIndex].lensType
        || !returnForm.inventories[currentFormIndex].lensMaterial
        || !returnForm.inventories[currentFormIndex].qty) {
        return false;
      }
    } else {
      return false;
    }
    return true;
  };

  const saveReturnFunc = () => {
    const message = validateForm();
    if (!message) {
      toast.error('Please fill all the mandatory fields!');
      return 1;
    }
    activePageHandler('ReturnSummary');
    return 1;
  };

  useEffect(() => {
    fetchReturnAction(returnDataState);
    const lensPowerHolder = { ...lensPowers, ...lensPower() };
    setLensPowers(lensPowerHolder);
    if (
      !reasonDataState.error
      && reasonDataState.reasonDetails
      && Object.keys(reasonDataState.reasonDetails).length === 0
    ) {
      fetchReasonAction(reasonDataState);
    }
    if (
      !master.error
      && master.masterDetails
      && Object.keys(master.masterDetails).length === 0
    ) {
      fetchMastersAction();
    }
    fetchVendorsAction(vendor);
  }, []);

  useEffect(() => {
    console.log(vendor);
    if (!vendor.error && vendor.vendorDetails) {
      const vendorsList = [];
      let tempVendor;
      const vendorLocal = _.cloneDeep(vendor.vendorDetails);

      vendorLocal.map((vendorInfo) => {
        tempVendor = vendorInfo;
        const optionBrands = [];

        if (vendorInfo.brand && vendorInfo.brand.length > 0) {
          vendorInfo.brand.map((brandObj) => {
            optionBrands.push({
              label: brandObj,
              value: brandObj,
            });
            return 1;
          });

          tempVendor.brand = optionBrands;
        }

        console.log(tempVendor);
        vendorsList.push({
          label: tempVendor.buisnessName,
          value: tempVendor,
        });
        return 1;
      });

      setVendorOptions(vendorsList);
    }

    if (vendor.error) {
      console.log('Unable to get vendors!');
    }
  }, [vendor]);

  useEffect(() => {
    console.log(returnDataState);
  }, [returnDataState]);

  useEffect(() => {
    console.log(returnForm);
  }, [returnForm]);

  useEffect(() => {
    console.log(saveReturnState);

    if (!saveReturnState.error
      && !saveReturnState.isSaving
      && saveReturnState.returnDetails
      && saveReturnState.returnDetails.message) {
      if (saveReturnState.returnDetails.message === 'success') {
        setActiveModal('ReturnSuccess');
      } else {
        toast.error('Unexpected Error:(');
      }
    }

    if (saveReturnState.error) {
      toast.error('Unexpected Error:(');
    }
  }, [saveReturnState]);

  useEffect(() => {
    console.log(updateReturnState);

    if (!updateReturnState.error
      && !updateReturnState.isUpdating
      && updateReturnState.returnDetails
      && updateReturnState.returnDetails.message) {
      if (updateReturnState.returnDetails.message === 'success') {
        toast.success('Updated Successfully!');
        activeModalAction('', 'close');
      } else {
        toast.error('Unexpected Error:(');
      }
    }

    if (updateReturnState.error) {
      toast.error('Unexpected Error:(');
    }
  }, [updateReturnState]);

  const updateReturnFunc = (type) => {
    const statusFormLocal = _.cloneDeep(statusForm);
    console.log(currentRowInfo);

    const formedData = {};
    if (type === 'shipping') {
      if (!statusFormLocal.bookingDate || !statusFormLocal.trackingId || !statusFormLocal.serviceProvider) {
        toast.error('Please fill all the mandatory fields!');
        return 1;
      }
      formedData.status = 'Shipped';
      formedData.shippingDetails = statusFormLocal;
    }

    if (type === 'notshipping') {
      if (!statusFormLocal.status) {
        toast.error('Please select status!');
        return 1;
      }
      formedData.status = statusFormLocal.status;
      formedData.comments = statusFormLocal.comments;
    }

    formedData.returnId = currentRowInfo.returnId;

    updateReturnAction(formedData);
    return 1;
  };

  useEffect(() => {
    if (
      !master.error
      && master.masterDetails
      && Object.keys(master.masterDetails).length > 0
    ) {
      const lensTypeList = [];
      const lensMaterialList = [];
      master.masterDetails.LLensType.map((lensType) => {
        lensTypeList.push({

          label: lensType,
          value: lensType,
        });
        return 1;
      });

      master.masterDetails.LLensMaterial.map((lensMaterial) => {
        lensMaterialList.push({
          label: lensMaterial,
          value: lensMaterial,
        });
        return 1;
      });

      setMetaV1Options({
        LensType: lensTypeList,
        LensMaterial: lensMaterialList,
      });
    }
  }, [master]);

  const addNewReturn = () => {
    setCurrentFormIndex(currentFormIndex + 1);
    activePageHandler('AddReturn');
  };

  const deleteReturn = (index) => {
    const returnFormLocal = _.cloneDeep(returnForm);
    returnFormLocal.inventories.splice(index, 1);
    setCurrentFormIndex(currentFormIndex - 1);
    setReturnForm(returnFormLocal);
  };

  const saveNewReturns = () => {
    const returnFormLocal = _.cloneDeep(returnForm);

    if (returnFormLocal.inventories.length === 0) {
      toast.error('Please choose atleast 1 inventory!');
      return 1;
    }

    console.log(returnFormLocal);

    if (!returnFormLocal.vendorDetails) {
      toast.error('Please select vendor name!');
      return 1;
    }
    /* eslint no-underscore-dangle: 0 */

    const finalReturnForm = {
      supplier: returnFormLocal.vendorDetails.label,
      supplierId: returnFormLocal.vendorDetails.value._id,
      vendorInvoiceDate: returnFormLocal.vendorInvoiceDate || new Date(),
      vendorInvoiceId: returnFormLocal.vendorInvoiceId || '',
      inventories: [],
    };

    let totalReturnQuantityLocal = 0;

    returnFormLocal.inventories.map((inventory) => {
      totalReturnQuantityLocal += Number(inventory.qty);
      const tempInventory = {};
      tempInventory.LensType = inventory.lensType ? inventory.lensType.value : '';
      tempInventory.LensMaterial = inventory.lensMaterial ? inventory.lensMaterial.value : '';
      tempInventory.Add = inventory.add ? inventory.add.value : '';
      tempInventory.Axis = inventory.axis ? inventory.axis.value : '';
      tempInventory.Cylindrical = inventory.cyl ? inventory.cyl.value : '';
      tempInventory.Spherical = inventory.sph ? inventory.sph.value : '';
      tempInventory.Category = 'LENS';
      tempInventory.Quantity = inventory.qty || '';
      tempInventory.Reason = inventory.reason ? inventory.reason.value : '';

      tempInventory.ItemCode = '';
      if (tempInventory.Spherical) {
        tempInventory.ItemCode += `${tempInventory.Spherical} `;
      } else {
        tempInventory.ItemCode += '0 ';
      }

      if (tempInventory.Cylindrical) {
        tempInventory.ItemCode += `${tempInventory.Cylindrical} `;
      } else {
        tempInventory.ItemCode += '0 ';
      }

      if (tempInventory.Add) {
        tempInventory.ItemCode += `${tempInventory.Add} `;
      } else {
        tempInventory.ItemCode += '0 ';
      }

      if (tempInventory.Axis) {
        tempInventory.ItemCode += `${tempInventory.Axis}`;
      } else {
        tempInventory.ItemCode += '0';
      }

      tempInventory.ProductName = '';
      tempInventory.ProductName += `${tempInventory.LensType} `;
      tempInventory.ProductName += `${tempInventory.LensMaterial} `;
      tempInventory.ProductName += `${tempInventory.ItemCode} `;
      tempInventory.ProductName += 'LENS';

      finalReturnForm.inventories.push(tempInventory);
      return 1;
    });

    setTotalReturnQuantity(totalReturnQuantityLocal);
    saveReturnAction(finalReturnForm);

    return 1;
  };

  const onInputChangeHandlerStatus = (e, fieldName, type) => {
    const statusFormLocal = _.cloneDeep(statusForm);
    let fieldValue = '';
    if (type === 'radio') {
      fieldValue = e;
    }

    if (type === 'textInput') {
      fieldValue = e.target.value;
    }

    if (type === 'select') {
      fieldValue = e;
    }

    statusFormLocal[fieldName] = fieldValue;

    setStatusForm(statusFormLocal);
    return 1;
  };

  const changeStatus = (item) => {
    console.log(item);
    // if(rtuen.status)
    if (item.status === 'Pending') {
      setActiveModal('ShippingInfo');
      setCurrentRowInfo(item);
    }

    if (item.status === 'Shipped') {
      setActiveModal('ReplacedCreditNote');
      setCurrentRowInfo(item);
    }
  };

  const viewDetails = (obj) => {
    debugger;
    // eslint-disable-next-line no-debugger
    setActivePage('ReturnDetails');
    // eslint-disable-next-line no-console
    console.log('view', obj);
    const selectedReturnData = returnDataState?.returnDetails?.find((d) => d._id === obj._id);
    setReturnDetailsData(selectedReturnData);
  };
  return (
    <div className="return">
      {
        activePage === 'ListReturn'
        && (
          <Container>
            <Row>
              <Col md={12} className="smalldiv__listheader">
                <h4>
                  Returns
                </h4>
                <Button
                  className="rounded btn-right"
                  color="success"
                  onClick={() => activePageHandler('AddReturn')}
                >
                  New Return
                </Button>
              </Col>
            </Row>
            <Row style={{ placeContent: 'center' }}>
              {
                returnDataState.isFetching
                  ? (
                    <div className="spinner-border" style={{ color: '#03a5a5' }}>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <ListReturn
                      returnData={returnDataState}
                      changeStatus={changeStatus}
                      viewDetails={viewDetails}
                    />
                  )
              }
            </Row>
          </Container>
        )
      }
      {
        (activePage === 'AddReturn' || activePage === 'ReturnSummary')
        && (
          <AddReturn
            deleteReturn={deleteReturn}
            addNewReturn={addNewReturn}
            metaV1Options={metaV1Options}
            lensPowers={lensPowers}
            activePageHandler={activePageHandler}
            activePage={activePage}
            returnForm={returnForm}
            onInputChangeHandler={onInputChangeHandler}
            saveReturn={saveReturnFunc}
            saveReturnState={saveReturnState}
            reasonOptions={reasonOptions}
            saveNewReturns={saveNewReturns}
            vendorOptions={vendorOptions}
            currentFormIndex={currentFormIndex}
          />
        )
      }
      {
        (activePage === 'ReturnDetails')
        && (
          <ReturnDetails
            tableData={returnDetailsData}
            activePageHandler={activePageHandler}
          />
        )
      }
      {
        activeModal === 'ReturnSuccess'
        && (
          <Modal
            modalStatus={activeModal === 'ReturnSuccess'}
            modalAction={() => activeModalAction('ListReturn', 'close')}
            color="success"
            title="Well Done!"
            btn="Success"
            message={(
              <div>
                <div>Return Added Successfully! <br /><br />
                  <span>Total Inventories: <b>{returnForm.inventories.length}</b></span><br />
                  <span>Total Quantity: <b>{totalReturnQuantity}</b></span><br /><br />
                </div>
                <Button
                  style={{ marginTop: '10px' }}
                  className="rounded"
                  color="success"
                  onClick={() => activeModalAction('ListReturn', 'close')}
                >
                  Back to List Return
                </Button>
              </div>
            )}
          />
        )
      }
      {
        activeModal === 'ReplacedCreditNote'
        && (
          <Modal
            modalStatus={activeModal === 'ReplacedCreditNote'}
            header
            title="Update Status"
            color="success"
            modalAction={() => activeModalAction('', 'close')}
            message={(
              <div>
                <form onSubmit={(e) => e.preventDefault()} className="form">
                  <Row>
                    <Col md={6} className="form-group">
                      <div className="masters__radioSplitter">
                        <input
                          style={{ width: '17px' }}
                          name="replaced"
                          type="radio"
                          checked={statusForm.status === 'Replaced'}
                          onChange={() => onInputChangeHandlerStatus('Replaced', 'status', 'radio')}
                        />
                        <label style={{ marginBottom: '0px' }} htmlFor="replaced">Replaced</label>
                      </div>
                      <div className="masters__radioSplitter">
                        <input
                          style={{ width: '17px' }}
                          name="creditNote"
                          type="radio"
                          checked={statusForm.status === 'Credit Note'}
                          onChange={() => onInputChangeHandlerStatus('Credit Note', 'status', 'radio')}
                        />
                        <label style={{ marginBottom: '0px' }} htmlFor="returns">Credit Note</label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="form-group">
                      <label htmlFor="type">COMMENTS</label>
                      <div>
                        <input
                          onChange={(e) => onInputChangeHandlerStatus(e, 'comments', 'textInput')}
                          name="comments"
                          component="input"
                          value={statusForm.comments || ''}
                          type="text"
                        />
                      </div>
                    </Col>
                  </Row>
                </form>
                <Button
                  style={{ marginTop: '15px' }}
                  type="button"
                  className="rounded btn-sm"
                  color="success"
                  disabled={updateReturnState.isUpdating}
                  onClick={() => updateReturnFunc('notshipping')}
                >
                  Submit
                </Button>
              </div>
            )}
          />
        )
      }
      {
        activeModal === 'ShippingInfo'
        && (
          <Modal
            modalStatus={activeModal === 'ShippingInfo'}
            header
            title="Shipping Info"
            color="success"
            modalAction={() => activeModalAction('', 'close')}
            message={(
              <div>
                <form onSubmit={(e) => e.preventDefault()} className="form">
                  <Row>
                    <Col md={6} className="form-group">
                      <label htmlFor="bookingDate">BOOKING DATE *</label>
                      <div>
                        <input
                          onChange={(e) => onInputChangeHandlerStatus(e, 'bookingDate', 'textInput')}
                          name="bookingDate"
                          component="input"
                          value={statusForm.bookingDate || ''}
                          type="date"
                        />
                      </div>
                    </Col>
                    <Col md={6} className="form-group">
                      <label htmlFor="serviceProvider">SERVICE PROVIDER *</label>
                      <div>
                        <input
                          onChange={(e) => onInputChangeHandlerStatus(e, 'serviceProvider', 'textInput')}
                          name="serviceProvider"
                          component="input"
                          value={statusForm.serviceProvider || ''}
                          type="text"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="form-group">
                      <label htmlFor="trackingId">TRACKING ID *</label>
                      <div>
                        <input
                          onChange={(e) => onInputChangeHandlerStatus(e, 'trackingId', 'textInput')}
                          name="trackingId"
                          component="input"
                          value={statusForm.trackingId || ''}
                          type="text"
                        />
                      </div>
                    </Col>
                  </Row>
                </form>
                <Button
                  style={{ marginTop: '15px' }}
                  type="button"
                  className="rounded btn-sm"
                  color="success"
                  disabled={updateReturnState.isUpdating}
                  onClick={() => updateReturnFunc('shipping')}
                >
                  Submit
                </Button>
              </div>
            )}
          />
        )
      }
    </div>
  );
};

Return.propTypes = {
  fetchVendorsAction: PropTypes.func.isRequired,
  vendor: PropTypes.arrayOf.isRequired,
  saveReturnAction: PropTypes.func.isRequired,
  updateReturnAction: PropTypes.func.isRequired,
  updateReturnState: PropTypes.objectOf.isRequired,
  saveReturnState: PropTypes.objectOf.isRequired,
  returnDataState: PropTypes.arrayOf.isRequired,
  fetchReturnAction: PropTypes.func.isRequired,
  fetchReasonAction: PropTypes.func.isRequired,
  reasonDataState: PropTypes.arrayOf.isRequired,
  fetchMastersAction: PropTypes.func.isRequired,
  master: PropTypes.objectOf.isRequired,
};

const mapDispatchToProps = {
  fetchVendorsAction: fetchVendor,
  saveReturnAction: saveReturn,
  updateReturnAction: updateReturn,
  fetchReturnAction: fetchReturn,
  fetchReasonAction: fetchReason,
  fetchMastersAction: fetchMaster,
};

const mapStateToProps = (state) => ({
  master: state.master,
  vendor: state.vendorData,
  saveReturnState: state.saveReturn,
  updateReturnState: state.updateReturn,
  returnDataState: state.returnData,
  reasonDataState: state.reasonData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Return);
