import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Container, Button,
} from 'reactstrap';
import LensFullVariants from '../../Tables/BasicTables/components/LensFullVariants';

const FullVariants = ({
  tableRowsData,
  activePageHandler,
  inventoryForm,
  updateVariants,
  deleteVariant,
  saveLens,
  inventorySave,
  adder,
  processAdder,
}) => {
  console.log(1);
  return (
    <Container className="addpurchase">
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => activePageHandler('AddLens')}
      >
        {' < '}
      </Button>
      <Row className="smalldiv__cardsContainer">
        <Col md={2} className="smalldiv__samerow">
          <span>LENS TYPE: </span>
          <p style={{ fontWeight: 600 }}>{inventoryForm.lensType ? inventoryForm.lensType.value : ''}</p>
        </Col>
      </Row>
      <LensFullVariants
        updateVariants={updateVariants}
        deleteVariant={deleteVariant}
        tableRowsData={tableRowsData}
        adder={adder}
        processAdder={processAdder}
      />
      <Row style={{ marginTop: '15px' }}>
        <Col md={2} className="form-group">
          <Button
            onClick={() => saveLens()}
            className="rounded"
            disabled={inventorySave.isSaving}
            color="success"
            size="sm"
          >
            {!inventorySave.isSaving ? 'Submit' : 'Submitting'}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

FullVariants.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
  inventoryForm: PropTypes.arrayOf.isRequired,
  activePageHandler: PropTypes.func.isRequired,
  updateVariants: PropTypes.func.isRequired,
  deleteVariant: PropTypes.func.isRequired,
  saveLens: PropTypes.func.isRequired,
  inventorySave: PropTypes.objectOf.isRequired,
  adder: PropTypes.func.isRequired,
  processAdder: PropTypes.func.isRequired,
};

export default FullVariants;
