import React from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import BasicTable from '../../Tables/BasicTables/components/ListCustomer';

const ListCustomer = ({
  customerData,
  removeCustomer,
  removeCustomerState,
}) => {
  console.log(customerData);
  return (
    <Container>
      <BasicTable
        tableRowsData={customerData.customerDetails}
        removeCustomer={removeCustomer}
        removeCustomerState={removeCustomerState}
      />
    </Container>
  );
};

export default ListCustomer;

ListCustomer.propTypes = {
  customerData: PropTypes.arrayOf.isRequired,
  removeCustomer: PropTypes.func.isRequired,
  removeCustomerState: PropTypes.objectOf.isRequired,
};
