import React from 'react';
import {
  Container,
} from 'reactstrap';
import PropTypes from 'prop-types';
import FullVariants from './FullVariants';
import NewPurchaseForm from './NewPurchaseForm';

const variantsHeaderData = [
  { id: 6, title: 'S.No' },
  { id: 1, title: 'Spherical' },
  { id: 2, title: 'Cylindrical' },
  { id: 4, title: 'Axis' },
  { id: 3, title: 'Add' },
  { id: 5, title: 'Quantity' },
];

const AddPurchaseOrder = ({
  groupIndex,
  viewSpecificDetails,
  metaV1Options,
  vendorOptions,
  saveNewPurchase,
  purchaseOrderCount,
  createdVariants,
  deleteVariant,
  updateVariants,
  generateVariants,
  lensPowers,
  purchaseForm,
  onInputChangeHandler,
  activePage,
  activePageHandler,
  totalVariantQuantity,
  viewDetailsMode,
  currentViewDetailsData,
  purchaseOrderUpdate,
  purchaseOrder,
  assignVendorForm,
}) => {
  console.log(activePage);
  return (
    <Container>
      {(activePage === 'NewPurchaseForm'
        || activePage === 'PurchaseSummary')
        && (
          <>
            <NewPurchaseForm
              assignVendorForm={assignVendorForm}
              viewSpecificDetails={viewSpecificDetails}
              purchaseOrderUpdate={purchaseOrderUpdate}
              purchaseOrder={purchaseOrder}
              currentViewDetailsData={currentViewDetailsData}
              viewDetailsMode={viewDetailsMode}
              activePage={activePage}
              totalVariantQuantity={totalVariantQuantity}
              purchaseOrderCount={purchaseOrderCount}
              createdVariants={createdVariants}
              purchaseForm={purchaseForm}
              onInputChangeHandler={onInputChangeHandler}
              generateVariants={generateVariants}
              lensPowers={lensPowers}
              vendorOptions={vendorOptions}
              metaV1Options={metaV1Options}
              activePageHandler={activePageHandler}
              saveNewPurchase={saveNewPurchase}
            />
          </>
        )}
      {activePage === 'FullVariants'
        && (
          <FullVariants
            viewDetailsMode={viewDetailsMode}
            currentViewDetailsData={currentViewDetailsData}
            updateVariants={updateVariants}
            purchaseForm={purchaseForm}
            activePageHandler={activePageHandler}
            tableHeaderData={variantsHeaderData}
            tableRowsData={createdVariants}
            deleteVariant={deleteVariant}
            groupIndex={groupIndex}
          />
        )}
    </Container>
  );
};

AddPurchaseOrder.propTypes = {
  purchaseOrderCount: PropTypes.number.isRequired,
  vendorOptions: PropTypes.arrayOf.isRequired,
  metaV1Options: PropTypes.objectOf.isRequired,
  saveNewPurchase: PropTypes.func.isRequired,
  generateVariants: PropTypes.func.isRequired,
  updateVariants: PropTypes.func.isRequired,
  createdVariants: PropTypes.arrayOf.isRequired,
  deleteVariant: PropTypes.arrayOf.isRequired,
  lensPowers: PropTypes.arrayOf.isRequired,
  activePage: PropTypes.string.isRequired,
  purchaseForm: PropTypes.objectOf.isRequired,
  onInputChangeHandler: PropTypes.func.isRequired,
  activePageHandler: PropTypes.func.isRequired,
  totalVariantQuantity: PropTypes.func.isRequired,
  currentViewDetailsData: PropTypes.objectOf.isRequired,
  viewDetailsMode: PropTypes.bool.isRequired,
  purchaseOrderUpdate: PropTypes.objectOf.isRequired,
  purchaseOrder: PropTypes.objectOf.isRequired,
  viewSpecificDetails: PropTypes.func.isRequired,
  groupIndex: PropTypes.number.isRequired,
  assignVendorForm: PropTypes.objectOf.isRequired,
};

export default AddPurchaseOrder;
