import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';

const tableHeaderData = [
  { id: 1, title: 'S.No' },
  { id: 2, title: 'Spherical' },
  { id: 3, title: 'Cylindrical' },
  { id: 5, title: 'Add' },
  { id: 4, title: 'Axis' },
  { id: 6, title: 'Quantity' },
  { id: 7, title: 'Adder' },
];
const LensFullVariants = ({
  tableRowsData = [],
  updateVariants,
  deleteVariant,
  adder,
  processAdder,
}) => {
  console.log('Basic Table', tableRowsData);
  tableRowsData.sort((oldd, newd) => {
    // const Axis = oldd.Axis - newd.Axis;
    const Add = oldd.Add - newd.Add;
    const Cylindrical = oldd.Cylindrical - newd.Cylindrical;
    const Spherical = oldd.Spherical - newd.Spherical;
    return Spherical || Cylindrical || Add;
  });
  return (
    <Table responsive hover>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRowsData.map((item, index) => (
          <tr key={item.ItemCode}>
            <td>{index + 1}</td>
            <td>{item.Spherical}</td>
            <td>{item.Cylindrical}</td>
            <td>{item.Add}</td>
            <td>{item.Axis}</td>
            <td>
              <form onSubmit={(e) => e.preventDefault()} className="form">
                <input
                  style={{ width: '80px', height: '23px', textAlign: 'center' }}
                  type="number"
                  component="input"
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => updateVariants(e, index)}
                  value={item.Quantity}
                />
              </form>
            </td>
            <td>
              <form onSubmit={(e) => e.preventDefault()} className="form">
                <input
                  style={{ width: '80px', height: '23px', textAlign: 'center' }}
                  type="number"
                  component="input"
                  onWheel={(e) => e.currentTarget.blur()}
                  onKeyDown={(e) => processAdder(e, index)}
                  onChange={(e) => adder(e, index)}
                  value={item.Adder}
                />
              </form>
            </td>
            <td>
              <button
                onClick={() => deleteVariant(index)}
                className="smalldiv__table-btn"
                type="button"
              >
                <DeleteForeverIcon />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

LensFullVariants.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
  updateVariants: PropTypes.func.isRequired,
  deleteVariant: PropTypes.func.isRequired,
  adder: PropTypes.func.isRequired,
  processAdder: PropTypes.func.isRequired,
};

export default LensFullVariants;
