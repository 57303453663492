import React from 'react';
import {
  Row,
  Col,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import ReturnSummaryTable from '../../Tables/BasicTables/components/ReturnSummary';

function ReturnSummary({
  deleteReturn,
  returnForm,
  addNewReturn,
  saveNewReturns,
  saveReturn,
}) {
  console.log('Return Summary');
  return (
    <div>
      <Row>
        <Col md={12}>
          <h4 className="page-subhead">Summary</h4>
        </Col>
      </Row>
      <Row
        style={{ marginBottom: '30px' }}
      >
        <Col md={12}>
          <ReturnSummaryTable
            returnForm={returnForm}
            deleteReturn={deleteReturn}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: '30px', marginLeft: '0px' }}>
        <Button
          color="success"
          outline
          size="sm"
          className="rounded"
          onClick={() => addNewReturn()}
          disabled={saveReturn.isSaving}
        >Add Variants
        </Button>
        <Button
          color="success"
          size="sm"
          className="rounded"
          disabled={saveReturn.isSaving}
          onClick={() => saveNewReturns()}
        >Confirm
        </Button>
      </Row>
    </div>

  );
}

ReturnSummary.propTypes = {
  deleteReturn: PropTypes.func.isRequired,
  returnForm: PropTypes.arrayOf.isRequired,
  saveNewReturns: PropTypes.func.isRequired,
  saveReturn: PropTypes.objectOf.isRequired,
  addNewReturn: PropTypes.func.isRequired,
};
export default ReturnSummary;
