import { handleActions } from 'redux-actions';
import _ from 'lodash';
import {
  savePurchaseOrderRequest,
  savePurchaseOrderSuccess,
  savePurchaseOrderFailure,
  defaultPurchaseOrder,
  updatePurchaseOrderRequest,
  updatePurchaseOrderSuccess,
  updatePurchaseOrderFailure,
  defaultUpdatePurchaseOrder,
  fetchPurchaseOrderRequest,
  fetchPurchaseOrderSuccess,
  fetchPurchaseOrderFailure,
  addToPurchaseOrder,
  updateToPurchaseOrder,
} from '../actions/purchaseOrderActions';

const defaultState = {
  isSaving: false,
  error: null,
  purchaseOrderDetails: {},
};

const defaultUpdateState = {
  isUpdating: false,
  error: null,
  updatedPurchaseOrderDetails: {},
};

const defaultStateFetch = {
  isFetching: false,
  error: null,
  purchaseOrderDetails: [],
  limit: 500,
  skip: 0,
};

export const purchaseOrderSaveReducer = handleActions(
  {
    [savePurchaseOrderRequest](state) {
      console.log(state);
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    },
    [savePurchaseOrderSuccess](state, { payload }) {
      return {
        ...state,
        purchaseOrderDetails: payload,
        isSaving: false,
        error: null,
      };
    },
    [defaultPurchaseOrder](state) {
      return {
        ...state,
        purchaseOrderDetails: {},
        isSaving: false,
        error: null,
      };
    },
    [savePurchaseOrderFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isSaving: false,
      };
    },
  },
  defaultState,
);

export const purchaseOrderUpdateReducer = handleActions(
  {
    [updatePurchaseOrderRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [updatePurchaseOrderSuccess](state, { payload }) {
      return {
        ...state,
        updatedPurchaseOrderDetails: payload,
        isUpdating: false,
        error: null,
      };
    },
    [defaultUpdatePurchaseOrder](state) {
      return {
        ...state,
        updatedPurchaseOrderDetails: {},
        isUpdating: false,
        error: null,
      };
    },
    [updatePurchaseOrderFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isUpdating: false,
      };
    },
  },
  defaultUpdateState,
);

export const purchaseOrderFetchReducer = handleActions(
  {
    [fetchPurchaseOrderRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchPurchaseOrderSuccess](state, { payload }) {
      return {
        ...state,
        purchaseOrderDetails: payload,
        limit: state.limit,
        skip: state.skip,
        isFetching: false,
        error: null,
      };
    },
    [fetchPurchaseOrderFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    },
    [addToPurchaseOrder](state, { payload }) {
      return {
        ...state,
        purchaseOrderDetails: [payload, ...state.purchaseOrderDetails],
      };
    },
    /* eslint no-underscore-dangle: 0 */
    [updateToPurchaseOrder](state, { payload }) {
      const purchaseOrderDetailsLocal = _.cloneDeep(state.purchaseOrderDetails);
      const foundIndex = purchaseOrderDetailsLocal.findIndex((po) => po._id === payload._id);

      purchaseOrderDetailsLocal[foundIndex] = payload;

      console.log(purchaseOrderDetailsLocal);
      return {
        ...state,
        purchaseOrderDetails: purchaseOrderDetailsLocal,
      };
    },
  },
  defaultStateFetch,
);
