import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';

const BasicTable = ({
  activePage,
  tableRowsData = [],
  consolidatedDataHandler,
  selectedPurchaseList,
  viewDetailsMode,
}) => {
  console.log(1);

  let totalValidLength = 0;

  tableRowsData.map((inventory) => {
    if ((viewDetailsMode ? inventory.status === 'Pending' : true)
      && !inventory.omitted
      && activePage === 'ViewPurchase'
      && (inventory.AvailableQuantity < inventory.Quantity)) {
      totalValidLength += 1;
    }
    return 1;
  });

  const tableHeaderData = [
    {
      id: 1,
      title: <input
        name="checkbox"
        type="checkbox"
        checked={selectedPurchaseList === totalValidLength && totalValidLength !== 0 && true}
        onChange={(e) => consolidatedDataHandler(e, 'All', 'checkbox', '')}
      />,
      type: ['ViewPurchase'],
    },
    { id: 2, title: 'S.NO', type: ['ViewPurchase', 'MaterialSummary'] },
    { id: 3, title: 'Lens Type', type: ['ViewPurchase', 'MaterialSummary'] },
    { id: 13, title: 'Lens Material', type: ['ViewPurchase', 'MaterialSummary'] },
    { id: 5, title: 'Sph', type: ['ViewPurchase', 'MaterialSummary'] },
    { id: 6, title: 'Cyl', type: ['ViewPurchase', 'MaterialSummary'] },
    { id: 8, title: 'Axis', type: ['ViewPurchase', 'MaterialSummary'] },
    { id: 7, title: 'Add', type: ['ViewPurchase', 'MaterialSummary'] },
    { id: 9, title: 'Purchase Quantity', type: ['ViewPurchase'] },
    { id: 10, title: 'Required Quantity', type: ['MaterialSummary'] },
    { id: 11, title: 'Available Quantity', type: ['MaterialSummary'] },
    { id: 12, title: 'Purchase Quantity', type: ['MaterialSummary'] },
  ];

  console.log(tableRowsData, activePage);
  return (
    <div style={{
      height: '60vh',
      overflowY: 'auto',
    }}
    >
      <Table hover>
        <thead>
          <tr>
            {tableHeaderData.map((item) => (
              item.type.includes(activePage)
              && <th key={item.id}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRowsData.map((item, index) => (
            /* eslint no-underscore-dangle: 0 */
            !item.omitted
            && (
              activePage === 'ViewPurchase'
                ? (
                  item.PurchaseQuantity > 0 && item.status === 'Pending'
                  && (
                    <tr key={item._id} role="presentation">
                      <td>
                        <input
                          name="checkbox"
                          type="checkbox"
                          checked={item.checked}
                          onChange={(e) => consolidatedDataHandler(e, index, 'checkbox', '')}
                        />
                      </td>
                      <td>{index + 1}</td>
                      <td>{item.LensType || '-'}</td>
                      <td>{item.LensMaterial || '-'}</td>
                      <td>{item.Spherical || '0'}</td>
                      <td>{item.Cylindrical || '0'}</td>
                      <td>{item.Axis || '0'}</td>
                      <td>{item.Add || '0'}</td>
                      <td>{item.PurchaseQuantity || '0'}</td>
                    </tr>
                  )
                ) : (
                  <tr key={item._id} role="presentation">
                    <td>{index + 1}</td>
                    <td>{item.LensType || '-'}</td>
                    <td>{item.LensMaterial || '-'}</td>
                    <td>{item.Spherical || '0'}</td>
                    <td>{item.Cylindrical || '0'}</td>
                    <td>{item.Axis || '0'}</td>
                    <td>{item.Add || '0'}</td>
                    <td>{item.Quantity || ''}</td>
                    {
                      item.PurchaseQuantity === 0 && (
                        <>
                          <td>{item.AvailableQuantity < 0 ? 0 : item.AvailableQuantity}</td>
                          <td>{item.PurchaseQuantity}</td>
                        </>
                      )
                    }
                    {
                      (item.PurchaseQuantity !== 0 && (item.AvailableQuantity < item.Quantity)) && (
                        <>
                          <td>{item.AvailableQuantity < 0 ? 0 : item.AvailableQuantity}</td>
                          <td>{item.PurchaseQuantity}</td>
                        </>
                      )
                    }
                    {
                      (item.PurchaseQuantity !== 0 && (item.AvailableQuantity >= item.Quantity)) && (
                        <>
                          <td>{item.Quantity}</td>
                          <td>{item.PurchaseQuantity}</td>
                        </>
                      )
                    }
                  </tr>
                )
            )))}
        </tbody>
      </Table>
    </div>
  );
};

BasicTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
  activePage: PropTypes.string.isRequired,
  consolidatedDataHandler: PropTypes.func.isRequired,
  selectedPurchaseList: PropTypes.number.isRequired,
  viewDetailsMode: PropTypes.bool.isRequired,
};

export default BasicTable;
