import { handleActions } from 'redux-actions';
import {
  saveCheckoutRequest,
  saveCheckoutSuccess,
  saveCheckoutFailure,
  defaultSaveCheckout,
  addToCheckoutData,
  fetchCheckoutRequest,
  fetchCheckoutSuccess,
  fetchCheckoutFailure,
} from '../actions/checkoutActions';

const defaultSaveState = {
  isSaving: false,
  error: null,
  checkoutDetails: {},
};

const defaultFetchState = {
  isfetching: false,
  error: null,
  checkoutDetails: [],
  limit: 500,
  skip: 0,
};
export const checkoutSaveReducer = handleActions(
  {
    [saveCheckoutRequest](state) {
      console.log(state);
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    },
    [saveCheckoutSuccess](state, { payload }) {
      return {
        ...state,
        checkoutDetails: payload,
        isSaving: false,
        error: null,
      };
    },
    [saveCheckoutFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isSaving: false,
      };
    },
    [defaultSaveCheckout](state) {
      return {
        ...state,
        checkoutDetails: {},
        error: null,
        isSaving: false,
      };
    },
  },
  defaultSaveState,
);

export const checkoutFetchReducer = handleActions(
  {
    [fetchCheckoutRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchCheckoutSuccess](state, { payload }) {
      return {
        ...state,
        checkoutDetails: payload,
        limit: state.limit,
        skip: state.skip,
        isFetching: false,
        error: null,
      };
    },
    [fetchCheckoutFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    },
    [addToCheckoutData](state, { payload }) {
      return {
        ...state,
        checkoutDetails: [payload, ...state.checkoutDetails],
        error: null,
      };
    },
  },
  defaultFetchState,
);
