import React from 'react';
import {
  Row,
  Col,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import OicWhiteLogo from '../../../shared/img/logo/oiclogowhite/OIC - logo white-01@3x.png';

const LoginForm = ({
  onInputChangeHandler,
  loginData,
  login,
  loggingIn,
}) => (
  <div className="login">
    <Row className="login__topwrapper">
      <Col md={4} className="login__colpadding">
        <div className="login__tablewrapper login__leftmainwrapper">
          <div className="login__tablecell">
            <img className="login__logo" alt="oic-logo" src={OicWhiteLogo} />
            <h1 className="login__left-title">Lofty Warehouse</h1>
          </div>
        </div>
      </Col>
      <Col md={8} className="login__colpadding">
        <div className="login__tablewrapper">
          <div className="login__tablecell">
            <div className="login__rightcontainer">
              <div className="login__rightsubcontainer">
                <h4 className="login__verticalgap login__leftheader">Login to your account.</h4>
                <form onSubmit={(e) => e.preventDefault()} className="form">
                  <Row className="login__verticalgap">
                    <Col md={12}>
                      <label className="login__label" htmlFor="orderId">PHONE NUMBER</label>
                      <input
                        autoComplete="off"
                        onChange={(e) => onInputChangeHandler(e, 'phoneNumber', 'textInput')}
                        name="phoneNumber"
                        component="input"
                        value={loginData.phoneNumber || ''}
                        type="number"
                      />
                    </Col>
                  </Row>
                  <Row className="login__verticalgap">
                    <Col md={12}>
                      <label className="login__label" htmlFor="orderId">PASSWORD</label>
                      <input
                        autoComplete="off"
                        onChange={(e) => onInputChangeHandler(e, 'password', 'textInput')}
                        name="password"
                        component="input"
                        value={loginData.password || ''}
                        type="password"
                      />
                    </Col>
                  </Row>
                </form>
                <Button
                  className="rounded"
                  color="success"
                  disabled={loggingIn}
                  onClick={() => login()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </div>
);

LoginForm.propTypes = {
  onInputChangeHandler: PropTypes.func.isRequired,
  loginData: PropTypes.objectOf.isRequired,
  login: PropTypes.func.isRequired,
  loggingIn: PropTypes.bool.isRequired,
};

export default LoginForm;
