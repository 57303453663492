import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import moment from 'moment';

const tableHeaderData = [
  { id: 2, title: 'Return ID/Date' },
  { id: 3, title: 'Vendor Name' },
  { id: 4, title: 'Vendor Invoice ID/Date' },
  { id: 5, title: 'Status' },
];

const BasicTable = ({ tableRowsData = [], changeStatus, viewDetails }) => {
  console.log('List Return');
  console.log(tableRowsData);
  return (
    <Table responsive hover>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRowsData.map((item) => (
          /* eslint no-underscore-dangle: 0 */
          // eslint-disable-next-line no-undef
          <tr key={item._id} role="presentation" onClick={() => viewDetails(item)}>
            <td>{item.returnId || '-'}/{moment(item._created_at).format('DD MMM YYYY')}</td>
            <td>{item.supplier || '-'}</td>
            <td>{item.vendorInvoiceId || '-'}/
              {moment(item.vendorInvoiceDate).format('DD MMM YYYY')}
            </td>
            <td>
              <label
                role="presentation"
                onClick={() => changeStatus(item)}
                className={
                  `smalldiv__statushighlight
                    ${item.status === 'Pending'
                  && ' smalldiv__orangestatus'}
                    ${item.status === 'Credit Note'
                  && ' smalldiv__greenstatus'}
                    ${item.status === 'Replaced'
                  && ' smalldiv__bluestatus'}
                    ${item.status === 'Shipped'
                  && ' smalldiv__bluishgreenstatus'}`
                }
                htmlFor="status"
              >
                {item.status || '-'}
              </label>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

BasicTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
  changeStatus: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  viewDetails: PropTypes.func.isRequired,
};

export default BasicTable;
