import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';

const tableHeaderData = [
  { id: 1, title: 'S.No' },
  { id: 2, title: 'Spherical' },
  { id: 3, title: 'Cylindrical' },
  { id: 4, title: 'Axis' },
  { id: 5, title: 'Add' },
  { id: 6, title: 'Quantity' },
];

const BasicTable = ({ tableRowsData = [] }) => {
  console.log('List Inventory Track Individual');
  return (
    <Table responsive hover>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th style={{ fontWeight: 'bold' }} key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRowsData.map((item, index) => (
          <tr>
            <td>{index + 1}</td>
            <td>{item.Spherical || '-'}</td>
            <td>{item.Cylindrical || '-'}</td>
            <td>{item.Axis || '-'}</td>
            <td>{item.Add || '-'}</td>
            <td>{item.Quantity || '-'}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

BasicTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
};

export default BasicTable;
