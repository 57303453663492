import { get, post } from './base/index';

export default {
  get: (data = {}) => {
    const params = { ...data };
    return get(
      `${process.env.REACT_APP_API_URL}v1/accounts/myinventory/getallmyinventory`,
      { params },
    );
  },
  post: (data = {}) => {
    const body = { ...data };
    return post(
      `${process.env.REACT_APP_API_URL}v2/inventory/client/inventory/postmyinventory`,
      body,
    );
  },
};
