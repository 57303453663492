import purchaseOrder from './api/purchaseorder';
import inventory from './api/inventory';
import inventoryTrack from './api/inventorytrack';
import account from './api/account';
import vendor from './api/vendor';
import master from './api/master';
import grn from './api/grn';
import customerOrder from './api/customerorder';
import checkout from './api/checkout';
import customer from './api/customer';
import adjustments from './api/adjustments';
import returns from './api/return';
import reports from './api/reports';
import IocReportFuction from './api/IocReportFuction';

const api = {
  purchaseOrder,
  inventory,
  account,
  vendor,
  master,
  grn,
  customerOrder,
  checkout,
  customer,
  adjustments,
  returns,
  reports,
  inventoryTrack,
  IocReportFuction,
};

export default api;
