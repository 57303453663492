import { createAction } from 'redux-actions';
import api from '../../utils/apiClient';

export const saveCustomerRequest = createAction('SAVE_CUSTOMER_REQUEST');
export const saveCustomerSuccess = createAction('SAVE_CUSTOMER_SUCCESS');
export const saveCustomerFailure = createAction('SAVE_CUSTOMER_FAILURE');
export const defaultSaveCustomer = createAction('DEFAULT_CUSTOMER');
export const addToCustomerData = createAction('ADD_TO_CUSTOMER_DATA');

export const removeCustomerRequest = createAction('REMOVE_CUSTOMER_REQUEST');
export const removeCustomerSuccess = createAction('REMOVE_CUSTOMER_SUCCESS');
export const removeCustomerFailure = createAction('REMOVE_CUSTOMER_FAILURE');
export const defaultRemoveCustomer = createAction('DEFAULT_REMOVE_CUSTOMER');
export const removeFromCustomerData = createAction('REMOVE_FROM_CUSTOMER_DATA');

export const fetchCustomerRequest = createAction('FETCH_CUSTOMER_REQUEST');
export const fetchCustomerSuccess = createAction('FETCH_CUSTOMER_SUCCESS');
export const fetchCustomerFailure = createAction('FETCH_CUSTOMER_FAILURE');

export const saveCustomer = (data) => async (dispatch) => {
  try {
    dispatch(saveCustomerRequest());
    const response = await api.customer.newCustomer(data);
    console.log(response.data);
    dispatch(saveCustomerSuccess(response.data));
    if (response.data.data) {
      dispatch(addToCustomerData(response.data.data));
    }
    dispatch(defaultSaveCustomer());
  } catch (e) {
    console.log(e);
    dispatch(saveCustomerFailure(e));
  }
};

export const removeCustomer = (data) => async (dispatch) => {
  try {
    dispatch(removeCustomerRequest());
    const response = await api.customer.removeCustomer(data);
    console.log(response.data);
    dispatch(removeCustomerSuccess(response.data));
    if (response.data.data) {
      dispatch(removeFromCustomerData(response.data.data));
    }
    dispatch(defaultRemoveCustomer());
  } catch (e) {
    console.log(e);
    dispatch(removeCustomerFailure(e));
  }
};

export const fetchCustomer = (data) => async (dispatch) => {
  try {
    const params = {
      limit: data.limit,
      skip: data.skip,
    };
    console.log(params);
    dispatch(fetchCustomerRequest());
    const response = await api.customer.get(params);
    console.log(response);
    dispatch(fetchCustomerSuccess(response.data.data));
  } catch (e) {
    console.log(e);
    dispatch(fetchCustomerFailure(e));
  }
};
