import React from 'react';
import PropTypes from 'prop-types';
import PurchaseSummaryTable from '../../Tables/BasicTables/components/PurchaseSummaryGrn';

const PurchaseSummary = ({
  searchedPo,
  viewSpecificDetails,
  currentViewDetailsData,
  viewDetailsMode,
}) => {
  console.log('Purchase summary component');
  return (
    <PurchaseSummaryTable
      viewSpecificDetails={viewSpecificDetails}
      tableRowsData={searchedPo}
      viewDetailsMode={viewDetailsMode}
      currentViewDetailsData={currentViewDetailsData}
    />
  );
};

PurchaseSummary.propTypes = {
  searchedPo: PropTypes.objectOf.isRequired,
  viewSpecificDetails: PropTypes.func.isRequired,
  currentViewDetailsData: PropTypes.objectOf.isRequired,
  viewDetailsMode: PropTypes.bool.isRequired,
};

export default PurchaseSummary;
