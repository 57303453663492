import { createAction } from 'redux-actions';
import api from '../../utils/apiClient';

export const saveBrandRequest = createAction('SAVE_BRAND_REQUEST');
export const saveBrandSuccess = createAction('SAVE_BRAND_SUCCESS');
export const saveBrandFailure = createAction('SAVE_BRAND_FAILURE');
export const defaultSaveBrand = createAction('DEFAULT_BRAND');
export const addToBrandData = createAction('ADD_TO_BRAND_DATA');

export const removeBrandRequest = createAction('REMOVE_BRAND_REQUEST');
export const removeBrandSuccess = createAction('REMOVE_BRAND_SUCCESS');
export const removeBrandFailure = createAction('REMOVE_BRAND_FAILURE');
export const defaultRemoveBrand = createAction('DEFAULT_REMOVE_BRAND');
export const removeFromBrandData = createAction('REMOVE_FROM_BRAND_DATA');

export const fetchBrandRequest = createAction('FETCH_BRAND_REQUEST');
export const fetchBrandSuccess = createAction('FETCH_BRAND_SUCCESS');
export const fetchBrandFailure = createAction('FETCH_BRAND_FAILURE');

export const saveBrand = (data) => async (dispatch) => {
  try {
    dispatch(saveBrandRequest());
    const response = await api.master.newBrand(data);
    console.log(response.data);
    dispatch(saveBrandSuccess(response.data));
    if (response.data.data) {
      dispatch(addToBrandData(response.data.data));
    }
    dispatch(defaultSaveBrand());
  } catch (e) {
    console.log(e);
    dispatch(saveBrandFailure(e));
  }
};

export const removeBrand = (data) => async (dispatch) => {
  try {
    dispatch(removeBrandRequest());
    const response = await api.master.removeBrand(data);
    console.log(response.data);
    dispatch(removeBrandSuccess(response.data));
    if (response.data.data) {
      dispatch(removeFromBrandData(response.data.data));
    }
    dispatch(defaultRemoveBrand());
  } catch (e) {
    console.log(e);
    dispatch(removeBrandFailure(e));
  }
};

export const fetchBrand = (data) => async (dispatch) => {
  try {
    const params = {
      limit: data.limit,
      skip: data.skip,
    };
    console.log(params);
    dispatch(fetchBrandRequest());
    const response = await api.master.getBrand(params);
    console.log(response);
    dispatch(fetchBrandSuccess(response.data.data));
  } catch (e) {
    console.log(e);
    dispatch(fetchBrandFailure(e));
  }
};
