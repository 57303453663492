import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

const FullVariantsTable = ({
  tableHeaderData = [],
  tableRowsData = [],
  updateVariants,
  currentViewDetailsData,
  viewDetailsMode,
  groupIndex,
}) => {
  console.log(tableRowsData, groupIndex);
  // console.log(currentViewDetailsData.groups[groupIndex].variants);
  // currentViewDetailsData.groups[groupIndex].variants.sort((oldd, newd) => {
  //   const Axis = oldd.Axis - newd.Axis;
  //   const Add = oldd.Add - newd.Add;
  //   const Cylindrical = oldd.Cylindrical - newd.Cylindrical;
  //   const Spherical = oldd.Spherical - newd.Spherical;
  //   const Quantity = oldd.Quantity - newd.Quantity;
  //   return Spherical || Cylindrical || Axis || Add || Quantity;
  // });
  return (
    <div
      style={{
        height: '70vh',
        overflowY: 'auto',
      }}
    >
      <Table hover>
        <thead>
          <tr>
            {tableHeaderData.map((item) => (
              <th key={item.id}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!viewDetailsMode
            && tableRowsData.groups[groupIndex].variants.map((item, index) => (
              <tr key={item.ItemCode}>
                <td>{index + 1}</td>
                <td>{item.LensType}</td>
                <td>{item.Spherical}</td>
                <td>{item.Cylindrical}</td>
                <td>{item.Axis}</td>
                <td>{item.Add}</td>
                <td>{item.Quantity || 0}</td>
                <td>
                  <form onSubmit={(e) => e.preventDefault()} className="form">
                    <input
                      style={{
                        width: '80px',
                        height: '23px',
                        textAlign: 'center',
                      }}
                      type="number"
                      min="0"
                      max={item.Quantity}
                      component="input"
                      onChange={(e) => updateVariants(e, index)}
                      value={item.ReceivedQuantity}
                    />
                  </form>
                </td>
              </tr>
            ))}
          {viewDetailsMode
            && currentViewDetailsData.groups[groupIndex].variants.map(
              (item, index) => (
                <tr key={item.ItemCode}>
                  <td>{index + 1}</td>
                  <td>{item.LensType}</td>
                  <td>{item.Spherical}</td>
                  <td>{item.Cylindrical}</td>
                  <td>{item.Axis}</td>
                  <td>{item.Add}</td>
                  <td>{item.Quantity || 0}</td>
                  <td>
                    <form onSubmit={(e) => e.preventDefault()} className="form">
                      <input
                        style={{
                          width: '80px',
                          height: '23px',
                          textAlign: 'center',
                        }}
                        type="number"
                        component="input"
                        onChange={(e) => updateVariants(e, index)}
                        value={item.ReceivedQuantity}
                      />
                    </form>
                  </td>
                </tr>
              ),
            )}
        </tbody>
      </Table>
    </div>
  );
};

FullVariantsTable.propTypes = {
  tableHeaderData: PropTypes.arrayOf.isRequired,
  tableRowsData: PropTypes.arrayOf.isRequired,
  updateVariants: PropTypes.func.isRequired,
  viewDetailsMode: PropTypes.bool.isRequired,
  currentViewDetailsData: PropTypes.objectOf.isRequired,
  groupIndex: PropTypes.number.isRequired,
};

export default FullVariantsTable;
