import { get, post } from './base/index';

export default {
  getVendors: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}v2/inventory/client/wvendor/vendor`, { params });
  },
  saveVendor: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/wvendor/vendor`, body);
  },
};
