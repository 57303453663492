import { get, post } from './base/index';

export default {
  newReturn: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/wreturn/return`, body);
  },
  get: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}v2/inventory/client/wreturn/return`, { params });
    // return get('http://localhost/api/v2/inventory/client/wreturn/return?limit=500&skip=0', { params });
  },
  updateReturn: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/wreturn/updatereturn`, body);
  },
};
