import React from 'react';
import Proptypes from 'prop-types';
import {
  Col,
  Row,
  Button,
} from 'reactstrap';
import Select from 'react-select';

const ReturnPrescription = ({
  onInputChangeHandler,
  saveReturn,
  lensPowers,
  reasonOptions,
  metaV1Options,
  saveReturnState,
  currentFormIndex,
  returnForm,
}) => {
  console.log(returnForm);
  console.log('Prescription form - returns!');
  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()} className="form">
        <Row>
          <Col md={12}>
            <h4 className="page-subhead">Prescription Details</h4>
          </Col>
        </Row>
        <Row>
          <Col md={3} className="form-group">
            <label htmlFor="lensType">Lens Type *</label>
            <Select
              onChange={(e) => onInputChangeHandler(e, 'lensType', 'select')}
              name="lensType"
              id="lensType"
              options={metaV1Options.LensType}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="lensMaterial">Lens Material *</label>
            <Select
              onChange={(e) => onInputChangeHandler(e, 'lensMaterial', 'select')}
              name="lensMaterial"
              id="lensMaterial"
              options={metaV1Options.LensMaterial}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} className="form-group">
            <label htmlFor="sph">SPH</label>
            <Select
              onChange={(e) => onInputChangeHandler(e, 'sph', 'select')}
              name="sph"
              id="sph"
              options={lensPowers.spherical}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={2} className="form-group">
            <label htmlFor="cyl">CYL</label>
            <Select
              onChange={(e) => onInputChangeHandler(e, 'cyl', 'select')}
              name="cyl"
              id="cyl"
              options={lensPowers.cylindrical}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={2} className="form-group">
            <label htmlFor="add">ADD</label>
            <Select
              onChange={(e) => onInputChangeHandler(e, 'add', 'select')}
              name="add"
              id="add"
              options={lensPowers.add}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={2} className="form-group">
            <label htmlFor="axis">AXIS</label>
            <Select
              onChange={(e) => onInputChangeHandler(e, 'axis', 'select')}
              name="axis"
              id="axis"
              options={lensPowers.axis}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={2} className="form-group">
            <label htmlFor="qty">QTY *</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'qty', 'textInput')}
              name="qty"
              component="input"
              type="text"
              value={
                returnForm.inventories[currentFormIndex] ? returnForm.inventories[currentFormIndex].qty : 0
              }
              placeholder="type here..."
            />
          </Col>
        </Row>
        <Row>
          <Col md={3} className="form-group">
            <label htmlFor="reason">REASON</label>
            <Select
              onChange={(e) => onInputChangeHandler(e, 'reason', 'select')}
              name="reason"
              id="reason"
              options={reasonOptions}
              disabled={saveReturnState.isSaving}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
        </Row>
      </form>
      <Button
        style={{ marginTop: '10px' }}
        className="rounded"
        onClick={() => saveReturn()}
        disabled={saveReturnState.isSaving}
        color="success"
        size="sm"
      >
        Submit
      </Button>
    </div>
  );
};

ReturnPrescription.propTypes = {
  onInputChangeHandler: Proptypes.func.isRequired,
  saveReturn: Proptypes.func.isRequired,
  lensPowers: Proptypes.objectOf.isRequired,
  reasonOptions: Proptypes.arrayOf.isRequired,
  metaV1Options: Proptypes.objectOf.isRequired,
  saveReturnState: Proptypes.objectOf.isRequired,
  currentFormIndex: Proptypes.number.isRequired,
  returnForm: Proptypes.objectOf.isRequired,
};

export default ReturnPrescription;
