import React, { useState, useEffect } from 'react';
import {
  Container,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import IndianStatesCitiesList from 'indian-states-cities-list';
import AddCustomer from './components/AddCustomer';
import ListCustomer from './components/ListCustomer';
import { saveCustomer, fetchCustomer, removeCustomer } from '../../redux/actions/customerActions';
import Modal from '../../shared/components/modal/Modal';

const Customer = ({
  saveCustomerState,
  saveCustomerAction,
  customerDataState,
  fetchCustomerAction,
  removeCustomerAction,
  removeCustomerState,
}) => {
  const [activePage, setActivePage] = useState('ListCustomer');
  const [activeModal, setActiveModal] = useState('');
  const [customerFormData, setCustomerFormData] = useState({});
  const [stateOptions] = useState(IndianStatesCitiesList.STATES_OBJECT);
  const [cityOptions] = useState(IndianStatesCitiesList.STATE_WISE_CITIES);
  const activePageHandler = (pageName) => {
    setActivePage(pageName);
  };

  const activeModalAction = (action, close) => {
    if (action === 'ListCustomer') {
      activePageHandler(action);
    }

    if (close) {
      setActiveModal('');
      setCustomerFormData({});
    } else {
      setActiveModal(action);
    }
  };

  const onInputChangeHandler = (e, fieldName, type) => {
    let fieldValue;

    if (type === 'select') {
      fieldValue = e;
    }

    if (type === 'textInput') {
      fieldValue = e.target.value;
    }

    const customerFormDataSetter = { ...customerFormData, [fieldName]: fieldValue };
    setCustomerFormData(customerFormDataSetter);
  };

  const saveCustomerFunc = () => {
    console.log(customerFormData);

    if (!customerFormData.customerName) {
      return toast.error('Please fill the mandatory fields');
    }

    if (customerFormData.phoneNumber) {
      if (!Number(customerFormData.phoneNumber)) {
        return toast.error('Phone Number should only contain numbers!');
      }

      if (customerFormData.phoneNumber.length !== 10) {
        return toast.error('Phone Number should be 10 digit!');
      }
    }

    const formedData = { ...customerFormData };

    if (customerFormData.city) {
      formedData.city = customerFormData.city.value;
    }

    if (customerFormData.state) {
      formedData.state = customerFormData.state.value;
    }

    console.log(formedData);

    saveCustomerAction(formedData);

    return 1;
  };

  useEffect(() => {
    fetchCustomerAction(customerDataState);
  }, []);

  useEffect(() => {
    console.log(customerDataState);
  }, [customerDataState]);

  useEffect(() => {
    console.log(saveCustomerState);
    if (!saveCustomerState.error
      && !saveCustomerState.isSaving
      && saveCustomerState.customerDetails
      && saveCustomerState.customerDetails.message) {
      if (saveCustomerState.customerDetails.message === 'success') {
        setActiveModal('CustomerSuccess');
      } else {
        toast.error('Unexpected Error:(');
      }
    }

    if (saveCustomerState.error) {
      toast.error('Unexpected Error:(');
    }
  }, [saveCustomerState]);

  useEffect(() => {
    console.log(removeCustomerState);
    if (!removeCustomerState.error
      && !removeCustomerState.isRemoving
      && removeCustomerState.customerDetails
      && removeCustomerState.customerDetails.message) {
      if (removeCustomerState.customerDetails.message === 'success') {
        toast.success('Customer Removed Successfully!');
      } else {
        toast.error('Unexpected Error:(');
      }
    }

    if (removeCustomerState.error) {
      toast.error('Unexpected Error:(');
    }
  }, [removeCustomerState]);

  const removeCustomerFunc = (item) => {
    removeCustomerAction(item);
  };

  return (
    <div className="customer">
      {
        activePage === 'ListCustomer'
        && (
          <Container>
            <Row>
              <Col md={12} className="smalldiv__listheader">
                <h4>
                  Customers
                </h4>
                <Button
                  className="rounded btn-right"
                  color="success"
                  onClick={() => activePageHandler('AddCustomer')}
                >
                  New Customer
                </Button>
              </Col>
            </Row>
            <Row style={{ placeContent: 'center' }}>
              {
                customerDataState.isFetching
                  ? (
                    <div className="spinner-border" style={{ color: '#03a5a5' }}>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <ListCustomer
                      customerData={customerDataState}
                      removeCustomerState={removeCustomerState}
                      removeCustomer={removeCustomerFunc}
                    />
                  )
              }
            </Row>
          </Container>
        )
      }
      {
        activePage === 'AddCustomer'
        && (
          <AddCustomer
            activePageHandler={activePageHandler}
            customerFormData={customerFormData}
            onInputChangeHandler={onInputChangeHandler}
            stateOptions={stateOptions}
            cityOptions={cityOptions}
            saveCustomer={saveCustomerFunc}
            saveCustomerState={saveCustomerState}
          />
        )
      }
      {
        activeModal === 'CustomerSuccess'
        && (
          <Modal
            modalStatus={activeModal === 'CustomerSuccess'}
            modalAction={() => activeModalAction('ListCustomer', 'close')}
            color="success"
            title="Well Done!"
            btn="Success"
            message={(
              <div>
                <div>Customer Added Successfully! <br /><br />
                  <span><b>{customerFormData.customerName}</b></span><br />
                </div>
                <Button
                  style={{ marginTop: '10px' }}
                  type="button btn-sm"
                  className="rounded"
                  color="success"
                  onClick={() => activeModalAction('NewCustomer', 'close')}
                >
                  New Customer
                </Button>
              </div>
            )}
          />
        )
      }
    </div>
  );
};

Customer.propTypes = {
  saveCustomerAction: PropTypes.func.isRequired,
  removeCustomerAction: PropTypes.func.isRequired,
  saveCustomerState: PropTypes.objectOf.isRequired,
  removeCustomerState: PropTypes.objectOf.isRequired,
  customerDataState: PropTypes.arrayOf.isRequired,
  fetchCustomerAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  saveCustomerAction: saveCustomer,
  removeCustomerAction: removeCustomer,
  fetchCustomerAction: fetchCustomer,
};

const mapStateToProps = (state) => ({
  saveCustomerState: state.saveCustomer,
  customerDataState: state.customerData,
  removeCustomerState: state.removeCustomer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
