/* eslint-disable import/named */
import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { cancelCustomerOrderReducer } from '../../redux/reducers/customerOrderReducer';
import { cancelPendingOrderReducer } from '../../redux/reducers/grnReducer';
import {
  sidebarReducer,
  themeReducer,
  rtlReducer,
  purchaseOrderSaveReducer,
  purchaseOrderFetchReducer,
  purchaseOrderUpdateReducer,
  purchaseOrderCountReducer,
  accountReducer,
  vendorFetchReducer,
  vendorSaveReducer,
  masterReducer,
  grnSaveReducer,
  grnFetchReducer,
  grnUpdateReducer,
  grnFetchPendingReducer,
  saveNewPoGrnReducer,
  inventoryFetchReducer,
  inventorySaveReducer,
  adjustmentFetchReducer,
  adjustmentSaveReducer,
  customerOrderSaveReducer,
  customerOrderFetchReducer,
  saveNewPoCustomerOrderReducer,
  checkoutSaveReducer,
  checkoutFetchReducer,
  customerSaveReducer,
  customerFetchReducer,
  customerRemoveReducer,
  reasonSaveReducer,
  reasonFetchReducer,
  reasonRemoveReducer,
  brandSaveReducer,
  brandFetchReducer,
  brandRemoveReducer,
  userFetchReducer,
  userSaveReducer,
  returnSaveReducer,
  returnFetchReducer,
  returnUpdateReducer,
  fetchCurrentUserReducer,
  inventoryTrackFetchReducer,
  oicReportSaveReducer,
} from '../../redux/reducers/index';

const reducer = combineReducers({
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  purchaseOrder: purchaseOrderSaveReducer,
  purchaseOrderUpdate: purchaseOrderUpdateReducer,
  purchaseOrderData: purchaseOrderFetchReducer,
  purchaseOrderCount: purchaseOrderCountReducer,
  account: accountReducer,
  vendorData: vendorFetchReducer,
  vendorSave: vendorSaveReducer,
  master: masterReducer,
  grnSave: grnSaveReducer,
  grnData: grnFetchReducer,
  grnUpdate: grnUpdateReducer,
  grnPendingData: grnFetchPendingReducer,
  cancelPendingOrder: cancelPendingOrderReducer,
  saveNewPoGrn: saveNewPoGrnReducer,
  inventoryData: inventoryFetchReducer,
  inventorySave: inventorySaveReducer,
  adjustmentData: adjustmentFetchReducer,
  adjustmentSave: adjustmentSaveReducer,
  saveCustomerOrder: customerOrderSaveReducer,
  customerOrderData: customerOrderFetchReducer,
  saveNewPoCustomerOrder: saveNewPoCustomerOrderReducer,
  cancelCustomerOrder: cancelCustomerOrderReducer,
  saveCheckout: checkoutSaveReducer,
  checkoutData: checkoutFetchReducer,
  saveCustomer: customerSaveReducer,
  customerData: customerFetchReducer,
  removeCustomer: customerRemoveReducer,
  saveReason: reasonSaveReducer,
  reasonData: reasonFetchReducer,
  removeReason: reasonRemoveReducer,
  saveBrand: brandSaveReducer,
  brandData: brandFetchReducer,
  removeBrand: brandRemoveReducer,
  saveUser: userSaveReducer,
  userData: userFetchReducer,
  saveReturn: returnSaveReducer,
  returnData: returnFetchReducer,
  updateReturn: returnUpdateReducer,
  fetchCurrentUser: fetchCurrentUserReducer,
  inventoryTrackData: inventoryTrackFetchReducer,
  addToReportCustomer: oicReportSaveReducer,
});

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
