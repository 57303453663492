import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';

const tableHeaderData = [
  { id: 1, title: 'Customer Order ID' },
  { id: 2, title: 'Reference Order ID' },
  { id: 3, title: 'Total Quantity' },
];

const BasicTable = ({ tableRowsData = {} }) => {
  console.log('EOD Customer Order');
  /* eslint no-underscore-dangle: 0 */
  return (
    <Table responsive hover style={{ marginTop: '15px' }}>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRowsData.map((item) => (
          <tr role="presentation">
            <td>{item._id.customerOrderId}</td>
            <td>{item._id.referenceOrderId}</td>
            <td>{item.totalQuantity}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

BasicTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
};

export default BasicTable;
