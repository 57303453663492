import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import moment from 'moment';

const tableHeaderData = [
  { id: 1, title: 'Track ID' },
  { id: 2, title: 'Created Time' },
  { id: 3, title: 'Source' },
  { id: 4, title: 'Comments' },
  { id: 5, title: 'Total Variants' },
  { id: 6, title: 'Total Quantity' },
];

const BasicTable = ({ tableRowsData = [], viewGroupSummary }) => {
  console.log('List Inventory Track');
  /* eslint no-underscore-dangle: 0 */
  return (
    <Table responsive hover>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th style={{ fontWeight: 'bold' }} key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRowsData.map((item) => (
          <tr role="presentation" onClick={() => viewGroupSummary(item)}>
            <td>{item.trackId || '-'}</td>
            <td>{moment(item._created_at).format('DD MMM YYYY')}</td>
            <td>{item.source || '-'}</td>
            <td>{item.comments || '-'}</td>
            <td>{item.totalInventories || '-'}</td>
            <td>{item.totalQuantities || '-'}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

BasicTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
  viewGroupSummary: PropTypes.func.isRequired,
};

export default BasicTable;
