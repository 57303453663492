import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';

const adjustmentsSummaryTableHeaderData = [
  { id: 1, title: 'S.No' },
  { id: 2, title: 'LensType' },
  { id: 4, title: 'Sph' },
  { id: 5, title: 'Cyl' },
  { id: 7, title: 'Axis' },
  { id: 6, title: 'Add' },
  { id: 9, title: 'QTY' },
  { id: 12, title: 'Reason' },
];

function AdjustmentSummaryTableData({
  adjustmentForm = [],
  deleteAdjustmentVariant,
}) {
  return (
    /* eslint no-underscore-dangle: 0 */
    <Table responsive hover>
      <thead>
        <tr>
          {adjustmentsSummaryTableHeaderData.map((item) => (
            <th style={{ fontWeight: 'bold' }} key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {adjustmentForm.map((item, index) => (
          <tr key={item.ItemCode}>
            <td>{index + 1}</td>
            <td>{item.LensType === undefined ? '-' : item.LensType.value}</td>
            <td>{item.Spherical === undefined ? '0' : item.Spherical.value}</td>
            <td>{item.Cylindrical === undefined ? '0' : item.Cylindrical.value}</td>
            <td>{item.Axis === undefined ? '0' : item.Axis.value}</td>
            <td>{item.Add === undefined ? '0' : item.Add.value}</td>
            <td>{item.Quantity === undefined ? '-' : item.Quantity}</td>
            <td>{item.Reason === undefined ? '-' : item.Reason.value}</td>
            <td>
              <button
                onClick={() => deleteAdjustmentVariant(index)}
                className="smalldiv__table-btn"
                type="button"
              >
                <DeleteForeverIcon />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

AdjustmentSummaryTableData.propTypes = {
  adjustmentForm: PropTypes.arrayOf.isRequired,
  deleteAdjustmentVariant: PropTypes.func.isRequired,
};

export default AdjustmentSummaryTableData;
