import React from 'react';
import {
  // Button,
  Container,
  Row,
  Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import BasicTable from '../../Tables/BasicTables/components/BasicTable';

const ListPurchaseOrder = ({
  tableHeaderData,
  tableRowsData,
  // activePageHandler,
  viewDetails,
}) => {
  console.log(tableRowsData);
  return (
    <Container>
      <Row>
        <Col md={12} className="smalldiv__listheader">
          <h4>
            Purchase Orders
          </h4>
          {/* <Button
            className="rounded btn-right"
            color="success"
            onClick={() => activePageHandler('NewPurchaseForm')}
          >
            New Purchase
          </Button> */}
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          {
            tableRowsData.isFetching
              ? (
                <div style={{ textAlign: 'center' }}>
                  <div className="spinner-border" style={{ color: '#03a5a5' }}>
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <BasicTable
                  viewDetails={viewDetails}
                  tableHeaderData={tableHeaderData}
                  tableRowsData={tableRowsData.purchaseOrderDetails}
                />
              )
          }
        </Col>
      </Row>

    </Container>
  );
};

export default ListPurchaseOrder;

ListPurchaseOrder.propTypes = {
  tableHeaderData: PropTypes.arrayOf.isRequired,
  tableRowsData: PropTypes.arrayOf.isRequired,
  // activePageHandler: PropTypes.func.isRequired,
  viewDetails: PropTypes.func.isRequired,
};
