/* eslint-disable import/prefer-default-export */
import { handleActions } from 'redux-actions';
import {
  addToReportCustomer,
} from '../actions/oicReportAction';

const defaultSaveState = {
  isSaving: false,
  error: null,
  customerDetails: [],
};

const oicReportSaveReducer = handleActions(
  {
    [addToReportCustomer](state, { payload }) {
      return {
        ...state,
        customerDetails: [...payload],
        error: null,
      };
    },
  },
  defaultSaveState,
);
export default oicReportSaveReducer;
