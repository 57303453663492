import React from 'react';
import PropTypes from 'prop-types';
import PurchaseSummaryTable from '../../Tables/BasicTables/components/PurchaseSummary';

const PurchaseSummary = ({
  purchaseForm,
  totalVariantQuantity,
  currentViewDetailsData,
  viewDetailsMode,
  viewSpecificDetails,
  groupIndex,
  activePageHandler,
}) => {
  console.log('Purchase Form', purchaseForm);
  return (
    <>
      <PurchaseSummaryTable
        tableRowsData={purchaseForm}
        totalVariantQuantity={totalVariantQuantity}
        viewDetailsMode={viewDetailsMode}
        currentViewDetailsData={currentViewDetailsData}
        viewSpecificDetails={viewSpecificDetails}
        activePageHandler={activePageHandler}
        groupIndex={groupIndex}
      />
    </>
  );
};

PurchaseSummary.propTypes = {
  totalVariantQuantity: PropTypes.number.isRequired,
  purchaseForm: PropTypes.objectOf.isRequired,
  currentViewDetailsData: PropTypes.objectOf.isRequired,
  viewDetailsMode: PropTypes.bool.isRequired,
  viewSpecificDetails: PropTypes.func.isRequired,
  groupIndex: PropTypes.number.isRequired,
  activePageHandler: PropTypes.func.isRequired,
};

export default PurchaseSummary;
