import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';

const tableHeaderData = [
  { id: 1, title: 'Brand' },
  { id: 2, title: 'Category' },
];

const BasicTable = ({ tableRowsData = [], removeBrand, removeBrandState }) => {
  console.log('List Brand');
  return (
    /* eslint no-underscore-dangle: 0 */
    <Table responsive hover>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th style={{ fontWeight: 'bold' }} key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRowsData.map((item) => (
          <tr key={item._id}>
            <td>{item.brandName || '-'}</td>
            <td>{item.category || '-'}</td>
            <td>
              {
                !removeBrandState.isRemoving
                && (
                  <button
                    onClick={() => removeBrand(item)}
                    className="smalldiv__table-btn"
                    type="button"
                  >
                    <DeleteForeverIcon />
                  </button>
                )
              }
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

BasicTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
  removeBrand: PropTypes.func.isRequired,
  removeBrandState: PropTypes.objectOf.isRequired,
};

export default BasicTable;
