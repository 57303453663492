import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';

const tableHeaderData = [
  { id: 1, title: 'Lens Type' },
  { id: 2, title: 'Lens Material' },
  { id: 3, title: 'Required Quantity' },
  { id: 4, title: 'Available Quantity' },
  { id: 5, title: 'Purchase Variants' },
];

const BasicTable = ({ tableRowsData = {} }) => {
  console.log('Basic Table');
  return (
    <Table responsive hover style={{ marginTop: '15px' }}>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRowsData.inventories.map((item, index) => (
          <>
            <tr role="presentation">
              <td>{item.LensType}</td>
              <td>{item.LensMaterial}</td>
              <td>{item.orderQuantity}</td>
              <td>{item.availableQuantity}</td>
              <td>{item.purchaseQuantity}</td>
            </tr>
            {((tableRowsData.inventories.length - 1) === index)
              && (
                <tr role="presentation">
                  <td />
                  <td><b>Grand Total:</b></td>
                  <td><b>{tableRowsData.totalRequiredQuantity}</b></td>
                  <td><b>{tableRowsData.totalAvailableQuantity}</b></td>
                  <td><b>{tableRowsData.totalPurchaseQuantity}</b></td>
                </tr>
              )}
          </>
        ))}
      </tbody>
    </Table>
  );
};

BasicTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
};

export default BasicTable;
