import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';

const tableHeaderData = [
  { id: 1, title: 'Business Name' },
  { id: 2, title: 'Contact Person' },
  { id: 3, title: 'Phone Number' },
];

const BasicTable = ({ tableRowsData = [] }) => {
  console.log('List User');
  return (
    /* eslint no-underscore-dangle: 0 */
    <Table responsive hover>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th style={{ fontWeight: 'bold' }} key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRowsData.map((item) => (
          <tr key={item._id}>
            <td>{item.buisnessName || '-'}</td>
            <td>{item.contactPerson || '-'}</td>
            <td>{item.phoneNumber || '-'}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

BasicTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
};

export default BasicTable;
