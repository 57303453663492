import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import classNames from 'classnames';

const ModalComponent = ({
  color, title, message, modalAction, modalStatus, header, textcolor,
}) => {
  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'success':
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }

  const modalClass = classNames({
    'modal-dialog--header': header,
  });

  return (
    <div>
      <Modal
        isOpen={modalStatus}
        modalClassName="ltr-support"
        className={`modal-dialog--${color} ${modalClass} theme-light`}
      >
        <div className="modal__header">
          <button
            className={`lnr lnr-cross modal__close-btn ${modalClass}`}
            aria-label="modal__close-btn"
            type="button"
            style={textcolor ? { color: textcolor } : { }}
            onClick={(action) => modalAction(action)}
          />
          {header ? '' : Icon}
          <h4
            className="text-modal  modal__title"
            style={textcolor ? { color: textcolor, fontWeight: '600', fontSize: '16px' } : {}}
          >{title}
          </h4>
        </div>
        <div className="modal__body">
          {message}
        </div>
        {/* <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={() => modalAction('cancel')}>Cancel</Button>{' '}
          <Button className="modal_ok" color={color} onClick={() => modalAction('ok')}>Ok</Button>
        </ButtonToolbar> */}
      </Modal>
    </div>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  textcolor: PropTypes.string.isRequired,
  modalAction: PropTypes.func.isRequired,
  modalStatus: PropTypes.bool.isRequired,
  header: PropTypes.bool.isRequired,
};

ModalComponent.defaultProps = {
  title: '',
  message: '',
};

export default ModalComponent;
