import React from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import BasicTable from '../../Tables/BasicTables/components/ListReturn';

const ListReturn = ({
  returnData,
  changeStatus,
  viewDetails,
}) => {
  console.log(returnData);
  return (
    <Container>
      <BasicTable
        tableRowsData={returnData.returnDetails}
        changeStatus={changeStatus}
        viewDetails={viewDetails}
      />
    </Container>
  );
};

export default ListReturn;

ListReturn.propTypes = {
  returnData: PropTypes.arrayOf.isRequired,
  changeStatus: PropTypes.func.isRequired,
  viewDetails: PropTypes.func.isRequired,
};
