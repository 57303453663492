import React, { useState, useEffect } from 'react';
import {
  Container,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import Proptypes from 'prop-types';
import IndianStatesCitiesList from 'indian-states-cities-list';
import { toast } from 'react-toastify';
import Modal from '../../shared/components/modal/Modal';
import AddUser from './components/AddUser';
import ListUser from './components/ListUser';
import api from '../../utils/apiClient';
import {
  saveUser,
  fetchUser,
} from '../../redux/actions/accountActions';

const userRoles = [{
  label: 'Warehouse Manager',
  value: 'Warehouse Manager',
}, {
  label: 'Purchase Admin',
  value: 'Purchase Admin',
}, {
  label: 'Production Admin',
  value: 'Production Admin',
}, {
  label: 'GRN Admin',
  value: 'GRN Admin',
}];

const Users = ({
  saveUserAction,
  userDataState,
  saveUserState,
  fetchUserAction,
}) => {
  const [activePage, setActivePage] = useState('ListUser');
  const [activeModal, setActiveModal] = useState('');
  const [userFormData, setUserFormData] = useState({});
  const [stateOptions] = useState(IndianStatesCitiesList.STATES_OBJECT);
  const [cityOptions] = useState(IndianStatesCitiesList.STATE_WISE_CITIES);
  const [phoneNumberChecking, setPhoneNumberChecking] = useState(false);

  const activePageHandler = (pageName) => {
    setActivePage(pageName);
  };

  const activeModalAction = (action, close) => {
    if (action === 'ListUser') {
      activePageHandler(action);
    }

    if (close) {
      setUserFormData({});
      setActiveModal('');
    } else {
      setActiveModal(action);
    }
  };

  const onInputChangeHandler = (e, fieldName, type) => {
    let fieldValue;

    if (type === 'select') {
      fieldValue = e;
    }

    if (type === 'textInput') {
      fieldValue = e.target.value;
    }

    const userFormDataSetter = { ...userFormData, [fieldName]: fieldValue };
    setUserFormData(userFormDataSetter);
  };

  const checkPhoneNumber = async () => {
    try {
      const formedData = {
        phoneNumber: `91${userFormData.phoneNumber}`,
      };
      const response = await api.account.commonPhoneNumberCheck(formedData);

      if (response.data && response.data.data === null) {
        console.log('Not Exists');
        return 'Not Exists';
      }
    } catch (err) {
      toast.error('Unexpected error :(');
    }
    return 1;
  };

  const saveUserFunc = async () => {
    if (!userFormData.userName || !userFormData.userRole || !userFormData.phoneNumber || !userFormData.password) {
      return toast.error('Please fill all the mandatory fields!');
    }

    if (!Number(userFormData.phoneNumber)) {
      return toast.error('Phone Number should only contain numbers!');
    }

    if (userFormData.phoneNumber.length !== 10) {
      return toast.error('Phone Number should be 10 digit!');
    }
    setPhoneNumberChecking(true);

    const checkResult = await checkPhoneNumber();
    setPhoneNumberChecking(false);

    if (checkResult !== 'Not Exists') {
      return toast.error('Phone number already exist!');
    }

    const formedData = {
      name: userFormData.userName,
      userType: userFormData.userRole.value,
      phoneNumber: `91${userFormData.phoneNumber}`,
      state: userFormData.state ? userFormData.state.value : '',
      city: userFormData.city ? userFormData.city.value : '',
      status: 'ACTIVE',
      password: userFormData.password,
      email: userFormData.emailId || '',
      address: userFormData.address || '',
    };

    console.log(formedData);
    saveUserAction(formedData);

    return 1;
  };

  useEffect(() => {
    fetchUserAction(userFormData);
  }, []);

  useEffect(() => {
    console.log(userDataState);
  }, [userDataState]);

  useEffect(() => {
    console.log(saveUserState);
    if (!saveUserState.error
      && !saveUserState.isSaving
      && saveUserState.userDetails
      && saveUserState.userDetails.message) {
      if (saveUserState.userDetails.message === 'user created successfully') {
        activeModalAction('UserSuccess');
      } else {
        toast.error('Unexpected Error:(');
      }
    }
  }, [saveUserState]);

  return (

    <div className="users">
      {
        activePage === 'ListUser'
        && (
          <Container>
            <Row>
              <Col md={12} className="smalldiv__listheader">
                <h4>
                  Users
                </h4>
                <Button
                  className="rounded btn-right"
                  color="success"
                  onClick={() => activePageHandler('AddUser')}
                >
                  Add User
                </Button>
              </Col>
            </Row>
            <Row style={{ placeContent: 'center' }}>
              {
                userDataState.isFetching
                  ? (
                    <div className="spinner-border" style={{ color: '#03a5a5' }}>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <ListUser
                      userDataState={userDataState}
                    />
                  )
              }
            </Row>
          </Container>
        )
      }
      {
        activePage === 'AddUser'
        && (
          <AddUser
            activePageHandler={activePageHandler}
            userFormData={userFormData}
            onInputChangeHandler={onInputChangeHandler}
            saveUser={saveUserFunc}
            saveUserState={saveUserState}
            stateOptions={stateOptions}
            cityOptions={cityOptions}
            userRoles={userRoles}
            phoneNumberChecking={phoneNumberChecking}
          />
        )
      }
      {
        activeModal === 'UserSuccess'
        && (
          <Modal
            modalStatus={activeModal === 'UserSuccess'}
            modalAction={() => activeModalAction('ListUser', 'close')}
            color="success"
            title="Well Done!"
            btn="Success"
            message={(
              <div>
                <div>User Added Successfully! <br /><br />
                  <span><b>{userFormData.userName}</b></span><br />
                </div>
                <Button
                  style={{ marginTop: '10px' }}
                  type="button btn-sm"
                  className="rounded"
                  color="success"
                  onClick={() => activeModalAction('NewUser', 'close')}
                >
                  New User
                </Button>
              </div>
            )}
          />
        )
      }
    </div>
  );
};

Users.propTypes = {
  saveUserAction: Proptypes.func.isRequired,
  fetchUserAction: Proptypes.func.isRequired,
  userDataState: Proptypes.arrayOf.isRequired,
  saveUserState: Proptypes.objectOf.isRequired,
};

const mapDispatchToProps = {
  saveUserAction: saveUser,
  fetchUserAction: fetchUser,
};

const mapStateToProps = (state) => ({
  saveUserState: state.saveUser,
  userDataState: state.userData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
