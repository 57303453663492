/* eslint-disable max-len */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import moment from 'moment';
import AddComment from '../../../../assets/addcomment_icons/message-text.png';

// eslint-disable-next-line react/prop-types
const BasicTable = ({
  // eslint-disable-next-line react/prop-types
  tableHeaderData = [], tableRowsData = [], viewDetails, readOnly,
}) => (
  <div>{console.log('tableRowsData', tableRowsData)}
    <Table responsive hover style={{ marginTop: '15px' }}>
      <thead>
        <tr>
          <th style={{ padding: '5px' }}>S.No</th>
          {tableHeaderData.map((item) => (
            <th key={item.id} style={{ padding: '5px' }}>{item.tableHeader}</th>
          ))}
          <th>{ }</th>
        </tr>
      </thead>
      <tbody>
        {tableRowsData.map((item, i) => (
          /* eslint no-underscore-dangle: 0 */
          // eslint-disable-next-line max-len
          <tr key={item._id} role="presentation" style={{ pointerEvents: readOnly ? 'none' : 'auto' }} onClick={() => viewDetails(item)}>
            <td>{i + 1}</td>
            {tableHeaderData.map((data) => (
              <td style={{ padding: '5px' }}>{data.type !== 'Id' ? `${data.title === 'adjustmentDate' ? moment(new Date(item[data.title])).format('DD MMM YYYY') : item[data.title]}` : `${item[data.title]}/${moment(new Date(item[data.dateKey])).format('DD MMM YYYY')}`}</td>
            ))}
            <td>
              <img
                src={AddComment}
                alt="AddComment"
                className="border border-1 bg-light"
                style={{ width: '20px', borderRadius: '50%', borderColor: '#03a5a5' }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

BasicTable.propTypes = {
  tableHeaderData: PropTypes.arrayOf.isRequired,
  tableRowsData: PropTypes.arrayOf.isRequired,
  viewDetails: PropTypes.func.isRequired,
};

export default BasicTable;
