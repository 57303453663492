import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';

const tableHeaderData = [
  { id: 10, title: 'S.No', key: 's.no' },
  { id: 1, title: 'Lens Type', key: 'LensType' },
  { id: 7, title: 'Lens Material', key: 'LensMaterial' },
  { id: 2, title: 'Index', key: 'Index' },
  { id: 3, title: 'Sph', key: 'Spherical' },
  { id: 4, title: 'Cyl', key: 'Cylindrical' },
  { id: 6, title: 'Axis', key: 'Axis' },
  { id: 5, title: 'Add', key: 'Add' },
  { id: 8, title: 'Price', key: 'CostPrice' },
  { id: 9, title: 'QTY', key: 'Quantity' },
];

const BasicTable = ({ tableRowsData = [] }) => {
  console.log('List Customer');
  console.log(tableRowsData);
  return (
    <div
      style={{
        height: '550px',
        overflowY: 'auto',
      }}
    >
      <Table
        hover
      >
        <thead>
          <tr>
            {tableHeaderData.map((item) => (
              <th key={item.id}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRowsData.map((item, index) => (
            /* eslint no-underscore-dangle: 0 */
            <tr key={item._id} role="presentation">
              <td>{index + 1}</td>
              {tableHeaderData.slice(1, tableHeaderData.length).map((field) => (
                <td>{item[field.key] || '-'}</td>
              ))}
              {/* <td>{item.LensType || '-'}</td>
              <td>{item.LensMaterial || '-'}</td>
              <td>{item.Index || '-'}</td>
              <td>{item.Spherical || '0'}</td>
              <td>{item.Cylindrical || '0'}</td>
              <td>{item.Axis || '0'}</td>
              <td>{item.Add || '0'}</td>
              <td>{item.CostPrice || '-'}</td>
              <td>{item.Quantity || '-'}</td> */}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

BasicTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
};

export { BasicTable, tableHeaderData };
