/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import moment from 'moment';
import DownloadImage from '../../../../shared/img/common/download.png';

const BasicTable = ({ tableHeaderData = [], tableRowsData = [], viewDetails }) => {
  console.log('Basic Table');
  return (
    <>
      <Table responsive hover style={{ marginTop: '15px' }}>
        <thead>
          <tr>
            {tableHeaderData.map((item) => (
              <th key={item.id}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRowsData.map((item) => (
            /* eslint no-underscore-dangle: 0 */
            <tr
              key={item._id}
              role="presentation"
              onClick={() => viewDetails(item)}
            >
              <td>
                {item.orderId}/{moment(item._created_at).format('DD MMM YYYY')}
              </td>
              <td>{item.sysCustomerOrderId}/{moment(item.sysCustomerOrderDate).format('DD MMM YYYY')}</td>
              <td>{item.supplier}</td>
              <td>{item.customerOrderId}/{moment(item.customerOrderDate).format('DD MMM YYYY')}</td>
              <td>{item.variants.length}</td>
              <td>
                {item.supplier && (
                <img
                  role="presentation"
                  onClick={(e) => {
									  e.stopPropagation();
									  window.open(`${item.invoiceUrl}`, '_blank');
                  }}
                  className="purchaseorder__download"
                  alt="Download"
                  src={DownloadImage}
                />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

BasicTable.propTypes = {
  tableHeaderData: PropTypes.arrayOf.isRequired,
  tableRowsData: PropTypes.arrayOf.isRequired,
  viewDetails: PropTypes.func.isRequired,
};

export default BasicTable;
