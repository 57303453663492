import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Proptypes from 'prop-types';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import Orders from '../PurchaseOrders';
import CustomerOrder from '../CustomerOrder';
import EOD from '../EOD';
import GoodsReceiptNote from '../GoodsReceiptNote';
import PendingReceipt from '../PendingReceipt';
import Lenses from '../Inventory';
import AdjustmentsInventory from '../Adjustments';
import Users from '../Users';
import Reasons from '../Reasons';
import Brands from '../Brands';
import Checkout from '../Checkout';
import Customer from '../Customer';
import Vendors from '../Vendors';
import Returns from '../Returns';
import Reports from '../Reports';
import InventoryTrack from '../InventoryTrack';
import OicReportRouter from '../OICReports/OICReportsRouter';

import {
  fetchCurrentUser,
} from '../../redux/actions/accountActions';
import { getToken, decodeJWTToken } from '../../utils/helpers';

const Masters = () => (
  <Switch>
    <Route path="/masters/users" component={Users} />
    <Route path="/masters/reasons" component={Reasons} />
    <Route path="/masters/brands" component={Brands} />
  </Switch>
);

const Purchases = ({ fetchCurrentUserState }) => (
  <Switch>
    {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
      || fetchCurrentUserState.userDetails.userType === 'Owner & Manager'
      || fetchCurrentUserState.userDetails.userType === 'Purchase Admin'
      || fetchCurrentUserState.userDetails.userType === 'GRN Admin'
    )
      && (
        <Route
          path="/purchase/orders"
          component={() => <Orders fetchCurrentUserState={fetchCurrentUserState} />}
        />
      )}

    {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
      || fetchCurrentUserState.userDetails.userType === 'Owner & Manager'
      || fetchCurrentUserState.userDetails.userType === 'GRN Admin'
    )
      && (
        <Route
          path="/purchase/grn"
          component={() => <GoodsReceiptNote fetchCurrentUserState={fetchCurrentUserState} />}
        />
      )}

    {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
      || fetchCurrentUserState.userDetails.userType === 'Owner & Manager'
      || fetchCurrentUserState.userDetails.userType === 'Purchase Admin'
    )
      && (
        <Route
          path="/purchase/pending"
          component={() => <PendingReceipt fetchCurrentUserState={fetchCurrentUserState} />}
        />
      )}

    {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
      || fetchCurrentUserState.userDetails.userType === 'Owner & Manager'
      || fetchCurrentUserState.userDetails.userType === 'Purchase Admin'
      || fetchCurrentUserState.userDetails.userType === 'GRN Admin'
    )
      && (
        <Route
          path="/purchase/return"
          component={() => <Returns fetchCurrentUserState={fetchCurrentUserState} />}
        />
      )}

    {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
      || fetchCurrentUserState.userDetails.userType === 'Owner & Manager'
    )
      && (
        <Route
          path="/purchase/vendor"
          component={() => <Vendors fetchCurrentUserState={fetchCurrentUserState} />}
        />
      )}
  </Switch>
);

const Inventory = ({ fetchCurrentUserState }) => (
  <Switch>
    {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
      || fetchCurrentUserState.userDetails.userType === 'Owner & Manager'
      || fetchCurrentUserState.userDetails.userType === 'Purchase Admin'
      || fetchCurrentUserState.userDetails.userType === 'GRN Admin'
      || fetchCurrentUserState.userDetails.userType === 'Production Admin'
    )
      && (
        <Route
          path="/inventory/lenses"
          component={() => <Lenses fetchCurrentUserState={fetchCurrentUserState} />}
        />
      )}
    {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
      || fetchCurrentUserState.userDetails.userType === 'Owner & Manager'
      || fetchCurrentUserState.userDetails.userType === 'Purchase Admin'
    )
      && (
        <Route
          path="/inventory/adjustments"
          component={() => <AdjustmentsInventory fetchCurrentUserState={fetchCurrentUserState} />}
        />
      )}

    {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
      || fetchCurrentUserState.userDetails.userType === 'Owner & Manager'
      || fetchCurrentUserState.userDetails.userType === 'Purchase Admin'
    )
      && (
        <Route
          path="/inventory/inventorytrack"
          component={() => <InventoryTrack fetchCurrentUserState={fetchCurrentUserState} />}
        />
      )}
  </Switch>
);

const Production = ({ fetchCurrentUserState }) => (
  <Switch>
    {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
      || fetchCurrentUserState.userDetails.userType === 'Owner & Manager'
      || fetchCurrentUserState.userDetails.userType === 'Purchase Admin'
      || fetchCurrentUserState.userDetails.userType === 'Production Admin'
    )
      && (
        <Route
          path="/production/orders"
          component={() => <CustomerOrder fetchCurrentUserState={fetchCurrentUserState} />}
        />
      )}

    {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
      || fetchCurrentUserState.userDetails.userType === 'Owner & Manager'
      || fetchCurrentUserState.userDetails.userType === 'Production Admin'
    )
      && (
        <Route
          path="/production/checkout"
          component={() => <Checkout fetchCurrentUserState={fetchCurrentUserState} />}
        />
      )}

    {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
      || fetchCurrentUserState.userDetails.userType === 'Owner & Manager'
    )
      && (
        <Route
          path="/production/customers"
          component={() => <Customer fetchCurrentUserState={fetchCurrentUserState} />}
        />
      )}
  </Switch>
);

const ReportsRoutes = ({ fetchCurrentUserState }) => (
  <Switch>
    {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
      || fetchCurrentUserState.userDetails.userType === 'Owner & Manager'
    )
      && (
        <>
          <Route
            path="/analysis/customerorder"
            component={() => <Reports fetchCurrentUserState={fetchCurrentUserState} />}
          />
          <Route
            path="/analysis/eod"
            component={() => <EOD fetchCurrentUserState={fetchCurrentUserState} />}
          />
        </>
      )}
  </Switch>
);

const WrappedRoutes = ({ fetchCurrentUserState }) => (
  <div>
    <Layout />
    <div className="container__wrap">
      {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
        || fetchCurrentUserState.userDetails.userType === 'Owner & Manager'
        || fetchCurrentUserState.userDetails.userType === 'Purchase Admin'
        || fetchCurrentUserState.userDetails.userType === 'GRN Admin'
      )
        && (
          <Route path="/purchase" component={() => <Purchases fetchCurrentUserState={fetchCurrentUserState} />} />
        )}

      {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
        || fetchCurrentUserState.userDetails.userType === 'Owner & Manager'
        || fetchCurrentUserState.userDetails.userType === 'Purchase Admin'
        || fetchCurrentUserState.userDetails.userType === 'Production Admin'
        || fetchCurrentUserState.userDetails.userType === 'GRN Admin'
      )
        && (
          <Route path="/inventory" component={() => <Inventory fetchCurrentUserState={fetchCurrentUserState} />} />
        )}

      {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
        || fetchCurrentUserState.userDetails.userType === 'Owner & Manager'
      )
        && (
          <Route path="/masters" component={() => <Masters />} />
        )}

      {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
        || fetchCurrentUserState.userDetails.userType === 'Owner & Manager'
        || fetchCurrentUserState.userDetails.userType === 'Purchase Admin'
        || fetchCurrentUserState.userDetails.userType === 'Production Admin'
      )
        && (
          <Route path="/production" component={() => <Production fetchCurrentUserState={fetchCurrentUserState} />} />
        )}

      <Route path="/analysis" component={() => <ReportsRoutes fetchCurrentUserState={fetchCurrentUserState} />} />
      <Route path="/reports" component={() => <OicReportRouter fetchCurrentUserState={fetchCurrentUserState} />} />
    </div>
  </div>
);

const Router = ({ fetchCurrentUserState, fetchCurrentUserAction }) => {
  const oicToken = localStorage.getItem('oicToken');
  const [isAuthenticated, setIsAuthentiated] = useState(false);

  useEffect(() => {
    if (window.location.pathname !== '/') {
      if (oicToken) {
        setIsAuthentiated(true);
        const headerTokens = getToken();
        if (headerTokens) {
          const user = decodeJWTToken(headerTokens['oic-authorization']);
          console.log(fetchCurrentUserState);

          if (
            !fetchCurrentUserState.error
            && fetchCurrentUserState.userDetails
            && Object.keys(fetchCurrentUserState.userDetails).length === 0
          ) {
            fetchCurrentUserAction(user);
          }
        }
      } else {
        toast.error('Authorizarion Needed!');
        setTimeout(() => {
          window.location = window.location.origin;
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    console.log(fetchCurrentUserState);
  }, [fetchCurrentUserState]);

  return (
    <MainWrapper>
      <main>
        <Switch>
          <Route exact path="/" component={LogIn} />
          <Route
            path="/"
            component={
              () => isAuthenticated
                && (
                  <WrappedRoutes
                    fetchCurrentUserState={fetchCurrentUserState}
                  />
                )
            }
          />
        </Switch>
      </main>
    </MainWrapper>
  );
};

Router.propTypes = {
  fetchCurrentUserState: Proptypes.objectOf.isRequired,
  fetchCurrentUserAction: Proptypes.func.isRequired,
};

WrappedRoutes.propTypes = {
  fetchCurrentUserState: Proptypes.objectOf.isRequired,
};

Inventory.propTypes = {
  fetchCurrentUserState: Proptypes.objectOf.isRequired,
};

Purchases.propTypes = {
  fetchCurrentUserState: Proptypes.objectOf.isRequired,
};

Production.propTypes = {
  fetchCurrentUserState: Proptypes.objectOf.isRequired,
};

ReportsRoutes.propTypes = {
  fetchCurrentUserState: Proptypes.objectOf.isRequired,
};

const mapStateToProps = (state) => ({
  fetchCurrentUserState: state.fetchCurrentUser,
});

const mapDispatchToProps = {
  fetchCurrentUserAction: fetchCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
