import { createAction } from 'redux-actions';
import api from '../../utils/apiClient';

export const saveGrnRequest = createAction('SAVE_GRN_REQUEST');
export const saveGrnSuccess = createAction('SAVE_GRN_SUCCESS');
export const saveGrnFailure = createAction('SAVE_GRN_FAILURE');
export const defaultGrn = createAction('DEFAULT_GRN');
export const addToGrn = createAction('ADD_TO_GRN');

export const saveNewPoGrnRequest = createAction('SAVE_NEW_PO_GRN_REQUEST');
export const saveNewPoGrnSuccess = createAction('SAVE_NEW_PO_GRN_SUCCESS');
export const saveNewPoGrnFailure = createAction('SAVE_NEW_PO_GRN_FAILURE');
export const defaultSaveNewPoGrn = createAction('DEFAULT_NEW_PO_GRN');

export const cancelPendingOrderRequest = createAction('CANCEL_PENDING_ORDER_REQUEST');
export const cancelPendingOrderSuccess = createAction('CANCEL_PENDING_ORDER_SUCCESS');
export const cancelPendingOrderFailure = createAction('CANCEL_PENDING_ORDER_FAILURE');
export const defaultCancelPendingOrder = createAction('DEFAULT_CANCEL_PENDING_ORDER');

export const fetchGrnRequest = createAction('FETCH_GRN_REQUEST');
export const fetchGrnSuccess = createAction('FETCH_GRN_SUCCESS');
export const fetchGrnFailure = createAction('FETCH_GRN_FAILURE');

export const fetchPendingGrnRequest = createAction('FETCH_PENDING_GRN_REQUEST');
export const fetchPendingGrnSuccess = createAction('FETCH_PENDING_GRN_SUCCESS');
export const fetchPendingGrnFailure = createAction('FETCH_PENDING_GRN_FAILURE');
export const updateToPendingGrn = createAction('UPDATE_TO_PENDING_GRN');
export const deletePendingGrn = createAction('DELETE_PENDING_GRN');

export const updateGrnRequest = createAction('UPDATE_GRN_REQUEST');
export const updateGrnSuccess = createAction('UPDATE_GRN_SUCCESS');
export const updateGrnFailure = createAction('UPDATE_GRN_FAILURE');
export const defaultUpdateGrn = createAction('DEFAULT_UPDATE_GRN');
export const updateToGrn = createAction('UPDATE_TO_GRN');

export const saveGrn = (data) => async (dispatch) => {
  try {
    dispatch(saveGrnRequest());
    const response = await api.grn.post(data);
    dispatch(saveGrnSuccess(response.data));
    if (response.data.data) {
      dispatch(addToGrn(response.data.data));
    }
    dispatch(defaultGrn());
  } catch (e) {
    dispatch(saveGrnFailure(e));
  }
};

export const saveNewPoGrn = (data) => async (dispatch) => {
  try {
    dispatch(saveNewPoGrnRequest());
    const response = await api.grn.newPoGrn(data);
    console.log(response);
    dispatch(saveNewPoGrnSuccess(response.data));
    if (response.data.data) {
      if (response.data.data.status === 'Pending') {
        dispatch(updateToPendingGrn(response.data.data));
      } else {
        dispatch(deletePendingGrn(response.data.data));
      }
    }
    dispatch(defaultSaveNewPoGrn());
  } catch (e) {
    dispatch(saveNewPoGrnFailure(e));
  }
};

export const fetchGrn = (data) => async (dispatch) => {
  try {
    console.log(data);
    const params = {
      limit: data.limit,
      skip: data.skip,
    };
    dispatch(fetchGrnRequest());
    const response = await api.grn.get(params);
    dispatch(fetchGrnSuccess(response.data.data));
  } catch (e) {
    dispatch(fetchGrnFailure(e));
  }
};

export const fetchPendingGrn = (data) => async (dispatch) => {
  try {
    console.log(data);
    const params = {
      limit: data.limit,
      skip: data.skip,
      filter: {
        status: 'Pending',
      },
    };
    dispatch(fetchPendingGrnRequest());
    const response = await api.grn.get(params);
    dispatch(fetchPendingGrnSuccess(response.data.data));
  } catch (e) {
    dispatch(fetchPendingGrnFailure(e));
  }
};

export const updateGrn = (data) => async (dispatch) => {
  try {
    dispatch(updateGrnRequest());
    const response = await api.grn.update(data);
    dispatch(updateGrnSuccess(response.data));
    if (response.data.data) {
      dispatch(updateToGrn(response.data.data));
    }
    dispatch(defaultUpdateGrn());
  } catch (e) {
    dispatch(updateGrnFailure(e));
  }
};

export const cancelPendingOrder = (data) => async (dispatch) => {
  try {
    console.log('cancelorders');
    dispatch(cancelPendingOrderRequest());
    const response = await api.grn.cancelPendingOrder(data);
    console.log('response is coming');
    console.log(response);
    dispatch(cancelPendingOrderSuccess(response.data));
    if (response.data.data) {
      dispatch(defaultCancelPendingOrder(response.data.data));
    }
  } catch (e) {
    dispatch(cancelPendingOrderFailure(e));
  }
};
