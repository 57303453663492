import axios, { defaultParams } from './axios';

export default async (url, body) => {
  try {
    return await axios.post(url, body, { ...defaultParams() });
  } catch (e) {
    console.log(e);
    throw e;
  }
};
