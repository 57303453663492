/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { utils, write } from 'xlsx';
import PropTypes from 'prop-types';
import {
  Table,
  Row,
  Col,
  Button,
  Container,
} from 'reactstrap';
import moment from 'moment';
// import { tableHeaderData } from 'ReturnReturnDetails';

const tableHeader = [
  { id: 1, title: 'S.No', dataIndex: '' },
  { id: 2, title: 'Lens Type', dataIndex: 'LensType' },
  { id: 3, title: 'Lens Material', dataIndex: 'LensMaterial' },
  { id: 4, title: 'Sph', dataIndex: 'Spherical' },
  { id: 5, title: 'Cyl', dataIndex: 'Cylindrical' },
  { id: 6, title: 'Axis', dataIndex: 'Axis' },
  { id: 7, title: 'Add', dataIndex: 'Add' },
  { id: 8, title: 'Qty', dataIndex: 'Quantity' },
  { id: 9, title: 'Reason', dataIndex: 'Reason' },

];

class Workbook {
  constructor() {
    if (!(this instanceof Workbook)) { return new Workbook(); }

    this.SheetNames = [];

    this.Sheets = {};
  }
}
function s2ab(s) {
  const buf = new ArrayBuffer(s.length);

  const view = new Uint8Array(buf);

  // eslint-disable-next-line no-plusplus, no-bitwise
  for (let i = 0; i !== s.length; i++) { view[i] = s.charCodeAt(i) & 0xFF; }

  return buf;
}
const download = (url, name) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.click();

  window.URL.revokeObjectURL(url);
};

const setexportCSV = (data) => {
  // eslint-disable-next-line no-debugger
  debugger;
  const filterData = [];
  data.forEach((element, i) => {
    const filterField = {};
    tableHeader.forEach((header) => {
      // eslint-disable-next-line eqeqeq
      filterField[header.title] = header.title == 'S.No' ? i + 1 : element[header.dataIndex];
    });
    filterData[i] = filterField;
  });
  console.log('filterData :', filterData, data.tableData);
  const wb = new Workbook();
  const ws = utils.json_to_sheet(filterData);

  wb.SheetNames.push('');
  wb.Sheets[''] = ws;

  const wbout = write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
  const url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }));

  download(url, 'Export.csv');
};

const ReturnDetails = ({

  tableData,
  activePageHandler,
  changeStatus,
}) => {
  console.log('Prem', tableData);
  console.log('Abu', tableData.inventories);
  tableData.inventories.sort((oldd, newd) => {
    const Axis = oldd.Axis - newd.Axis;
    const Add = oldd.Add - newd.Add;
    const Cylindrical = oldd.Cylindrical - newd.Cylindrical;
    const Spherical = oldd.Spherical - newd.Spherical;
    const Quantity = oldd.Quantity - newd.Quantity;
    return Spherical || Cylindrical || Axis || Add || Quantity;
  });

  return (
    <Container>
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => activePageHandler('ListReturn')}
      >
        &lt;
      </Button>
      <Col md={25}>
        <h4 className="page-title">RETURN DETAILS</h4>
      </Col>
      <Row className="smalldiv__cardsContainer">
        <Col md={2} className="smalldiv__samerow">
          <span>RETURN ID/DATE</span>
          <p style={{ fontWeight: 600 }}>{tableData.returnId}/{moment(tableData._created_at).format('DD MMM YYYY')}</p>
        </Col>
        <Col md={2} className="smalldiv__samerow">
          <span>VENDOR NAME</span>
          <p style={{ fontWeight: 600 }}>{tableData.supplier}</p>
        </Col>
        <Col md={2} className="smalldiv__samerow">
          <span>VENDOR INVOICE ID/DATE</span>
          <p style={{ fontWeight: 600 }}>{tableData.vendorInvoiceId}/  {moment(tableData.vendorInvoiceDate).format('DD MMM YYYY')}</p>
        </Col>
        <Col md={2} className="smalldiv__samerow">
          <span>STATUS</span><br />
          {/* <p style={{ fontWeight: 600 }}>{tableData.status}</p> */}
          <label
            role="presentation"
            onClick={() => changeStatus(tableData)}
            className={
                  `smalldiv__statushighlight
                    ${tableData.status === 'Pending'
                  && ' smalldiv__orangestatus'}
                    ${tableData.status === 'Credit Note'
                  && ' smalldiv__greenstatus'}
                    ${tableData.status === 'Replaced'
                  && ' smalldiv__bluestatus'}
                    ${tableData.status === 'Shipped'
                  && ' smalldiv__bluishgreenstatus'}`
                }
            htmlFor="status"
          >
            {tableData.status || '-'}
          </label>
        </Col>
      </Row>
      <Button
        className="rounded btn-right"
        color="success"
        onClick={() => setexportCSV(tableData.inventories)}
      >
        Export To CSV
      </Button>
      <Col md={25}>
        <h4><b>Material Summary</b></h4>
      </Col>
      <Table responsive hover>
        <thead>
          <tr>
            {tableHeader.map((item) => (
              <th style={{ fontWeight: 'bold' }} key={item.id}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.inventories.map((item, index) => (
            <tr key={item._id}>
              <td>{index + 1 }</td>
              <td>{item.LensType || '-'}</td>
              <td>{item.LensMaterial || '-'}</td>
              <td>{item.Spherical || '-'}</td>
              <td>{item.Cylindrical || '-'}</td>
              <td>{item.Axis || '-'}</td>
              <td>{item.Add || '-'}</td>
              <td>{item.Quantity || '-'}</td>
              <td>{item.Reason || '-'}</td>
            </tr>
          ))}

        </tbody>
      </Table>
    </Container>

  );
};

export default ReturnDetails;

ReturnDetails.propTypes = {
  tableData: PropTypes.arrayOf.isRequired,
  activePageHandler: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
};
