import { handleActions } from 'redux-actions';
import {
  fetchAdjustmentsRequest,
  fetchAdjustmentsSuccess,
  fetchAdjustmentsFailure,
  saveAdjustmentsRequest,
  saveAdjustmentsSuccess,
  saveAdjustmentsFailure,
  defaultAdjustment,
  addToAdjustments,
} from '../actions/purchaseAdjustmentsActions';

const defaultState = {
  isSaving: false,
  error: null,
  adjustmentsDetails: {},
};

const defaultStateFetch = {
  isFetching: false,
  error: null,
  adjustmentsDetails: [],
  limit: 500,
  skip: 0,
};

/* eslint-disable import/prefer-default-export */

const adjustmentSaveReducer = handleActions(
  {
    [saveAdjustmentsRequest](state) {
      console.log(' saveAdjustmentRequest ', state);
      return {
        ...state,
        isSaving: true,
      };
    },
    [saveAdjustmentsSuccess](state, { payload }) {
      console.log('saveAdjustmentSuccess ', state);
      return {
        ...state,
        adjustmentsDetails: payload,
        isSaving: false,
      };
    },
    [defaultAdjustment](state) {
      return {
        ...state,
        adjustmentsDetails: {},
      };
    },
    [saveAdjustmentsFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isSaving: false,
      };
    },
  },
  defaultState,
);

const adjustmentFetchReducer = handleActions(
  {
    [fetchAdjustmentsRequest](state) {
      console.log('fetchSRequest', state);
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchAdjustmentsSuccess](state, { payload }) {
      console.log('fetchSuccess', state);
      return {
        ...state,
        adjustmentsDetails: payload,
        isFetching: false,
        limit: state.limit,
        skip: state.skip,
        error: null,
      };
    },
    [fetchAdjustmentsFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    },
    [addToAdjustments](state, { payload }) {
      console.log([payload, ...state.adjustmentsDetails]);
      return {
        ...state,
        adjustmentsDetails: [payload, ...state.adjustmentsDetails],
      };
    },
  },
  defaultStateFetch,
);

export { adjustmentSaveReducer, adjustmentFetchReducer };
