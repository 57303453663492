import { get } from './base/index';

export default {
  get: (data = {}) => {
    const params = { ...data };
    return get(
      `${process.env.REACT_APP_API_URL}v2/inventory/client/inventory/inventorytrack`,
      { params },
    );
  },
  getDetails: (data = {}) => {
    const params = { ...data };
    return get(
      `${process.env.REACT_APP_API_URL}v2/inventory/client/inventory/inventorytrackdetails`,
      { params },
    );
  },

};
