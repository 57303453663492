/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React from 'react';
import Proptypes from 'prop-types';
import moment from 'moment';
import {
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';
import MaterialSummaryTable from '../../Tables/BasicTables/components/MaterialSummary';
import CustomerOrderSummary from '../../Tables/BasicTables/components/CustomerOrderSummary';

const MaterialSummary = ({
  activePageHandler,
  consolidatedOrderData,
  activePage,
  consolidatedDataHandler,
  activeModalAction,
  selectedPurchaseList,
  prescriptionFilters,
  viewDetailsMode,
  summary,
  saveNewPoCustomerOrderState,
  saveNewPurchase,
}) => {
  /* eslint no-underscore-dangle: 0 */
  console.log(consolidatedOrderData);
  return (
    <Container className="materialsummary">
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => {
          if (viewDetailsMode) {
            activePageHandler('ListOrder');
          } else {
            activePageHandler('AddOrder');
          }
        }}
      >
        {' < '}
      </Button>
      <Row className="smalldiv__cardsContainer">
        <Col md={2} className="smalldiv__samerow">
          <span>CUSTOMER ORDER ID/DATE</span>
          <p style={{ fontWeight: 600 }}>
            {consolidatedOrderData.orderId}
            /
            {moment(consolidatedOrderData._created_at).format('DD MMM YYYY')}
          </p>
        </Col>
        <Col md={2} className="smalldiv__samerow">
          <span>CUSTOMER NAME</span>
          <p style={{ fontWeight: 600 }}>{consolidatedOrderData.customerName}</p>
        </Col>
        <Col md={2} className="smalldiv__samerow">
          <span>REF. ORDER ID/DATE</span>
          <p style={{ fontWeight: 600 }}>
            {consolidatedOrderData.customerOrderId}
            /
            {moment(consolidatedOrderData.customerOrderDate).format('DD MMM YYYY')}
          </p>
        </Col>
      </Row>
      {
        activePage === 'GroupSummary'
        && (
          <div>
            <Row>
              <Col md={12}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                >
                  <h4 className="page-subhead">
                    Group Summary
                  </h4>
                </div>
              </Col>
            </Row>
            <CustomerOrderSummary
              summary={summary}
            />
          </div>
        )
      }
      <Row>
        <Col md={12}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          >
            <h4 className="page-subhead">
              {
                activePage === 'MaterialSummary'
                && 'Material Summary'
              }
              {
                activePage === 'ViewPurchase'
                && 'Purchase List'
              }
            </h4>
            {
              activePage === 'ViewPurchase'
              && (
                <Button
                  type="button"
                  className="rounded btn-sm"
                  color="success"
                  outline
                  onClick={() => activeModalAction('SelectPrescription')}
                >
                  Filter
                </Button>
              )
            }

          </div>
          {
            activePage === 'ViewPurchase'
            && (
              <Row style={{ marginBottom: '15px' }}>
                <Col md={1} style={{ display: 'flex', gap: '5px' }}>
                  <span>Selected: </span>
                  <p style={{ fontWeight: 600, marginTop: '0px' }}>{selectedPurchaseList}</p>
                </Col>
                {(prescriptionFilters.startSph && prescriptionFilters.endSph)
                  && (
                    <Col md={2} className="smalldiv__samerow">
                      <span>SPH: </span>
                      <p style={{ fontWeight: 600 }}>
                        {prescriptionFilters.startSph.value} to {prescriptionFilters.endSph.value}
                      </p>
                    </Col>
                  )}
                {(prescriptionFilters.startCyl && prescriptionFilters.endCyl)
                  && (
                    <Col md={2} className="smalldiv__samerow">
                      <span>CYL: </span>
                      <p style={{ fontWeight: 600 }}>
                        {prescriptionFilters.startCyl.value} to {prescriptionFilters.endCyl.value}
                      </p>
                    </Col>
                  )}
                {(prescriptionFilters.startAdd && prescriptionFilters.endAdd)
                  && (
                    <Col md={2} className="smalldiv__samerow">
                      <span>ADD: </span>
                      <p style={{ fontWeight: 600 }}>
                        {prescriptionFilters.startAdd.value} to {prescriptionFilters.endAdd.value}
                      </p>
                    </Col>
                  )}
              </Row>
            )
          }

          {
            activePage !== 'GroupSummary'
            && (
              <MaterialSummaryTable
                activePage={activePage}
                tableRowsData={consolidatedOrderData.inventories}
                consolidatedDataHandler={consolidatedDataHandler}
                selectedPurchaseList={selectedPurchaseList}
                viewDetailsMode={viewDetailsMode}
              />
            )
          }

          {
							activePage === 'GroupSummary'
							&& (
								<>
  <Button
    style={{ marginTop: '15px' }}
    type="button"
    className="rounded Rectangle-868 Rectangle-cancel btn-cancelOrder "
    onClick={() => activeModalAction('AddCancel')}
  >
    Cancel Order
  </Button>
  <Button
    style={{ marginTop: '15px' }}
    type="button"
    className="rounded Rectangle-719 Rectangle-vs"
    color="success"
    onClick={() => activePageHandler('MaterialSummary')}
  >
    View Material Summary
  </Button>
								</>
							)
						}
          {activePage === 'MaterialSummary'
            && (
              <Button
                style={{ marginTop: '15px' }}
                type="button"
                className="rounded"
                color="success"
                onClick={() => activePageHandler('ViewPurchase')}
              >
                View Purchase List
              </Button>
            )}
          {activePage === 'ViewPurchase'
            && (
              <Button
                style={{ marginTop: '15px' }}
                type="button"
                className="rounded"
                color="success"
                disabled={saveNewPoCustomerOrderState.isSaving}
                onClick={() => saveNewPurchase()}
              >
                Raise Purchase Order
              </Button>
            )}
        </Col>
      </Row>
    </Container>
  );
};

MaterialSummary.propTypes = {
  activePageHandler: Proptypes.func.isRequired,
  consolidatedOrderData: Proptypes.objectOf.isRequired,
  activePage: Proptypes.string.isRequired,
  consolidatedDataHandler: Proptypes.func.isRequired,
  activeModalAction: Proptypes.func.isRequired,
  selectedPurchaseList: Proptypes.number.isRequired,
  prescriptionFilters: Proptypes.objectOf.isRequired,
  viewDetailsMode: Proptypes.bool.isRequired,
  summary: Proptypes.objectOf.isRequired,
  saveNewPoCustomerOrderState: Proptypes.objectOf.isRequired,
  saveNewPurchase: Proptypes.func.isRequired,
};

export default MaterialSummary;
