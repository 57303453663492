import React from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import BasicTable from '../../Tables/BasicTables/components/ListVendor';

const ListVendor = ({
  vendorDataState,
}) => {
  console.log(vendorDataState);
  return (
    <Container>
      <BasicTable
        tableRowsData={vendorDataState.vendorDetails}
      />
    </Container>
  );
};

ListVendor.propTypes = {
  vendorDataState: PropTypes.arrayOf.isRequired,
};

export default ListVendor;
