import React, { useState } from 'react';
import {
  Row,
  Col,
  Container,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/named
import { BasicTable } from '../../Tables/BasicTables/components/ListLens';
import Filters from './ListFilters';

const ListLens = ({
  inventoryData,
  activePageHandler,
  onSearchHandler,
  searchTerm,
  filterOptions,
  handlerFilters,
  fetchCurrentUserState,
  activeModalAction,
  setexportCSV,
}) => {
  const [advancedFilter, setAdvancedFilter] = useState(false);

  const toggleFilter = () => {
    setAdvancedFilter(!advancedFilter);
  };

  return (
    <Container>
      <Row>
        <Col md={6}>
          <Filters
            onSearchHandler={onSearchHandler}
            searchTerm={searchTerm}
            toggleFilter={toggleFilter}
            searchOnly={1}
          />
        </Col>
        <Col md={6}>
          {(fetchCurrentUserState.userDetails.userType === 'Warehouse Manager'
            || fetchCurrentUserState.userDetails.userType === 'Owner & Manager')
            && (
              <div style={{ display: 'flex', float: 'right' }}>
                <Button
                  className="rounded btn-right"
                  color="success"
                  onClick={() => activeModalAction('ImportExcel')}
                >
                  Import From CSV
                </Button>
                <Button
                  className="rounded btn-right"
                  color="success"
                  onClick={() => setexportCSV(inventoryData)}
                >
                  Export To CSV
                </Button>
                <Button
                  className="rounded btn-right"
                  color="success"
                  onClick={() => activePageHandler('AddLens')}
                >
                  Add Lens
                </Button>
              </div>
            )}
        </Col>
      </Row>
      {
        advancedFilter
        && (
          <Row style={{ textAlign: '-webkit-center' }}>
            <Col md={12}>
              <Filters
                handlerFilters={handlerFilters}
                filterOptions={filterOptions}
                onSearchHandler={onSearchHandler}
                searchTerm={searchTerm}
                searchOnly={0}
              />
            </Col>
          </Row>
        )
      }

      {
        inventoryData.isFetching
          ? (
            <div style={{ textAlign: 'center' }}>
              <div className="spinner-border" style={{ color: '#03a5a5' }}>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <BasicTable
              tableRowsData={inventoryData.inventoryDetails}
            />
          )
      }
      {console.log('inventoryData', inventoryData)}
    </Container>
  );
};

ListLens.propTypes = {
  inventoryData: PropTypes.arrayOf.isRequired,
  activePageHandler: PropTypes.func.isRequired,
  onSearchHandler: PropTypes.func.isRequired,
  searchTerm: PropTypes.func.isRequired,
  filterOptions: PropTypes.arrayOf.isRequired,
  handlerFilters: PropTypes.func.isRequired,
  fetchCurrentUserState: PropTypes.objectOf.isRequired,
  activeModalAction: PropTypes.func.isRequired,
  setexportCSV: PropTypes.func.isRequired,
};

export default ListLens;
