const condition = (conditionCheck, end, i) => {
  console.log(conditionCheck, end, i);
  if (conditionCheck === '<') {
    return i <= end;
  }

  if (conditionCheck === '>') {
    return i >= end;
  }
  return 0;
};

const createVariants = (props) => {
  console.log(props);
  const startSpherical = props.startSph ? props.startSph.value : '';
  const startCylindrical = props.startCyl ? props.startCyl.value : '';
  const startAxis = props.startAxis ? props.startAxis.value : '';
  const startAdd = props.startAdd ? props.startAdd.value : '';
  const endAdd = props.endAdd ? props.endAdd.value : '';
  const endSpherical = props.endSph ? props.endSph.value : '';
  const endCylindrical = props.endCyl ? props.endCyl.value : '';
  const increments = {
    sphericalIncrement: props.incSph ? props.incSph.value : '',
    cylindricalIncrement: props.incCyl ? props.incCyl.value : '',
    addIncrement: props.incAdd ? props.incAdd.value : '',
  };
  const prescriptionStore = [];
  const conditions = {
    SphericalCondition: '<',
    CylindricalCondition: '<',
    AddCondition: '<',
  };

  if (startSpherical !== '' || endSpherical !== '') {
    if (startSpherical === '') {
      return 'Choose start spherical';
    }

    if (endSpherical === '') {
      return 'Choose end spherical';
    }

    if (startSpherical > endSpherical) {
      conditions.SphericalCondition = '>';
      increments.sphericalIncrement = -increments.sphericalIncrement;
      // return 'Start spherical should be less than end spherical';
    }

    if (increments.sphericalIncrement === '') {
      return 'Choose spherical increment';
    }
    const totalSpherical = startSpherical + endSpherical;
    if (totalSpherical % increments.sphericalIncrement !== 0) {
      return 'Choose valid spherical values';
    }
  }
  if (startAdd !== '' || endAdd !== '') {
    if (startAdd === '') {
      return 'Choose start add';
    }

    if (endAdd === '') {
      return 'Choose end add';
    }

    if (startAdd > endAdd) {
      conditions.AddCondition = '>';
      increments.addIncrement = -increments.addIncrement;
    }

    if (increments.addIncrement === '') {
      return 'Choose add increment';
    }

    const totalAdd = startAdd + endAdd;
    if (totalAdd % increments.addIncrement !== 0) {
      return 'Choose valid add values';
    }
  }

  if (startCylindrical !== '' || endCylindrical !== '') {
    if (startCylindrical === '') {
      return 'Choose start cylindrical';
    }

    if (endCylindrical === '') {
      return 'Choose end cylindrical';
    }

    if (startCylindrical > endCylindrical) {
      conditions.CylindricalCondition = '>';
      increments.cylindricalIncrement = -increments.cylindricalIncrement;
    }

    if (increments.cylindricalIncrement === '') {
      return 'Choose cylindrical increment';
    }

    const totalCylindrical = startCylindrical + endCylindrical;
    if (totalCylindrical % increments.cylindricalIncrement !== 0) {
      return 'Choose valid cylindrical values';
    }
  }

  let differenceOrder = [];

  if (startSpherical !== '') {
    let sphericalDifference = 0;
    for (
      let i = startSpherical;
      condition(conditions.SphericalCondition, endSpherical, i);
      i += increments.sphericalIncrement
    ) {
      sphericalDifference += 1;
    }

    differenceOrder.push({
      difference: sphericalDifference,
      start: startSpherical,
      end: endSpherical,
      increment: increments.sphericalIncrement,
      name: 'Spherical',
    });
  }

  console.log(differenceOrder);

  if (startCylindrical !== '') {
    let cylindricalDifference = 0;
    for (
      let i = startCylindrical;
      condition(conditions.CylindricalCondition, endCylindrical, i);
      i += increments.cylindricalIncrement
    ) {
      cylindricalDifference += 1;
    }

    differenceOrder.push({
      difference: cylindricalDifference,
      start: startCylindrical,
      end: endCylindrical,
      increment: increments.cylindricalIncrement,
      name: 'Cylindrical',
    });
  }

  console.log(differenceOrder);

  if (startAdd !== '') {
    let addDifference = 0;
    for (
      let i = startAdd;
      condition(conditions.AddCondition, endAdd, i);
      i += increments.addIncrement
    ) {
      addDifference += 1;
    }

    differenceOrder.push({
      difference: addDifference,
      start: startAdd,
      end: endAdd,
      increment: increments.addIncrement,
      name: 'Add',
    });
  }

  differenceOrder = differenceOrder.sort(
    (a, b) => parseFloat(b.difference) - parseFloat(a.difference),
  );

  console.log(differenceOrder);
  if (differenceOrder.length === 1) {
    for (
      let i = differenceOrder[0].start;
      condition(conditions[`${differenceOrder[0].name}Condition`], differenceOrder[0].end, i);
      i += differenceOrder[0].increment
    ) {
      const objHold = {
        Spherical: '',
        Cylindrical: '',
        Add: '',
        Axis: startAxis,
        Quantity: '',
      };

      objHold.ItemCode = '';

      if (differenceOrder[0].name === 'Spherical') {
        objHold.ItemCode += `${objHold[differenceOrder[0].name]} `;
      } else {
        objHold.ItemCode += '0 ';
      }

      if (differenceOrder[0].name === 'Cylindrical') {
        objHold.ItemCode += `${objHold[differenceOrder[0].name]} `;
      } else {
        objHold.ItemCode += '0 ';
      }

      if (differenceOrder[0].name === 'Add') {
        objHold.ItemCode += `${objHold[differenceOrder[0].name]} `;
      } else {
        objHold.ItemCode += '0 ';
      }

      objHold.ItemCode += startAxis || '0';

      objHold.Quantity = '';

      prescriptionStore.push(objHold);
    }
  }

  if (differenceOrder.length === 3) {
    for (
      let i = differenceOrder[0].start;
      condition(conditions[`${differenceOrder[0].name}Condition`], differenceOrder[0].end, i);
      i += differenceOrder[0].increment
    ) {
      for (
        let j = differenceOrder[1].start;
        condition(conditions[`${differenceOrder[1].name}Condition`], differenceOrder[1].end, j);
        j += differenceOrder[1].increment
      ) {
        for (
          let k = differenceOrder[2].start;
          condition(conditions[`${differenceOrder[2].name}Condition`], differenceOrder[2].end, k);
          k += differenceOrder[2].increment
        ) {
          const objHold = {
            Spherical: '',
            Cylindrical: '',
            Add: '',
            Axis: startAxis,
            Quantity: '',
          };
          objHold[differenceOrder[0].name] = i;
          objHold[differenceOrder[1].name] = j;
          objHold[differenceOrder[2].name] = k;
          objHold.Quantity = '';

          objHold.ItemCode = '';
          if (differenceOrder[0].name === 'Spherical') {
            objHold.ItemCode += `${objHold[differenceOrder[0].name]} `;
          } else if (differenceOrder[1].name === 'Spherical') {
            objHold.ItemCode += `${objHold[differenceOrder[1].name]} `;
          } else if (differenceOrder[2].name === 'Spherical') {
            objHold.ItemCode += `${objHold[differenceOrder[2].name]} `;
          } else {
            objHold.ItemCode += '0 ';
          }

          if (differenceOrder[0].name === 'Cylindrical') {
            objHold.ItemCode += `${objHold[differenceOrder[0].name]} `;
          } else if (differenceOrder[1].name === 'Cylindrical') {
            objHold.ItemCode += `${objHold[differenceOrder[1].name]} `;
          } else if (differenceOrder[2].name === 'Cylindrical') {
            objHold.ItemCode += `${objHold[differenceOrder[2].name]} `;
          } else {
            objHold.ItemCode += '0 ';
          }

          if (differenceOrder[0].name === 'Add') {
            objHold.ItemCode += `${objHold[differenceOrder[0].name]} `;
          } else if (differenceOrder[1].name === 'Add') {
            objHold.ItemCode += `${objHold[differenceOrder[1].name]} `;
          } else if (differenceOrder[2].name === 'Add') {
            objHold.ItemCode += `${objHold[differenceOrder[2].name]} `;
          } else {
            objHold.ItemCode += '0 ';
          }

          objHold.ItemCode += startAxis || '0';

          prescriptionStore.push(objHold);
        }
      }
    }
  }

  if (differenceOrder.length === 2) {
    for (
      let i = differenceOrder[0].start;
      condition(conditions[`${differenceOrder[0].name}Condition`], differenceOrder[0].end, i);
      i += differenceOrder[0].increment
    ) {
      for (
        let j = differenceOrder[1].start;
        condition(conditions[`${differenceOrder[1].name}Condition`], differenceOrder[1].end, j);
        j += differenceOrder[1].increment
      ) {
        const objHold = {
          Spherical: '',
          Cylindrical: '',
          Add: '',
          Axis: startAxis,
          Quantity: '',
        };
        objHold[differenceOrder[0].name] = i;
        objHold[differenceOrder[1].name] = j;

        objHold.ItemCode = '';
        if (differenceOrder[0].name === 'Spherical') {
          objHold.ItemCode += `${objHold[differenceOrder[0].name]} `;
        } else if (differenceOrder[1].name === 'Spherical') {
          objHold.ItemCode += `${objHold[differenceOrder[1].name]} `;
        } else {
          objHold.ItemCode += '0 ';
        }

        if (differenceOrder[0].name === 'Cylindrical') {
          objHold.ItemCode += `${objHold[differenceOrder[0].name]} `;
        } else if (differenceOrder[1].name === 'Cylindrical') {
          objHold.ItemCode += `${objHold[differenceOrder[1].name]} `;
        } else {
          objHold.ItemCode += '0 ';
        }

        if (differenceOrder[0].name === 'Add') {
          objHold.ItemCode += `${objHold[differenceOrder[0].name]} `;
        } else if (differenceOrder[1].name === 'Add') {
          objHold.ItemCode += `${objHold[differenceOrder[1].name]} `;
        } else {
          objHold.ItemCode += '0 ';
        }

        objHold.ItemCode += startAxis || '0';

        objHold.Quantity = '';

        prescriptionStore.push(objHold);
      }
    }
  }

  return { Prescriptions: prescriptionStore, showPrescriptions: true };
};

export default createVariants;
