import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import moment from 'moment';

const tableHeaderData = [
  { id: 1, title: 'Gatepass ID/Date' },
  { id: 2, title: 'Customer Order ID/Date' },
  { id: 3, title: 'Customer Name' },
  { id: 4, title: 'Reference Order ID/Date' },
];

const BasicTable = ({
  tableRowsData,
  viewDetails,
}) => {
  console.log('List Checkout Table');
  return (
    <Table responsive hover>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRowsData.map((item) => (
          /* eslint no-underscore-dangle: 0 */
          <tr key={item._id} role="presentation" onClick={() => viewDetails(item)}>
            <td>{item.checkoutId}/{moment(item._created_at).format('DD MMM YYYY')}</td>
            <td>{item.orderId}/{moment(item.sysCustomerOrderDate).format('DD MMM YYYY')}</td>
            <td>{item.customerName || '-'}</td>
            <td>{item.customerOrderId}/{moment(item.customerOrderDate).format('DD MMM YYYY')}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

BasicTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
  viewDetails: PropTypes.func.isRequired,
};

export default BasicTable;
