import React from 'react';
import Proptypes from 'prop-types';
import {
  Container,
  Col,
  Row,
  Button,
} from 'reactstrap';
import Select from 'react-select';

const AddCustomer = ({
  onInputChangeHandler,
  activePageHandler,
  vendorFormData,
  saveVendor,
  saveVendorState,
  stateOptions,
  cityOptions,
  brandOptions,
}) => {
  console.log('Add Cutomer');
  return (
    <Container>
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => activePageHandler('ListVendor')}
      >
        {' < '}
      </Button>
      <Row>
        <Col md={12}>
          <h4 className="page-title">New Vendor</h4>
        </Col>
      </Row>
      <form onSubmit={(e) => e.preventDefault()} className="form">
        <Row>
          <Col md={3} className="form-group">
            <label htmlFor="buisnessName">BUSINESS NAME *</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'buisnessName', 'textInput')}
              name="buisnessName"
              component="input"
              value={vendorFormData.buisnessName || ''}
              type="text"
              placeholder="type here..."
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="contactPerson">CONTACT PERSON</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'contactPerson', 'textInput')}
              name="contactPerson"
              component="input"
              value={vendorFormData.contactPerson || ''}
              type="text"
              placeholder="type here..."
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="phoneNumber">PHONE NUMBER *</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'phoneNumber', 'textInput')}
              name="phoneNumber"
              component="input"
              value={vendorFormData.phoneNumber || ''}
              type="number"
              placeholder="type here..."
            />
          </Col>
        </Row>
        <Row>
          <Col md={3} className="form-group">
            <label htmlFor="state">STATE</label>
            <Select
              onChange={(e) => onInputChangeHandler(e, 'state', 'select')}
              name="state"
              value={vendorFormData.state || ''}
              id="state"
              options={stateOptions}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="city">CITY</label>
            <Select
              onChange={(e) => onInputChangeHandler(e, 'city', 'select')}
              name="city"
              value={vendorFormData.city || ''}
              id="city"
              options={cityOptions[vendorFormData.state && vendorFormData.state.name]}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="address">ADDRESS</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'address', 'textInput')}
              name="address"
              component="input"
              value={vendorFormData.address || ''}
              type="text"
              placeholder="type here..."
            />
          </Col>
        </Row>
        <Row>
          <Col md={3} className="form-group">
            <label htmlFor="emailId">EMAIL ADDRESS</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'emailId', 'textInput')}
              name="emailId"
              component="input"
              value={vendorFormData.emailId || ''}
              type="text"
              placeholder="type here..."
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="gstIn">GST IN</label>
            <input
              onChange={(e) => onInputChangeHandler(e, 'gstIn', 'textInput')}
              name="gstIn"
              component="input"
              value={vendorFormData.gstIn || ''}
              type="text"
              placeholder="type here..."
            />
          </Col>
          <Col md={3} className="form-group">
            <label htmlFor="brand">BRAND *</label>
            <Select
              onChange={(e) => onInputChangeHandler(e, 'brand', 'select')}
              name="brand"
              value={vendorFormData.brand || ''}
              id="brand"
              isMulti
              options={brandOptions}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
        </Row>
      </form>
      <Button
        style={{ marginTop: '10px' }}
        className="rounded"
        disabled={saveVendorState.isSaving}
        onClick={() => saveVendor()}
        color="success"
        size="sm"
      >
        Submit
      </Button>
    </Container>
  );
};

AddCustomer.propTypes = {
  onInputChangeHandler: Proptypes.func.isRequired,
  vendorFormData: Proptypes.objectOf.isRequired,
  activePageHandler: Proptypes.func.isRequired,
  saveVendor: Proptypes.func.isRequired,
  saveVendorState: Proptypes.objectOf.isRequired,
  stateOptions: Proptypes.arrayOf.isRequired,
  cityOptions: Proptypes.arrayOf.isRequired,
  brandOptions: Proptypes.arrayOf.isRequired,
};

export default AddCustomer;
