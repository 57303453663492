import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';

const tableHeaderData = [
  { id: 1, title: 'S.No', type: ['all'] },
  { id: 2, title: 'Lens Type', type: ['all'] },
  { id: 3, title: 'Lens Material', type: ['all'] },
  { id: 4, title: 'Required Variants', type: ['all', 'viewMode'] },
  { id: 5, title: 'Required Quantity', type: ['all', 'viewMode'] },
  { id: 6, title: 'Taken Variants', type: ['all'] },
  { id: 7, title: 'Taken Quantity', type: ['all'] },
];

const BasicTable = ({
  summary = [],
  viewDetailsMode,
}) => {
  console.log(1);

  return (
    <div style={{
      height: '35vh',
      overflowY: 'auto',
    }}
    >
      <Table hover>
        <thead>
          <tr>
            {tableHeaderData.map((item) => (
              viewDetailsMode ? (
                !item.type.includes('viewMode') && (
                  <th key={item.id}>{item.title}</th>
                )
              ) : (
                item.type.includes('all') && (
                  <th key={item.id}>{item.title}</th>
                )
              )
            ))}
          </tr>
        </thead>
        <tbody>
          {summary.inventories.map((item, index) => (
            <>
              <tr key={item.id} role="presentation">
                <td>{index + 1}</td>
                <td>{item.LensType || '-'}</td>
                <td>{item.LensMaterial || '-'}</td>
                {!viewDetailsMode && (
                  <>
                    <td>{item.requiredVariants}</td>
                    <td>{item.requiredQuantity}</td>
                  </>
                )}
                <td>{item.totalCheckoutVariants}</td>
                <td>{item.totalCheckoutQuantity}</td>
              </tr>

              {((summary.inventories.length - 1) === index)
                && (
                  <tr key={item.id} role="presentation">
                    <td />
                    <td />
                    <td><b>Grand Total:</b></td>
                    {!viewDetailsMode && (
                      <>
                        <td><b>{summary.totalRequiredVariants}</b></td>
                        <td><b>{summary.totalRequiredQuantity}</b></td>
                      </>
                    )}
                    <td><b>{summary.totalCheckoutVariants}</b></td>
                    <td><b>{summary.totalCheckoutQuantity}</b></td>
                  </tr>
                )}
            </>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

BasicTable.propTypes = {
  summary: PropTypes.arrayOf.isRequired,
  viewDetailsMode: PropTypes.bool.isRequired,
};

export default BasicTable;
