import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import moment from 'moment';

const tableHeaderData = [
  { id: 1, title: 'GRN ID/Date' },
  { id: 2, title: 'Customer Order ID/Date' },
  { id: 3, title: 'Customer Name' },
  { id: 7, title: 'Reference Order ID/Date' },
  { id: 4, title: 'Purchase Order ID/Date' },
  { id: 5, title: 'Vendor Name' },
  { id: 6, title: 'Vendor Invoice ID/Date' },
];

const BasicTable = ({ tableRowsData = [], viewDetails }) => {
  console.log(tableRowsData);
  return (
    <Table responsive hover>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRowsData.map((item) => (
          /* eslint no-underscore-dangle: 0 */
          <tr
            key={item._id}
            role="presentation"
            onClick={() => {
              viewDetails(item);
            }}
            title={item.cancelReason}
            style={{
              backgroundColor: (item.status === 'cancelled' ? '#fff2f2' : ''),
              borderLeft: (item.status === 'cancelled' ? 'solid red' : ''),
            }}
          >
            <td>{item.grId}/{moment(item._created_at).format('DD MMM YYYY')}</td>
            <td>{item.sysCustomerOrderId}/{moment(item.sysCustomerOrderDate).format('DD MMM YYYY')}</td>
            <td>{item.customerName}</td>
            <td>{item.customerOrderId}/{moment(item.customerOrderDate).format('DD MMM YYYY')}</td>
            <td>{item.poId}/{moment(item.poDate).format('DD MMM YYYY')}</td>
            <td>{item.supplier}</td>
            <td>{item.vendorInvoiceId}/{moment(item.vendorInvoiceDate).format('DD MMM YYYY')}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

BasicTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
  viewDetails: PropTypes.func.isRequired,
};

export default BasicTable;
