import { createAction } from 'redux-actions';
import api from '../../utils/apiClient';

export const saveInventoryRequest = createAction('SAVE_INVENTORY_REQUEST');
export const saveInventorySuccess = createAction('SAVE_INVENTORY_SUCCESS');
export const saveInventoryFailure = createAction('SAVE_INVENTORY_FAILURE');
export const defaultInventory = createAction('DEFAULT_INVENTORY');
export const updateFilterInventory = createAction('UPDATE_FILTER_INVENTORY');

export const fetchInventoryRequest = createAction('FETCH_INVENTORY_REQUEST');
export const fetchInventorySuccess = createAction('FETCH_INVENTORY_SUCCESS');
export const fetchInventoryFailure = createAction('FETCH_INVENTORY_FAILURE');
export const addToInventory = createAction('ADD_TO_INVENTORY');

export const saveInventory = (data) => async (dispatch) => {
  try {
    dispatch(saveInventoryRequest());
    const response = await api.inventory.post(data);
    console.log(response);
    dispatch(saveInventorySuccess(response.data));
    if (response.data.myInventoryInsert) {
      dispatch(addToInventory(response.data.myInventoryInsert));
    }
    dispatch(defaultInventory());
  } catch (e) {
    dispatch(saveInventoryFailure(e));
  }
};

export const fetchInventory = (data) => async (dispatch) => {
  try {
    console.log(data);
    const params = {
      limit: data.limit || 500,
      q: data.q,
      storeId: '',
      offset: data.offset || 0,
      filter: data.filter,
      sort: { _created_at: -1 },
      ignoreCategory: JSON.stringify(['SUNGLASSES', 'EYEGLASSES', 'CONTACTLENS']),
    };

    if (!data.offset) {
      dispatch(fetchInventoryRequest());
    }

    const response = await api.inventory.get(params);
    console.log(response);

    const form = {
      inventoryDetails: response.data.data,
      merge: false,
    };

    if (data.offset) {
      form.merge = true;
    }

    dispatch(fetchInventorySuccess(form));
  } catch (e) {
    console.log(e);
    dispatch(fetchInventoryFailure(e));
  }
};
