import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Row,
  Col,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import lensPower from '../../utils/commonFunctions/lensPower';
import AddPendingReceipt from './components/AddPendingReceipt';
import ListPendingReceipt from './components/ListPendingReceipt';
import { fetchVendor } from '../../redux/actions/vendorActions';
import { fetchPendingGrn, saveNewPoGrn, cancelPendingOrder } from '../../redux/actions/grnActions';
import Modal from '../../shared/components/modal/Modal';
import FullVariants from './components/FullVariants';

const PendingReceipt = ({
  fetchVendorsAction,
  vendor,
  fetchPendingGrnAction,
  grnPendingData,
  saveNewPoGrnAction,
  saveNewPoGrnState,
  saveCancelState,
  cancelPendingOrderAction,
}) => {
  const [vendorOptions, setVendorOptions] = useState([]);
  const [activePage, setActivePage] = useState('ListPendingReceipt');
  const [currentViewDetailsData, setCurrentViewDetailsData] = useState({});
  const [viewDetailsMode, setViewDetailsMode] = useState(false);
  const [totalVariantQuantity, setTotalVariantQuantity] = useState(0);
  const [totalVariant, setTotalVariant] = useState(0);
  const [activeModal, setActiveModal] = useState('');
  const [lensPowers, setLensPowers] = useState({});
  const [prescriptionFilters, setPrescriptionFilters] = useState({});
  const [groupIndex, setGroupIndex] = useState(0);
  const [cancelFormData, setCancelFormData] = useState({});
  const history = useHistory();

  useEffect(() => {
    const lensPowerHolder = { ...lensPowers, ...lensPower() };
    setLensPowers(lensPowerHolder);

    fetchVendorsAction(vendor);

    fetchPendingGrnAction(grnPendingData);
  }, []);

  const activePageHandler = (pageName) => {
    if (pageName === 'ListPendingReceipt') {
      setCurrentViewDetailsData({});
      setViewDetailsMode(false);
    }
    setActivePage(pageName);
  };

  const activeModalAction = (action, close) => {
    if (action === 'ListPendingReceipt') {
      activePageHandler('ListPendingReceipt');
    }

    if (action === 'BacktoPurchaseOrder') {
      history.push({
        pathname: '/purchase/pending',
      });
    }

    if (close) {
      setActiveModal('');
    } else {
      setActiveModal(action);
    }
  };

  useEffect(() => {
    console.log(saveNewPoGrnState);
    if (!saveNewPoGrnState.error
      && !saveNewPoGrnState.isSaving
      && saveNewPoGrnState.purchaseOrderDetails
      && Object.keys(saveNewPoGrnState.purchaseOrderDetails).length > 0) {
      activeModalAction('PoSuccess', '');
    }

    if (saveNewPoGrnState.error) {
      toast.error('Failed to save!');
    }
  }, [saveNewPoGrnState]);

  useEffect(() => {
    console.log(vendor);
    if (!vendor.error && vendor.vendorDetails) {
      const vendorsList = [];
      let tempVendor;
      const vendorLocal = _.cloneDeep(vendor.vendorDetails);

      vendorLocal.map((vendorInfo) => {
        tempVendor = vendorInfo;
        const optionBrands = [];

        if (vendorInfo.brand && vendorInfo.brand.length > 0) {
          vendorInfo.brand.map((brandObj) => {
            optionBrands.push({
              label: brandObj,
              value: brandObj,
            });
            return 1;
          });

          tempVendor.brand = optionBrands;
        }

        console.log(tempVendor);
        vendorsList.push({
          label: tempVendor.buisnessName,
          value: tempVendor,
        });
        return 1;
      });

      setVendorOptions(vendorsList);
    }

    if (vendor.error) {
      console.log('Unable to get vendors!');
    }
  }, [vendor]);

  const viewDetails = (data) => {
    const dataLocal = _.cloneDeep(data);

    const freshVariants = [];

    dataLocal.variants.map((variant) => {
      console.log(variant.Status);
      if (variant.Status !== 'Processed') {
        let exists = false;
        freshVariants.map((freshVariant, index) => {
          if ((freshVariant.LensType === variant.LensType) && (freshVariant.LensMaterial === variant.LensMaterial)) {
            exists = true;
            freshVariants[index].variants.push(variant);
          }
          return 1;
        });

        if (!exists) {
          freshVariants.push({
            LensType: variant.LensType,
            LensMaterial: variant.LensMaterial,
            Brand: variant.Brand || '',
            Model: variant.Model || '',
            variants: [variant],
          });
        }
        exists = false;
      }
      return 1;
    });

    delete dataLocal.variants;

    dataLocal.groups = freshVariants;
    console.log(dataLocal);

    setCurrentViewDetailsData(dataLocal);
    setViewDetailsMode(true);
    activePageHandler('AddPendingReceipt');
  };

  const onInputChangeHandler = (e, fieldName, type) => {
    let fieldValue;

    if (type === 'select') {
      fieldValue = e;
    }

    if (type === 'textInput') {
      fieldValue = e.target.value;
    }

    const currentViewDetailsSetter = { ...currentViewDetailsData, [fieldName]: fieldValue };
    setCurrentViewDetailsData(currentViewDetailsSetter);
    const cancelFormDataSetter = { ...cancelFormData, [fieldName]: fieldValue };
    setCancelFormData(cancelFormDataSetter);
  };

  const updateVariants = (e, index) => {
    if (viewDetailsMode) {
      const currentViewDetailsDataLocal = _.cloneDeep(currentViewDetailsData);

      if (index === 'All') {
        currentViewDetailsDataLocal.groups[groupIndex].variants.map((variant, index1) => {
          if (!variant.omitted && variant.Status !== 'Processed') {
            currentViewDetailsDataLocal.groups[groupIndex].variants[index1].checked = e.target.checked;
          }
          return 1;
        });
      } else {
        currentViewDetailsDataLocal.groups[groupIndex].variants[index].checked = e.target.checked;
      }
      setCurrentViewDetailsData(currentViewDetailsDataLocal);
    }
  };

  useEffect(() => {
    console.log(currentViewDetailsData);
  }, [currentViewDetailsData]);

  const generatePurchaseOrder = () => {
    const currentViewDetailsDataLocal = _.cloneDeep(currentViewDetailsData);
    console.log(currentViewDetailsDataLocal);

    if (!currentViewDetailsDataLocal.vendorDetails
      || !currentViewDetailsDataLocal.vendorDetails.label) {
      toast.error('Please fill all the mandatory fields!');
      return 1;
    }
    /* eslint no-underscore-dangle: 0 */

    const selectedVaraints = [];
    let totalVariantQuantityLocal = 0;
    let totalVariantLocal = 0;

    currentViewDetailsDataLocal.groups.map((group) => {
      group.variants.map((variant) => {
        if (variant.checked) {
          const temp = variant;
          temp.Spherical = variant.Spherical;
          temp.Cylindrical = variant.Cylindrical;
          temp.Add = variant.Add;
          temp.Axis = variant.Axis;
          temp.ItemCode = variant.ItemCode;
          temp._id = variant._id;
          totalVariantQuantityLocal += variant.Quantity - variant.ReceivedQuantity;
          totalVariantLocal += 1;
          temp.Quantity = variant.Quantity - variant.ReceivedQuantity;
          selectedVaraints.push(temp);
        }
        return 1;
      });
      return 1;
    });

    if (totalVariantLocal === 0) {
      toast.error('Atleaset select one variant!');
      return 1;
    }

    setTotalVariantQuantity(totalVariantQuantityLocal);
    setTotalVariant(totalVariantLocal);

    const formedBody = {};

    formedBody.supplier = currentViewDetailsDataLocal.vendorDetails.label;
    formedBody.supplierId = currentViewDetailsDataLocal.vendorDetails.value._id;
    formedBody.customerOrderDate = currentViewDetailsDataLocal.customerOrderDate;
    formedBody.customerOrderId = currentViewDetailsDataLocal.customerOrderId;
    formedBody.sysCustomerOrderDate = currentViewDetailsDataLocal.sysCustomerOrderDate;
    formedBody.sysCustomerOrderId = currentViewDetailsDataLocal.sysCustomerOrderId;
    formedBody.customerName = currentViewDetailsDataLocal.customerName;
    formedBody.variants = selectedVaraints;
    formedBody.grId = currentViewDetailsData.grId;

    saveNewPoGrnAction(formedBody);
    return 1;
  };

  const prescriptionFiltersHandler = (value, name) => {
    const prescriptionFiltersLocal = { ...prescriptionFilters, [name]: value };

    setPrescriptionFilters(prescriptionFiltersLocal);
  };

  const resetPrescriptionFilter = () => {
    setPrescriptionFilters({});
    const currentViewDetailsDataLocal = _.cloneDeep(currentViewDetailsData);

    currentViewDetailsDataLocal.groups[groupIndex].variants.map((variant, index) => {
      currentViewDetailsDataLocal.groups[groupIndex].variants[index].omitted = false;
      return 1;
    });

    setCurrentViewDetailsData(currentViewDetailsDataLocal);
  };

  const viewSpecificDetails = (index) => {
    resetPrescriptionFilter();
    setGroupIndex(index);
    activePageHandler('FullVariants');
  };

  const filterPendingReceipt = (finalFilter) => {
    console.log(finalFilter);
    const currentViewDetailsDataLocal = _.cloneDeep(currentViewDetailsData);

    currentViewDetailsDataLocal.groups[groupIndex].variants.map((variant, index) => {
      if ((finalFilter.startSph !== undefined ? (Number(variant.Spherical) >= finalFilter.startSph) : true)
        && (finalFilter.endSph !== undefined ? (Number(variant.Spherical) <= finalFilter.endSph) : true)
        && (finalFilter.startCyl !== undefined ? (Number(variant.Cylindrical) >= finalFilter.startCyl) : true)
        && (finalFilter.endCyl !== undefined ? (Number(variant.Cylindrical) <= finalFilter.endCyl) : true)
        && (finalFilter.startAdd !== undefined ? (Number(variant.Add) >= finalFilter.startAdd) : true)
        && (finalFilter.endAdd !== undefined ? (Number(variant.Add) <= finalFilter.endAdd) : true)
      ) {
        currentViewDetailsDataLocal.groups[groupIndex].variants[index].omitted = false;
      } else {
        currentViewDetailsDataLocal.groups[groupIndex].variants[index].omitted = true;
      }
      return 1;
    });

    console.log(currentViewDetailsDataLocal);

    setCurrentViewDetailsData(currentViewDetailsDataLocal);
  };

  const applyPrescriptionFilter = () => {
    const prescriptionFiltersLocal = { ...prescriptionFilters };

    const keys = Object.keys(prescriptionFiltersLocal);

    const finalFilter = {};

    keys.map((key) => {
      finalFilter[key] = prescriptionFiltersLocal[key].value;
      return 1;
    });

    console.log(finalFilter);
    filterPendingReceipt(finalFilter);
  };
  const saveCancelFunc = () => {
    const cancelDatas = { ...currentViewDetailsData, ...cancelFormData };
    setCancelFormData({});
    if (currentViewDetailsData.status === 'Processed'
        && currentViewDetailsData.status === 'Active'
        && currentViewDetailsData.status === 'Shipped') {
      toast.error('Order can not be cancelled!');
      return 1;
    }
    cancelPendingOrderAction(cancelDatas);
    return 1;
  };
  useEffect(() => {
    console.log(saveCancelState);
    if (!saveCancelState.error
      && !saveCancelState.isDeleting
      && saveCancelState.grnDetails
      && saveCancelState.grnDetails.message) {
      if (saveCancelState.grnDetails.message === 'success') {
        setActiveModal('CancelSuccess');
      } else {
        toast.error('Unexpected Error:(');
      }
    }

    if (saveCancelState.error) {
      toast.error('Unexpected Error:(');
    }
  }, [saveCancelState]);

  return (
    <div className="grn">
      {
        activeModal === 'PoSuccess'
        && (
          <Modal
            modalStatus={activeModal === 'PoSuccess'}
            modalAction={() => activeModalAction('ListPendingReceipt', 'close')}
            color="success"
            title="Well Done!"
            btn="Success"
            message={(
              <div>Purchase Order Generated Successfully! <br /><br />
                <span>Total Variants: <b>{totalVariant}</b></span><br />
                <span>Total Quantity: <b>{totalVariantQuantity}</b></span>
              </div>
            )}
          />
        )
      }
      {
        activePage === 'ListPendingReceipt'
        && (
          <ListPendingReceipt
            grnRowsData={grnPendingData}
            viewDetails={viewDetails}
          />
        )
      }
      {
        activePage === 'AddPendingReceipt'
        && (
          <AddPendingReceipt
            groupIndex={groupIndex}
            viewSpecificDetails={viewSpecificDetails}
            saveNewPoGrnState={saveNewPoGrnState}
            onInputChangeHandler={onInputChangeHandler}
            activePageHandler={activePageHandler}
            activeModalAction={activeModalAction}
            vendorOptions={vendorOptions}
            currentViewDetailsData={currentViewDetailsData}
            viewDetailsMode={viewDetailsMode}
            generatePurchaseOrder={generatePurchaseOrder}
          />
        )
      }

      {
        activePage === 'FullVariants'
        && (
          <FullVariants
            updateVariants={updateVariants}
            activeModalAction={activeModalAction}
            groupIndex={groupIndex}
            currentViewDetailsData={currentViewDetailsData}
            activePageHandler={activePageHandler}
          />
        )
      }

      {
        activeModal === 'SelectPrescription'
        && (
          <Modal
            modalStatus={activeModal === 'SelectPrescription'}
            header
            title="Filter"
            color="success"
            modalAction={() => activeModalAction('', 'close')}
            message={(
              <div>
                <form onSubmit={(e) => e.preventDefault()} className="form">
                  <Row style={{ alignItems: 'center' }}>
                    <Col md={2} className="form-group">
                      <></>
                      <label htmlFor="sph">SPH</label>
                    </Col>
                    <Col md={4} className="form-group">
                      <label htmlFor="startSph">START</label>
                      <Select
                        onChange={(e) => prescriptionFiltersHandler(e, 'startSph')}
                        name="startSph"
                        value={prescriptionFilters.startSph || ''}
                        id="startSph"
                        options={lensPowers.spherical}
                        clearable={false}
                        className="react-select"
                        placeholder=""
                        classNamePrefix="react-select"
                      />
                    </Col>
                    <Col md={4} className="form-group">
                      <label htmlFor="endSph">END</label>
                      <Select
                        onChange={(e) => prescriptionFiltersHandler(e, 'endSph')}
                        name="endSph"
                        value={prescriptionFilters.endSph || ''}
                        id="endSph"
                        options={lensPowers.spherical}
                        clearable={false}
                        className="react-select"
                        placeholder=""
                        classNamePrefix="react-select"
                      />
                    </Col>
                  </Row>
                  <Row style={{ alignItems: 'center' }}>
                    <Col md={2} className="form-group">
                      <></>
                      <label htmlFor="cyl">CYL</label>
                    </Col>
                    <Col md={4} className="form-group">
                      <label htmlFor="startCyl">START</label>
                      <Select
                        onChange={(e) => prescriptionFiltersHandler(e, 'startCyl')}
                        name="startCyl"
                        value={prescriptionFilters.startCyl || ''}
                        id="startCyl"
                        options={lensPowers.cylindrical}
                        clearable={false}
                        className="react-select"
                        placeholder=""
                        classNamePrefix="react-select"
                      />
                    </Col>
                    <Col md={4} className="form-group">
                      <label htmlFor="endCyl">END</label>
                      <Select
                        onChange={(e) => prescriptionFiltersHandler(e, 'endCyl')}
                        name="endCyl"
                        value={prescriptionFilters.endCyl || ''}
                        id="endCyl"
                        options={lensPowers.cylindrical}
                        clearable={false}
                        className="react-select"
                        placeholder=""
                        classNamePrefix="react-select"
                      />
                    </Col>
                  </Row>
                  <Row style={{ alignItems: 'center' }}>
                    <Col md={2} className="form-group">
                      <></>
                      <label htmlFor="add">ADD</label>
                    </Col>
                    <Col md={4} className="form-group">
                      <label htmlFor="startAdd">START</label>
                      <Select
                        onChange={(e) => prescriptionFiltersHandler(e, 'startAdd')}
                        name="startAdd"
                        value={prescriptionFilters.startAdd || ''}
                        id="startAdd"
                        options={lensPowers.add}
                        clearable={false}
                        className="react-select"
                        placeholder=""
                        classNamePrefix="react-select"
                      />
                    </Col>
                    <Col md={4} className="form-group">
                      <label htmlFor="endAdd">END</label>
                      <Select
                        onChange={(e) => prescriptionFiltersHandler(e, 'endAdd')}
                        name="endAdd"
                        value={prescriptionFilters.endAdd || ''}
                        id="endAdd"
                        options={lensPowers.add}
                        clearable={false}
                        className="react-select"
                        placeholder=""
                        classNamePrefix="react-select"
                      />
                    </Col>
                  </Row>
                </form>
                <Row style={{ marginTop: '10px' }}>
                  <Col md={3}>
                    <Button
                      outline
                      type="button"
                      className="rounded btn-sm"
                      color="success"
                      onClick={() => resetPrescriptionFilter()}
                    >
                      Reset
                    </Button>
                  </Col>
                  <Col md={3}>
                    <Button
                      type="button"
                      className="rounded btn-sm"
                      color="success"
                      onClick={() => applyPrescriptionFilter()}
                    >
                      Apply
                    </Button>
                  </Col>
                </Row>

              </div>
            )}
          />
        )
      }
      {
        activeModal === 'AddCancel'
         && (
         <Modal
           color="success"
           modalStatus={activeModal === 'AddCancel'}
           header
           title="Cancel Order"
           modalAction={() => activeModalAction('BacktoPurchaseOrder', 'close')}
           message={(
             <>
               <form onSubmit={(e) => e.preventDefault()} className="form">
                 <Row style={{ alignItems: 'center' }}>
                   <Col md={6} className="form-group">
                     <label htmlFor="sph" className="reason-for-cancelation">Reason for Cancellation</label>
                   </Col>
                 </Row>
                 <Row>
                   <Col md={6} className="form-group">
                     <div>
                       <input
                         style={{
                           width: '390px',
                           height: '119px',
                           margin: '6px 73.9px 21px 0',
                           padding: '18px 28px 16px 22px',
                           border: 'solid 1px #d8d8d8',
                           backgroundcolor: '#fff',
                         }}
                         onChange={(e) => onInputChangeHandler(e, 'cancelReason', 'textInput')}
                         name="cancelReason"
                         component="input"
                         value={cancelFormData.cancelReason}
                         type="text"
                       />
                     </div>
                   </Col>
                 </Row>
               </form>
               <Button
                 style={{ marginTop: '15px', alignItems: 'center' }}
                 type="button"
                 className="rounded btn-sm"
                 color="success"
                 disabled={saveCancelState.isDeleting}
                 onClick={() => saveCancelFunc(currentViewDetailsData)}
               >
                 Confirm
               </Button>
             </>
           )}
         />
         )
      }
    </div>
  );
};

PendingReceipt.propTypes = {
  fetchVendorsAction: PropTypes.func.isRequired,
  vendor: PropTypes.arrayOf.isRequired,
  fetchPendingGrnAction: PropTypes.func.isRequired,
  grnPendingData: PropTypes.arrayOf.isRequired,
  saveNewPoGrnAction: PropTypes.func.isRequired,
  saveNewPoGrnState: PropTypes.objectOf.isRequired,
  saveCancelState: PropTypes.arrayOf.isRequired,
  cancelPendingOrderAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  fetchVendorsAction: fetchVendor,
  fetchPendingGrnAction: fetchPendingGrn,
  saveNewPoGrnAction: saveNewPoGrn,
  cancelPendingOrderAction: cancelPendingOrder,
};

const mapStateToProps = (state) => ({
  vendor: state.vendorData,
  grnPendingData: state.grnPendingData,
  saveNewPoGrnState: state.saveNewPoGrn,
  saveCancelState: state.cancelPendingOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingReceipt);
