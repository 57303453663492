import { get, post } from './base/index';

export default {
  post: (data = {}) => {
    const body = { ...data };
    // console.log('test');
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/wcustomerorder/customerorder`, body);
    // return post('http://localhost/api/v2/inventory/client/wcustomerorder/customerorder', body);
  },
  get: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}v2/inventory/client/wcustomerorder/customerorder`, { params });
  },
  checkAvailability: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/wcustomerorder/checkavailability`, body);
  },
  newPoCustomerOrder: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/wcustomerorder/newpocustomerorder`, body);
  },
  cancelCustomerOrder: (data = {}) => {
    const body = { ...data };
    // return post('http://localhost/api/v2/inventory/client/wcustomerorder/cancelcustomerorder', body);
    return post(`${process.env.REACT_APP_API_URL}v2/inventory/client/wcustomerorder/cancelcustomerorder`, body);
  },
};
