/* eslint-disable import/no-duplicates */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { utils, write } from 'xlsx';
import { connect, useDispatch } from 'react-redux';
import {
  Button,
} from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import _ from 'lodash';
import csvjson from 'csvjson';
import S3 from 'react-aws-s3';
import ReactFileReader from 'react-file-reader';
import lensPower from '../../utils/commonFunctions/lensPower';
import AddLens from './components/AddLens';
import ListLens from './components/ListLens';
import FullVariants from './components/FullVariants';
import { fetchMaster } from '../../redux/actions/masterActions';
import createVariants from '../../utils/commonFunctions/createVariants';
import Modal from '../../shared/components/modal/Modal';
// import fileDownload from 'js-file-download';
import { tableHeaderData } from '../Tables/BasicTables/components/ListLens';

import { saveInventory, fetchInventory, updateFilterInventory } from '../../redux/actions/inventoryActions';

const config = {
  bucketName: process.env.REACT_APP_AWS_BUCKET,
  dirName: 'lofty',
  region: 'ap-south-1',
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};
function Workbook() {
  if (!(this instanceof Workbook)) { return new Workbook(); }

  this.SheetNames = [];

  this.Sheets = {};
}
function s2ab(s) {
  const buf = new ArrayBuffer(s.length);

  const view = new Uint8Array(buf);

  // eslint-disable-next-line no-plusplus, no-bitwise
  for (let i = 0; i !== s.length; i++) { view[i] = s.charCodeAt(i) & 0xFF; }

  return buf;
}
const download = (url, name) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.click();

  window.URL.revokeObjectURL(url);
};

const ReactS3Client = new S3(config);

const Inventory = ({
  fetchMastersAction,
  master,
  saveInventoryAction,
  inventorySave,
  fetchInventoryAction,
  inventoryData,
  fetchCurrentUserState,
}) => {
  const [inventoryForm, setInventoryForm] = useState({
    startSph: {
      label: 0,
      value: 0,
    },
    endSph: {
      label: 0,
      value: 0,
    },
    startCyl: {
      label: 0,
      value: 0,
    },
    endCyl: {
      label: 0,
      value: 0,
    },
    startAdd: {
      label: 0,
      value: 0,
    },
    endAdd: {
      label: 0,
      value: 0,
    },
    startAxis: {
      label: 0,
      value: 0,
    },
    lensCoating: {
      label: 'UNCOAT',
      value: 'UNCOAT',
    },
    index: {
      label: '1.56',
      value: '1.56',
    },
    lensColor: {
      value: 'CLEAR',
      label: 'CLEAR',
    },
  });
  const [activePage, setActivePage] = useState('ListLens');
  const [lensPowers, setLensPowers] = useState({});
  const [metaV1Options, setMetaV1Options] = useState({});
  const [createdVariants, setCreatedVariants] = useState([]);
  const [activeModal, setActiveModal] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [totals, setTotals] = useState({});
  const [comments, setComments] = useState('');
  const [isImporting, setIsImporting] = useState(false);
  const [filterOptions, setFilterOptions] = useState([
    {
      label: 'Lens Type',
      value: 'LensType',
      options: [],
    },
    {
      label: 'Lens Color',
      value: 'LensColor',
      options: [],
    },
    {
      label: 'Index',
      value: 'Index',
      options: [],
    },
    {
      label: 'Lens Material',
      value: 'LensMaterial',
      options: [],
    },
    {
      label: 'Lens Coating',
      value: 'LensCoating',
      options: [],
    },
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    const lensPowerHolder = { ...lensPowers, ...lensPower() };
    setLensPowers(lensPowerHolder);

    if (
      !master.error
      && master.masterDetails
      && Object.keys(master.masterDetails).length === 0
    ) {
      fetchMastersAction();
    }

    console.log(inventoryData);
    if (!inventoryData.inventoryDetails || Object.keys(inventoryData.inventoryDetails).length === 0) {
      const inventoryDataLocal = inventoryData;
      inventoryDataLocal.offset = 0;
      fetchInventoryAction(inventoryDataLocal);
    }
  }, []);

  useEffect(() => {
    console.log(inventoryData);
  }, [inventoryData]);

  useEffect(() => {
    if (!master.error && master.masterDetails && Object.keys(master.masterDetails).length > 0) {
      const lensTypeList = [];
      const lensColorList = [];
      const lensCoatingList = [];
      const lensMaterialList = [];
      const eyeList = [];
      const indexList = [];
      const hsnCodeList = [{
        label: '90014090',
        value: '90014090',
      }];

      const filterOptionsLocal = _.cloneDeep(filterOptions);

      master.masterDetails.LLensType.map((obj) => {
        lensTypeList.push({
          label: obj,
          value: obj,
        });
        return 1;
      });

      master.masterDetails.LensColor.map((obj) => {
        lensColorList.push({
          label: obj,
          value: obj,
        });
        return 1;
      });

      master.masterDetails.LensCoating.map((obj) => {
        lensCoatingList.push({
          label: obj,
          value: obj,
        });
        return 1;
      });

      master.masterDetails.LLensMaterial.map((obj) => {
        lensMaterialList.push({
          label: obj,
          value: obj,
        });
        return 1;
      });

      master.masterDetails.Eye.map((obj) => {
        eyeList.push({
          label: obj,
          value: obj,
        });
        return 1;
      });

      master.masterDetails.Index.map((obj) => {
        indexList.push({
          label: obj,
          value: obj,
        });
        return 1;
      });

      filterOptionsLocal[0].options = lensTypeList;
      filterOptionsLocal[1].options = lensColorList;
      filterOptionsLocal[2].options = indexList;
      filterOptionsLocal[3].options = lensMaterialList;
      filterOptionsLocal[4].options = lensCoatingList;

      setFilterOptions(filterOptionsLocal);

      setMetaV1Options({
        LensType: lensTypeList,
        LensColor: lensColorList,
        LensCoating: lensCoatingList,
        LensMaterial: lensMaterialList,
        Eye: eyeList,
        Index: indexList,
        HSNCode: hsnCodeList,
      });
      console.log(metaV1Options);
    }
  }, [master]);

  const onInputChangeHandler = (e, fieldName, type) => {
    let fieldValue;

    if (type === 'select') {
      fieldValue = e;
    }

    if (type === 'textInput') {
      fieldValue = e.target.value;
    }

    const inventoryFormSetter = { ...inventoryForm, [fieldName]: fieldValue };
    console.log(inventoryFormSetter);
    setInventoryForm(inventoryFormSetter);
  };

  useEffect(() => {
    console.log(inventorySave);
  }, [inventorySave]);

  const activePageHandler = (pageName) => {
    if (pageName === 'ListLens') {
      setInventoryForm({
        startSph: {
          label: 0,
          value: 0,
        },
        endSph: {
          label: 0,
          value: 0,
        },
        startCyl: {
          label: 0,
          value: 0,
        },
        endCyl: {
          label: 0,
          value: 0,
        },
        startAdd: {
          label: 0,
          value: 0,
        },
        endAdd: {
          label: 0,
          value: 0,
        },
        startAxis: {
          label: 0,
          value: 0,
        },
      });
      setCreatedVariants([]);
    }
    setActivePage(pageName);
  };

  const generateVariants = () => {
    const createdVariantsLocal = createVariants(inventoryForm);
    console.log(createdVariantsLocal);
    if (!createdVariantsLocal.showPrescriptions) {
      toast.error(createdVariantsLocal);
    } else if (createdVariantsLocal.Prescriptions.length === 0) {
      toast.error('Variants created: 0');
    } else {
      toast.success(`Variants created: ${createdVariantsLocal.Prescriptions.length}`);
      setCreatedVariants(createdVariantsLocal.Prescriptions);
      console.log(createdVariants);
    }
  };

  const adder = (e, index) => {
    if (new RegExp('^\\d+$').test(e.target.value)) {
      const updatedVariants = [...createdVariants];
      updatedVariants[index].Adder = e.target.value;
      setCreatedVariants(updatedVariants);
    }
  };

  const processAdder = (e, index) => {
    if (e.key === 'Enter') {
      const updatedVariants = [...createdVariants];
      updatedVariants[index].Quantity = Number(updatedVariants[index].Quantity) + Number(updatedVariants[index].Adder);
      updatedVariants[index].Adder = '';
      setCreatedVariants(updatedVariants);
    }
  };

  const updateVariants = (e, index) => {
    if (new RegExp('^\\d+$').test(e.target.value)) {
      const updatedVariants = [...createdVariants];
      updatedVariants[index].Quantity = e.target.value;
      setCreatedVariants(updatedVariants);
    }
  };

  const deleteVariant = (index) => {
    const updatedVariants = [...createdVariants];
    updatedVariants.splice(index, 1);
    setCreatedVariants(updatedVariants);
  };

  const activeModalAction = (action, close) => {
    if (action === 'ListLens') {
      setActiveModal('');
      activePageHandler(action);
      return 1;
    }

    if (close) {
      setActiveModal('');
    } else {
      setActiveModal(action);
    }
    return 1;
  };

  const saveLens = () => {
    console.log(inventoryForm);
    const createdVariantsLocal = [...createdVariants];
    const formedBody = {};
    if (!inventoryForm.lensType || !inventoryForm.lensMaterial
      || createdVariants.length === 0) {
      toast.error('Please fill all the mandatory fields!');
      activePageHandler('AddLens');
      return 1;
    }

    const processedVariants = [];
    const totalsLocal = {
      totalVariants: 0,
      totalQuantity: 0,
    };

    createdVariantsLocal.map((variant) => {
      if (variant.Quantity) {
        totalsLocal.totalQuantity += Number(variant.Quantity);
        totalsLocal.totalVariants += 1;

        const tempVariant = variant;
        tempVariant.ProductName = '';
        tempVariant.ProductName += `${inventoryForm.lensType.value} `;
        tempVariant.ProductName += `${inventoryForm.lensMaterial.value} `;
        tempVariant.ProductName += `${variant.ItemCode} `;
        tempVariant.ProductName += 'LENS';

        tempVariant.LensType = inventoryForm.lensType ? inventoryForm.lensType.value : '';
        tempVariant.LensColor = inventoryForm.lensColor ? inventoryForm.lensColor.value : '';
        tempVariant.LensMaterial = inventoryForm.lensMaterial ? inventoryForm.lensMaterial.value : '';
        tempVariant.LensCoating = inventoryForm.lensCoating ? inventoryForm.lensCoating.value : '';
        tempVariant.Index = inventoryForm.index ? inventoryForm.index.value : '';
        tempVariant.HSNCode = inventoryForm.hsnCode ? inventoryForm.hsnCode.value : '';
        tempVariant.Model = inventoryForm.model;
        tempVariant.Category = 'LENS';

        processedVariants.push(tempVariant);
      }
      return 1;
    });

    if (totalsLocal.totalVariants === 0) {
      return toast.error('Please fill atleast 1 inventory!');
    }
    setTotals(totalsLocal);
    formedBody.inventories = processedVariants;

    saveInventoryAction(formedBody);
    return 1;
  };

  useEffect(() => {
    console.log(inventorySave);
    if (!inventorySave.error
      && !inventorySave.isSaving) {
      if (inventorySave.inventoryDetails.myInventoryInsert) {
        setActiveModal('LensAdded');
        setIsImporting(false);
        setComments('');
      }
    }

    if (inventorySave.error) {
      setIsImporting(false);
      toast.error('Unexpected error :(');
    }
  }, [inventorySave]);

  const onSearchHandler = (e) => {
    if (e.key === 'Enter') {
      console.log(searchTerm);
      const inventoryDataLocal = _.cloneDeep(inventoryData);
      inventoryDataLocal.q = searchTerm;
      inventoryDataLocal.offset = 0;
      dispatch(updateFilterInventory(inventoryDataLocal));
      console.log(inventoryDataLocal);
      fetchInventoryAction(inventoryDataLocal);
    } else {
      setSearchTerm(e.target.value);
    }
  };

  const filterInventoyCall = (paramsFilter) => {
    const formattedFilter = {};
    const filterOptionsLocal = _.cloneDeep(paramsFilter);
    filterOptionsLocal.map((filter) => {
      filter.options.map((subOption) => {
        if (subOption.checked) {
          formattedFilter[filter.value] = subOption.label;
        }
        return 1;
      });
      return 1;
    });

    const inventoryDataLocal = _.cloneDeep(inventoryData);
    inventoryDataLocal.filter = formattedFilter;
    inventoryDataLocal.offset = 0;
    dispatch(updateFilterInventory(inventoryDataLocal));
    console.log(inventoryDataLocal);
    fetchInventoryAction(inventoryDataLocal);
  };

  const handlerFilters = (type, label, e, optionType) => {
    const filterOptionsLocal = _.cloneDeep(filterOptions);
    let callWithFilter = false;

    if (type === 'options') {
      filterOptionsLocal.map((filter, index) => {
        if (filter.label === label) {
          filterOptionsLocal[index].checked = true;
        } else {
          filterOptionsLocal[index].checked = false;
        }
        return 1;
      });
    }

    if (type === 'subOptions') {
      callWithFilter = true;
      filterOptionsLocal.map((filter, index) => {
        filter.options.map((subOption, index1) => {
          if (subOption.label === label) {
            if (e.target.checked) {
              filterOptionsLocal[index].options[index1].checked = true;
              filterOptionsLocal[index].count = 1;
            } else {
              filterOptionsLocal[index].count = 0;
              filterOptionsLocal[index].options[index1].checked = false;
            }
          }

          if (optionType === filter.label && subOption.label !== label) {
            filterOptionsLocal[index].options[index1].checked = false;
          }
          return 1;
        });
        return 1;
      });
    }

    if (type === 'resetAll') {
      callWithFilter = true;
      filterOptionsLocal.map((filter, index) => {
        filterOptionsLocal[index].checked = false;
        filter.options.map((subOption, index1) => {
          filterOptionsLocal[index].options[index1].checked = false;
          filterOptionsLocal[index].count = 0;
          return 1;
        });
        return 1;
      });
    }

    if (callWithFilter) {
      filterInventoyCall(filterOptionsLocal);
    }
    setFilterOptions(filterOptionsLocal);
  };

  const loadMoreInventory = () => {
    const inventoryDataLocal = inventoryData;
    inventoryDataLocal.offset += inventoryDataLocal.limit;
    fetchInventoryAction(inventoryDataLocal);
  };

  const csvToJSON = (csv, fileName) => {
    const lines = csv.split('\n');
    const incomingHeaders = lines[0].split(',');

    const newIncomingHeaders = [];

    incomingHeaders.map((header) => {
      let temp = header;

      temp = temp.replace('\r', '');

      newIncomingHeaders.push(temp);
      return 1;
    });

    const requirementHeaders = [
      'Spherical',
      'Cylindrical',
      'Add',
      'Axis',
      'LensMaterial',
      'LensType',
      'Quantity',
    ];

    let fieldCount = 0;

    requirementHeaders.map((reqHeader) => {
      if (newIncomingHeaders.includes(reqHeader)) {
        fieldCount += 1;
      }
      return 1;
    });

    if (requirementHeaders.length !== fieldCount) {
      toast.error('Template not matched!');
      setIsImporting(false);
      return 1;
    }

    const jsonInv = csvjson.toSchemaObject(csv);

    let errorLines = '';
    const totalsLocal = {
      totalVariants: 0,
      totalQuantity: 0,
    };

    jsonInv.map((inventory, index) => {
      if ((inventory.Spherical === '') || (inventory.Cylindrical === '') || (inventory.Add === '')
        || (inventory.Axis === '') || (inventory.LensType === '') || (inventory.LensMaterial === '')
        || !(inventory.Quantity)) {
        errorLines += `${index} `;
      } else {
        totalsLocal.totalQuantity += Number(inventory.Quantity);
        totalsLocal.totalVariants += 1;
      }

      jsonInv[index].ItemCode = '';
      jsonInv[index].ItemCode += `${inventory.Spherical} `;
      jsonInv[index].ItemCode += `${inventory.Cylindrical} `;
      jsonInv[index].ItemCode += `${inventory.Add} `;
      jsonInv[index].ItemCode += `${inventory.Axis}`;

      jsonInv[index].ProductName = '';
      jsonInv[index].ProductName += `${inventory.LensType} `;
      jsonInv[index].ProductName += `${inventory.LensMaterial} `;
      jsonInv[index].ProductName += `${inventory.ItemCode} `;
      jsonInv[index].ProductName += 'LENS';
      return 1;
    });

    if (errorLines) {
      setIsImporting(false);
      return toast.error(`Format is not matching at line nos: ${errorLines}`);
    }

    setTotals(totalsLocal);

    saveInventoryAction({
      source: 'Import From Excel',
      comments,
      fileName,
      inventories: jsonInv,
    });

    return 1;
  };

  const importCsv = (files) => {
    const currentTime = Math.floor(Date.now() / 1000);
    setIsImporting(true);
    ReactS3Client
      .uploadFile(files[0], `inventoryimport/${currentTime}`)
      .then((data) => console.log(data))
      .catch((err) => console.error(err));

    const reader = new FileReader();
    reader.readAsText(files[0]);
    reader.onload = () => {
      const text = reader.result;
      csvToJSON(text, files[0].name);
    };
  };
  // const convertToCSV = (arr) => {
  //   const array = [Object.keys(arr[0])].concat(arr);

  //   return array.map((it) => Object.values(it).toString()).join('\n');
  // };

  const setexportCSV = (data) => {
    // eslint-disable-next-line no-debugger
    debugger;
    const filterData = [];
    data.inventoryDetails.forEach((element, i) => {
      const filterField = {};
      tableHeaderData.forEach((header) => {
        // eslint-disable-next-line eqeqeq
        filterField[header.key] = header.key == 'S.No' ? i + 1 : element[header.key];
      });
      filterData[i] = filterField;
    });
    console.log('filterData :', filterData, data.inventoryDetails);
    const wb = new Workbook();
    const ws = utils.json_to_sheet(filterData);

    wb.SheetNames.push('');
    wb.Sheets[''] = ws;

    const wbout = write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
    const url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }));

    download(url, 'Export.csv');
  };

  return (
    <React.Fragment>
      <div className="inventory">
        {activePage === 'ListLens' && (
          <>
            <ListLens
              fetchCurrentUserState={fetchCurrentUserState}
              handlerFilters={handlerFilters}
              filterOptions={filterOptions}
              searchTerm={searchTerm}
              inventoryData={inventoryData}
              activePageHandler={activePageHandler}
              onSearchHandler={onSearchHandler}
              activeModalAction={activeModalAction}
              setexportCSV={setexportCSV}
            />
            {!inventoryData.noMoreData
              && (
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '14px',
                    textDecoration: 'underline',
                    color: '#03a5a5',
                  }}
                >
                  <span
                    style={{
                      cursor: 'pointer',
                    }}
                    role="presentation"
                    onClick={() => loadMoreInventory()}
                  >
                    Load More
                  </span>
                </p>
              )}
          </>
        )}
        {activePage === 'AddLens' && (
          <AddLens
            metaV1Options={metaV1Options}
            onInputChangeHandler={onInputChangeHandler}
            activePageHandler={activePageHandler}
            inventoryForm={inventoryForm}
            lensPowers={lensPowers}
            generateVariants={generateVariants}
            createdVariants={createdVariants}
          />
        )}
        {activePage === 'FullVariants' && (
          <FullVariants
            processAdder={processAdder}
            adder={adder}
            saveLens={saveLens}
            updateVariants={updateVariants}
            inventoryForm={inventoryForm}
            activePageHandler={activePageHandler}
            tableRowsData={createdVariants}
            deleteVariant={deleteVariant}
            inventorySave={inventorySave}
          />
        )}
        {
          activeModal === 'LensAdded'
          && (
            <Modal
              modalStatus={activeModal === 'LensAdded'}
              modalAction={() => activeModalAction('ListLens', 'close')}
              color="success"
              title="Well Done!"
              btn="Success"
              message={(
                <div>Inventory Added Successfully! <br /><br />
                  <span>Total Variants: <b>{totals.totalVariants}</b></span><br />
                  <span>Total Quantity: <b>{totals.totalQuantity}</b></span>
                </div>
              )}
            />
          )
        }
        {
          activeModal === 'ImportExcel'
          && (
            <Modal
              modalStatus={activeModal === 'ImportExcel'}
              modalAction={() => activeModalAction('ListLens', 'close')}
              header
              title="Import Inventory"
              color="success"
              message={(
                <div>
                  <form onSubmit={(e) => e.preventDefault()} className="form">
                    <label htmlFor="comments">Comments</label>
                    <input
                      onChange={(e) => setComments(e.target.value)}
                      name="comments"
                      component="input"
                      value={comments || ''}
                      type="text"
                      placeholder="type here..."
                    />
                  </form>
                  <div style={{
                    marginTop: '10px',
                  }}
                  >
                    {
                      !isImporting ? (
                        <ReactFileReader
                          handleFiles={importCsv}
                          fileTypes=".csv"
                        >
                          <Button
                            className="rounded btn-left"
                            color="success"
                          >
                            Import From CSV
                          </Button>
                        </ReactFileReader>
                      ) : (
                        <Button
                          disabled
                          className="rounded btn-left"
                          color="success"
                        >
                          Import From CSV
                        </Button>
                      )
                    }

                  </div>
                </div>
              )}
            />
          )
        }
      </div>
    </React.Fragment>
  );
};

Inventory.propTypes = {
  fetchMastersAction: PropTypes.func.isRequired,
  master: PropTypes.objectOf.isRequired,
  saveInventoryAction: PropTypes.func.isRequired,
  inventorySave: PropTypes.objectOf.isRequired,
  fetchInventoryAction: PropTypes.func.isRequired,
  inventoryData: PropTypes.arrayOf.isRequired,
  fetchCurrentUserState: PropTypes.objectOf.isRequired,
};

const mapDispatchToProps = {
  fetchMastersAction: fetchMaster,
  saveInventoryAction: saveInventory,
  fetchInventoryAction: fetchInventory,
};

const mapStateToProps = (state) => ({
  master: state.master,
  inventorySave: state.inventorySave,
  inventoryData: state.inventoryData,
  fetchCurrentUserState: state.fetchCurrentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
