import { handleActions } from 'redux-actions';
import {
  fetchAccountDataRequest,
  fetchAccountDataSuccess,
  fetchAccountDataFailure,
  saveUserRequest,
  saveUserSuccess,
  saveUserFailure,
  defaultSaveUser,
  addToUserData,
  fetchUserRequest,
  fetchUserSuccess,
  fetchUserFailure,
  fetchCurrentUserRequest,
  fetchCurrentUserSuccess,
  fetchCurrentUserFailure,
} from '../actions/accountActions';

const defaultState = {
  accountDetails: [],
  isFetching: false,
  error: null,
};

const defaultSaveUserState = {
  isSaving: false,
  error: null,
  userDetails: {},
};

const defaultFetchCurrentUser = {
  isFetching: false,
  error: null,
  userDetails: {},
};

const defaultFetchUser = {
  isfetching: false,
  error: null,
  userDetails: [],
  limit: 500,
  skip: 0,
};

export const accountReducer = handleActions(
  {
    [fetchAccountDataRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchAccountDataSuccess](state, { payload }) {
      return {
        ...state,
        accountDetails: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchAccountDataFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState,
);

export const fetchCurrentUserReducer = handleActions(
  {
    [fetchCurrentUserRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchCurrentUserSuccess](state, { payload }) {
      return {
        ...state,
        userDetails: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchCurrentUserFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultFetchCurrentUser,
);

export const userSaveReducer = handleActions(
  {
    [saveUserRequest](state) {
      console.log(state);
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    },
    [saveUserSuccess](state, { payload }) {
      return {
        ...state,
        userDetails: payload,
        isSaving: false,
        error: null,
      };
    },
    [saveUserFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isSaving: false,
      };
    },
    [defaultSaveUser](state) {
      return {
        ...state,
        userDetails: {},
        error: null,
        isSaving: false,
      };
    },
  },
  defaultSaveUserState,
);

export const userFetchReducer = handleActions(
  {
    [fetchUserRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchUserSuccess](state, { payload }) {
      return {
        ...state,
        userDetails: payload,
        limit: state.limit,
        skip: state.skip,
        isFetching: false,
        error: null,
      };
    },
    [fetchUserFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    },
    [addToUserData](state, { payload }) {
      return {
        ...state,
        userDetails: [payload, ...state.userDetails],
        error: null,
      };
    },
  },
  defaultFetchUser,
);
