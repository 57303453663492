import { get, post } from './base/index';

export default {
  getAccount: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}v1/accounts/account`, { params });
  },
  login: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v1/accounts/auth/accounts/verify`, body);
  },
  signup: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v1/accounts/auth/accounts`, body);
  },
  commonPhoneNumberCheck: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}v1/accounts/superadmin/clientuser/getuserphonenumberv2`, { params });
  },
  createUser: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}v1/accounts/users`, body);
  },
  getUser: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}v1/accounts/users`, { params });
  },
  getCurrentUser: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}v1/accounts/users/${params.userId}`);
  },
};
