/* eslint-disable radix */
/* eslint-disable no-const-assign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
/* eslint-disable no-shadow */
/* eslint-disable no-else-return */
/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable import/named */
/* eslint-disable no-use-before-define */
/* eslint-disable no-debugger */
/* eslint-disable no-empty */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import DownloadCircleIcon from 'mdi-react/DownloadCircleIcon';
import { connect, useDispatch } from 'react-redux';
import { utils, write } from 'xlsx';
import { Button, Modal } from 'reactstrap';
import moment from 'moment';
// import Modal from '../../../shared/components/modal/Modal';
import AddTemplateIcon from '../../../assets/sidebar_icons/add_icon/templete/add_templete.png';
import BasicTable from '../../Tables/BasicTables/components/IocReportTable';
import ModalPop from './ModalPop/ModalPop';
import MultiDropDown from '../../../shared/components/multi-select';
import api from '../../../utils/apiClient';
// import CustomerOrder from '../../CustomerOrder';
import { addReportCustomer } from '../../../redux/actions/oicReportAction';
import tableHeader from '../TableHeader';

function Workbook() {
  if (!(this instanceof Workbook)) { return new Workbook(); }

  this.SheetNames = [];

  this.Sheets = {};
}
function s2ab(s) {
  const buf = new ArrayBuffer(s.length);

  const view = new Uint8Array(buf);

  // eslint-disable-next-line no-bitwise
  for (let i = 0; i !== s.length; ++i) { view[i] = s.charCodeAt(i) & 0xFF; }

  return buf;
}
function AddOicReport() {
  const filterFields = {
    functionName: '',
    customersName: [],
    timeLine: '',
    supplier: '',
    resultType: '',
    startDate: null,
    endDate: null,
    reportName: null,
  };
  const referanceFields = [{ fieldValue: 'functionName', header: 'FunctionName' },
    { fieldValue: 'supplier', header: 'Vendor' }, { fieldValue: 'customersName', header: 'CustomersName' }, { fieldValue: 'timeLine', header: 'TimeLine' }, { fieldValue: 'resultType', header: 'ResultType' }];
  const dispatch = useDispatch();
  const [isswappage, setSwapPage] = useState('AddReport');
  const [tableHeaderData, setTableHeaderData] = useState([]);
  const [tableRowsData, setTableRowsData] = useState([]);
  const [activeModal, setActiveModal] = useState('');
  const [selectFuctionData, getFuctionData] = useState([]);
  // const [lensType, setLensType] = useState([]);
  // const [lensMaterial, setLensMaterial] = useState([]);
  const [customer, setCustomers] = useState([]);
  const [ReportFilter, setReportFilter] = useState({ ...filterFields });
  const [timelines, setTimeLine] = useState([]);
  const [requiredfield, isRequiredField] = useState(true);
  const [LoadingMask, isLoadingMask] = useState(false);
  const [supplier, isSupplier] = useState(false);
  const [asCustomer, isCustomer] = useState(true);
  const [supplierlist, setSupplierList] = useState([]);
  const FunctionsLists = [
    {
      displayLabel: 'Customer Orders', fieldValue: 'customerorder', value: 'customerorder', resultType: 'Summary',
    },
    {
      displayLabel: 'Purchase Orders', fieldValue: 'purchase', value: 'purchase', resultType: 'Summary',
    },
    {
      displayLabel: 'Goods Receipt Notes', fieldValue: 'grn', value: 'grn', resultType: 'Summary',
    },
    {
      displayLabel: 'Pending Purchases', fieldValue: 'pendingpurchase', value: 'pendingpurchase', resultType: 'Summary',
    },
    {
      displayLabel: 'Return Purchases', fieldValue: 'return', value: 'return', resultType: 'Itemaized',
    },
    {
      displayLabel: 'Inventory Adjustments', fieldValue: 'inventory', value: 'inventoryadjustment', resultType: 'Summary',
    },
    // { displayLabel: 'Inventory Onboarding', fieldValue: 'inventoryonboard', value: 'inventoryonboard' },
  ];
  const addComments = (payload) => {

  };
  const onGetResult = async () => {
    setSwapPage('GetReportResult');
  };
  const activeModalAction = (open, close) => {
    if (close) setActiveModal('');
    else if (open) setActiveModal(open);
  };
  const onSaveReport = () => {
    activeModalAction('AddReport', '');
  };
  const onBackpage = () => {
    if (isswappage === 'AddReport') {
      const refer = document.createElement('a');
      refer.href = '/reports';
      refer.click();
    } else {
      setSwapPage('AddReport');
    }
  };
  const onRedirectpage = () => {
    dispatch(addReportCustomer([]));
    const refer = document.createElement('a');
    refer.href = '/reports';
    refer.click();
  };
  useEffect(async () => {
    dispatch(addReportCustomer([]));
    const viewData = JSON.parse(localStorage.getItem('viewReport'));
    if (viewData?._id) {
      await onViewDetails(viewData);
    }
  }, []);

  useEffect(async () => {
    if (ReportFilter.timeLine == 'Custom') {
      if (ReportFilter.startDate == null && ReportFilter.endDate == null) {
        isRequiredField(true);
      }
    }
    if (!supplier && !asCustomer) {
      const emptyfield = Object.keys(filterFields).find((field) => (ReportFilter[field] == '' || ReportFilter[field]?.length === 0));
      isRequiredField(!!emptyfield);
    } else if (!supplier && asCustomer) {
      const emptyfield = Object.keys(filterFields).find((field) => ((ReportFilter[field] == '' || ReportFilter[field]?.length === 0) && field != 'customersName'));
      isRequiredField(!!emptyfield);
    } else if (supplier && !asCustomer) {
      const emptyfield = Object.keys(filterFields).find((field) => ((ReportFilter[field] == '' || ReportFilter[field]?.length === 0) && field != 'supplier'));
      isRequiredField(!!emptyfield);
    } else {
      const emptyfield = Object.keys(filterFields).find((field) => ReportFilter[field] === '' && field != 'supplier');
      isRequiredField(!!emptyfield);
    }
    if (ReportFilter.functionName) {
      const tablehead = await tableHeader(ReportFilter.functionName);
      setTableHeaderData(tablehead);
    }
  }, [ReportFilter]);
  useEffect(async () => {
    const viewData = JSON.parse(localStorage.getItem('viewReport'));
    if (!viewData?._id) {
      await onFilterReports(ReportFilter, selectFuctionData);
    }
  }, [requiredfield]);
  const onViewDetails = (saveReport) => {
    const saveDetails = {};
    saveDetails._id = saveReport._id;
    saveDetails.functionName = saveReport.functionName;
    saveDetails.startDate = saveReport.startDate;
    saveDetails.endDate = saveReport.endDate;
    // saveDetails.lensMaterial = saveReport.lensMaterial;
    // saveDetails.lensType = saveReport.lensType;
    saveDetails.reportName = saveReport.reportName;
    saveDetails.timeLine = saveReport.timeLine;
    saveDetails.supplier = saveReport.supplier;
    saveDetails.resultType = saveReport.resultType;
    saveDetails.customersName = saveReport.customersName;
    dispatch(addReportCustomer([...saveDetails.customersName]));
    saveDetails._created_at = saveReport._created_at;
    saveDetails._modified_at = saveReport._modified_at;
    saveDetails.__v = saveReport.__v;
    saveDetails.filterResult = [...saveReport.filterResult];
    setTableRowsData(saveDetails.filterResult);
    setReportFilter({ ...saveReport });
  };
  const saveReportFunc = async (modaldata) => {
    // setFileName(modaldata.reportName);
    const payload = { ...ReportFilter };
    modaldata.forEach((data) => {
      Object.keys(data).forEach((key) => {
        if (key == 'fieldName') {
          if (data[key] == 'startDate' || data[key] == 'endDate') {
            payload[data[key]] = new Date(data.fieldValue) || null;
          } else {
            payload[data[key]] = data.fieldValue || null;
          }
        }
      });
    });
    payload.filterResult = [...tableRowsData];
    console.log(payload);
    if (payload.reportName !== null) {
      try {
        const saveReport = await api.IocReportFuction.saveReport(payload);
        onRedirectpage();
        const saveData = saveReport.data.data;
        onViewDetails(saveData);
      } catch (e) {
        console.log(e);
      }
    } else {
      setReportFilter(payload);
    }
    setActiveModal('');
    activeModalAction('', 'close');
  };

  const onselectFunctions = async (val) => {
    const data = { limit: 500, skip: 0 };
    let header = val;
    if (val == 'pendingpurchase') {
      header = 'grn';
    } else if (val === 'pendingreturn') {
      header = 'return';
    }
    if (val == 'pendingpurchase' || val == 'pendingreturn') {
      data.filter = { status: 'Pending' };
    }
    await onReportReset('functionName', val);
    if (header !== '') {
      isLoadingMask(true);
      const param = FunctionsLists.find((filterval) => header == filterval.fieldValue);
      try {
        const selectFuntionsData = await api.IocReportFuction.getReportFunction(data, param);
        if (selectFuntionsData.data) {
          const Datas = selectFuntionsData.data?.data || [];
          getFuctionData(Datas);
          isSupplier(!Datas[0].supplier);
          isCustomer(!Datas[0].customerName);
          const customerNamelist = [];
          const suplierlist = [];
          Datas.forEach((element) => {
            if (element?.customerName) {
              customerNamelist.push(element.customerName);
            }
            if (element?.supplier && !element?.customerName) {
              suplierlist.push(element.supplier);
            }
          });
          const FilterSupplierList = suplierlist.filter((custom, cst) => suplierlist.indexOf(custom) == cst);
          const FilterCustomerList = customerNamelist.filter((custom, cst) => customerNamelist.indexOf(custom) == cst);
          if (FilterCustomerList.length) {
            setCustomers(FilterCustomerList);
          }
          if (FilterSupplierList.length) {
            setSupplierList(FilterSupplierList);
          }
          if (!Datas[0]?.supplier && !Datas[0]?.customerName) {
            setTimeLine([
              'Today', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'Custom', 'Default',
            ]);
          } else {
            setTimeLine([]);
          }
          isLoadingMask(false);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  const onChangeTimeLine = (value) => {
    const gettimeLine = { ...ReportFilter };
    gettimeLine.startDate = null;
    gettimeLine.endDate = null;
    gettimeLine.timeLine = value;
    // gettimeLine.resultType = '';
    setReportFilter(gettimeLine);
    if (value === 'Custom') {
      setActiveModal('Customaization');
    }
  };
  async function onReportReset(field, val) {
    getFuctionData([]);
    // eslint-disable-next-line no-use-before-define
    dispatch(addReportCustomer([]));
    // setLensType([]);
    // setLensMaterial([]);
    setSupplierList([]);
    setCustomers([]);
    // setTimeLine([]);
    const getReportFilter = { ...filterFields };
    getReportFilter[field] = val;
    getReportFilter.resultType = FunctionsLists.find((list) => list.fieldValue == val)?.resultType || '';
    getReportFilter.customersName = [];
    getReportFilter.supplier = '';
    setReportFilter({ ...getReportFilter });
    // dispatch(addReportCustomer([]));
  }
  const onSelectCustomer = (val) => {
    const getcustomer = { ...ReportFilter };
    getcustomer.customersName = val;
    // getcustomer.resultType = '';
    getcustomer.timeLine = '';
    getcustomer.supplier = '';
    getcustomer.startDate = null;
    getcustomer.endDate = null;
    setReportFilter(getcustomer);
    if (supplier && !!val) {
      setTimeLine([
        'Today', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'Custom', 'Default',
      ]);
    } else {
      setTimeLine([]);
    }
    if (val.length && !supplier) {
      const suppliers = [];
      selectFuctionData.forEach((custom) => {
        val.forEach((s) => {
          if (custom.customerName == s) {
            suppliers.push(custom.supplier);
          }
        });
      });
      const FilterSupplierList = suppliers.filter((custm, c) => suppliers.indexOf(custm) === c && custm);
      setSupplierList(FilterSupplierList);
    } else {
      setSupplierList([]);
    }
  };

  const onSelectSupplier = (vendor) => {
    const getvendor = { ...ReportFilter };
    // getvendor.resultType = '';
    getvendor.timeLine = '';
    getvendor.supplier = vendor;
    getvendor.startDate = null;
    getvendor.endDate = null;
    setReportFilter(getvendor);
    if (vendor) {
      setTimeLine([
        'Today', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'Custom', 'Default',
      ]);
    } else {
      setTimeLine([]);
    }
  };
  const onFilterReports = (payload, data) => {
    const filterval = { ...payload };
    const filterFields = [];
    const returncustom = filterval.customersName.length > 0 ? filterval.customersName : [''];
    if (filterval.resultType === 'Summary') {
      returncustom.forEach((customer) => {
        data.forEach(async (list) => {
          const getdetails = {};
          const startend = await startToEndDate(filterval.timeLine, list.customerOrderDate);
          let filteration = false;
          if (!supplier && !asCustomer) {
            filteration = !!((customer === list.customerName && list.supplier === filterval.supplier));
          } else if (supplier && !asCustomer) {
            filteration = customer === list.customerName;
          } else if (!supplier && asCustomer) {
            filteration = list.supplier === filterval.supplier;
          } else if (supplier && asCustomer) {
            filteration = true;
          }
          if (filteration && startend) {
            if (!asCustomer) {
              getdetails.customerName = list?.customerName || [];
            }
            if (!supplier) {
              getdetails.supplier = list?.supplier || null;
            }
            // const customerOrderDate = list?.customerOrderDate || list.vendorInvoiceDate;
            if (list?.customerOrderDate) {
              getdetails.referanceOrderDate = list.customerOrderDate;
            }
            const customerOrderDate = list?.sysCustomerOrderDate || list?._modified_at;
            if (customerOrderDate) {
              getdetails.customerOrderDate = customerOrderDate;
            }
            if (list?.sysCustomerOrderId || (list?.orderId && list.orderId.includes('CO'))) {
              getdetails.customerOrderId = list?.sysCustomerOrderId || list.orderId;
            }
            if (list?.orderId || list?.poId) {
              getdetails.orderId = list?.poId || list.orderId;
            }
            if (list?.vendorInvoiceDate) {
              getdetails.vendorInvoiceDate = list.vendorInvoiceDate;
            }
            if (list.adjustmentDate) {
              getdetails.adjustmentDate = list.adjustmentDate;
            }
            if (list?.returnId) {
              getdetails.returnId = list.returnId;
            }
            if (list?.vendorInvoiceId) {
              getdetails.vendorInvoiceId = list.vendorInvoiceId;
            }
            if (list?.customerOrderId) {
              getdetails.referanceOrderId = list.customerOrderId;
            }
            if (list?.grId) {
              getdetails.grnId = list.grId;
              getdetails.grnDate = list._created_at;
            }
            if (list?.poDate) {
              getdetails.orderDate = list.poDate;
            }
            if (list.status) {
              getdetails.Status = list.status;
            }
            // getdetails.LensMaterial = ReportFilter?.lensMaterial || ReportFilter.ProductName.split(' ')[3];
            // getdetails.LensType = ReportFilter?.lensType || `${ReportFilter.ProductName.split(' ')[1]} ${ReportFilter.ProductName.split(' ')[2]}`;
            getdetails.TimeLine = filterval.timeLine;
            if (ReportFilter?.startDate) {
              getdetails.StartDate = filterval.startDate;
              getdetails.EndDate = filterval.endDate;
            }
            getdetails.FunctionName = ReportFilter.functionName;
            getdetails.ResultType = filterval.resultType;
            let singleVisionCR = 0;
            let singleVisionAcrylic = 0;
            let bifocalKTCR = 0;
            let BifocalKTAcrylic = 0;
            let BifocalDCR = 0;
            let BifocalDAcrylic = 0;
            const mapval = list?.variants || list.inventories;
            getdetails.singleVision = 0;
            getdetails.bifocalKt = 0;
            getdetails.bifocalD = 0;
            getdetails.cr = 0;
            getdetails.acrylic = 0;
            mapval.forEach((invent) => {
              getdetails.singleVision += invent.LensType === 'SINGLE VISION' ? parseInt(invent.Quantity) : 0;
              getdetails.bifocalKt += invent.LensType === 'BIFOCAL KT' ? parseInt(invent.Quantity) : 0;
              getdetails.bifocalD += invent.LensType === 'BIFOCAL D' ? parseInt(invent.Quantity) : 0;
              getdetails.cr += invent.LensMaterial === 'CR' ? parseInt(invent.Quantity) : 0;
              getdetails.acrylic += invent.LensMaterial === 'ACRYLIC' ? parseInt(invent.Quantity) : 0;
              singleVisionCR += invent.LensType === 'SINGLE VISION' && invent.LensMaterial === 'CR' ? parseInt(invent.Quantity) : 0;
              singleVisionAcrylic += invent.LensType === 'SINGLE VISION' && invent.LensMaterial === 'ACRYLIC' ? parseInt(invent.Quantity) : 0;
              bifocalKTCR += invent.LensType === 'BIFOCAL KT' && invent.LensMaterial === 'CR' ? parseInt(invent.Quantity) : 0;
              BifocalDAcrylic += invent.LensType === 'BIFOCAL D' && invent.LensMaterial === 'ACRYLIC' ? parseInt(invent.Quantity) : 0;
              BifocalDCR += invent.LensType === 'BIFOCAL D' && invent.LensMaterial === 'CR' ? parseInt(invent.Quantity) : 0;
              BifocalKTAcrylic += invent.LensType === 'BIFOCAL KT' && invent.LensMaterial === 'ACRYLIC' ? parseInt(invent.Quantity) : 0;
            });
            getdetails.totalQuantity = singleVisionCR + singleVisionAcrylic + bifocalKTCR + BifocalDAcrylic + BifocalDCR + BifocalKTAcrylic;
            filterFields.push(getdetails);
          }
        });
      });
    } else if (filterval.resultType === 'Itemaized') {
      // eslint-disable-next-line no-shadow

      returncustom.forEach((customer) => {
        data.forEach(async (list) => {
          const startend = await startToEndDate(filterval.timeLine, list.customerOrderDate);
          let filteration = false;
          if (!supplier && !asCustomer) {
            filteration = !!((customer === list.customerName && list.supplier === filterval.supplier));
          } else if (supplier && !asCustomer) {
            filteration = customer === list.customerName;
          } else if (!supplier && asCustomer) {
            filteration = list.supplier === filterval.supplier;
          }
          if (filteration && startend) {
            const Itemaized = list?.variants || list.inventories;
            Itemaized.forEach((invent) => {
              const getdetails = {};
              if (!asCustomer) {
                getdetails.customerName = list?.customerName || [];
              }
              if (!supplier) {
                getdetails.supplier = list?.supplier || null;
              }
              getdetails.returnId = list.returnId;
              getdetails.returnOrderDate = list._created_at;
              getdetails.vendorInvoiceId = list.vendorInvoiceId;
              getdetails.vendorInvoiceDate = list.vendorInvoiceDate;
              getdetails.Status = list.status;
              getdetails.LensMaterial = ReportFilter.lensMaterial;
              getdetails.LensType = ReportFilter.lensType;
              getdetails.TimeLine = ReportFilter.timeLine;
              if (ReportFilter?.startDate) {
                getdetails.StartDate = ReportFilter.startDate;
                getdetails.EndDate = ReportFilter.endDate;
              }
              getdetails.FunctionName = ReportFilter.functionName;
              getdetails.ResultType = filterval.resultType;
              getdetails.singleVision = invent.LensType === 'SINGLE VISION' ? parseInt(invent.Quantity) : 0;
              getdetails.bifocalKt = invent.LensType === 'BIFOCAL KT' ? parseInt(invent.Quantity) : 0;
              getdetails.bifocalD = invent.LensType === 'BIFOCAL D' ? parseInt(invent.Quantity) : 0;
              getdetails.cr = invent.LensMaterial === 'CR' ? parseInt(invent.Quantity) : 0;
              getdetails.acrylic = invent.LensMaterial === 'ACRYLIC' ? parseInt(invent.Quantity) : 0;
              const singleVisionCR = invent.LensType === 'SINGLE VISION' && invent.LensMaterial === 'CR' ? parseInt(invent.Quantity) : 0;
              const singleVisionAcrylic = invent.LensType === 'SINGLE VISION' && invent.LensMaterial === 'ACRYLIC' ? parseInt(invent.Quantity) : 0;
              const bifocalKTCR = invent.LensType === 'BIFOCAL KT' && invent.LensMaterial === 'CR' ? parseInt(invent.Quantity) : 0;
              const BifocalDAcrylic = invent.LensType === 'BIFOCAL D' && invent.LensMaterial === 'ACRYLIC' ? parseInt(invent.Quantity) : 0;
              const BifocalDCR = invent.LensType === 'BIFOCAL D' && invent.LensMaterial === 'CR' ? parseInt(invent.Quantity) : 0;
              const BifocalKTAcrylic = invent.LensType === 'BIFOCAL KT' && invent.LensMaterial === 'ACRYLIC' ? parseInt(invent.Quantity) : 0;
              getdetails.totalQuantity = singleVisionCR + singleVisionAcrylic + bifocalKTCR + BifocalDAcrylic + BifocalDCR + BifocalKTAcrylic;
              filterFields.push(getdetails);
            });
          }
        });
      });
    }
    setTableRowsData(filterFields || []);
  };
  // eslint-disable-next-line consistent-return
  const startToEndDate = async (datefield, date) => {
    // eslint-disable-next-line no-mixed-operators
    const currentdate = new Date(date);
    // eslint-disable-next-line no-mixed-operators
    const today = new Date();
    // const currentdaymilsec = Math.ceil((currentdate / (1000 * 60 * 60 * 24)).toFixed(1));
    const currentdaymilsec = Math.floor(currentdate / (1000 * 60 * 60 * 24)).toFixed(1);
    const todaymilsec = Math.floor(today / (1000 * 60 * 60 * 24)).toFixed(1);
    if (datefield === 'Today') {
      return todaymilsec === currentdaymilsec;
    } else if (datefield === 'Yesterday') {
      return todaymilsec - 2 === currentdaymilsec;
    } else if (datefield === 'This Week') {
      const previousSunday = new Date();
      previousSunday.setDate(today.getDate() - today.getDay());
      const last = today.getDate() - today.getDay() + 6;
      const upcomingSaturday = new Date(today.setDate(last));
      const previousSundaymilsec = Math.floor(previousSunday / (1000 * 60 * 60 * 24));
      const upcomingSaturdaymilsec = Math.floor(upcomingSaturday / (1000 * 60 * 60 * 24));
      return previousSundaymilsec <= currentdaymilsec && upcomingSaturdaymilsec >= currentdaymilsec;
    } else if (datefield === 'This Month') {
      const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      const firstDaymilsec = Math.floor(firstDay / (1000 * 60 * 60 * 24)).toFixed(1);
      const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      const lastDaymilsec = Math.floor(lastDay / (1000 * 60 * 60 * 24)).toFixed(1);
      return firstDaymilsec <= currentdaymilsec && currentdaymilsec <= lastDaymilsec;
    } else if (datefield === 'Last Week') {
      const lastSunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 8);
      const lastSaturday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 2);
      const lastSaturdaymilsec = Math.floor(lastSaturday / (1000 * 60 * 60 * 24)).toFixed(1);
      const lastSundaymilsec = Math.floor(lastSunday / (1000 * 60 * 60 * 24)).toFixed(1);
      return lastSundaymilsec <= currentdaymilsec && currentdaymilsec <= lastSaturdaymilsec;
    } else if (datefield === 'Last Month') {
      const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
      const lastMonthStartmilsec = Math.floor(lastMonthStart / (1000 * 60 * 60 * 24)).toFixed(1);
      const lastMonthEndmilsec = Math.floor(lastMonthEnd / (1000 * 60 * 60 * 24)).toFixed(1);
      return lastMonthStartmilsec <= currentdaymilsec && currentdaymilsec <= lastMonthEndmilsec;
    } else if (datefield === 'Custom') {
      const startDatemilsec = Math.floor(ReportFilter.startDate / (1000 * 60 * 60 * 24)).toFixed(1);
      const endDatemilsec = Math.floor(ReportFilter.endDate / (1000 * 60 * 60 * 24)).toFixed(1);
      return startDatemilsec <= endDatemilsec;
    } else {
      return true;
    }
  };
  const downloadCsv = async () => {
    const wb = new Workbook();
    // eslint-disable-next-line no-undef
    const exportdata = await convertTableData();
    const ws = utils.json_to_sheet(exportdata);

    wb.SheetNames.push('');
    wb.Sheets[''] = ws;

    const wbout = write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

    const url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }));

    downloadFile(url, 'import.csv');
  };
  const downloadFile = (url, file) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = file;
    a.click();

    window.URL.revokeObjectURL(url);
  };
  const convertTableData = () => {
    const getReportData = [];
    tableRowsData.forEach((exportfile) => {
      const exportdetails = {};
      tableHeaderData.forEach((head) => {
        exportdetails[head.tableHeader.toUpperCase()] = head.type !== 'Id' ? `${head.title === 'adjustmentDate' ? moment(new Date(item[head.title])).format('DD MMM YYYY') : item[head.title]}` : `${exportfile[head.title]}/${moment(exportfile[head.dateKey]).format('DD MMM YYYY')}`;
      });
      getReportData.push({ ...exportdetails });
    });
    return getReportData;
  };

  return (
    <div className="container">
      <div className="row" style={{ marginBottom: '12px' }}>
        <div className="smalldiv__listheader col-md-12">
          <div>
            <div
              className="backButton btn btn-sm btn btn-outline-secondary"
              onClick={onBackpage}
              aria-hidden="true"
            >
              &lt;
            </div>
            <h4
              style={{ color: '#03a5a5' }}
              onClick={onRedirectpage}
              aria-hidden="true"
            >
              REPORTS
            </h4>
          </div>
        </div>
      </div>
      <form className="form">
        {
          ReportFilter.reportName && (
            <div
              className="d-flex h5 font-bld"
              style={{ marginBottom: '12px' }}
            >
              <img
                className="sidebar__link-icon lnr"
                style={{ width: '25px' }}
                src={AddTemplateIcon}
                alt="Template"
              />
              {ReportFilter.reportName}
            </div>
          )
        }
        {isswappage === 'AddReport'
          && (
            <>
              <div className="d-flex row col-md-12" style={{ marginBottom: '12px' }}>
                <div className="form-group col-4">
                  <label htmlFor="func">
                    FUNCTION
                  </label>
                  <select
                    className="form-select form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    value={ReportFilter.functionName}
                    disabled={ReportFilter._id}
                    onChange={(e) => onselectFunctions(e.target.value)}
                  >
                    <option value="">--Please Select the Fuction--</option>
                    {ReportFilter._id ? <option value={ReportFilter.functionName} selected>{ReportFilter.functionName}</option>
                      : FunctionsLists.map((list) => (
                        <option
                          value={list.fieldValue}
                        >
                          {list.displayLabel}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group col-4">
                  <label htmlFor="func">
                    CUSTOMERS
                  </label>
                  <MultiDropDown options={customer} multiSelectValue={onSelectCustomer} disabled={ReportFilter?._id || asCustomer} />
                </div>
              </div>
              <div className="d-flex row col-md-12" style={{ marginBottom: '12px' }}>
                <div className="form-group col-4">
                  <label htmlFor="lens.typ">
                    VENDORS
                  </label>
                  <select
                    className="form-select form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    disabled={ReportFilter?._id || supplier}
                    value={ReportFilter.supplier}
                    onChange={(e) => onSelectSupplier(e.target.value)}
                  >
                    <option value="">--Please Select the Vendors--</option>
                    {ReportFilter._id ? <option value={ReportFilter.supplier} selected>{ReportFilter.supplier}</option>
                      : supplierlist.map((list) => (
                        <option
                          value={list}
                        >
                          {list}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group col-4">
                  <label htmlFor="lens.matrl">
                    TIMELINE
                  </label>
                  <select
                    className="form-select form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    value={ReportFilter.timeLine}
                    disabled={ReportFilter._id}
                    onChange={(e) => onChangeTimeLine(e.target.value)}
                  >
                    <option value="">--Please Select the Timeline--</option>
                    {ReportFilter._id ? <option value={ReportFilter.timeLine} selected>{ReportFilter.timeLine}</option>
                      : timelines.map((list) => (
                        <option
                          value={list}
                        >
                          {list}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div
                className="d-flex row col-md-12"
                style={{ marginBottom: '10px' }}
              >
                <div className="col">
                  RESULTS
                </div>
              </div>
              <div
                className="d-flex row col-md-12"
                style={{ marginBottom: '20px' }}
              >
                <div className="col-3">
                  <div
                    className="form-check"
                    aria-hidden="true"
                  >
                    <div className="radio-item">
                      <input
                        type="radio"
                        id="flexRadioDefault1"
                        value="Itemaized"
                        name="Itemaized"
                        style={{ width: 'auto' }}
                        checked={ReportFilter.resultType === 'Itemaized'}
                        disabled
                      // onClick={() => onselectResult('Itemaized')}
                      />
                      <label htmlFor="flexRadioDefault1">Itemaized</label>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div
                    className="form-check ml-2"
                    aria-hidden="true"
                  >
                    <div className="radio-item">
                      <input
                        type="radio"
                        id="flexRadioDefault2"
                        value="Summary"
                        name="Summary"
                        style={{ width: 'auto' }}
                        checked={ReportFilter.resultType === 'Summary'}
                        disabled
                      // onClick={() => onselectResult('Summary')}
                      />
                      <label htmlFor="flexRadioDefault2">Summary</label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex row col-md-12"
                style={{ marginBottom: '20px' }}
              >
                <div className="mr-2" style={{ display: ReportFilter._id || requiredfield ? 'none' : 'block' }}>

                  <Button
                    className="rounded btn btn-outline-success smalldiv__listheader font-bld font-hv-success"
                    style={{ backgroundColor: 'white', textAlign: 'center' }}
                    aria-hidden="true"
                    onClick={onSaveReport}
                  >
                    Save As Template
                    <img
                      className="sidebar__link-icon lnr"
                      style={{ width: '17px', marginLeft: '10px', display: requiredfield ? 'none' : '' }}
                      src={AddTemplateIcon}
                      alt="Template"
                    />
                  </Button>
                </div>
                <div className="d-flex">
                  <Button
                    className="rounded btn btn-success smalldiv__listheader font-bld"
                    style={{ color: 'white', textAlign: 'center' }}
                    onClick={onGetResult}
                    aria-hidden="true"
                    disabled={requiredfield}
                  >
                    Get Results
                  </Button>
                </div>
              </div>
            </>
          )}
        {isswappage === 'GetReportResult'
          && (
            <>
              <div className="smalldiv__cardsContainer row" style={{ marginBottom: '10px' }}>
                {
                  // eslint-disable-next-line array-callback-return, eqeqeq
                  referanceFields.map((fields) => (
                    <>
                      {(ReportFilter[fields.fieldValue]?.length > 0 && ReportFilter[fields.fieldValue]) ? (
                        <div className="smalldiv__samerow col-md-2">
                          <span>{fields.header}</span>
                          <p style={{ fontWeight: 600 }}>{ReportFilter[fields.fieldValue]}</p>
                          <p>{(ReportFilter.startDate && fields.fieldValue == 'timeLine')
                            ? (
                              <div><p>startDate : {moment(ReportFilter.startDate).format('DD MMM YYYY')}</p>
                                <p>endDate : {moment(ReportFilter.endDate).format('DD MMM YYYY')}</p>
                              </div>
                            )
                            : null}
                          </p>
                        </div>
                      ) : null}
                    </>
                  ))
                }

              </div>
              <div className="row">
                <div className="smalldiv__listheader col-md-12">
                  <h4 style={{ color: 'black' }}>
                    Results
                  </h4>
                  <div
                    className="rounded btn btn-success"
                    aria-hidden="true"
                    onClick={downloadCsv}
                  >
                    Download CSV
                    <DownloadCircleIcon
                      style={{
                        width: '17px', height: '17px', marginLeft: '4px', marginRight: '0px',
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: '12px' }}>
                <div className="smalldiv__listheader col-md-12">
                  <BasicTable
                    tableHeaderData={tableHeaderData}
                    tableRowsData={tableRowsData}
                    viewDetails={addComments}
                    readOnly={!!ReportFilter._id}
                  />
                </div>
              </div>
            </>
          )}
      </form>
      {activeModal !== ''
        && (
          <ModalPop
            saveReportFunc={saveReportFunc}
            activeModalAction={activeModalAction}
            activeModal={activeModal}
            ModalTitle="Save as Template"
          />
        )}
      <Modal
        isOpen={LoadingMask}
        modalClassName="ltr-support"
        className="my-modal"
      >
        <div>
          <div className="spinner-border" style={{ color: '#03a5a5' }}>
            <span className="sr-only">Loading...</span>
          </div>
          <div style={{ color: '#03a5a5' }}>Wait a Minute...</div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  customerlist: state.addToReportCustomer,
});

export default connect(mapStateToProps)(AddOicReport);
