import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
/* eslint no-underscore-dangle: 0 */

const tableHeaderData = [
  { id: 12, title: '' },
  { id: 1, title: 'S.No' },
  { id: 2, title: 'Lens Type' },
  { id: 11, title: 'Lens Material' },
  { id: 3, title: 'Sph' },
  { id: 4, title: 'Cyl' },
  { id: 6, title: 'Axis' },
  { id: 5, title: 'Add' },
  { id: 7, title: 'Ordered QTY' },
  { id: 8, title: 'Stock QTY' },
  { id: 9, title: 'Taken QTY' },
  { id: 10, title: 'Gatepass QTY' },
];

const BasicTable = ({
  tableRowsData = [],
  searchedCoHandler,
  currentViewDetailsData,
  viewDetailsMode,
  viewPossibleCheckouts,
}) => {
  console.log(tableRowsData, currentViewDetailsData);
  console.log('Checkout Summary Table');
  return (
    <div style={{
      height: '300px',
      overflowY: 'auto',
    }}
    >
      <Table hover>
        <thead>
          <tr>
            {tableHeaderData.map((item) => (
              !viewDetailsMode
                ? (
                  <th key={item.id}>{item.title}</th>
                ) : (
                  item.title !== 'Ordered QTY' && item.title !== 'Stock QTY'
                  && item.title !== 'Taken QTY' && item.title !== ''
                  && <th key={item.id}>{item.title}</th>
                )
            ))}
          </tr>
        </thead>
        <tbody>
          {
            !viewDetailsMode
              ? (
                tableRowsData.map((item, index) => (
                  !item.omitted
                  && (

                    viewPossibleCheckouts
                      ? (
                        item.possibleCheckout
                        && (
                          <tr key={item._id} role="presentation">
                            <td>{index + 1}</td>
                            <td>
                              <input
                                name="checkbox"
                                type="checkbox"
                                checked={item.checked}
                                onChange={(e) => searchedCoHandler(e, 'checked', 'checkbox', index)}
                              />
                            </td>
                            <td>{item.LensType || '-'}</td>
                            <td>{item.LensMaterial || '-'}</td>
                            <td>{item.Spherical || '0'}</td>
                            <td>{item.Cylindrical || '0'}</td>
                            <td>{item.Axis || '0'}</td>
                            <td>{item.Add || '0'}</td>
                            <td>{item.Quantity || ''}</td>
                            <td>{item.ActualQuantity !== undefined ? item.ActualQuantity : 0}</td>
                            <td>{item.CheckoutQuantity !== undefined ? item.CheckoutQuantity : 0}</td>
                            <td>
                              <form onSubmit={(e) => e.preventDefault()} className="form">
                                <input
                                  style={item.error ? { borderColor: 'red', width: '40%' } : { width: '40%' }}
                                  onChange={(e) => searchedCoHandler(e, 'CheckoutQuantityLocal', 'textInput', index)}
                                  name="CheckoutQuantityLocal"
                                  component="input"
                                  value={item.CheckoutQuantityLocal || ''}
                                  type="number"
                                />
                              </form>
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr key={item._id} role="presentation">
                          <td>
                            <input
                              name="checkbox"
                              type="checkbox"
                              checked={item.checked}
                              onChange={(e) => searchedCoHandler(e, 'checked', 'checkbox', index)}
                            />
                          </td>
                          <td>{index + 1}</td>
                          <td>{item.LensType || '-'}</td>
                          <td>{item.LensMaterial || '-'}</td>
                          <td>{item.Spherical || '0'}</td>
                          <td>{item.Cylindrical || '0'}</td>
                          <td>{item.Axis || '0'}</td>
                          <td>{item.Add || '0'}</td>
                          <td>{item.Quantity || ''}</td>
                          <td>{item.ActualQuantity !== undefined ? item.ActualQuantity : 0}</td>
                          <td>{item.CheckoutQuantity !== undefined ? item.CheckoutQuantity : 0}</td>
                          <td>
                            <form onSubmit={(e) => e.preventDefault()} className="form">
                              <input
                                style={item.error ? { borderColor: 'red', width: '40%' } : { width: '40%' }}
                                onChange={(e) => searchedCoHandler(e, 'CheckoutQuantityLocal', 'textInput', index)}
                                name="CheckoutQuantityLocal"
                                component="input"
                                value={item.CheckoutQuantityLocal || ''}
                                type="number"
                              />
                            </form>
                          </td>
                        </tr>
                      )
                  )
                ))
              ) : (
                currentViewDetailsData.map((item, index) => (
                  // item.status === 'Processed'
                  // && (
                  <tr key={item._id} role="presentation">
                    <td>{index + 1}</td>
                    <td>{item.LensType}</td>
                    <td>{item.LensMaterial || '-'}</td>
                    <td>{item.Spherical}</td>
                    <td>{item.Cylindrical || ''}</td>
                    <td>{item.Axis || ''}</td>
                    <td>{item.Add || ''}</td>
                    <td>{item.CheckoutQuantity !== undefined ? item.CheckoutQuantity : 0}</td>
                  </tr>
                  // )
                ))
              )
          }
        </tbody>
      </Table>
    </div>
  );
};

BasicTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
  searchedCoHandler: PropTypes.func.isRequired,
  currentViewDetailsData: PropTypes.func.isRequired,
  viewDetailsMode: PropTypes.bool.isRequired,
  viewPossibleCheckouts: PropTypes.bool.isRequired,
};

export default BasicTable;
