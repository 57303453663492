import React from 'react';
import {
  Row,
  Col,
  Button,
  Container,
} from 'reactstrap';
import PropTypes from 'prop-types';
import AdjustmentsTable from './AdjustmentTablesList';

function AdjustmentList(
  {
    activePageHandler,
    adjustmentTableData,
    downloadCsv,
  },
) {
  console.log(adjustmentTableData);
  return (
    <Container>
      <Row>
        <Col md={12} className="smalldiv__listheader">
          <h4>
            Adjustment
          </h4>
          <Button
            className="rounded"
            color="success"
            style={{ marginLeft: '700px' }}
            onClick={
              () => {
                activePageHandler('ADD ADJUSTMENT');
              }
            }
          >
            Add Adjustments
          </Button>
          <Button
            className="rounded"
            color="success"
            onClick={
              () => {
                downloadCsv(adjustmentTableData);
              }
            }
          >
            Download CSV
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ textAlign: 'center' }}>
          {
            adjustmentTableData.isFetching
              ? (
                <div className="spinner-border" style={{ color: '#03a5a5' }}>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <AdjustmentsTable
                  adjustmentTableData={adjustmentTableData.adjustmentsDetails}
                />
              )
          }
        </Col>

      </Row>
    </Container>
  );
}
AdjustmentList.propTypes = {
  adjustmentTableData: PropTypes.arrayOf.isRequired,
  activePageHandler: PropTypes.func.isRequired,
  downloadCsv: PropTypes.func.isRequired,
};
export default AdjustmentList;
