import React from 'react';
import Proptypes from 'prop-types';
import {
  Container,
  Col,
  Row,
  Button,
} from 'reactstrap';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import CheckoutSummary from '../../Tables/BasicTables/components/CheckoutSummary';
import DownloadImage from '../../../shared/img/common/download.png';
import CheckoutGroupSummary from '../../Tables/BasicTables/components/CheckoutGroupSummary';

const AddCheckout = ({
  viewPossibleCheckouts,
  activePageHandler,
  searchedCoHandler,
  checkoutForm,
  searchedCo,
  checkAvailabilityStatus,
  addComments,
  currentViewDetailsData,
  viewDetailsMode,
  searchTerm,
  onSearchHandler,
  checkPossibleCheckouts,
  downloadableCheckouts,
  summary,
}) => {
  console.log(viewDetailsMode, currentViewDetailsData);
  /* eslint no-underscore-dangle: 0 */
  return (
    <Container>
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => activePageHandler('ListCheckout')}
      >
        {' < '}
      </Button>
      <Row>
        <Col md={12}>
          <h4 className="page-title">{!viewDetailsMode ? 'New Gatepass' : 'Gatepass Details'}</h4>
        </Col>
      </Row>
      {
        !viewDetailsMode
          ? (
            <form onSubmit={(e) => e.preventDefault()} className="form">
              <Row className="smalldiv__cardsContainer">
                <Col md={2} className="form-group smalldiv__samerow">
                  <span>CUSTOMER ORDER ID: </span>
                  <p>
                    <b>
                      {searchedCo.orderId}
                      /
                      {moment(searchedCo._created_at).format('DD MMM YYYY')}
                    </b>
                  </p>
                </Col>
                <Col md={2} className="form-group smalldiv__samerow">
                  <span>CUSTOMER NAME: </span>
                  <p><b>{searchedCo.customerName || ''}</b></p>
                </Col>
                <Col md={2} className="form-group smalldiv__samerow">
                  <span>REF. ORDER ID/DATE</span>
                  <p>
                    <b>
                      {searchedCo.customerOrderId}
                      /
                      {moment(searchedCo.customerOrderDate).format('DD MMM YYYY')}
                    </b>
                  </p>
                </Col>
              </Row>
            </form>
          ) : (
            <form onSubmit={(e) => e.preventDefault()} className="form">
              <Row className="smalldiv__cardsContainer">
                <Col md={2} className="form-group smalldiv__samerow">
                  <span>GATEPASS ID/DATE</span>
                  <p>
                    <b>
                      {currentViewDetailsData.checkoutId}
                      /
                      {moment(currentViewDetailsData._created_at).format('DD MMM YYYY')}
                    </b>
                  </p>
                </Col>
                <Col md={2} className="form-group smalldiv__samerow">
                  <span>CUSTOMER ORDER ID: </span>
                  <p>
                    <b>
                      {currentViewDetailsData.sysCustomerOrderId}
                      /
                      {moment(currentViewDetailsData.sysCustomerOrderDate).format('DD MMM YYYY')}
                    </b>
                  </p>
                </Col>
                <Col md={2} className="form-group smalldiv__samerow">
                  <span>CUSTOMER NAME: </span>
                  <p><b>{currentViewDetailsData.customerName || ''}</b></p>
                </Col>
                <Col md={2} className="form-group smalldiv__samerow">
                  <span>REF. ORDER ID/DATE</span>
                  <p>
                    <b>
                      {currentViewDetailsData.customerOrderId}
                      /
                      {moment(currentViewDetailsData.customerOrderDate).format('DD MMM YYYY')}
                    </b>
                  </p>
                </Col>
              </Row>
            </form>
          )
      }
      <div>
        <Row>
          <Col md={12}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
            >
              <h4 className="page-subhead">
                Group Summary
              </h4>
            </div>
          </Col>
        </Row>
        <CheckoutGroupSummary
          summary={summary}
          viewDetailsMode={viewDetailsMode}
        />
      </div>

      {(viewDetailsMode || checkAvailabilityStatus === 'Checked')
        && (
          <>
            <div
              role="presentation"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <h4 className="page-subhead">Product Summary</h4>
              </div>
              {
                !viewDetailsMode && (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {
                      viewPossibleCheckouts
                      && (
                        <CSVLink
                          data={downloadableCheckouts}
                          filename={`${searchedCo.customerName}-${checkoutForm.orderId}.csv`}
                        >
                          <img
                            className="purchaseorder__download"
                            alt="Download"
                            src={DownloadImage}
                          />
                        </CSVLink>
                      )
                    }
                    <form onSubmit={(e) => e.preventDefault()} className="form">
                      <input
                        name="searchterm"
                        value={searchTerm}
                        component="input"
                        id="searchterm"
                        onChange={(e) => onSearchHandler(e)}
                        onKeyDown={(e) => onSearchHandler(e)}
                        type="text"
                        title="Search Format: Sph Cyl Add Axis &#013; Example: 0 0.75 - -"
                        placeholder="Search by prescription..."
                      />
                    </form>
                  </div>
                )
              }

            </div>
            <Row>
              <Col md={12}>
                <CheckoutSummary
                  viewPossibleCheckouts={viewPossibleCheckouts}
                  searchedCoHandler={searchedCoHandler}
                  tableRowsData={searchedCo.inventories}
                  currentViewDetailsData={currentViewDetailsData.inventories}
                  viewDetailsMode={viewDetailsMode}
                />
              </Col>
            </Row>
          </>
        )}

      {!viewDetailsMode
        && (
          <div>
            {(checkAvailabilityStatus === 'Checked' && !viewPossibleCheckouts)
              && (
                <Button
                  style={{ marginTop: '10px' }}
                  className="rounded"
                  color="success"
                  size="sm"
                  onClick={() => checkPossibleCheckouts()}
                >
                  View Gatepass List
                </Button>
              )}
            {(checkAvailabilityStatus === 'Checked' && viewPossibleCheckouts)
              && (
                <Button
                  style={{ marginTop: '10px' }}
                  className="rounded"
                  color="success"
                  size="sm"
                  onClick={() => addComments()}
                >
                  Submit
                </Button>
              )}
          </div>
        )}

    </Container>
  );
};

AddCheckout.propTypes = {
  searchedCoHandler: Proptypes.func.isRequired,
  activePageHandler: Proptypes.func.isRequired,
  checkoutForm: Proptypes.objectOf.isRequired,
  searchedCo: Proptypes.objectOf.isRequired,
  checkAvailabilityStatus: Proptypes.string.isRequired,
  addComments: Proptypes.func.isRequired,
  currentViewDetailsData: Proptypes.objectOf.isRequired,
  viewDetailsMode: Proptypes.bool.isRequired,
  searchTerm: Proptypes.string.isRequired,
  onSearchHandler: Proptypes.func.isRequired,
  checkPossibleCheckouts: Proptypes.func.isRequired,
  viewPossibleCheckouts: Proptypes.bool.isRequired,
  downloadableCheckouts: Proptypes.func.isRequired,
  summary: Proptypes.objectOf.isRequired,
};

export default AddCheckout;
