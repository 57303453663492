import _ from 'lodash';

const customerOrderSummaryGeneration = (inventories) => {
  const inventoriesLocal = _.cloneDeep(inventories);

  const freshInventories = [];
  let totalRequiredVariants = 0;
  let totalRequiredQuantity = 0;
  let totalAvailableQuantity = 0;
  let totalPurchaseQuantity = 0;

  inventoriesLocal.map((inventory, index1) => {
    let exists = false;

    totalRequiredVariants += 1;
    totalRequiredQuantity += inventory.Quantity ? inventory.Quantity : 0;

    freshInventories.map((freshVariant, index) => {
      if ((freshVariant.LensType === inventory.LensType) && (freshVariant.LensMaterial === inventory.LensMaterial)) {
        exists = true;

        if (inventory.AvailableQuantity >= 0) {
          if (inventory.AvailableQuantity < inventory.Quantity) {
            freshInventories[index].purchaseQuantity += Math.abs(inventory.AvailableQuantity - inventory.Quantity);
            freshInventories[index].availableQuantity += inventory.AvailableQuantity;

            totalPurchaseQuantity += Math.abs(inventory.AvailableQuantity - inventory.Quantity);
            totalAvailableQuantity += inventory.AvailableQuantity;
          } else {
            freshInventories[index].availableQuantity += inventory.Quantity;
            totalAvailableQuantity += inventory.Quantity;
          }
        } else {
          freshInventories[index].purchaseQuantity += inventory.Quantity;
          totalPurchaseQuantity += inventory.Quantity;
        }

        freshInventories[index].requiredVariants += 1;
        freshInventories[index].requiredQuantity += inventory.Quantity ? inventory.Quantity : 0;
      }
      return 1;
    });

    if (!exists) {
      const temp = {
        LensType: inventory.LensType,
        LensMaterial: inventory.LensMaterial,
        id: index1,
        requiredVariants: 1,
        requiredQuantity: inventory.Quantity ? inventory.Quantity : 0,
        purchaseQuantity: 0,
        availableQuantity: 0,
      };

      if (inventory.AvailableQuantity >= 0) {
        if (inventory.AvailableQuantity < inventory.Quantity) {
          temp.purchaseQuantity += Math.abs(inventory.AvailableQuantity - inventory.Quantity);
          temp.availableQuantity += inventory.AvailableQuantity;
        } else {
          temp.availableQuantity += inventory.Quantity;
        }
      } else {
        temp.purchaseQuantity += inventory.Quantity;
      }

      totalPurchaseQuantity += temp.purchaseQuantity;
      totalAvailableQuantity += temp.availableQuantity;

      freshInventories.push(temp);
    }
    exists = false;

    return 1;
  });

  const summaryLocal = {
    inventories: freshInventories,
    totalRequiredQuantity,
    totalRequiredVariants,
    totalAvailableQuantity,
    totalPurchaseQuantity,
  };

  return summaryLocal;
};

const checkoutSummaryGeneration = (inventories) => {
  const inventoriesLocal = _.cloneDeep(inventories);

  const freshInventories = [];
  let totalRequiredVariants = 0;
  let totalRequiredQuantity = 0;
  let totalCheckoutQuantity = 0;
  let totalCheckoutVariants = 0;

  inventoriesLocal.map((inventory, index1) => {
    let exists = false;

    if (inventory.CheckoutQuantity < inventory.Quantity) {
      totalRequiredVariants += 1;
      totalRequiredQuantity += (inventory.Quantity - inventory.CheckoutQuantity);
    }
    if (inventory.CheckoutQuantity) {
      totalCheckoutVariants += 1;
      totalCheckoutQuantity += inventory.CheckoutQuantity;
    }

    freshInventories.map((freshVariant, index) => {
      if ((freshVariant.LensType === inventory.LensType) && (freshVariant.LensMaterial === inventory.LensMaterial)) {
        exists = true;

        if (inventory.CheckoutQuantity < inventory.Quantity) {
          freshInventories[index].requiredVariants += 1;
          freshInventories[index].requiredQuantity += inventory.Quantity;
        }
        if (inventory.CheckoutQuantity) {
          freshInventories[index].totalCheckoutQuantity += inventory.CheckoutQuantity;
          freshInventories[index].totalCheckoutVariants += 1;
        }
      }
      return 1;
    });

    if (!exists) {
      const temp = {
        LensType: inventory.LensType,
        LensMaterial: inventory.LensMaterial,
        id: index1,
      };

      if (inventory.CheckoutQuantity < inventory.Quantity) {
        temp.requiredVariants = 1;
        temp.requiredQuantity = (inventory.Quantity - inventory.CheckoutQuantity);
      } else {
        temp.requiredVariants = 0;
        temp.requiredQuantity = 0;
      }

      if (inventory.CheckoutQuantity) {
        temp.totalCheckoutQuantity = inventory.CheckoutQuantity;
        temp.totalCheckoutVariants = 1;
      } else {
        temp.totalCheckoutQuantity = 0;
        temp.totalCheckoutVariants = 0;
      }
      freshInventories.push(temp);
    }
    exists = false;

    return 1;
  });

  const summaryLocal = {
    inventories: freshInventories,
    totalRequiredQuantity,
    totalRequiredVariants,
    totalCheckoutVariants,
    totalCheckoutQuantity,
  };
  console.log(summaryLocal);

  return summaryLocal;
};

export {
  customerOrderSummaryGeneration,
  checkoutSummaryGeneration,
};
