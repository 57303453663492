import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Table,
} from 'reactstrap';

const tableHeaderData = [
  { id: 1, title: 'S.NO' },
  { id: 2, title: 'Lens Type' },
  { id: 3, title: 'Lens Material' },
  { id: 4, title: 'Required Variants' },
  { id: 5, title: 'Required Quantity' },
];

const BasicTable = ({
  currentViewDetailsData = {},
  viewDetailsMode,
  viewSpecificDetails,
}) => {
  const currentViewDetailsDataLocal = _.cloneDeep(currentViewDetailsData);
  let grandTotalQuantity = 0;
  let grandTotalOrderVariants = 0;

  if (viewDetailsMode) {
    currentViewDetailsDataLocal.groups.map((group, index) => {
      group.variants.map((variant) => {
        currentViewDetailsDataLocal.groups[index].totalOrderVariants = group.totalOrderVariants
          ? group.totalOrderVariants + 1 : 1;

        if (variant.Quantity) {
          if (group.totalCount === undefined) {
            currentViewDetailsDataLocal.groups[index].totalCount = variant.Quantity - variant.ReceivedQuantity;
          } else if (variant.Quantity === undefined) {
            currentViewDetailsDataLocal.groups[index].totalCount = group.totalCount;
          } else {
            currentViewDetailsDataLocal.groups[index].totalCount = group.totalCount
              + variant.Quantity - variant.ReceivedQuantity;
          }
        }
        grandTotalQuantity += (variant.Quantity - variant.ReceivedQuantity);
        grandTotalOrderVariants += 1;
        return 1;
      });
      return 1;
    });
  }

  /* eslint no-underscore-dangle: 0 */
  return (
    <Table responsive hover>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {
          currentViewDetailsDataLocal.groups.map((group, index) => (
            <>
              <tr role="presentation">
                <td>{index + 1}</td>
                <td>{group.LensType || '-'}</td>
                <td>{group.LensMaterial || '-'}</td>
                <td>{group.totalOrderVariants || '-'}</td>
                <td>{group.totalCount || 0}</td>
                <td
                  role="presentation"
                  style={{ color: '#03a5a5' }}
                  onClick={() => viewSpecificDetails(index)}
                >
                  View Details
                </td>
              </tr>
              {((currentViewDetailsDataLocal.groups.length - 1) === index)
                && (
                  <tr role="presentation">
                    <td />
                    <td />
                    <td><b>Grand Total:</b></td>
                    <td><b>{grandTotalOrderVariants}</b></td>
                    <td><b>{grandTotalQuantity}</b></td>
                  </tr>
                )}
            </>
          ))
        }
      </tbody>
    </Table>
  );
};

BasicTable.propTypes = {
  currentViewDetailsData: PropTypes.objectOf.isRequired,
  viewDetailsMode: PropTypes.bool.isRequired,
  viewSpecificDetails: PropTypes.func.isRequired,
};

export default BasicTable;
