import { createAction } from 'redux-actions';
import api from '../../utils/apiClient';

export const fetchMasterDataRequest = createAction('FETCH_MASTER_DATA_REQUEST');
export const fetchMasterDataSuccess = createAction('FETCH_MASTER_DATA_SUCCESS');
export const fetchMasterDataFailure = createAction('FETCH_MASTER_DATA_FAILURE');

export const fetchMaster = () => async (dispatch) => {
  try {
    dispatch(fetchMasterDataRequest());
    const response = await api.master.getMetav1();
    dispatch(fetchMasterDataSuccess(response.data.data));
  } catch (e) {
    dispatch(fetchMasterDataFailure(e));
  }
};
