import React from 'react';
import {
  Row,
  Col,
  Button,
  Container,
} from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';

function AddAdjustment(
  {
    lensPowers,
    metaV1Options,
    inputChangeHandler,
    adjustmentForm,
    activePageHandler,
    commitAdjustment,
    reasonOptions,
    adjustmentSave,
    currentFormIndex,
  },
) {
  console.log('lensPowers', lensPowers);
  console.log(adjustmentForm);
  return (
    <Container>
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => activePageHandler('ADJUSTMENT LIST')}
      >
        {' < '}
      </Button>
      <Row>
        <Col md={12}>
          <h4 className="page-title">Add Adjustment</h4>
        </Col>
      </Row>
      <form onSubmit={(e) => e.preventDefault()} className="form">
        <Row style={{ marginRight: '0px', marginBottom: '30px' }}>
          <Col md={3}>
            <label htmlFor="adjustmentDate">ADJUSTMENT DATE</label>
            <input
              name="adjustmentDate"
              // value={cu.adjustmentDate}
              component="input"
              id="adjustmentDate"
              onChange={(e) => inputChangeHandler(e, 'adjustmentDate', 'textInput')}
              type="date"
            />
          </Col>
        </Row>
        {/* )} */}
        <Row>
          <Col md={12}>
            <h4 className="page-subhead">Prescription Details</h4>
          </Col>
        </Row>
        <Row style={{ marginRight: '0px', marginBottom: '30px' }}>

          <Col md={12} lg={2}>
            <label htmlFor="lensType">TYPE *</label>
            <Select
              name="Type"
              id="Type"
              onChange={(e) => inputChangeHandler(e, 'Type', 'select')}
              clearable={false}
              className="react-select"
              placeholder="Select"
              classNamePrefix="react-select"
              options={metaV1Options.Type}
            />
          </Col>
          <Col md={12} lg={2}>
            <label htmlFor="lensType">LENS TYPE *</label>
            <Select
              name="lensType"
              id="lensType"
              onChange={(e) => inputChangeHandler(e, 'LensType', 'select')}
              clearable={false}
              className="react-select"
              placeholder="Select"
              classNamePrefix="react-select"
              options={metaV1Options.LensType}
            />
          </Col>
          <Col md={12} lg={2}>
            <label htmlFor="lensMaterial">LENS MATERIAL *</label>
            <Select
              name="lensMaterial"
              id="lensMaterial"
              onChange={(e) => inputChangeHandler(e, 'LensMaterial', 'select')}
              clearable={false}
              className="react-select"
              placeholder="Select"
              classNamePrefix="react-select"
              options={metaV1Options.LensMaterial}
            />
          </Col>
          <Col md={12} lg={2}>
            <label htmlFor="model">MODEL</label>
            <input
              name="model"
              // value={invetoryForm.Quantity}
              type="text"
              id="model"
              onChange={(e) => inputChangeHandler(e, 'Model', 'textInput')}
              component="input"
              placeholder="type here..."
            />
          </Col>
        </Row>
        <Row style={{ marginRight: '0px', marginBottom: '30px' }}>
          <Col md={6} lg={2}>
            <label htmlFor="model">SPH</label>
            <Select
              name="sph"
              // value={invetoryForm.Spherical}
              id="sph"
              onChange={(e) => inputChangeHandler(e, 'Spherical', 'select')}
              clearable={false}
              className="react-select"
              placeholder="Select"
              classNamePrefix="react-select"
              options={lensPowers.spherical}
            // defaultValue={metaV1Options.LensCoating[1]}
            />
          </Col>
          <Col md={6} lg={2}>
            <label htmlFor="model">CYL</label>
            <Select
              name="cyl"
              // value={invetoryForm.Cylindrical}
              id="cyl"
              onChange={(e) => inputChangeHandler(e, 'Cylindrical', 'select')}
              clearable={false}
              className="react-select"
              placeholder="Select"
              classNamePrefix="react-select"
              options={lensPowers.cylindrical}
            // defaultValue={metaV1Options.LensCoating[1]}
            />
          </Col>
          <Col md={6} lg={2}>
            <label htmlFor="add">ADD</label>
            <Select
              name="add"
              // value={invetoryForm.Add}
              id="add"
              onChange={(e) => inputChangeHandler(e, 'Add', 'select')}
              clearable={false}
              className="react-select"
              placeholder="Select"
              classNamePrefix="react-select"
              options={lensPowers.add}
            />
          </Col>
          <Col md={6} lg={2}>
            <label htmlFor="axis">AXIS</label>
            <Select
              name="axis"
              // value={invetoryForm.Axis}
              id="axis"
              onChange={(e) => inputChangeHandler(e, 'Axis', 'select')}
              clearable={false}
              className="react-select"
              placeholder="Select"
              classNamePrefix="react-select"
              options={lensPowers.axis}
            />
          </Col>
          <Col md={6} lg={2}>
            <label htmlFor="quantity">QTY *</label>
            <input
              name="quantity"
              value={
                adjustmentForm.inventories[currentFormIndex] ? adjustmentForm.inventories[currentFormIndex].Quantity : 0
              }
              type="number"
              id="quantity"
              onChange={(e) => inputChangeHandler(e, 'Quantity', 'textInput')}
              component="input"
              placeholder="type here..."
            />
          </Col>
        </Row>
        <Row style={{ marginRight: '0px', marginBottom: '30px' }}>
          <Col md={12} lg={4}>
            <label htmlFor="reason">REASON</label>
            <Select
              name="reason"
              id="reason"
              options={reasonOptions}
              onChange={(e) => inputChangeHandler(e, 'Reason', 'select')}
              clearable={false}
              className="react-select"
              placeholder="Select"
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={12} lg={4}>
            <label htmlFor="comment">COMMENTS</label>
            <input
              name="comment"
              // value={invetoryForm.Comments}
              id="comment"
              type="text"
              onChange={(e) => inputChangeHandler(e, 'Comments', 'textInput')}
              placeholder="type here..."
              component="input"
            // options={metaV1Options.LensCoating}
            // defaultValue={metaV1Options.LensCoating[1]}
            />
          </Col>
        </Row>
      </form>
      <Row style={{ marginRight: '0px', marginBottom: '30px' }}>
        <Button
          className="rounded"
          color="success"
          size="sm"
          disabled={adjustmentSave.isSaving}
          onClick={() => commitAdjustment()}
        >
          Submit
        </Button>
      </Row>
    </Container>
  );
}
AddAdjustment.propTypes = {
  lensPowers: PropTypes.objectOf.isRequired,
  metaV1Options: PropTypes.objectOf.isRequired,
  inputChangeHandler: PropTypes.func.isRequired,
  adjustmentForm: PropTypes.objectOf.isRequired,
  activePageHandler: PropTypes.func.isRequired,
  commitAdjustment: PropTypes.func.isRequired,
  reasonOptions: PropTypes.arrayOf.isRequired,
  adjustmentSave: PropTypes.objectOf.isRequired,
  currentFormIndex: PropTypes.number.isRequired,
};
export default AddAdjustment;
