import { handleActions } from 'redux-actions';
import _ from 'lodash';
import {
  saveGrnRequest,
  saveGrnSuccess,
  saveGrnFailure,
  defaultGrn,
  addToGrn,
  fetchGrnRequest,
  fetchGrnSuccess,
  fetchGrnFailure,
  updateGrnRequest,
  updateGrnSuccess,
  updateGrnFailure,
  defaultUpdateGrn,
  updateToGrn,
  fetchPendingGrnRequest,
  fetchPendingGrnSuccess,
  fetchPendingGrnFailure,
  saveNewPoGrnRequest,
  saveNewPoGrnSuccess,
  saveNewPoGrnFailure,
  defaultSaveNewPoGrn,
  updateToPendingGrn,
  deletePendingGrn,
  cancelPendingOrderFailure,
  cancelPendingOrderRequest,
  cancelPendingOrderSuccess,
  defaultCancelPendingOrder,
} from '../actions/grnActions';

const defaultState = {
  isSaving: false,
  error: null,
  grnDetails: {},
};

const defaultStateFetch = {
  isfetching: false,
  error: null,
  grnDetails: [],
  limit: 500,
  skip: 0,
};

const defaultNewPoGrnState = {
  isSaving: false,
  error: null,
  purchaseOrderDetails: {},
};

const defaultStateFetchPending = {
  isfetching: false,
  error: null,
  grnDetails: [],
  limit: 500,
  skip: 0,
};

const defaultUpdateState = {
  isUpdating: false,
  error: null,
  updatedGrnDetails: {},
};

const defaultcancelPendingOrderState = {
  isDeleting: false,
  error: null,
  grnDetails: {},
};

export const cancelPendingOrderReducer = handleActions(
  {
    [cancelPendingOrderRequest](state) {
      console.log(state);
      console.log('request');
      return {
        ...state,
        isDeleting: true,
        error: null,
      };
    },
    [cancelPendingOrderSuccess](state, { payload }) {
      console.log('success');
      return {
        ...state,
        grnDetails: payload,
        isDeleting: false,
        error: null,
      };
    },
    [defaultCancelPendingOrder](state) {
      console.log('default');
      return {
        ...state,
        grnDetails: {},
        error: null,
        isDeleting: false,
      };
    },
    [cancelPendingOrderFailure](state, { payload }) {
      console.log('failed');
      return {
        ...state,
        error: payload,
        isDeleting: false,
      };
    },
  },
  defaultcancelPendingOrderState,
);

export const grnSaveReducer = handleActions(
  {
    [saveGrnRequest](state) {
      console.log(state);
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    },
    [saveGrnSuccess](state, { payload }) {
      return {
        ...state,
        grnDetails: payload,
        isSaving: false,
        error: null,
      };
    },
    [defaultGrn](state) {
      return {
        ...state,
        grnDetails: {},
        error: null,
        isSaving: false,
      };
    },
    [saveGrnFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isSaving: false,
      };
    },
  },
  defaultState,
);

export const saveNewPoGrnReducer = handleActions(
  {
    [saveNewPoGrnRequest](state) {
      console.log(state);
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    },
    [saveNewPoGrnSuccess](state, { payload }) {
      return {
        ...state,
        purchaseOrderDetails: payload,
        isSaving: false,
        error: null,
      };
    },
    [defaultSaveNewPoGrn](state) {
      return {
        ...state,
        purchaseOrderDetails: {},
        error: null,
        isSaving: false,
      };
    },
    [saveNewPoGrnFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isSaving: false,
      };
    },
  },
  defaultNewPoGrnState,
);

export const grnFetchReducer = handleActions(
  {
    [fetchGrnRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchGrnSuccess](state, { payload }) {
      return {
        ...state,
        grnDetails: payload,
        limit: state.limit,
        skip: state.skip,
        isFetching: false,
        error: null,
      };
    },
    [fetchGrnFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    },
    [addToGrn](state, { payload }) {
      return {
        ...state,
        grnDetails: [payload, ...state.grnDetails],
      };
    },

    /* eslint no-underscore-dangle: 0 */

    [updateToGrn](state, { payload }) {
      const grnDetailsLocal = _.cloneDeep(state.grnDetails);
      const foundIndex = grnDetailsLocal.findIndex((po) => po._id === payload._id);

      grnDetailsLocal[foundIndex] = payload;

      console.log(grnDetailsLocal);
      return {
        ...state,
        grnDetails: grnDetailsLocal,
      };
    },
  },
  defaultStateFetch,
);

export const grnFetchPendingReducer = handleActions(
  {
    [fetchPendingGrnRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchPendingGrnSuccess](state, { payload }) {
      return {
        ...state,
        grnDetails: payload,
        limit: state.limit,
        skip: state.skip,
        isFetching: false,
        error: null,
      };
    },
    [fetchPendingGrnFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    },

    [updateToPendingGrn](state, { payload }) {
      const grnDetailsLocal = _.cloneDeep(state.grnDetails);
      const foundIndex = grnDetailsLocal.findIndex((po) => po._id === payload._id);

      grnDetailsLocal[foundIndex] = payload;

      console.log(grnDetailsLocal);
      return {
        ...state,
        grnDetails: grnDetailsLocal,
      };
    },

    [deletePendingGrn](state, { payload }) {
      const grnDetailsLocal = _.cloneDeep(state.grnDetails);
      const foundIndex = grnDetailsLocal.findIndex((po) => po._id === payload._id);

      delete grnDetailsLocal[foundIndex];

      return {
        ...state,
        grnDetails: grnDetailsLocal,
      };
    },
  },
  defaultStateFetchPending,
);

export const grnUpdateReducer = handleActions(
  {
    [updateGrnRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [updateGrnSuccess](state, { payload }) {
      return {
        ...state,
        updatedGrnDetails: payload,
        isUpdating: false,
        error: null,
      };
    },
    [defaultUpdateGrn](state) {
      return {
        ...state,
        updatedGrnDetails: {},
        isUpdating: false,
        error: null,
      };
    },
    [updateGrnFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isUpdating: false,
      };
    },
  },
  defaultUpdateState,
);
