import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';

const BasicTable = ({
  currentViewDetailsData = {},
  updateVariants,
  groupIndex,
}) => {
  console.log(currentViewDetailsData);
  console.log(currentViewDetailsData.groups[groupIndex].variants);
  currentViewDetailsData.groups[groupIndex].variants.sort((oldd, newd) => {
    const Axis = oldd.Axis - newd.Axis;
    const Add = oldd.Add - newd.Add;
    const Cylindrical = oldd.Cylindrical - newd.Cylindrical;
    const Spherical = oldd.Spherical - newd.Spherical;
    const Quantity = oldd.Quantity - newd.Quantity;
    return Spherical || Cylindrical || Axis || Add || Quantity;
  });

  let fullCheck = 0;
  currentViewDetailsData.groups[groupIndex].variants.map((variant) => {
    if (variant.checked) {
      fullCheck += 1;
    }
    return 1;
  });
  const tableHeaderData = [
    {
      id: 0,
      title: <input
        name="checkbox"
        type="checkbox"
        checked={fullCheck === currentViewDetailsData.groups[groupIndex].variants.length}
        onChange={(e) => updateVariants(e, 'All')}
      />,
    },
    { id: 1, title: 'S.No' },
    { id: 2, title: 'Spherical' },
    { id: 3, title: 'Cylindrical' },
    { id: 5, title: 'Axis' },
    { id: 4, title: 'Add' },
    { id: 6, title: 'Required Quantity' },
  ];

  return (
    <div style={{
      height: '80vh',
      overflowY: 'auto',
    }}
    >
      <Table hover>
        <thead>
          <tr>
            {tableHeaderData.map((item) => (
              <th key={item.id}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentViewDetailsData.groups[groupIndex].variants.map((item, index) => (
            /* eslint no-underscore-dangle: 0 */
            (!item.omitted && item.Status !== 'Processed')
            && (
              <tr key={item._id} role="presentation">
                <td>
                  <input
                    name="checkbox"
                    type="checkbox"
                    className="checkbox-btn"
                    checked={item.checked}
                    onChange={(e) => updateVariants(e, index)}
                  />
                </td>
                <td>{index + 1}</td>
                <td>{item.Spherical}</td>
                <td>{item.Cylindrical}</td>
                <td>{item.Axis}</td>
                <td>{item.Add}</td>
                <td>{item.Quantity - item.ReceivedQuantity}</td>
              </tr>
            )
          ))}
        </tbody>
      </Table>
    </div>
  );
};

BasicTable.propTypes = {
  currentViewDetailsData: PropTypes.objectOf.isRequired,
  updateVariants: PropTypes.func.isRequired,
  groupIndex: PropTypes.number.isRequired,
};

export default BasicTable;
