/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable import/named */
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  BootstrapInput, useStyles,
  MenuProps,
} from './utils';
import { addReportCustomer } from '../../../redux/actions/oicReportAction';

// eslint-disable-next-line react/prop-types
function MultiDropDown({
  options = [], multiSelectValue, customerlist, disabled,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // console.log('customerlist', customerlist);
  // const [selected, setSelected] = useState(options);
  // eslint-disable-next-line react/prop-types
  const isAllSelected = options.length > 0 && customerlist.customerDetails.length === options.length;
  const handleChange = async (event) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      // eslint-disable-next-line no-param-reassign
      const customername = customerlist.customerDetails.length === options.length ? [] : options;
      dispatch(addReportCustomer(customername));
      multiSelectValue(customername);
      return;
    }
    // setSelected(value);
    dispatch(addReportCustomer(value));
    multiSelectValue(value);
  };
  return (

    <Select
      labelId="mutiple-select-label"
      multiple
      value={customerlist.customerDetails}
      onChange={handleChange}
      // eslint-disable-next-line no-shadow
      renderValue={(selected) => selected.join(', ')}
      input={<BootstrapInput />}
      MenuProps={MenuProps}
      style={{
        display: 'flex',
      }}
      disabled={disabled}
    >
      <MenuItem
        value="all"
        classes={{
          root: isAllSelected ? classes.selectedAll : '',
        }}
      >
        <ListItemIcon>
          <Checkbox
            checked={isAllSelected}
            indeterminate={
              customerlist.customerDetails.length > 0 && customerlist.customerDetails.length < options.length
            }
            style={{
              color: isAllSelected ? '#03a5a5' : '',
            }}
          />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.selectAllText }}
          primary="Select All"
          style={{ fontSize: '13px' }}
        />
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          <ListItemIcon>
            <Checkbox
              checked={customerlist.customerDetails.indexOf(option) > -1}
              style={{ color: customerlist.customerDetails.indexOf(option) > -1 ? '#03a5a5' : '' }}
            />
          </ListItemIcon>
          <ListItemText
            primary={option}
            classes={{ primary: classes.selectText }}
          />
        </MenuItem>
      ))}
    </Select>
  );
}

MultiDropDown.propTypes = {
  customerlist: PropTypes.objectOf.isRequired,
};
const mapStateToProps = (state) => ({
  customerlist: state.addToReportCustomer,
});

export default connect(mapStateToProps)(MultiDropDown);
