/* eslint-disable no-plusplus */
/* eslint-disable react/no-this-in-sfc */
import React, { useState, useEffect } from 'react';
import {
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { write, utils } from 'xlsx';
import AdjustmentList from './components/AdjustmentLists';
import AddAdjustment from './components/AddAdjustment';
import AdjustmentSummary from './components/AdjustmentSummary';
import lensPower from '../../utils/commonFunctions/lensPower';
import { fetchMaster } from '../../redux/actions/masterActions';
import { saveAdjustments, fetchAdjustments } from '../../redux/actions/purchaseAdjustmentsActions';
import Modal from '../../shared/components/modal/Modal';
import { fetchReason } from '../../redux/actions/reasonActions';

function AdjustmentsInventory({
  fetchMastersAction,
  master,
  saveAdjustmentsAction,
  fetchAdjustmentsAction,
  adjustmentSave,
  adjustmentData,
  fetchReasonAction,
  reasonDataState,
}) {
  const [activePage, setActivePage] = useState('ADJUSTMENT LIST');
  const [lensPowers, setLensPowers] = useState({});
  const [metaV1Options, setMetaV1Options] = useState({});
  const [adjustmentForm, setAdjustmentForm] = useState({ inventories: [] });
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [totalAdjustmentQuantity, setTotalAdjustmentQuantity] = useState(0);
  const [reasonOptions, setReasonOptions] = useState([]);
  const [activeModal, setActiveModal] = useState('');

  useEffect(() => {
    console.log(reasonDataState);
    if (reasonDataState.reasonDetails) {
      const reasonLocal = [];
      reasonDataState.reasonDetails.map((reason) => {
        if (reason.type === 'adjustments') {
          reasonLocal.push({
            label: reason.title,
            value: reason.title,
          });
        }
        return 1;
      });
      setReasonOptions(reasonLocal);
    }
    return 1;
  }, [reasonDataState]);

  useEffect(() => {
    fetchAdjustmentsAction(adjustmentData);
    const lensPowerHolder = { ...lensPowers, ...lensPower() };
    setLensPowers(lensPowerHolder);
    if (
      !master.error
      && master.masterDetails
      && Object.keys(master.masterDetails).length === 0
    ) {
      fetchMastersAction();
    }
    if (
      !reasonDataState.error
      && reasonDataState.reasonDetails
      && reasonDataState.reasonDetails.length === 0
    ) {
      fetchReasonAction(reasonDataState);
    }
  }, []);

  const inputChangeHandler = (e, fieldName, type) => {
    let fieldValue;

    console.log(fieldName);

    const adjustmentFormLocal = _.cloneDeep(adjustmentForm);
    if (fieldName === 'adjustmentDate') {
      fieldValue = e.target.value;
      adjustmentFormLocal[fieldName] = fieldValue;
      setAdjustmentForm(adjustmentFormLocal);
      return 1;
    }

    if (type === 'textInput') {
      fieldValue = e.target.value;

      if (fieldName === 'Quantity' && new RegExp('^\\d+$').test(fieldValue) === false) {
        return false;
      }

      if (!adjustmentFormLocal.inventories[currentFormIndex]) {
        adjustmentFormLocal.inventories.push({});
      }

      adjustmentFormLocal.inventories[currentFormIndex][fieldName] = fieldValue;
    }

    if (type === 'select') {
      fieldValue = e;
      if (!adjustmentFormLocal.inventories[currentFormIndex]) {
        adjustmentFormLocal.inventories.push({});
      }
      adjustmentFormLocal.inventories[currentFormIndex][fieldName] = fieldValue;
    }

    setAdjustmentForm(adjustmentFormLocal);
    return 1;
  };

  const deleteAdjustmentVariant = (index) => {
    const adjustmentFormLocal = _.cloneDeep(adjustmentForm);
    adjustmentFormLocal.inventories.splice(index, 1);
    setCurrentFormIndex(currentFormIndex - 1);
    setAdjustmentForm(adjustmentFormLocal);
  };

  useEffect(() => {
    console.log('saveAdjustment', adjustmentSave);
    if (!adjustmentSave.error
      && !adjustmentSave.isSaving
      && adjustmentSave.adjustmentsDetails
      && adjustmentSave.adjustmentsDetails.message) {
      if (adjustmentSave.adjustmentsDetails.message === 'success') {
        setActiveModal('AdjustmentSuccess');
      } else {
        toast.error('Unexpected Error:(');
      }
    }
  }, [adjustmentSave]);

  /* eslint-disable no-param-reassign */
  const saveNewAdjustments = () => {
    const adjustmentFormLocal = _.cloneDeep(adjustmentForm);

    if (adjustmentFormLocal.inventories.length === 0) {
      toast.error('Please choose atleast 1 inventory!');
      return 1;
    }

    const finalAdjustmentForm = {
      adjustmentDate: adjustmentFormLocal.adjustmentDate || new Date(),
      inventories: [],
    };

    let totalAdjustmentQuantityLocal = 0;

    adjustmentFormLocal.inventories.map((inventory) => {
      totalAdjustmentQuantityLocal += Number(inventory.Quantity);
      const tempInventory = inventory;
      tempInventory.LensType = inventory.LensType ? inventory.LensType.value : '';
      tempInventory.LensMaterial = inventory.LensMaterial ? inventory.LensMaterial.value : '';
      tempInventory.Add = inventory.Add ? inventory.Add.value : '';
      tempInventory.Axis = inventory.Axis ? inventory.Axis.value : '';
      tempInventory.Cylindrical = inventory.Cylindrical ? inventory.Cylindrical.value : '';
      tempInventory.Spherical = inventory.Spherical ? inventory.Spherical.value : '';
      tempInventory.Category = 'LENS';
      tempInventory.Comments = inventory.Comments || '';
      tempInventory.Reason = inventory.Reason ? inventory.Reason.value : '';
      if (inventory.Type.value === 'OUTGOING') {
        inventory.Quantity = -Number(inventory.Quantity);
      }
      if (inventory.Type.value === 'INCOMING') {
        inventory.Quantity = Number(inventory.Quantity);
      }
      tempInventory.ItemCode = '';
      if (tempInventory.Spherical) {
        tempInventory.ItemCode += `${tempInventory.Spherical} `;
      } else {
        tempInventory.ItemCode += '0 ';
      }

      if (tempInventory.Cylindrical) {
        tempInventory.ItemCode += `${tempInventory.Cylindrical} `;
      } else {
        tempInventory.ItemCode += '0 ';
      }

      if (tempInventory.Add) {
        tempInventory.ItemCode += `${tempInventory.Add} `;
      } else {
        tempInventory.ItemCode += '0 ';
      }

      if (tempInventory.Axis) {
        tempInventory.ItemCode += `${tempInventory.Axis}`;
      } else {
        tempInventory.ItemCode += '0';
      }

      tempInventory.ProductName = '';
      tempInventory.ProductName += `${tempInventory.LensType} `;
      tempInventory.ProductName += `${tempInventory.LensMaterial} `;
      tempInventory.ProductName += `${tempInventory.ItemCode} `;
      tempInventory.ProductName += 'LENS';

      finalAdjustmentForm.inventories.push(tempInventory);
      return 1;
    });

    console.log(finalAdjustmentForm);

    setTotalAdjustmentQuantity(totalAdjustmentQuantityLocal);
    saveAdjustmentsAction(finalAdjustmentForm);

    return 1;
  };

  useEffect(() => {
    console.log(master);
    if (
      !master.error
      && master.masterDetails
      && Object.keys(master.masterDetails).length > 0
    ) {
      const lensTypeList = [];
      const lensMaterialList = [];
      const type = [{
        label: 'INCOMING',
        value: 'INCOMING',
      }, {
        label: 'OUTGOING',
        value: 'OUTGOING',
      }];

      master.masterDetails.LLensType.map((lensType) => {
        lensTypeList.push({
          label: lensType,
          value: lensType,
        });
        return 1;
      });

      master.masterDetails.LLensMaterial.map((lensMaterial) => {
        lensMaterialList.push({
          label: lensMaterial,
          value: lensMaterial,
        });
        return 1;
      });

      setMetaV1Options({
        LensType: lensTypeList,
        LensMaterial: lensMaterialList,
        Type: type,
      });
    }
  }, [master]);

  const validateForm = () => {
    if (adjustmentForm.inventories[currentFormIndex]) {
      if (!adjustmentForm.inventories[currentFormIndex].LensType
        || !adjustmentForm.inventories[currentFormIndex].Type
        || !adjustmentForm.inventories[currentFormIndex].LensMaterial
        || !adjustmentForm.inventories[currentFormIndex].Quantity) {
        return false;
      }
    } else {
      return false;
    }
    return true;
  };

  const activePageHandler = (pageName) => {
    if (pageName === 'ADJUSTMENT LIST') {
      setCurrentFormIndex(0);
      setAdjustmentForm({ inventories: [] });
    }
    setActivePage(pageName);
    return 1;
  };

  const activeModalAction = (action, close) => {
    if (action === 'ADJUSTMENT LIST') {
      setActiveModal('');
      activePageHandler(action);
      return 1;
    }

    if (close) {
      setActiveModal('');
    } else {
      setActiveModal(action);
    }
    return 1;
  };

  const addNewAdjustment = () => {
    setCurrentFormIndex(currentFormIndex + 1);
    activePageHandler('ADD ADJUSTMENT');
  };

  const commitAdjustment = () => {
    const message = validateForm();
    if (!message) {
      toast.error('Please fill all the mandatory fields!');
      return 1;
    }
    activePageHandler('ADJUSTMENT SUMMARY');
    return 1;
  };

  function Workbook() {
    if (!(this instanceof Workbook)) { return new Workbook(); }

    this.SheetNames = [];

    this.Sheets = {};
  }
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    // eslint-disable-next-line no-bitwise
    for (let i = 0; i !== s.length; ++i) { view[i] = s.charCodeAt(i) & 0xFF; }

    return buf;
  }
  const convertTableData = () => {
    const getReportData = [];
    adjustmentData.adjustmentsDetails.forEach((exportfile) => {
      const exportdetails = {};
      const convertedDate = new Date(exportfile.adjustmentDate).toLocaleDateString('US').replaceAll('-', '-');
      exportdetails.Date = convertedDate;
      exportdetails.LensType = exportfile.inventories[0].LensType;
      exportdetails.Spherical = exportfile.inventories[0].Spherical;
      exportdetails.Cylindrical = exportfile.inventories[0].Cylindrical;
      exportdetails.Axis = exportfile.inventories[0].Axis;
      exportdetails.Add = exportfile.inventories[0].Add;
      exportdetails.Quantity = exportfile.inventories[0].Quantity;
      exportdetails.Category = exportfile.inventories[0].Category;
      exportdetails.Brand = exportfile.inventories[0].Brand;
      exportdetails.Model = exportfile.inventories[0].Model;
      exportdetails.ProductName = exportfile.inventories[0].ProductName;
      exportdetails.ItemCode = exportfile.inventories[0].ItemCode;
      exportdetails.Reason = exportfile.inventories[0].Reason;
      exportdetails.Comments = exportfile.inventories[0].Comments;
      getReportData.push({ ...exportdetails });
    });
    return getReportData;
  };
  const downloadFile = (url, file) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = file;
    a.click();

    window.URL.revokeObjectURL(url);
  };
  const downloadCsv = async () => {
    const wb = new Workbook();
    const exportdata = await convertTableData();
    const ws = utils.json_to_sheet(exportdata);

    wb.SheetNames.push('');
    wb.Sheets[''] = ws;

    const wbout = write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

    const url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }));

    downloadFile(url, 'import.csv');
  };

  return (
    <div className="adjustments">
      {activePage === 'ADJUSTMENT LIST' && (
        <AdjustmentList
          adjustmentTableData={adjustmentData}
          activePageHandler={activePageHandler}
          downloadCsv={downloadCsv}
        />
      )}
      {activePage === 'ADD ADJUSTMENT' && (
        <AddAdjustment
          adjustmentSave={adjustmentSave}
          currentFormIndex={currentFormIndex}
          commitAdjustment={commitAdjustment}
          adjustmentForm={adjustmentForm}
          lensPowers={lensPowers}
          metaV1Options={metaV1Options}
          inputChangeHandler={inputChangeHandler}
          activePageHandler={activePageHandler}
          reasonOptions={reasonOptions}
        />
      )}
      {activePage === 'ADJUSTMENT SUMMARY' && (
        <AdjustmentSummary
          deleteAdjustmentVariant={deleteAdjustmentVariant}
          adjustmentForm={adjustmentForm.inventories}
          inputChangeHandler={inputChangeHandler}
          activePageHandler={activePageHandler}
          saveNewAdjustments={saveNewAdjustments}
          addNewAdjustment={addNewAdjustment}
          adjustmentSave={adjustmentSave}
        />
      )}

      {activeModal === 'AdjustmentSuccess'
        && (
          <Modal
            modalStatus={activeModal === 'AdjustmentSuccess'}
            modalAction={() => activeModalAction('ADJUSTMENT LIST', 'close')}
            color="success"
            title="Well Done!"
            btn="Success"
            message={(
              <>
                <div>
                  <p>Stock Adjustments Added Successfully</p><br />
                  <span>Total Variants: <b>{adjustmentForm.inventories.length}</b></span><br />
                  <span>Total Quantity: <b>{totalAdjustmentQuantity}</b></span><br /><br />
                  <Button
                    className="rounded"
                    color="success"
                    onClick={() => activeModalAction('ADJUSTMENT LIST', 'close')}
                  >
                    Back to Adjustments
                  </Button>
                </div>
              </>
            )}
          />
        )}
    </div>
  );
}
AdjustmentsInventory.propTypes = {
  fetchMastersAction: PropTypes.func.isRequired,
  adjustmentSave: PropTypes.objectOf.isRequired,
  adjustmentData: PropTypes.arrayOf.isRequired,
  master: PropTypes.objectOf.isRequired,
  saveAdjustmentsAction: PropTypes.func.isRequired,
  fetchAdjustmentsAction: PropTypes.func.isRequired,
  fetchReasonAction: PropTypes.func.isRequired,
  reasonDataState: PropTypes.arrayOf.isRequired,
};

const mapDispatchToProps = {
  fetchMastersAction: fetchMaster,
  fetchAdjustmentsAction: fetchAdjustments,
  saveAdjustmentsAction: saveAdjustments,
  fetchReasonAction: fetchReason,
};
const mapStateToProps = (state) => ({
  master: state.master,
  account: state.account,
  adjustmentSave: state.adjustmentSave,
  adjustmentData: state.adjustmentData,
  reasonDataState: state.reasonData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdjustmentsInventory);
