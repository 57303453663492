import React from 'react';
import PropTypes from 'prop-types';
import InventoryAdjustmentsTables from '../../Tables/AdjustmentsTables/components/InventoryAdjustmentsTable';

export default function AdjustmentsTable({
  adjustmentTableData,
}) {
  return (
    <div className="inventory__adjustments__table">
      <InventoryAdjustmentsTables
        adjustmentsTableRowData={adjustmentTableData}
      />
    </div>
  );
}

AdjustmentsTable.propTypes = {
  adjustmentTableData: PropTypes.arrayOf.isRequired,
};
