import React, { useState, useEffect } from 'react';
import {
  Container,
} from 'reactstrap';
import { toast } from 'react-toastify';
import api from '../../utils/apiClient';
import EODCustomerOrder from '../Tables/BasicTables/components/EODCustomerOrder';
import EODPurchaseOrder from '../Tables/BasicTables/components/EODPurchaseOrder';
import EODCheckout from '../Tables/BasicTables/components/EODCheckout';
import EODReturns from '../Tables/BasicTables/components/EODReturns';
import EODGRN from '../Tables/BasicTables/components/EODGrn';
import EODAdjustments from '../Tables/BasicTables/components/EODAdjustments';

const EOD = () => {
  const [result, setResult] = useState(false);
  const [current, setCurrent] = useState('CustomerOrders');
  /* eslint no-underscore-dangle: 0 */

  const searchFunc = async (e) => {
    setResult(false);
    try {
      const response = await api.reports.eod({ date: e.target.value });
      if (response.data.data) {
        setResult(response.data.data);
      } else {
        throw new Error();
      }
    } catch (err) {
      console.log(err);
      toast.error('Unable to fetch!');
    }
  };

  useEffect(() => {
    console.log(current);
  }, [result, current]);

  return (
    <div className="reports">
      <Container>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',
          gap: '5px',
        }}
        >
          <b>Select Date: </b>
          <form onSubmit={(e) => e.preventDefault()} className="form">
            <input
              name="searchterm"
              type="date"
              component="input"
              // placeholder="Search by Reference Id"
              onChange={(e) => searchFunc(e)}
            />
          </form>
        </div>

        {result
          && (
            <div style={{ display: 'flex', gap: '50px' }}>
              <h4
                role="presentation"
                // className="page-subhead"
                onClick={() => setCurrent('CustomerOrders')}
                style={
                  current === 'CustomerOrders'
                    ? {
                      borderBottom: '2px solid #03a5a5',
                      color: '#03a5a5',
                      fontSize: '16px',
                      fontWeight: '600',
                    }
                    : {
                      cursor: 'pointer',
                      color: 'black',
                      fontSize: '16px',
                      fontWeight: '600',
                    }
                }
              >
                Customer Orders
              </h4>
              <h4
                role="presentation"
                // className="page-subhead"
                onClick={() => setCurrent('PurchaseOrders')}
                style={
                  current === 'PurchaseOrders'
                    ? {
                      borderBottom: '2px solid #03a5a5',
                      color: '#03a5a5',
                      fontSize: '16px',
                      fontWeight: '600',
                    }
                    : {
                      cursor: 'pointer',
                      color: 'black',
                      fontSize: '16px',
                      fontWeight: '600',
                    }
                }
              >
                Purchase Orders
              </h4>
              <h4
                role="presentation"
                // className="page-subhead"
                onClick={() => setCurrent('GRN')}
                style={
                  current === 'GRN'
                    ? {
                      borderBottom: '2px solid #03a5a5',
                      color: '#03a5a5',
                      fontSize: '16px',
                      fontWeight: '600',
                    }
                    : {
                      cursor: 'pointer',
                      color: 'black',
                      fontSize: '16px',
                      fontWeight: '600',
                    }
                }
              >
                GRN
              </h4>
              <h4
                role="presentation"
                // className="page-subhead"
                onClick={() => setCurrent('Gatepass')}
                style={
                  current === 'Gatepass'
                    ? {
                      borderBottom: '2px solid #03a5a5',
                      color: '#03a5a5',
                      fontSize: '16px',
                      fontWeight: '600',
                    }
                    : {
                      cursor: 'pointer',
                      color: 'black',
                      fontSize: '16px',
                      fontWeight: '600',
                    }
                }
              >
                Gatepass
              </h4>
              <h4
                role="presentation"
                // className="page-subhead"
                onClick={() => setCurrent('Adjustments')}
                style={
                  current === 'Adjustments'
                    ? {
                      borderBottom: '2px solid #03a5a5',
                      color: '#03a5a5',
                      fontSize: '16px',
                      fontWeight: '600',
                    }
                    : {
                      cursor: 'pointer',
                      color: 'black',
                      fontSize: '16px',
                      fontWeight: '600',
                    }
                }
              >
                Adjustments
              </h4>
              <h4
                role="presentation"
                // className="page-subhead"
                onClick={() => setCurrent('Returns')}
                style={
                  current === 'Returns'
                    ? {
                      borderBottom: '2px solid #03a5a5',
                      color: '#03a5a5',
                      fontSize: '16px',
                      fontWeight: '600',
                    }
                    : {
                      cursor: 'pointer',
                      color: 'black',
                      fontSize: '16px',
                      fontWeight: '600',
                    }
                }
              >
                Returns
              </h4>
            </div>
          )}

        {(result && current === 'CustomerOrders')
          && (
            <>
              <EODCustomerOrder
                tableRowsData={result.customerOrders}
              />
            </>
          )}

        {(result && current === 'PurchaseOrders')
          && (
            <>
              <EODPurchaseOrder
                tableRowsData={result.purchaseOrders}
              />
            </>
          )}

        {(result && current === 'GRN')
          && (
            <>
              <EODGRN
                tableRowsData={result.grns}
              />
            </>
          )}

        {(result && current === 'Gatepass')
          && (
            <>
              <EODCheckout
                tableRowsData={result.checkouts}
              />
            </>
          )}

        {(result && current === 'Adjustments')
          && (
            <>
              <EODAdjustments
                tableRowsData={result.adjustments}
              />
            </>
          )}

        {(result && current === 'Returns')
          && (
            <>
              <EODReturns
                tableRowsData={result.returns}
              />
            </>
          )}
      </Container>
    </div>
  );
};

export default EOD;
