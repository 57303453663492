import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import _ from 'lodash';

const tableHeaderData = [
  { id: 1, title: 'S.NO' },
  { id: 4, title: 'Lens Type' },
  { id: 5, title: 'Lens Material' },
  { id: 6, title: 'Total Variants' },
  { id: 7, title: 'Total Quantity' },
];

const PurchaseSummaryTable = ({
  tableRowsData = [],
  totalVariantQuantity = 0,
  currentViewDetailsData,
  viewDetailsMode,
  viewSpecificDetails,
  activePageHandler,
}) => {
  console.log(tableRowsData);
  const currentViewDetailsDataLocal = _.cloneDeep(currentViewDetailsData);
  let grandTotalQuantity = 0;
  let grandTotalOrderVariants = 0;

  if (viewDetailsMode) {
    currentViewDetailsDataLocal.groups.map((group, index) => {
      group.variants.map((variant) => {
        currentViewDetailsDataLocal.groups[index].consolidatedVariants = currentViewDetailsDataLocal.groups[index]
          .consolidatedVariants ? currentViewDetailsDataLocal.groups[index].consolidatedVariants + 1 : 1;

        currentViewDetailsDataLocal.groups[index].consolidatedQuantity = group.consolidatedQuantity
          ? group.consolidatedQuantity + Number(variant.Quantity)
          : Number(variant.Quantity);

        grandTotalQuantity += Number(variant.Quantity);
        grandTotalOrderVariants += 1;
        return 1;
      });
      return 1;
    });
  }

  console.log(grandTotalOrderVariants, grandTotalQuantity);

  return (
    <Table hover>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {
          !viewDetailsMode
            ? (
              <tr>
                <td>{1}</td>
                <td>{tableRowsData.lensType ? tableRowsData.lensType.value : '-'}</td>
                <td>{tableRowsData.lensMaterial ? tableRowsData.lensMaterial.value : '-'}</td>
                <td>{totalVariantQuantity || 0}</td>
                <td style={{ color: '#03a5a5' }}>
                  <div role="presentation" onClick={() => activePageHandler('FullVariants')}>
                    View/Update Receipt
                  </div>
                </td>
              </tr>
            ) : (
              currentViewDetailsDataLocal.groups.map((group, index) => (
                <>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{group.LensType || '-'}</td>
                    <td>{group.LensMaterial || '-'}</td>
                    <td>{group.consolidatedVariants || 0}</td>
                    <td>{group.consolidatedQuantity || 0}</td>
                    <td style={{ color: '#03a5a5' }}>
                      <div
                        role="presentation"
                        onClick={() => {
                          viewSpecificDetails('FullVariants', index);
                        }}
                      >
                        View Details
                      </div>
                    </td>
                  </tr>

                  {((currentViewDetailsDataLocal.groups.length - 1) === index)
                    && (
                      <tr role="presentation">
                        <td />
                        <td />
                        <td><b>Grand Total:</b></td>
                        <td><b>{grandTotalOrderVariants}</b></td>
                        <td><b>{grandTotalQuantity}</b></td>
                      </tr>
                    )}
                </>
              ))
            )
        }
      </tbody>
    </Table>
  );
};

PurchaseSummaryTable.propTypes = {
  activePageHandler: PropTypes.func.isRequired,
  tableRowsData: PropTypes.arrayOf.isRequired,
  totalVariantQuantity: PropTypes.number.isRequired,
  currentViewDetailsData: PropTypes.objectOf.isRequired,
  viewDetailsMode: PropTypes.bool.isRequired,
  viewSpecificDetails: PropTypes.func.isRequired,
};

export default PurchaseSummaryTable;
