import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';

const tableHeaderData = [
  { id: 1, title: 'Customer Name' },
  { id: 2, title: 'Phone Number' },
  { id: 3, title: 'Email Address' },
  { id: 4, title: 'State' },
  { id: 5, title: 'City' },
  { id: 6, title: '' },
];

const BasicTable = ({
  tableRowsData = [],
  removeCustomer,
  removeCustomerState,
}) => {
  console.log('List Customer');
  console.log(tableRowsData);
  return (
    <Table responsive hover>
      <thead>
        <tr>
          {tableHeaderData.map((item) => (
            <th key={item.id}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRowsData.map((item) => (
          /* eslint no-underscore-dangle: 0 */
          <tr key={item._id} role="presentation">
            <td>{item.customerName || '-'}</td>
            <td>{item.phoneNumber || '-'}</td>
            <td>{item.emailId || '-'}</td>
            <td>{item.state || '-'}</td>
            <td>{item.city || '-'}</td>
            <td>
              {
                !removeCustomerState.isRemoving
                && (
                  <button
                    onClick={() => removeCustomer(item)}
                    className="smalldiv__table-btn"
                    type="button"
                  >
                    <DeleteForeverIcon />
                  </button>
                )
              }
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

BasicTable.propTypes = {
  tableRowsData: PropTypes.arrayOf.isRequired,
  removeCustomer: PropTypes.func.isRequired,
  removeCustomerState: PropTypes.objectOf.isRequired,
};

export default BasicTable;
