import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import BasicTable from '../../Tables/BasicTables/components/ListPendingReceipt';

const ListPendingReceipt = ({
  grnRowsData,
  viewDetails,
}) => {
  console.log(grnRowsData);
  return (
    <Container>
      <Row>
        <Col md={12} className="smalldiv__listheader">
          <h4>
            Pending
          </h4>
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col md={12}>
          {
            grnRowsData.isFetching
              ? (
                <div style={{ textAlign: 'center' }}>
                  <div className="spinner-border" style={{ color: '#03a5a5' }}>
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <BasicTable
                  tableRowsData={grnRowsData.grnDetails}
                  viewDetails={viewDetails}
                />
              )
          }
        </Col>
      </Row>
    </Container>
  );
};

export default ListPendingReceipt;

ListPendingReceipt.propTypes = {
  grnRowsData: PropTypes.arrayOf.isRequired,
  viewDetails: PropTypes.func.isRequired,
};
