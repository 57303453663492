import React, { useState } from 'react';
import { toast } from 'react-toastify';
import LoginForm from './components/LoginForm';
import api from '../../utils/apiClient';

const LogIn = () => {
  const [loginData, setLoginData] = useState({});
  const [loggingIn, setLoggingIn] = useState(false);

  const onInputChangeHandler = (e, fieldName, type) => {
    let fieldValue;

    if (type === 'select') {
      fieldValue = e;
    }

    if (type === 'textInput') {
      fieldValue = e.target.value;
    }

    const loginDataSetter = { ...loginData, [fieldName]: fieldValue };
    setLoginData(loginDataSetter);
  };

  const login = async () => {
    if (!Number(loginData.phoneNumber)) {
      return toast.error('Phone Number should only contain numbers!');
    }
    if (!loginData.phoneNumber) {
      return toast.error('Phone Number is required!');
    }

    if (loginData.phoneNumber.length !== 10) {
      return toast.error('Phone Number should be 10 digit!');
    }

    if (!loginData.password) {
      return toast.error('Password is required!');
    }
    setLoggingIn(true);
    const formedData = {};
    const signupData = {};

    signupData.phoneNumber = `91${loginData.phoneNumber}`;
    signupData.saveOtp = true;

    formedData.phoneNumber = `91${loginData.phoneNumber}`;
    formedData.password = loginData.password;
    formedData.passwordPresent = true;

    try {
      const signUpInfo = await api.account.signup(signupData);

      if (signUpInfo.data && signUpInfo.data.data && signUpInfo.data.data.passwordPresent) {
        const loginInfo = await api.account.login(formedData);

        if (loginInfo && loginInfo.data && loginInfo.data.data.tokens) {
          localStorage.setItem('oicToken', JSON.stringify(loginInfo.data.data.tokens));
          window.location = `${window.location.origin}/purchase/orders`;
        } else {
          toast.error('Invalid credentials!');
        }
      }
    } catch (err) {
      toast.error('Invalid credentials!');
    }
    setLoggingIn(false);
    return 1;
  };

  return (
    <div className="login">
      <LoginForm
        onInputChangeHandler={onInputChangeHandler}
        loginData={loginData}
        login={login}
        loggingIn={loggingIn}
      />
    </div>
  );
};

export default LogIn;
