import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Container,
  Row,
} from 'reactstrap';

function AddLens({
  onInputChangeHandler,
  activePageHandler,
  inventoryForm,
  lensPowers,
  metaV1Options,
  createdVariants,
  generateVariants,
}) {
  return (
    <Container>
      <Button
        outline
        className="backButton btn btn-sm"
        type="button"
        onClick={() => activePageHandler('ListLens')}
      >
        {' < '}
      </Button>
      <Row>
        <Col md={12}>
          <h4 className="page-title">Add Lens</h4>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <h4 className="page-subhead">Basic Information</h4>
        </Col>
      </Row>
      <form onSubmit={(e) => e.preventDefault()} className="form">
        <Row>
          <Col md={12} lg={3} className="form-group">
            <label htmlFor="lenstype">LENS TYPE *</label>
            <Select
              name="lenstype"
              value={inventoryForm.lensType}
              id="lensType"
              onChange={(e) => onInputChangeHandler(e, 'lensType', 'select')}
              clearable={false}
              className="react-select"
              placeholder="Select"
              classNamePrefix="react-select"
              options={metaV1Options.LensType}
            />
          </Col>
          <Col md={12} lg={3} className="form-group">
            <label htmlFor="lensColor">LENS COLOR</label>
            <Select
              name="lenscolor"
              value={inventoryForm.lensColor}
              id="lenscolor"
              onChange={(e) => onInputChangeHandler(e, 'lensColor', 'select')}
              clearable={false}
              className="react-select"
              placeholder="Select"
              classNamePrefix="react-select"
              options={metaV1Options.LensColor}
            />
          </Col>

          <Col md={12} lg={3} className="form-group">
            <label htmlFor="lenscoating">LENS COATING</label>
            <Select
              name="lenscoating"
              value={inventoryForm.lensCoating}
              id="lenscoating"
              onChange={(e) => onInputChangeHandler(e, 'lensCoating', 'select')}
              clearable={false}
              className="react-select"
              placeholder="Select"
              classNamePrefix="react-select"
              options={metaV1Options.LensCoating}
            />
          </Col>

          <Col md={12} lg={3} className="form-group">
            <label htmlFor="lensmaterial">LENS MATERIAL *</label>
            <Select
              name="lensmaterial"
              value={inventoryForm.lensMaterial}
              id="lensmaterial"
              onChange={(e) => onInputChangeHandler(e, 'lensMaterial', 'select')}
              clearable={false}
              className="react-select"
              placeholder="Select"
              classNamePrefix="react-select"
              options={metaV1Options.LensMaterial}
            />
          </Col>

          <Col md={12} lg={3} className="form-group">
            <label htmlFor="index">INDEX</label>
            <Select
              name="index"
              value={inventoryForm.index}
              id="index"
              onChange={(e) => onInputChangeHandler(e, 'index', 'select')}
              clearable={false}
              className="react-select"
              placeholder="Select"
              classNamePrefix="react-select"
              options={metaV1Options.Index}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h4 className="page-subhead">Prescription Details</h4>
          </Col>
        </Row>
        <Row>
          <Col md={1} className="form-group" />
          <Col md={2} className="form-group">
            <span>SPH</span>
          </Col>
          <Col md={2} className="form-group">
            <span>CYL</span>
          </Col>
          <Col md={2} className="form-group">
            <span>ADD</span>
          </Col>
          <Col md={2} className="form-group">
            <span>AXIS</span>
          </Col>
        </Row>
        <Row>
          <Col md={1} className="form-group">
            <span>START</span>
          </Col>
          <Col md={2} className="form-group">
            <Select
              name=""
              value={inventoryForm.startSph}
              id=""
              options={lensPowers.spherical}
              defaultValue=""
              onChange={(e) => onInputChangeHandler(e, 'startSph', 'select')}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={2} className="form-group">
            <Select
              name=""
              value={inventoryForm.startCyl}
              id=""
              options={lensPowers.cylindrical}
              defaultValue=""
              onChange={(e) => onInputChangeHandler(e, 'startCyl', 'select')}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={2} className="form-group">
            <Select
              name=""
              value={inventoryForm.startAdd}
              id=""
              options={lensPowers.add}
              defaultValue=""
              onChange={(e) => onInputChangeHandler(e, 'startAdd', 'select')}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={2} className="form-group">
            <Select
              name=""
              value={inventoryForm.startAxis}
              id=""
              options={lensPowers.axis}
              defaultValue=""
              onChange={(e) => onInputChangeHandler(e, 'startAxis', 'select')}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
        </Row>
        <Row>
          <Col md={1} className="form-group">
            <span>END</span>
          </Col>
          <Col md={2} className="form-group">
            <Select
              name=""
              value={inventoryForm.endSph}
              id=""
              options={lensPowers.spherical}
              onChange={(e) => onInputChangeHandler(e, 'endSph', 'select')}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={2} className="form-group">
            <Select
              name=""
              value={inventoryForm.endCyl}
              id=""
              options={lensPowers.cylindrical}
              defaultValue=""
              onChange={(e) => onInputChangeHandler(e, 'endCyl', 'select')}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={2} className="form-group">
            <Select
              name=""
              value={inventoryForm.endAdd}
              id=""
              options={lensPowers.add}
              defaultValue=""
              onChange={(e) => onInputChangeHandler(e, 'endAdd', 'select')}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
        </Row>
        <Row>
          <Col md={1} className="form-group">
            <span>INC</span>
          </Col>
          <Col md={2} className="form-group">
            <Select
              name=""
              value={inventoryForm.incSph}
              id=""
              options={lensPowers.incrementOptions}
              onChange={(e) => onInputChangeHandler(e, 'incSph', 'select')}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={2} className="form-group">
            <Select
              name=""
              value={inventoryForm.incCyl}
              id=""
              options={lensPowers.incrementOptions}
              onChange={(e) => onInputChangeHandler(e, 'incCyl', 'select')}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
          <Col md={2} className="form-group">
            <Select
              name=""
              value={inventoryForm.incAdd}
              id=""
              options={lensPowers.incrementOptions}
              defaultValue=""
              onChange={(e) => onInputChangeHandler(e, 'incAdd', 'select')}
              clearable={false}
              className="react-select"
              placeholder=""
              classNamePrefix="react-select"
            />
          </Col>
        </Row>
      </form>
      <Row>
        <Button
          color="success"
          className="rounded"
          onClick={() => generateVariants()}
        >
          Generate Variant
        </Button>
        <Button
          color="success"
          disabled={(createdVariants.length === 0) && true}
          className="rounded"
          onClick={() => activePageHandler('FullVariants')}
        >
          View Variants
        </Button>
      </Row>
    </Container>
  );
}

AddLens.propTypes = {
  onInputChangeHandler: PropTypes.func.isRequired,
  inventoryForm: PropTypes.objectOf.isRequired,
  activePageHandler: PropTypes.func.isRequired,
  lensPowers: PropTypes.objectOf.isRequired,
  metaV1Options: PropTypes.objectOf.isRequired,
  createdVariants: PropTypes.arrayOf.isRequired,
  generateVariants: PropTypes.func.isRequired,
};

export default AddLens;
