import { handleActions } from 'redux-actions';
import {
  saveVendorRequest,
  saveVendorSuccess,
  saveVendorFailure,
  defaultSaveVendor,
  addToVendorData,
  fetchVendorRequest,
  fetchVendorSuccess,
  fetchVendorFailure,
} from '../actions/vendorActions';

const defaultSaveState = {
  isSaving: false,
  error: null,
  vendorDetails: {},
};

const defaultFetchState = {
  isfetching: false,
  error: null,
  vendorDetails: [],
  limit: 500,
  skip: 0,
};
export const vendorSaveReducer = handleActions(
  {
    [saveVendorRequest](state) {
      console.log(state);
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    },
    [saveVendorSuccess](state, { payload }) {
      return {
        ...state,
        vendorDetails: payload,
        isSaving: false,
        error: null,
      };
    },
    [saveVendorFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isSaving: false,
      };
    },
    [defaultSaveVendor](state) {
      return {
        ...state,
        vendorDetails: {},
        error: null,
        isSaving: false,
      };
    },
  },
  defaultSaveState,
);

export const vendorFetchReducer = handleActions(
  {
    [fetchVendorRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchVendorSuccess](state, { payload }) {
      return {
        ...state,
        vendorDetails: payload,
        limit: state.limit,
        skip: state.skip,
        isFetching: false,
        error: null,
      };
    },
    [fetchVendorFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    },
    [addToVendorData](state, { payload }) {
      return {
        ...state,
        vendorDetails: [payload, ...state.vendorDetails],
        error: null,
      };
    },
  },
  defaultFetchState,
);
