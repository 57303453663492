import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import OICReport from './index';
import AddOicReport from './EditReport';

const OicReportRouter = ({ fetchCurrentUserState }) => (
  <Switch>
    <Route
      path="/reports/add"
      component={() => <AddOicReport fetchCurrentUserState={fetchCurrentUserState} />}
    />
    <Route
      path="/reports/:id"
      component={() => <AddOicReport fetchCurrentUserState={fetchCurrentUserState} />}
    />
    <Route
      path="/reports"
      component={() => <OICReport fetchCurrentUserState={fetchCurrentUserState} />}
    />
  </Switch>

);

OicReportRouter.propTypes = {
  fetchCurrentUserState: PropTypes.func.isRequired,
};

export default withRouter(OicReportRouter);
